// Generated by purs bundle 0.14.3
var PS = {};
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Control.Alt"] = $PS["Control.Alt"] || {};
  var exports = $PS["Control.Alt"];                          
  var Alt = function (Functor0, alt) {
      this.Functor0 = Functor0;
      this.alt = alt;
  };                                                       
  var alt = function (dict) {
      return dict.alt;
  };
  exports["Alt"] = Alt;
  exports["alt"] = alt;
})(PS);
(function(exports) {
  "use strict";

  exports.arrayApply = function (fs) {
    return function (xs) {
      var l = fs.length;
      var k = xs.length;
      var result = new Array(l*k);
      var n = 0;
      for (var i = 0; i < l; i++) {
        var f = fs[i];
        for (var j = 0; j < k; j++) {
          result[n++] = f(xs[j]);
        }
      }
      return result;
    };
  };
})(PS["Control.Apply"] = PS["Control.Apply"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Control.Semigroupoid"] = $PS["Control.Semigroupoid"] || {};
  var exports = $PS["Control.Semigroupoid"];
  var Semigroupoid = function (compose) {
      this.compose = compose;
  };
  var semigroupoidFn = new Semigroupoid(function (f) {
      return function (g) {
          return function (x) {
              return f(g(x));
          };
      };
  });
  var compose = function (dict) {
      return dict.compose;
  };
  exports["compose"] = compose;
  exports["semigroupoidFn"] = semigroupoidFn;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Control.Category"] = $PS["Control.Category"] || {};
  var exports = $PS["Control.Category"];
  var Control_Semigroupoid = $PS["Control.Semigroupoid"];                
  var Category = function (Semigroupoid0, identity) {
      this.Semigroupoid0 = Semigroupoid0;
      this.identity = identity;
  };
  var identity = function (dict) {
      return dict.identity;
  };
  var categoryFn = new Category(function () {
      return Control_Semigroupoid.semigroupoidFn;
  }, function (x) {
      return x;
  });
  exports["identity"] = identity;
  exports["categoryFn"] = categoryFn;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Function"] = $PS["Data.Function"] || {};
  var exports = $PS["Data.Function"];
  var flip = function (f) {
      return function (b) {
          return function (a) {
              return f(a)(b);
          };
      };
  };
  var $$const = function (a) {
      return function (v) {
          return a;
      };
  };
  var applyFlipped = function (x) {
      return function (f) {
          return f(x);
      };
  };
  exports["flip"] = flip;
  exports["const"] = $$const;
  exports["applyFlipped"] = applyFlipped;
})(PS);
(function(exports) {
  "use strict";

  exports.arrayMap = function (f) {
    return function (arr) {
      var l = arr.length;
      var result = new Array(l);
      for (var i = 0; i < l; i++) {
        result[i] = f(arr[i]);
      }
      return result;
    };
  };
})(PS["Data.Functor"] = PS["Data.Functor"] || {});
(function(exports) {
  "use strict";

  exports.unit = {};
})(PS["Data.Unit"] = PS["Data.Unit"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Unit"] = $PS["Data.Unit"] || {};
  var exports = $PS["Data.Unit"];
  var $foreign = $PS["Data.Unit"];
  exports["unit"] = $foreign.unit;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Functor"] = $PS["Data.Functor"] || {};
  var exports = $PS["Data.Functor"];
  var $foreign = $PS["Data.Functor"];
  var Control_Semigroupoid = $PS["Control.Semigroupoid"];
  var Data_Function = $PS["Data.Function"];
  var Data_Unit = $PS["Data.Unit"];                  
  var Functor = function (map) {
      this.map = map;
  };
  var map = function (dict) {
      return dict.map;
  };
  var mapFlipped = function (dictFunctor) {
      return function (fa) {
          return function (f) {
              return map(dictFunctor)(f)(fa);
          };
      };
  };
  var $$void = function (dictFunctor) {
      return map(dictFunctor)(Data_Function["const"](Data_Unit.unit));
  };
  var voidLeft = function (dictFunctor) {
      return function (f) {
          return function (x) {
              return map(dictFunctor)(Data_Function["const"](x))(f);
          };
      };
  };
  var voidRight = function (dictFunctor) {
      return function (x) {
          return map(dictFunctor)(Data_Function["const"](x));
      };
  }; 
  var functorFn = new Functor(Control_Semigroupoid.compose(Control_Semigroupoid.semigroupoidFn));
  var functorArray = new Functor($foreign.arrayMap);
  exports["Functor"] = Functor;
  exports["map"] = map;
  exports["mapFlipped"] = mapFlipped;
  exports["void"] = $$void;
  exports["voidRight"] = voidRight;
  exports["voidLeft"] = voidLeft;
  exports["functorFn"] = functorFn;
  exports["functorArray"] = functorArray;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Control.Apply"] = $PS["Control.Apply"] || {};
  var exports = $PS["Control.Apply"];
  var $foreign = $PS["Control.Apply"];
  var Control_Category = $PS["Control.Category"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];            
  var Apply = function (Functor0, apply) {
      this.Functor0 = Functor0;
      this.apply = apply;
  }; 
  var applyFn = new Apply(function () {
      return Data_Functor.functorFn;
  }, function (f) {
      return function (g) {
          return function (x) {
              return f(x)(g(x));
          };
      };
  });
  var applyArray = new Apply(function () {
      return Data_Functor.functorArray;
  }, $foreign.arrayApply);
  var apply = function (dict) {
      return dict.apply;
  };
  var applyFirst = function (dictApply) {
      return function (a) {
          return function (b) {
              return apply(dictApply)(Data_Functor.map(dictApply.Functor0())(Data_Function["const"])(a))(b);
          };
      };
  };
  var applySecond = function (dictApply) {
      return function (a) {
          return function (b) {
              return apply(dictApply)(Data_Functor.map(dictApply.Functor0())(Data_Function["const"](Control_Category.identity(Control_Category.categoryFn)))(a))(b);
          };
      };
  };
  exports["Apply"] = Apply;
  exports["apply"] = apply;
  exports["applyFirst"] = applyFirst;
  exports["applySecond"] = applySecond;
  exports["applyFn"] = applyFn;
  exports["applyArray"] = applyArray;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Control.Applicative"] = $PS["Control.Applicative"] || {};
  var exports = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Data_Unit = $PS["Data.Unit"];                  
  var Applicative = function (Apply0, pure) {
      this.Apply0 = Apply0;
      this.pure = pure;
  };
  var pure = function (dict) {
      return dict.pure;
  };
  var unless = function (dictApplicative) {
      return function (v) {
          return function (v1) {
              if (!v) {
                  return v1;
              };
              if (v) {
                  return pure(dictApplicative)(Data_Unit.unit);
              };
              throw new Error("Failed pattern match at Control.Applicative (line 66, column 1 - line 66, column 65): " + [ v.constructor.name, v1.constructor.name ]);
          };
      };
  };
  var when = function (dictApplicative) {
      return function (v) {
          return function (v1) {
              if (v) {
                  return v1;
              };
              if (!v) {
                  return pure(dictApplicative)(Data_Unit.unit);
              };
              throw new Error("Failed pattern match at Control.Applicative (line 61, column 1 - line 61, column 63): " + [ v.constructor.name, v1.constructor.name ]);
          };
      };
  };
  var liftA1 = function (dictApplicative) {
      return function (f) {
          return function (a) {
              return Control_Apply.apply(dictApplicative.Apply0())(pure(dictApplicative)(f))(a);
          };
      };
  }; 
  var applicativeArray = new Applicative(function () {
      return Control_Apply.applyArray;
  }, function (x) {
      return [ x ];
  });
  exports["Applicative"] = Applicative;
  exports["pure"] = pure;
  exports["liftA1"] = liftA1;
  exports["unless"] = unless;
  exports["when"] = when;
  exports["applicativeArray"] = applicativeArray;
})(PS);
(function(exports) {
  "use strict";

  exports.arrayBind = function (arr) {
    return function (f) {
      var result = [];
      for (var i = 0, l = arr.length; i < l; i++) {
        Array.prototype.push.apply(result, f(arr[i]));
      }
      return result;
    };
  };
})(PS["Control.Bind"] = PS["Control.Bind"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Control.Bind"] = $PS["Control.Bind"] || {};
  var exports = $PS["Control.Bind"];
  var $foreign = $PS["Control.Bind"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Category = $PS["Control.Category"];
  var Data_Function = $PS["Data.Function"];          
  var Bind = function (Apply0, bind) {
      this.Apply0 = Apply0;
      this.bind = bind;
  };
  var Discard = function (discard) {
      this.discard = discard;
  };
  var discard = function (dict) {
      return dict.discard;
  }; 
  var bindArray = new Bind(function () {
      return Control_Apply.applyArray;
  }, $foreign.arrayBind);
  var bind = function (dict) {
      return dict.bind;
  };
  var bindFlipped = function (dictBind) {
      return Data_Function.flip(bind(dictBind));
  };
  var composeKleisliFlipped = function (dictBind) {
      return function (f) {
          return function (g) {
              return function (a) {
                  return bindFlipped(dictBind)(f)(g(a));
              };
          };
      };
  }; 
  var discardUnit = new Discard(function (dictBind) {
      return bind(dictBind);
  });
  var join = function (dictBind) {
      return function (m) {
          return bind(dictBind)(m)(Control_Category.identity(Control_Category.categoryFn));
      };
  };
  exports["Bind"] = Bind;
  exports["bind"] = bind;
  exports["bindFlipped"] = bindFlipped;
  exports["discard"] = discard;
  exports["join"] = join;
  exports["composeKleisliFlipped"] = composeKleisliFlipped;
  exports["bindArray"] = bindArray;
  exports["discardUnit"] = discardUnit;
})(PS);
(function(exports) {
  "use strict";

  var refEq = function (r1) {
    return function (r2) {
      return r1 === r2;
    };
  };

  exports.eqBooleanImpl = refEq;
  exports.eqIntImpl = refEq; 
  exports.eqStringImpl = refEq;

  exports.eqArrayImpl = function (f) {
    return function (xs) {
      return function (ys) {
        if (xs.length !== ys.length) return false;
        for (var i = 0; i < xs.length; i++) {
          if (!f(xs[i])(ys[i])) return false;
        }
        return true;
      };
    };
  };
})(PS["Data.Eq"] = PS["Data.Eq"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Symbol"] = $PS["Data.Symbol"] || {};
  var exports = $PS["Data.Symbol"];
  var IsSymbol = function (reflectSymbol) {
      this.reflectSymbol = reflectSymbol;
  };
  var reflectSymbol = function (dict) {
      return dict.reflectSymbol;
  };
  exports["IsSymbol"] = IsSymbol;
  exports["reflectSymbol"] = reflectSymbol;
})(PS);
(function(exports) {
  "use strict";

  exports.unsafeGet = function (label) {
    return function (rec) {
      return rec[label];
    };
  };

  exports.unsafeSet = function (label) {
    return function (value) {
      return function (rec) {
        var copy = {};
        for (var key in rec) {
          if ({}.hasOwnProperty.call(rec, key)) {
            copy[key] = rec[key];
          }
        }
        copy[label] = value;
        return copy;
      };
    };
  };
})(PS["Record.Unsafe"] = PS["Record.Unsafe"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Record.Unsafe"] = $PS["Record.Unsafe"] || {};
  var exports = $PS["Record.Unsafe"];
  var $foreign = $PS["Record.Unsafe"];
  exports["unsafeGet"] = $foreign.unsafeGet;
  exports["unsafeSet"] = $foreign.unsafeSet;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Type.Proxy"] = $PS["Type.Proxy"] || {};
  var exports = $PS["Type.Proxy"];
  var $$Proxy = (function () {
      function $$Proxy() {

      };
      $$Proxy.value = new $$Proxy();
      return $$Proxy;
  })();
  exports["Proxy"] = $$Proxy;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Eq"] = $PS["Data.Eq"] || {};
  var exports = $PS["Data.Eq"];
  var $foreign = $PS["Data.Eq"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Record_Unsafe = $PS["Record.Unsafe"];
  var Type_Proxy = $PS["Type.Proxy"];                
  var EqRecord = function (eqRecord) {
      this.eqRecord = eqRecord;
  };
  var Eq = function (eq) {
      this.eq = eq;
  };
  var Eq1 = function (eq1) {
      this.eq1 = eq1;
  }; 
  var eqUnit = new Eq(function (v) {
      return function (v1) {
          return true;
      };
  });
  var eqString = new Eq($foreign.eqStringImpl);
  var eqRowNil = new EqRecord(function (v) {
      return function (v1) {
          return function (v2) {
              return true;
          };
      };
  });
  var eqRecord = function (dict) {
      return dict.eqRecord;
  };
  var eqRec = function (dictRowToList) {
      return function (dictEqRecord) {
          return new Eq(eqRecord(dictEqRecord)(Type_Proxy["Proxy"].value));
      };
  };                                           
  var eqInt = new Eq($foreign.eqIntImpl);  
  var eqBoolean = new Eq($foreign.eqBooleanImpl);
  var eq1 = function (dict) {
      return dict.eq1;
  };
  var eq = function (dict) {
      return dict.eq;
  };
  var eqArray = function (dictEq) {
      return new Eq($foreign.eqArrayImpl(eq(dictEq)));
  }; 
  var eqRowCons = function (dictEqRecord) {
      return function (dictCons) {
          return function (dictIsSymbol) {
              return function (dictEq) {
                  return new EqRecord(function (v) {
                      return function (ra) {
                          return function (rb) {
                              var tail = eqRecord(dictEqRecord)(Type_Proxy["Proxy"].value)(ra)(rb);
                              var key = Data_Symbol.reflectSymbol(dictIsSymbol)(Type_Proxy["Proxy"].value);
                              var get = Record_Unsafe.unsafeGet(key);
                              return eq(dictEq)(get(ra))(get(rb)) && tail;
                          };
                      };
                  });
              };
          };
      };
  };
  var notEq = function (dictEq) {
      return function (x) {
          return function (y) {
              return eq(eqBoolean)(eq(dictEq)(x)(y))(false);
          };
      };
  };
  exports["Eq"] = Eq;
  exports["eq"] = eq;
  exports["notEq"] = notEq;
  exports["Eq1"] = Eq1;
  exports["eq1"] = eq1;
  exports["eqInt"] = eqInt;
  exports["eqString"] = eqString;
  exports["eqUnit"] = eqUnit;
  exports["eqArray"] = eqArray;
  exports["eqRec"] = eqRec;
  exports["eqRowNil"] = eqRowNil;
  exports["eqRowCons"] = eqRowCons;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Maybe"] = $PS["Data.Maybe"] || {};
  var exports = $PS["Data.Maybe"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Category = $PS["Control.Category"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];          
  var Nothing = (function () {
      function Nothing() {

      };
      Nothing.value = new Nothing();
      return Nothing;
  })();
  var Just = (function () {
      function Just(value0) {
          this.value0 = value0;
      };
      Just.create = function (value0) {
          return new Just(value0);
      };
      return Just;
  })();
  var maybe = function (v) {
      return function (v1) {
          return function (v2) {
              if (v2 instanceof Nothing) {
                  return v;
              };
              if (v2 instanceof Just) {
                  return v1(v2.value0);
              };
              throw new Error("Failed pattern match at Data.Maybe (line 230, column 1 - line 230, column 51): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
          };
      };
  };
  var isNothing = maybe(true)(Data_Function["const"](false));
  var isJust = maybe(false)(Data_Function["const"](true));
  var functorMaybe = new Data_Functor.Functor(function (v) {
      return function (v1) {
          if (v1 instanceof Just) {
              return new Just(v(v1.value0));
          };
          return Nothing.value;
      };
  });
  var fromMaybe = function (a) {
      return maybe(a)(Control_Category.identity(Control_Category.categoryFn));
  };
  var fromJust = function (dictPartial) {
      return function (v) {
          if (v instanceof Just) {
              return v.value0;
          };
          throw new Error("Failed pattern match at Data.Maybe (line 281, column 1 - line 281, column 46): " + [ v.constructor.name ]);
      };
  }; 
  var eqMaybe = function (dictEq) {
      return new Data_Eq.Eq(function (x) {
          return function (y) {
              if (x instanceof Nothing && y instanceof Nothing) {
                  return true;
              };
              if (x instanceof Just && y instanceof Just) {
                  return Data_Eq.eq(dictEq)(x.value0)(y.value0);
              };
              return false;
          };
      });
  };
  var applyMaybe = new Control_Apply.Apply(function () {
      return functorMaybe;
  }, function (v) {
      return function (v1) {
          if (v instanceof Just) {
              return Data_Functor.map(functorMaybe)(v.value0)(v1);
          };
          if (v instanceof Nothing) {
              return Nothing.value;
          };
          throw new Error("Failed pattern match at Data.Maybe (line 68, column 1 - line 70, column 30): " + [ v.constructor.name, v1.constructor.name ]);
      };
  });
  var bindMaybe = new Control_Bind.Bind(function () {
      return applyMaybe;
  }, function (v) {
      return function (v1) {
          if (v instanceof Just) {
              return v1(v.value0);
          };
          if (v instanceof Nothing) {
              return Nothing.value;
          };
          throw new Error("Failed pattern match at Data.Maybe (line 126, column 1 - line 128, column 28): " + [ v.constructor.name, v1.constructor.name ]);
      };
  });
  exports["Nothing"] = Nothing;
  exports["Just"] = Just;
  exports["maybe"] = maybe;
  exports["fromMaybe"] = fromMaybe;
  exports["isJust"] = isJust;
  exports["isNothing"] = isNothing;
  exports["fromJust"] = fromJust;
  exports["functorMaybe"] = functorMaybe;
  exports["bindMaybe"] = bindMaybe;
  exports["eqMaybe"] = eqMaybe;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Either"] = $PS["Data.Either"] || {};
  var exports = $PS["Data.Either"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];              
  var Left = (function () {
      function Left(value0) {
          this.value0 = value0;
      };
      Left.create = function (value0) {
          return new Left(value0);
      };
      return Left;
  })();
  var Right = (function () {
      function Right(value0) {
          this.value0 = value0;
      };
      Right.create = function (value0) {
          return new Right(value0);
      };
      return Right;
  })();
  var functorEither = new Data_Functor.Functor(function (f) {
      return function (m) {
          if (m instanceof Left) {
              return new Left(m.value0);
          };
          if (m instanceof Right) {
              return new Right(f(m.value0));
          };
          throw new Error("Failed pattern match at Data.Either (line 31, column 1 - line 31, column 52): " + [ m.constructor.name ]);
      };
  });
  var either = function (v) {
      return function (v1) {
          return function (v2) {
              if (v2 instanceof Left) {
                  return v(v2.value0);
              };
              if (v2 instanceof Right) {
                  return v1(v2.value0);
              };
              throw new Error("Failed pattern match at Data.Either (line 208, column 1 - line 208, column 64): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
          };
      };
  };
  var hush = either(Data_Function["const"](Data_Maybe.Nothing.value))(Data_Maybe.Just.create);
  var applyEither = new Control_Apply.Apply(function () {
      return functorEither;
  }, function (v) {
      return function (v1) {
          if (v instanceof Left) {
              return new Left(v.value0);
          };
          if (v instanceof Right) {
              return Data_Functor.map(functorEither)(v.value0)(v1);
          };
          throw new Error("Failed pattern match at Data.Either (line 70, column 1 - line 72, column 30): " + [ v.constructor.name, v1.constructor.name ]);
      };
  });
  var bindEither = new Control_Bind.Bind(function () {
      return applyEither;
  }, either(function (e) {
      return function (v) {
          return new Left(e);
      };
  })(function (a) {
      return function (f) {
          return f(a);
      };
  }));
  exports["Left"] = Left;
  exports["Right"] = Right;
  exports["either"] = either;
  exports["hush"] = hush;
  exports["bindEither"] = bindEither;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Control.Plus"] = $PS["Control.Plus"] || {};
  var exports = $PS["Control.Plus"];                     
  var Plus = function (Alt0, empty) {
      this.Alt0 = Alt0;
      this.empty = empty;
  };       
  var empty = function (dict) {
      return dict.empty;
  };
  exports["Plus"] = Plus;
  exports["empty"] = empty;
})(PS);
(function(exports) {
  "use strict";

  exports.foldrArray = function (f) {
    return function (init) {
      return function (xs) {
        var acc = init;
        var len = xs.length;
        for (var i = len - 1; i >= 0; i--) {
          acc = f(xs[i])(acc);
        }
        return acc;
      };
    };
  };

  exports.foldlArray = function (f) {
    return function (init) {
      return function (xs) {
        var acc = init;
        var len = xs.length;
        for (var i = 0; i < len; i++) {
          acc = f(acc)(xs[i]);
        }
        return acc;
      };
    };
  };
})(PS["Data.Foldable"] = PS["Data.Foldable"] || {});
(function(exports) {
  "use strict";

  exports.boolConj = function (b1) {
    return function (b2) {
      return b1 && b2;
    };
  };

  exports.boolDisj = function (b1) {
    return function (b2) {
      return b1 || b2;
    };
  };

  exports.boolNot = function (b) {
    return !b;
  };
})(PS["Data.HeytingAlgebra"] = PS["Data.HeytingAlgebra"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.HeytingAlgebra"] = $PS["Data.HeytingAlgebra"] || {};
  var exports = $PS["Data.HeytingAlgebra"];
  var $foreign = $PS["Data.HeytingAlgebra"];
  var HeytingAlgebra = function (conj, disj, ff, implies, not, tt) {
      this.conj = conj;
      this.disj = disj;
      this.ff = ff;
      this.implies = implies;
      this.not = not;
      this.tt = tt;
  };
  var tt = function (dict) {
      return dict.tt;
  };
  var not = function (dict) {
      return dict.not;
  };
  var implies = function (dict) {
      return dict.implies;
  };
  var ff = function (dict) {
      return dict.ff;
  };
  var disj = function (dict) {
      return dict.disj;
  };
  var heytingAlgebraBoolean = new HeytingAlgebra($foreign.boolConj, $foreign.boolDisj, false, function (a) {
      return function (b) {
          return disj(heytingAlgebraBoolean)(not(heytingAlgebraBoolean)(a))(b);
      };
  }, $foreign.boolNot, true);
  var conj = function (dict) {
      return dict.conj;
  };
  var heytingAlgebraFunction = function (dictHeytingAlgebra) {
      return new HeytingAlgebra(function (f) {
          return function (g) {
              return function (a) {
                  return conj(dictHeytingAlgebra)(f(a))(g(a));
              };
          };
      }, function (f) {
          return function (g) {
              return function (a) {
                  return disj(dictHeytingAlgebra)(f(a))(g(a));
              };
          };
      }, function (v) {
          return ff(dictHeytingAlgebra);
      }, function (f) {
          return function (g) {
              return function (a) {
                  return implies(dictHeytingAlgebra)(f(a))(g(a));
              };
          };
      }, function (f) {
          return function (a) {
              return not(dictHeytingAlgebra)(f(a));
          };
      }, function (v) {
          return tt(dictHeytingAlgebra);
      });
  };
  exports["ff"] = ff;
  exports["disj"] = disj;
  exports["not"] = not;
  exports["heytingAlgebraBoolean"] = heytingAlgebraBoolean;
  exports["heytingAlgebraFunction"] = heytingAlgebraFunction;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Monoid"] = $PS["Data.Monoid"] || {};
  var exports = $PS["Data.Monoid"];
  var Monoid = function (Semigroup0, mempty) {
      this.Semigroup0 = Semigroup0;
      this.mempty = mempty;
  };
  var mempty = function (dict) {
      return dict.mempty;
  };
  exports["Monoid"] = Monoid;
  exports["mempty"] = mempty;
})(PS);
(function(exports) {
  "use strict";

  exports.concatArray = function (xs) {
    return function (ys) {
      if (xs.length === 0) return ys;
      if (ys.length === 0) return xs;
      return xs.concat(ys);
    };
  };
})(PS["Data.Semigroup"] = PS["Data.Semigroup"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Semigroup"] = $PS["Data.Semigroup"] || {};
  var exports = $PS["Data.Semigroup"];
  var $foreign = $PS["Data.Semigroup"];
  var Semigroup = function (append) {
      this.append = append;
  }; 
  var semigroupArray = new Semigroup($foreign.concatArray);
  var append = function (dict) {
      return dict.append;
  };
  exports["Semigroup"] = Semigroup;
  exports["append"] = append;
  exports["semigroupArray"] = semigroupArray;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Monoid.Disj"] = $PS["Data.Monoid.Disj"] || {};
  var exports = $PS["Data.Monoid.Disj"];
  var Data_HeytingAlgebra = $PS["Data.HeytingAlgebra"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Semigroup = $PS["Data.Semigroup"];      
  var Disj = function (x) {
      return x;
  };
  var semigroupDisj = function (dictHeytingAlgebra) {
      return new Data_Semigroup.Semigroup(function (v) {
          return function (v1) {
              return Data_HeytingAlgebra.disj(dictHeytingAlgebra)(v)(v1);
          };
      });
  };
  var monoidDisj = function (dictHeytingAlgebra) {
      return new Data_Monoid.Monoid(function () {
          return semigroupDisj(dictHeytingAlgebra);
      }, Data_HeytingAlgebra.ff(dictHeytingAlgebra));
  };
  exports["Disj"] = Disj;
  exports["monoidDisj"] = monoidDisj;
})(PS);
(function(exports) {
  "use strict";

  // module Unsafe.Coerce

  exports.unsafeCoerce = function (x) {
    return x;
  };
})(PS["Unsafe.Coerce"] = PS["Unsafe.Coerce"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Unsafe.Coerce"] = $PS["Unsafe.Coerce"] || {};
  var exports = $PS["Unsafe.Coerce"];
  var $foreign = $PS["Unsafe.Coerce"];
  exports["unsafeCoerce"] = $foreign.unsafeCoerce;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Safe.Coerce"] = $PS["Safe.Coerce"] || {};
  var exports = $PS["Safe.Coerce"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];                
  var coerce = function (dictCoercible) {
      return Unsafe_Coerce.unsafeCoerce;
  };
  exports["coerce"] = coerce;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Newtype"] = $PS["Data.Newtype"] || {};
  var exports = $PS["Data.Newtype"];
  var Safe_Coerce = $PS["Safe.Coerce"];
  var unwrap = function (dictNewtype) {
      return Safe_Coerce.coerce();
  };
  var over = function (dictNewtype) {
      return function (dictNewtype1) {
          return function (v) {
              return Safe_Coerce.coerce();
          };
      };
  };
  var alaF = function (dictCoercible) {
      return function (dictCoercible1) {
          return function (dictNewtype) {
              return function (dictNewtype1) {
                  return function (v) {
                      return Safe_Coerce.coerce();
                  };
              };
          };
      };
  };
  exports["unwrap"] = unwrap;
  exports["alaF"] = alaF;
  exports["over"] = over;
})(PS);
(function(exports) {
  "use strict";

  var unsafeCompareImpl = function (lt) {
    return function (eq) {
      return function (gt) {
        return function (x) {
          return function (y) {
            return x < y ? lt : x === y ? eq : gt;
          };
        };
      };
    };
  };                                         
  exports.ordIntImpl = unsafeCompareImpl;   
  exports.ordStringImpl = unsafeCompareImpl;
})(PS["Data.Ord"] = PS["Data.Ord"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Ordering"] = $PS["Data.Ordering"] || {};
  var exports = $PS["Data.Ordering"];
  var Data_Eq = $PS["Data.Eq"];                    
  var LT = (function () {
      function LT() {

      };
      LT.value = new LT();
      return LT;
  })();
  var GT = (function () {
      function GT() {

      };
      GT.value = new GT();
      return GT;
  })();
  var EQ = (function () {
      function EQ() {

      };
      EQ.value = new EQ();
      return EQ;
  })();
  var eqOrdering = new Data_Eq.Eq(function (v) {
      return function (v1) {
          if (v instanceof LT && v1 instanceof LT) {
              return true;
          };
          if (v instanceof GT && v1 instanceof GT) {
              return true;
          };
          if (v instanceof EQ && v1 instanceof EQ) {
              return true;
          };
          return false;
      };
  });
  exports["LT"] = LT;
  exports["GT"] = GT;
  exports["EQ"] = EQ;
  exports["eqOrdering"] = eqOrdering;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Ord"] = $PS["Data.Ord"] || {};
  var exports = $PS["Data.Ord"];
  var $foreign = $PS["Data.Ord"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Ordering = $PS["Data.Ordering"];
  var Ord = function (Eq0, compare) {
      this.Eq0 = Eq0;
      this.compare = compare;
  };
  var Ord1 = function (Eq10, compare1) {
      this.Eq10 = Eq10;
      this.compare1 = compare1;
  }; 
  var ordUnit = new Ord(function () {
      return Data_Eq.eqUnit;
  }, function (v) {
      return function (v1) {
          return Data_Ordering.EQ.value;
      };
  });
  var ordString = new Ord(function () {
      return Data_Eq.eqString;
  }, $foreign.ordStringImpl(Data_Ordering.LT.value)(Data_Ordering.EQ.value)(Data_Ordering.GT.value));
  var ordInt = new Ord(function () {
      return Data_Eq.eqInt;
  }, $foreign.ordIntImpl(Data_Ordering.LT.value)(Data_Ordering.EQ.value)(Data_Ordering.GT.value));
  var compare1 = function (dict) {
      return dict.compare1;
  };
  var compare = function (dict) {
      return dict.compare;
  };
  exports["Ord"] = Ord;
  exports["compare"] = compare;
  exports["Ord1"] = Ord1;
  exports["compare1"] = compare1;
  exports["ordInt"] = ordInt;
  exports["ordString"] = ordString;
  exports["ordUnit"] = ordUnit;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Foldable"] = $PS["Data.Foldable"] || {};
  var exports = $PS["Data.Foldable"];
  var $foreign = $PS["Data.Foldable"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Data_Either = $PS["Data.Either"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Function = $PS["Data.Function"];
  var Data_HeytingAlgebra = $PS["Data.HeytingAlgebra"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Monoid_Disj = $PS["Data.Monoid.Disj"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Ordering = $PS["Data.Ordering"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_Unit = $PS["Data.Unit"];                
  var Foldable = function (foldMap, foldl, foldr) {
      this.foldMap = foldMap;
      this.foldl = foldl;
      this.foldr = foldr;
  };
  var foldr = function (dict) {
      return dict.foldr;
  };
  var traverse_ = function (dictApplicative) {
      return function (dictFoldable) {
          return function (f) {
              return foldr(dictFoldable)((function () {
                  var $313 = Control_Apply.applySecond(dictApplicative.Apply0());
                  return function ($314) {
                      return $313(f($314));
                  };
              })())(Control_Applicative.pure(dictApplicative)(Data_Unit.unit));
          };
      };
  };
  var for_ = function (dictApplicative) {
      return function (dictFoldable) {
          return Data_Function.flip(traverse_(dictApplicative)(dictFoldable));
      };
  };
  var foldl = function (dict) {
      return dict.foldl;
  };
  var indexl = function (dictFoldable) {
      return function (idx) {
          var go = function (cursor) {
              return function (a) {
                  if (cursor.elem instanceof Data_Maybe.Just) {
                      return cursor;
                  };
                  var $155 = cursor.pos === idx;
                  if ($155) {
                      return {
                          elem: new Data_Maybe.Just(a),
                          pos: cursor.pos
                      };
                  };
                  return {
                      pos: cursor.pos + 1 | 0,
                      elem: cursor.elem
                  };
              };
          };
          var $315 = foldl(dictFoldable)(go)({
              elem: Data_Maybe.Nothing.value,
              pos: 0
          });
          return function ($316) {
              return (function (v) {
                  return v.elem;
              })($315($316));
          };
      };
  };
  var maximumBy = function (dictFoldable) {
      return function (cmp) {
          var max$prime = function (v) {
              return function (v1) {
                  if (v instanceof Data_Maybe.Nothing) {
                      return new Data_Maybe.Just(v1);
                  };
                  if (v instanceof Data_Maybe.Just) {
                      return new Data_Maybe.Just((function () {
                          var $162 = Data_Eq.eq(Data_Ordering.eqOrdering)(cmp(v.value0)(v1))(Data_Ordering.GT.value);
                          if ($162) {
                              return v.value0;
                          };
                          return v1;
                      })());
                  };
                  throw new Error("Failed pattern match at Data.Foldable (line 441, column 3 - line 441, column 27): " + [ v.constructor.name, v1.constructor.name ]);
              };
          };
          return foldl(dictFoldable)(max$prime)(Data_Maybe.Nothing.value);
      };
  };
  var maximum = function (dictOrd) {
      return function (dictFoldable) {
          return maximumBy(dictFoldable)(Data_Ord.compare(dictOrd));
      };
  };
  var minimumBy = function (dictFoldable) {
      return function (cmp) {
          var min$prime = function (v) {
              return function (v1) {
                  if (v instanceof Data_Maybe.Nothing) {
                      return new Data_Maybe.Just(v1);
                  };
                  if (v instanceof Data_Maybe.Just) {
                      return new Data_Maybe.Just((function () {
                          var $166 = Data_Eq.eq(Data_Ordering.eqOrdering)(cmp(v.value0)(v1))(Data_Ordering.LT.value);
                          if ($166) {
                              return v.value0;
                          };
                          return v1;
                      })());
                  };
                  throw new Error("Failed pattern match at Data.Foldable (line 454, column 3 - line 454, column 27): " + [ v.constructor.name, v1.constructor.name ]);
              };
          };
          return foldl(dictFoldable)(min$prime)(Data_Maybe.Nothing.value);
      };
  };
  var minimum = function (dictOrd) {
      return function (dictFoldable) {
          return minimumBy(dictFoldable)(Data_Ord.compare(dictOrd));
      };
  }; 
  var foldableMaybe = new Foldable(function (dictMonoid) {
      return function (v) {
          return function (v1) {
              if (v1 instanceof Data_Maybe.Nothing) {
                  return Data_Monoid.mempty(dictMonoid);
              };
              if (v1 instanceof Data_Maybe.Just) {
                  return v(v1.value0);
              };
              throw new Error("Failed pattern match at Data.Foldable (line 138, column 1 - line 144, column 27): " + [ v.constructor.name, v1.constructor.name ]);
          };
      };
  }, function (v) {
      return function (z) {
          return function (v1) {
              if (v1 instanceof Data_Maybe.Nothing) {
                  return z;
              };
              if (v1 instanceof Data_Maybe.Just) {
                  return v(z)(v1.value0);
              };
              throw new Error("Failed pattern match at Data.Foldable (line 138, column 1 - line 144, column 27): " + [ v.constructor.name, z.constructor.name, v1.constructor.name ]);
          };
      };
  }, function (v) {
      return function (z) {
          return function (v1) {
              if (v1 instanceof Data_Maybe.Nothing) {
                  return z;
              };
              if (v1 instanceof Data_Maybe.Just) {
                  return v(v1.value0)(z);
              };
              throw new Error("Failed pattern match at Data.Foldable (line 138, column 1 - line 144, column 27): " + [ v.constructor.name, z.constructor.name, v1.constructor.name ]);
          };
      };
  });
  var foldableEither = new Foldable(function (dictMonoid) {
      return function (v) {
          return function (v1) {
              if (v1 instanceof Data_Either.Left) {
                  return Data_Monoid.mempty(dictMonoid);
              };
              if (v1 instanceof Data_Either.Right) {
                  return v(v1.value0);
              };
              throw new Error("Failed pattern match at Data.Foldable (line 181, column 1 - line 187, column 28): " + [ v.constructor.name, v1.constructor.name ]);
          };
      };
  }, function (v) {
      return function (z) {
          return function (v1) {
              if (v1 instanceof Data_Either.Left) {
                  return z;
              };
              if (v1 instanceof Data_Either.Right) {
                  return v(z)(v1.value0);
              };
              throw new Error("Failed pattern match at Data.Foldable (line 181, column 1 - line 187, column 28): " + [ v.constructor.name, z.constructor.name, v1.constructor.name ]);
          };
      };
  }, function (v) {
      return function (z) {
          return function (v1) {
              if (v1 instanceof Data_Either.Left) {
                  return z;
              };
              if (v1 instanceof Data_Either.Right) {
                  return v(v1.value0)(z);
              };
              throw new Error("Failed pattern match at Data.Foldable (line 181, column 1 - line 187, column 28): " + [ v.constructor.name, z.constructor.name, v1.constructor.name ]);
          };
      };
  });
  var foldMapDefaultR = function (dictFoldable) {
      return function (dictMonoid) {
          return function (f) {
              return foldr(dictFoldable)(function (x) {
                  return function (acc) {
                      return Data_Semigroup.append(dictMonoid.Semigroup0())(f(x))(acc);
                  };
              })(Data_Monoid.mempty(dictMonoid));
          };
      };
  };
  var foldableArray = new Foldable(function (dictMonoid) {
      return foldMapDefaultR(foldableArray)(dictMonoid);
  }, $foreign.foldlArray, $foreign.foldrArray);
  var foldMap = function (dict) {
      return dict.foldMap;
  };
  var any = function (dictFoldable) {
      return function (dictHeytingAlgebra) {
          return Data_Newtype.alaF()()()()(Data_Monoid_Disj.Disj)(foldMap(dictFoldable)(Data_Monoid_Disj.monoidDisj(dictHeytingAlgebra)));
      };
  };
  var elem = function (dictFoldable) {
      return function (dictEq) {
          var $323 = any(dictFoldable)(Data_HeytingAlgebra.heytingAlgebraBoolean);
          var $324 = Data_Eq.eq(dictEq);
          return function ($325) {
              return $323($324($325));
          };
      };
  };
  exports["Foldable"] = Foldable;
  exports["foldr"] = foldr;
  exports["foldl"] = foldl;
  exports["foldMap"] = foldMap;
  exports["traverse_"] = traverse_;
  exports["for_"] = for_;
  exports["indexl"] = indexl;
  exports["maximum"] = maximum;
  exports["minimum"] = minimum;
  exports["foldableArray"] = foldableArray;
  exports["foldableMaybe"] = foldableMaybe;
  exports["foldableEither"] = foldableEither;
})(PS);
(function(exports) {
  "use strict";

  exports.unfoldrArrayImpl = function (isNothing) {
    return function (fromJust) {
      return function (fst) {
        return function (snd) {
          return function (f) {
            return function (b) {
              var result = [];
              var value = b;
              while (true) { // eslint-disable-line no-constant-condition
                var maybe = f(value);
                if (isNothing(maybe)) return result;
                var tuple = fromJust(maybe);
                result.push(fst(tuple));
                value = snd(tuple);
              }
            };
          };
        };
      };
    };
  };
})(PS["Data.Unfoldable"] = PS["Data.Unfoldable"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Tuple"] = $PS["Data.Tuple"] || {};
  var exports = $PS["Data.Tuple"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Ordering = $PS["Data.Ordering"];        
  var Tuple = (function () {
      function Tuple(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Tuple.create = function (value0) {
          return function (value1) {
              return new Tuple(value0, value1);
          };
      };
      return Tuple;
  })();
  var uncurry = function (f) {
      return function (v) {
          return f(v.value0)(v.value1);
      };
  };
  var swap = function (v) {
      return new Tuple(v.value1, v.value0);
  };
  var snd = function (v) {
      return v.value1;
  }; 
  var functorTuple = new Data_Functor.Functor(function (f) {
      return function (m) {
          return new Tuple(m.value0, f(m.value1));
      };
  });                                                                                                   
  var fst = function (v) {
      return v.value0;
  }; 
  var eqTuple = function (dictEq) {
      return function (dictEq1) {
          return new Data_Eq.Eq(function (x) {
              return function (y) {
                  return Data_Eq.eq(dictEq)(x.value0)(y.value0) && Data_Eq.eq(dictEq1)(x.value1)(y.value1);
              };
          });
      };
  };
  var ordTuple = function (dictOrd) {
      return function (dictOrd1) {
          return new Data_Ord.Ord(function () {
              return eqTuple(dictOrd.Eq0())(dictOrd1.Eq0());
          }, function (x) {
              return function (y) {
                  var v = Data_Ord.compare(dictOrd)(x.value0)(y.value0);
                  if (v instanceof Data_Ordering.LT) {
                      return Data_Ordering.LT.value;
                  };
                  if (v instanceof Data_Ordering.GT) {
                      return Data_Ordering.GT.value;
                  };
                  return Data_Ord.compare(dictOrd1)(x.value1)(y.value1);
              };
          });
      };
  };
  exports["Tuple"] = Tuple;
  exports["fst"] = fst;
  exports["snd"] = snd;
  exports["uncurry"] = uncurry;
  exports["swap"] = swap;
  exports["eqTuple"] = eqTuple;
  exports["ordTuple"] = ordTuple;
  exports["functorTuple"] = functorTuple;
})(PS);
(function(exports) {
  "use strict";

  exports.unfoldr1ArrayImpl = function (isNothing) {
    return function (fromJust) {
      return function (fst) {
        return function (snd) {
          return function (f) {
            return function (b) {
              var result = [];
              var value = b;
              while (true) { // eslint-disable-line no-constant-condition
                var tuple = f(value);
                result.push(fst(tuple));
                var maybe = snd(tuple);
                if (isNothing(maybe)) return result;
                value = fromJust(maybe);
              }
            };
          };
        };
      };
    };
  };
})(PS["Data.Unfoldable1"] = PS["Data.Unfoldable1"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Unfoldable1"] = $PS["Data.Unfoldable1"] || {};
  var exports = $PS["Data.Unfoldable1"];
  var $foreign = $PS["Data.Unfoldable1"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Tuple = $PS["Data.Tuple"];                
  var Unfoldable1 = function (unfoldr1) {
      this.unfoldr1 = unfoldr1;
  }; 
  var unfoldable1Array = new Unfoldable1($foreign.unfoldr1ArrayImpl(Data_Maybe.isNothing)(Data_Maybe.fromJust())(Data_Tuple.fst)(Data_Tuple.snd));
  exports["Unfoldable1"] = Unfoldable1;
  exports["unfoldable1Array"] = unfoldable1Array;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Unfoldable"] = $PS["Data.Unfoldable"] || {};
  var exports = $PS["Data.Unfoldable"];
  var $foreign = $PS["Data.Unfoldable"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Data_Unfoldable1 = $PS["Data.Unfoldable1"];  
  var Unfoldable = function (Unfoldable10, unfoldr) {
      this.Unfoldable10 = Unfoldable10;
      this.unfoldr = unfoldr;
  };
  var unfoldr = function (dict) {
      return dict.unfoldr;
  }; 
  var unfoldableArray = new Unfoldable(function () {
      return Data_Unfoldable1.unfoldable1Array;
  }, $foreign.unfoldrArrayImpl(Data_Maybe.isNothing)(Data_Maybe.fromJust())(Data_Tuple.fst)(Data_Tuple.snd));
  exports["Unfoldable"] = Unfoldable;
  exports["unfoldr"] = unfoldr;
  exports["unfoldableArray"] = unfoldableArray;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.List.Types"] = $PS["Data.List.Types"] || {};
  var exports = $PS["Data.List.Types"];
  var Control_Alt = $PS["Control.Alt"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Plus = $PS["Control.Plus"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Ordering = $PS["Data.Ordering"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_Unfoldable = $PS["Data.Unfoldable"];
  var Data_Unfoldable1 = $PS["Data.Unfoldable1"];                
  var Nil = (function () {
      function Nil() {

      };
      Nil.value = new Nil();
      return Nil;
  })();
  var Cons = (function () {
      function Cons(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Cons.create = function (value0) {
          return function (value1) {
              return new Cons(value0, value1);
          };
      };
      return Cons;
  })();
  var NonEmptyList = function (x) {
      return x;
  };
  var listMap = function (f) {
      var chunkedRevMap = function ($copy_chunksAcc) {
          return function ($copy_v) {
              var $tco_var_chunksAcc = $copy_chunksAcc;
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(chunksAcc, v) {
                  if (v instanceof Cons && (v.value1 instanceof Cons && v.value1.value1 instanceof Cons)) {
                      $tco_var_chunksAcc = new Cons(v, chunksAcc);
                      $copy_v = v.value1.value1.value1;
                      return;
                  };
                  var unrolledMap = function (v1) {
                      if (v1 instanceof Cons && (v1.value1 instanceof Cons && v1.value1.value1 instanceof Nil)) {
                          return new Cons(f(v1.value0), new Cons(f(v1.value1.value0), Nil.value));
                      };
                      if (v1 instanceof Cons && v1.value1 instanceof Nil) {
                          return new Cons(f(v1.value0), Nil.value);
                      };
                      return Nil.value;
                  };
                  var reverseUnrolledMap = function ($copy_v1) {
                      return function ($copy_acc) {
                          var $tco_var_v1 = $copy_v1;
                          var $tco_done1 = false;
                          var $tco_result;
                          function $tco_loop(v1, acc) {
                              if (v1 instanceof Cons && (v1.value0 instanceof Cons && (v1.value0.value1 instanceof Cons && v1.value0.value1.value1 instanceof Cons))) {
                                  $tco_var_v1 = v1.value1;
                                  $copy_acc = new Cons(f(v1.value0.value0), new Cons(f(v1.value0.value1.value0), new Cons(f(v1.value0.value1.value1.value0), acc)));
                                  return;
                              };
                              $tco_done1 = true;
                              return acc;
                          };
                          while (!$tco_done1) {
                              $tco_result = $tco_loop($tco_var_v1, $copy_acc);
                          };
                          return $tco_result;
                      };
                  };
                  $tco_done = true;
                  return reverseUnrolledMap(chunksAcc)(unrolledMap(v));
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($tco_var_chunksAcc, $copy_v);
              };
              return $tco_result;
          };
      };
      return chunkedRevMap(Nil.value);
  };
  var functorList = new Data_Functor.Functor(listMap);                 
  var foldableList = new Data_Foldable.Foldable(function (dictMonoid) {
      return function (f) {
          return Data_Foldable.foldl(foldableList)(function (acc) {
              var $205 = Data_Semigroup.append(dictMonoid.Semigroup0())(acc);
              return function ($206) {
                  return $205(f($206));
              };
          })(Data_Monoid.mempty(dictMonoid));
      };
  }, function (f) {
      var go = function ($copy_b) {
          return function ($copy_v) {
              var $tco_var_b = $copy_b;
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(b, v) {
                  if (v instanceof Nil) {
                      $tco_done = true;
                      return b;
                  };
                  if (v instanceof Cons) {
                      $tco_var_b = f(b)(v.value0);
                      $copy_v = v.value1;
                      return;
                  };
                  throw new Error("Failed pattern match at Data.List.Types (line 112, column 12 - line 114, column 30): " + [ v.constructor.name ]);
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($tco_var_b, $copy_v);
              };
              return $tco_result;
          };
      };
      return go;
  }, function (f) {
      return function (b) {
          var rev = (function () {
              var go = function ($copy_acc) {
                  return function ($copy_v) {
                      var $tco_var_acc = $copy_acc;
                      var $tco_done1 = false;
                      var $tco_result;
                      function $tco_loop(acc, v) {
                          if (v instanceof Nil) {
                              $tco_done1 = true;
                              return acc;
                          };
                          if (v instanceof Cons) {
                              $tco_var_acc = new Cons(v.value0, acc);
                              $copy_v = v.value1;
                              return;
                          };
                          throw new Error("Failed pattern match at Data.List.Types (line 108, column 7 - line 108, column 23): " + [ acc.constructor.name, v.constructor.name ]);
                      };
                      while (!$tco_done1) {
                          $tco_result = $tco_loop($tco_var_acc, $copy_v);
                      };
                      return $tco_result;
                  };
              };
              return go(Nil.value);
          })();
          var $207 = Data_Foldable.foldl(foldableList)(Data_Function.flip(f))(b);
          return function ($208) {
              return $207(rev($208));
          };
      };
  });
  var semigroupList = new Data_Semigroup.Semigroup(function (xs) {
      return function (ys) {
          return Data_Foldable.foldr(foldableList)(Cons.create)(ys)(xs);
      };
  });
  var unfoldable1List = new Data_Unfoldable1.Unfoldable1(function (f) {
      return function (b) {
          var go = function ($copy_source) {
              return function ($copy_memo) {
                  var $tco_var_source = $copy_source;
                  var $tco_done = false;
                  var $tco_result;
                  function $tco_loop(source, memo) {
                      var v = f(source);
                      if (v.value1 instanceof Data_Maybe.Just) {
                          $tco_var_source = v.value1.value0;
                          $copy_memo = new Cons(v.value0, memo);
                          return;
                      };
                      if (v.value1 instanceof Data_Maybe.Nothing) {
                          $tco_done = true;
                          return Data_Foldable.foldl(foldableList)(Data_Function.flip(Cons.create))(Nil.value)(new Cons(v.value0, memo));
                      };
                      throw new Error("Failed pattern match at Data.List.Types (line 136, column 22 - line 138, column 61): " + [ v.constructor.name ]);
                  };
                  while (!$tco_done) {
                      $tco_result = $tco_loop($tco_var_source, $copy_memo);
                  };
                  return $tco_result;
              };
          };
          return go(b)(Nil.value);
      };
  });
  var unfoldableList = new Data_Unfoldable.Unfoldable(function () {
      return unfoldable1List;
  }, function (f) {
      return function (b) {
          var go = function ($copy_source) {
              return function ($copy_memo) {
                  var $tco_var_source = $copy_source;
                  var $tco_done = false;
                  var $tco_result;
                  function $tco_loop(source, memo) {
                      var v = f(source);
                      if (v instanceof Data_Maybe.Nothing) {
                          $tco_done = true;
                          return Data_Foldable.foldl(foldableList)(Data_Function.flip(Cons.create))(Nil.value)(memo);
                      };
                      if (v instanceof Data_Maybe.Just) {
                          $tco_var_source = v.value0.value1;
                          $copy_memo = new Cons(v.value0.value0, memo);
                          return;
                      };
                      throw new Error("Failed pattern match at Data.List.Types (line 143, column 22 - line 145, column 52): " + [ v.constructor.name ]);
                  };
                  while (!$tco_done) {
                      $tco_result = $tco_loop($tco_var_source, $copy_memo);
                  };
                  return $tco_result;
              };
          };
          return go(b)(Nil.value);
      };
  });
  var eq1List = new Data_Eq.Eq1(function (dictEq) {
      return function (xs) {
          return function (ys) {
              var go = function ($copy_v) {
                  return function ($copy_v1) {
                      return function ($copy_v2) {
                          var $tco_var_v = $copy_v;
                          var $tco_var_v1 = $copy_v1;
                          var $tco_done = false;
                          var $tco_result;
                          function $tco_loop(v, v1, v2) {
                              if (!v2) {
                                  $tco_done = true;
                                  return false;
                              };
                              if (v instanceof Nil && v1 instanceof Nil) {
                                  $tco_done = true;
                                  return v2;
                              };
                              if (v instanceof Cons && v1 instanceof Cons) {
                                  $tco_var_v = v.value1;
                                  $tco_var_v1 = v1.value1;
                                  $copy_v2 = v2 && Data_Eq.eq(dictEq)(v1.value0)(v.value0);
                                  return;
                              };
                              $tco_done = true;
                              return false;
                          };
                          while (!$tco_done) {
                              $tco_result = $tco_loop($tco_var_v, $tco_var_v1, $copy_v2);
                          };
                          return $tco_result;
                      };
                  };
              };
              return go(xs)(ys)(true);
          };
      };
  });                                                      
  var eqList = function (dictEq) {
      return new Data_Eq.Eq(Data_Eq.eq1(eq1List)(dictEq));
  };
  var ord1List = new Data_Ord.Ord1(function () {
      return eq1List;
  }, function (dictOrd) {
      return function (xs) {
          return function (ys) {
              var go = function ($copy_v) {
                  return function ($copy_v1) {
                      var $tco_var_v = $copy_v;
                      var $tco_done = false;
                      var $tco_result;
                      function $tco_loop(v, v1) {
                          if (v instanceof Nil && v1 instanceof Nil) {
                              $tco_done = true;
                              return Data_Ordering.EQ.value;
                          };
                          if (v instanceof Nil) {
                              $tco_done = true;
                              return Data_Ordering.LT.value;
                          };
                          if (v1 instanceof Nil) {
                              $tco_done = true;
                              return Data_Ordering.GT.value;
                          };
                          if (v instanceof Cons && v1 instanceof Cons) {
                              var v2 = Data_Ord.compare(dictOrd)(v.value0)(v1.value0);
                              if (v2 instanceof Data_Ordering.EQ) {
                                  $tco_var_v = v.value1;
                                  $copy_v1 = v1.value1;
                                  return;
                              };
                              $tco_done = true;
                              return v2;
                          };
                          throw new Error("Failed pattern match at Data.List.Types (line 61, column 5 - line 61, column 20): " + [ v.constructor.name, v1.constructor.name ]);
                      };
                      while (!$tco_done) {
                          $tco_result = $tco_loop($tco_var_v, $copy_v1);
                      };
                      return $tco_result;
                  };
              };
              return go(xs)(ys);
          };
      };
  });                                                         
  var ordList = function (dictOrd) {
      return new Data_Ord.Ord(function () {
          return eqList(dictOrd.Eq0());
      }, Data_Ord.compare1(ord1List)(dictOrd));
  }; 
  var applyList = new Control_Apply.Apply(function () {
      return functorList;
  }, function (v) {
      return function (v1) {
          if (v instanceof Nil) {
              return Nil.value;
          };
          if (v instanceof Cons) {
              return Data_Semigroup.append(semigroupList)(Data_Functor.map(functorList)(v.value0)(v1))(Control_Apply.apply(applyList)(v.value1)(v1));
          };
          throw new Error("Failed pattern match at Data.List.Types (line 158, column 1 - line 160, column 48): " + [ v.constructor.name, v1.constructor.name ]);
      };
  });
  var applicativeList = new Control_Applicative.Applicative(function () {
      return applyList;
  }, function (a) {
      return new Cons(a, Nil.value);
  });                                              
  var altList = new Control_Alt.Alt(function () {
      return functorList;
  }, Data_Semigroup.append(semigroupList));
  var plusList = new Control_Plus.Plus(function () {
      return altList;
  }, Nil.value);
  exports["Nil"] = Nil;
  exports["Cons"] = Cons;
  exports["NonEmptyList"] = NonEmptyList;
  exports["ordList"] = ordList;
  exports["semigroupList"] = semigroupList;
  exports["foldableList"] = foldableList;
  exports["unfoldableList"] = unfoldableList;
  exports["applicativeList"] = applicativeList;
  exports["plusList"] = plusList;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.NonEmpty"] = $PS["Data.NonEmpty"] || {};
  var exports = $PS["Data.NonEmpty"];
  var Control_Plus = $PS["Control.Plus"];                        
  var NonEmpty = (function () {
      function NonEmpty(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      NonEmpty.create = function (value0) {
          return function (value1) {
              return new NonEmpty(value0, value1);
          };
      };
      return NonEmpty;
  })();
  var singleton = function (dictPlus) {
      return function (a) {
          return new NonEmpty(a, Control_Plus.empty(dictPlus));
      };
  };
  exports["NonEmpty"] = NonEmpty;
  exports["singleton"] = singleton;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.List.NonEmpty"] = $PS["Data.List.NonEmpty"] || {};
  var exports = $PS["Data.List.NonEmpty"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_NonEmpty = $PS["Data.NonEmpty"];
  var singleton = (function () {
      var $172 = Data_NonEmpty.singleton(Data_List_Types.plusList);
      return function ($173) {
          return Data_List_Types.NonEmptyList($172($173));
      };
  })();
  var cons = function (y) {
      return function (v) {
          return new Data_NonEmpty.NonEmpty(y, new Data_List_Types.Cons(v.value0, v.value1));
      };
  };
  exports["singleton"] = singleton;
  exports["cons"] = cons;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Control.Applicative.Free"] = $PS["Control.Applicative.Free"] || {};
  var exports = $PS["Control.Applicative.Free"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Category = $PS["Control.Category"];
  var Data_Either = $PS["Data.Either"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_List_NonEmpty = $PS["Data.List.NonEmpty"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_NonEmpty = $PS["Data.NonEmpty"];
  var Data_Tuple = $PS["Data.Tuple"];                
  var Pure = (function () {
      function Pure(value0) {
          this.value0 = value0;
      };
      Pure.create = function (value0) {
          return new Pure(value0);
      };
      return Pure;
  })();
  var Lift = (function () {
      function Lift(value0) {
          this.value0 = value0;
      };
      Lift.create = function (value0) {
          return new Lift(value0);
      };
      return Lift;
  })();
  var Ap = (function () {
      function Ap(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Ap.create = function (value0) {
          return function (value1) {
              return new Ap(value0, value1);
          };
      };
      return Ap;
  })();
  var mkAp = function (fba) {
      return function (fb) {
          return new Ap(fba, fb);
      };
  };
  var liftFreeAp = Lift.create;
  var goLeft = function ($copy_dictApplicative) {
      return function ($copy_fStack) {
          return function ($copy_valStack) {
              return function ($copy_nat) {
                  return function ($copy_func) {
                      return function ($copy_count) {
                          var $tco_var_dictApplicative = $copy_dictApplicative;
                          var $tco_var_fStack = $copy_fStack;
                          var $tco_var_valStack = $copy_valStack;
                          var $tco_var_nat = $copy_nat;
                          var $tco_var_func = $copy_func;
                          var $tco_done = false;
                          var $tco_result;
                          function $tco_loop(dictApplicative, fStack, valStack, nat, func, count) {
                              if (func instanceof Pure) {
                                  $tco_done = true;
                                  return new Data_Tuple.Tuple(new Data_List_Types.Cons({
                                      func: Control_Applicative.pure(dictApplicative)(func.value0),
                                      count: count
                                  }, fStack), valStack);
                              };
                              if (func instanceof Lift) {
                                  $tco_done = true;
                                  return new Data_Tuple.Tuple(new Data_List_Types.Cons({
                                      func: nat(func.value0),
                                      count: count
                                  }, fStack), valStack);
                              };
                              if (func instanceof Ap) {
                                  $tco_var_dictApplicative = dictApplicative;
                                  $tco_var_fStack = fStack;
                                  $tco_var_valStack = Data_List_NonEmpty.cons(func.value1)(valStack);
                                  $tco_var_nat = nat;
                                  $tco_var_func = func.value0;
                                  $copy_count = count + 1 | 0;
                                  return;
                              };
                              throw new Error("Failed pattern match at Control.Applicative.Free (line 102, column 41 - line 105, column 81): " + [ func.constructor.name ]);
                          };
                          while (!$tco_done) {
                              $tco_result = $tco_loop($tco_var_dictApplicative, $tco_var_fStack, $tco_var_valStack, $tco_var_nat, $tco_var_func, $copy_count);
                          };
                          return $tco_result;
                      };
                  };
              };
          };
      };
  };
  var goApply = function ($copy_dictApplicative) {
      return function ($copy_fStack) {
          return function ($copy_vals) {
              return function ($copy_gVal) {
                  var $tco_var_dictApplicative = $copy_dictApplicative;
                  var $tco_var_fStack = $copy_fStack;
                  var $tco_var_vals = $copy_vals;
                  var $tco_done = false;
                  var $tco_result;
                  function $tco_loop(dictApplicative, fStack, vals, gVal) {
                      if (fStack instanceof Data_List_Types.Nil) {
                          $tco_done = true;
                          return new Data_Either.Left(gVal);
                      };
                      if (fStack instanceof Data_List_Types.Cons) {
                          var gRes = Control_Apply.apply(dictApplicative.Apply0())(fStack.value0.func)(gVal);
                          var $14 = fStack.value0.count === 1;
                          if ($14) {
                              if (fStack.value1 instanceof Data_List_Types.Nil) {
                                  $tco_done = true;
                                  return new Data_Either.Left(gRes);
                              };
                              $tco_var_dictApplicative = dictApplicative;
                              $tco_var_fStack = fStack.value1;
                              $tco_var_vals = vals;
                              $copy_gVal = gRes;
                              return;
                          };
                          if (vals instanceof Data_List_Types.Nil) {
                              $tco_done = true;
                              return new Data_Either.Left(gRes);
                          };
                          if (vals instanceof Data_List_Types.Cons) {
                              $tco_done = true;
                              return Data_Either.Right.create(new Data_Tuple.Tuple(new Data_List_Types.Cons({
                                  func: gRes,
                                  count: fStack.value0.count - 1 | 0
                              }, fStack.value1), new Data_NonEmpty.NonEmpty(vals.value0, vals.value1)));
                          };
                          throw new Error("Failed pattern match at Control.Applicative.Free (line 83, column 11 - line 88, column 50): " + [ vals.constructor.name ]);
                      };
                      throw new Error("Failed pattern match at Control.Applicative.Free (line 72, column 3 - line 88, column 50): " + [ fStack.constructor.name ]);
                  };
                  while (!$tco_done) {
                      $tco_result = $tco_loop($tco_var_dictApplicative, $tco_var_fStack, $tco_var_vals, $copy_gVal);
                  };
                  return $tco_result;
              };
          };
      };
  };
  var functorFreeAp = new Data_Functor.Functor(function (f) {
      return function (x) {
          return mkAp(new Pure(f))(x);
      };
  });
  var foldFreeAp = function (dictApplicative) {
      return function (nat) {
          return function (z) {
              var go = function ($copy_v) {
                  var $tco_done = false;
                  var $tco_result;
                  function $tco_loop(v) {
                      if (v.value1.value0 instanceof Pure) {
                          var v1 = goApply(dictApplicative)(v.value0)(v.value1.value1)(Control_Applicative.pure(dictApplicative)(v.value1.value0.value0));
                          if (v1 instanceof Data_Either.Left) {
                              $tco_done = true;
                              return v1.value0;
                          };
                          if (v1 instanceof Data_Either.Right) {
                              $copy_v = v1.value0;
                              return;
                          };
                          throw new Error("Failed pattern match at Control.Applicative.Free (line 54, column 17 - line 56, column 24): " + [ v1.constructor.name ]);
                      };
                      if (v.value1.value0 instanceof Lift) {
                          var v1 = goApply(dictApplicative)(v.value0)(v.value1.value1)(nat(v.value1.value0.value0));
                          if (v1 instanceof Data_Either.Left) {
                              $tco_done = true;
                              return v1.value0;
                          };
                          if (v1 instanceof Data_Either.Right) {
                              $copy_v = v1.value0;
                              return;
                          };
                          throw new Error("Failed pattern match at Control.Applicative.Free (line 57, column 17 - line 59, column 24): " + [ v1.constructor.name ]);
                      };
                      if (v.value1.value0 instanceof Ap) {
                          var nextVals = new Data_NonEmpty.NonEmpty(v.value1.value0.value1, v.value1.value1);
                          $copy_v = goLeft(dictApplicative)(v.value0)(nextVals)(nat)(v.value1.value0.value0)(1);
                          return;
                      };
                      throw new Error("Failed pattern match at Control.Applicative.Free (line 53, column 5 - line 62, column 47): " + [ v.value1.value0.constructor.name ]);
                  };
                  while (!$tco_done) {
                      $tco_result = $tco_loop($copy_v);
                  };
                  return $tco_result;
              };
              return go(new Data_Tuple.Tuple(Data_List_Types.Nil.value, Data_List_NonEmpty.singleton(z)));
          };
      };
  };
  var retractFreeAp = function (dictApplicative) {
      return foldFreeAp(dictApplicative)(Control_Category.identity(Control_Category.categoryFn));
  };
  var applyFreeAp = new Control_Apply.Apply(function () {
      return functorFreeAp;
  }, function (fba) {
      return function (fb) {
          return mkAp(fba)(fb);
      };
  });
  var applicativeFreeAp = new Control_Applicative.Applicative(function () {
      return applyFreeAp;
  }, Pure.create);
  var hoistFreeAp = function (f) {
      return foldFreeAp(applicativeFreeAp)(function ($37) {
          return liftFreeAp(f($37));
      });
  };
  exports["retractFreeAp"] = retractFreeAp;
  exports["hoistFreeAp"] = hoistFreeAp;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Control.Lazy"] = $PS["Control.Lazy"] || {};
  var exports = $PS["Control.Lazy"];               
  var Lazy = function (defer) {
      this.defer = defer;
  }; 
  var defer = function (dict) {
      return dict.defer;
  };
  exports["defer"] = defer;
  exports["Lazy"] = Lazy;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Control.Monad"] = $PS["Control.Monad"] || {};
  var exports = $PS["Control.Monad"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];                
  var Monad = function (Applicative0, Bind1) {
      this.Applicative0 = Applicative0;
      this.Bind1 = Bind1;
  };
  var unlessM = function (dictMonad) {
      return function (mb) {
          return function (m) {
              return Control_Bind.bind(dictMonad.Bind1())(mb)(function (b) {
                  return Control_Applicative.unless(dictMonad.Applicative0())(b)(m);
              });
          };
      };
  };
  var ap = function (dictMonad) {
      return function (f) {
          return function (a) {
              return Control_Bind.bind(dictMonad.Bind1())(f)(function (f$prime) {
                  return Control_Bind.bind(dictMonad.Bind1())(a)(function (a$prime) {
                      return Control_Applicative.pure(dictMonad.Applicative0())(f$prime(a$prime));
                  });
              });
          };
      };
  };
  exports["Monad"] = Monad;
  exports["unlessM"] = unlessM;
  exports["ap"] = ap;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Control.Monad.Error.Class"] = $PS["Control.Monad.Error.Class"] || {};
  var exports = $PS["Control.Monad.Error.Class"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Data_Either = $PS["Data.Either"];
  var Data_Functor = $PS["Data.Functor"];                        
  var MonadThrow = function (Monad0, throwError) {
      this.Monad0 = Monad0;
      this.throwError = throwError;
  };
  var MonadError = function (MonadThrow0, catchError) {
      this.MonadThrow0 = MonadThrow0;
      this.catchError = catchError;
  };
  var throwError = function (dict) {
      return dict.throwError;
  };                                                      
  var catchError = function (dict) {
      return dict.catchError;
  };
  var $$try = function (dictMonadError) {
      return function (a) {
          return catchError(dictMonadError)(Data_Functor.map(((((dictMonadError.MonadThrow0()).Monad0()).Bind1()).Apply0()).Functor0())(Data_Either.Right.create)(a))((function () {
              var $17 = Control_Applicative.pure(((dictMonadError.MonadThrow0()).Monad0()).Applicative0());
              return function ($18) {
                  return $17(Data_Either.Left.create($18));
              };
          })());
      };
  };
  exports["throwError"] = throwError;
  exports["MonadThrow"] = MonadThrow;
  exports["MonadError"] = MonadError;
  exports["try"] = $$try;
})(PS);
(function(exports) {
  /* globals setImmediate, clearImmediate, setTimeout, clearTimeout */
  /* eslint-disable no-unused-vars, no-prototype-builtins, no-use-before-define, no-unused-labels, no-param-reassign */
  "use strict";

  var Aff = function () {
    // A unique value for empty.
    var EMPTY = {};

    /*

  An awkward approximation. We elide evidence we would otherwise need in PS for
  efficiency sake.

  data Aff eff a
    = Pure a
    | Throw Error
    | Catch (Aff eff a) (Error -> Aff eff a)
    | Sync (Eff eff a)
    | Async ((Either Error a -> Eff eff Unit) -> Eff eff (Canceler eff))
    | forall b. Bind (Aff eff b) (b -> Aff eff a)
    | forall b. Bracket (Aff eff b) (BracketConditions eff b) (b -> Aff eff a)
    | forall b. Fork Boolean (Aff eff b) ?(Fiber eff b -> a)
    | Sequential (ParAff aff a)

  */  
    var PURE    = "Pure";
    var THROW   = "Throw";
    var CATCH   = "Catch";
    var SYNC    = "Sync";
    var ASYNC   = "Async";
    var BIND    = "Bind";
    var BRACKET = "Bracket";
    var FORK    = "Fork";
    var SEQ     = "Sequential";

    /*

  data ParAff eff a
    = forall b. Map (b -> a) (ParAff eff b)
    | forall b. Apply (ParAff eff (b -> a)) (ParAff eff b)
    | Alt (ParAff eff a) (ParAff eff a)
    | ?Par (Aff eff a)

  */  
    var MAP   = "Map";
    var APPLY = "Apply";
    var ALT   = "Alt";

    // Various constructors used in interpretation
    var CONS      = "Cons";      // Cons-list, for stacks
    var RESUME    = "Resume";    // Continue indiscriminately
    var RELEASE   = "Release";   // Continue with bracket finalizers
    var FINALIZER = "Finalizer"; // A non-interruptible effect
    var FINALIZED = "Finalized"; // Marker for finalization
    var FORKED    = "Forked";    // Reference to a forked fiber, with resumption stack
    var FIBER     = "Fiber";     // Actual fiber reference
    var THUNK     = "Thunk";     // Primed effect, ready to invoke

    function Aff(tag, _1, _2, _3) {
      this.tag = tag;
      this._1  = _1;
      this._2  = _2;
      this._3  = _3;
    }

    function AffCtr(tag) {
      var fn = function (_1, _2, _3) {
        return new Aff(tag, _1, _2, _3);
      };
      fn.tag = tag;
      return fn;
    }

    function nonCanceler(error) {
      return new Aff(PURE, void 0);
    }

    function runEff(eff) {
      try {
        eff();
      } catch (error) {
        setTimeout(function () {
          throw error;
        }, 0);
      }
    }

    function runSync(left, right, eff) {
      try {
        return right(eff());
      } catch (error) {
        return left(error);
      }
    }

    function runAsync(left, eff, k) {
      try {
        return eff(k)();
      } catch (error) {
        k(left(error))();
        return nonCanceler;
      }
    }

    var Scheduler = function () {
      var limit    = 1024;
      var size     = 0;
      var ix       = 0;
      var queue    = new Array(limit);
      var draining = false;

      function drain() {
        var thunk;
        draining = true;
        while (size !== 0) {
          size--;
          thunk     = queue[ix];
          queue[ix] = void 0;
          ix        = (ix + 1) % limit;
          thunk();
        }
        draining = false;
      }

      return {
        isDraining: function () {
          return draining;
        },
        enqueue: function (cb) {
          var i, tmp;
          if (size === limit) {
            tmp = draining;
            drain();
            draining = tmp;
          }

          queue[(ix + size) % limit] = cb;
          size++;

          if (!draining) {
            drain();
          }
        }
      };
    }();

    function Supervisor(util) {
      var fibers  = {};
      var fiberId = 0;
      var count   = 0;

      return {
        register: function (fiber) {
          var fid = fiberId++;
          fiber.onComplete({
            rethrow: true,
            handler: function (result) {
              return function () {
                count--;
                delete fibers[fid];
              };
            }
          })();
          fibers[fid] = fiber;
          count++;
        },
        isEmpty: function () {
          return count === 0;
        },
        killAll: function (killError, cb) {
          return function () {
            if (count === 0) {
              return cb();
            }

            var killCount = 0;
            var kills     = {};

            function kill(fid) {
              kills[fid] = fibers[fid].kill(killError, function (result) {
                return function () {
                  delete kills[fid];
                  killCount--;
                  if (util.isLeft(result) && util.fromLeft(result)) {
                    setTimeout(function () {
                      throw util.fromLeft(result);
                    }, 0);
                  }
                  if (killCount === 0) {
                    cb();
                  }
                };
              })();
            }

            for (var k in fibers) {
              if (fibers.hasOwnProperty(k)) {
                killCount++;
                kill(k);
              }
            }

            fibers  = {};
            fiberId = 0;
            count   = 0;

            return function (error) {
              return new Aff(SYNC, function () {
                for (var k in kills) {
                  if (kills.hasOwnProperty(k)) {
                    kills[k]();
                  }
                }
              });
            };
          };
        }
      };
    }

    // Fiber state machine
    var SUSPENDED   = 0; // Suspended, pending a join.
    var CONTINUE    = 1; // Interpret the next instruction.
    var STEP_BIND   = 2; // Apply the next bind.
    var STEP_RESULT = 3; // Handle potential failure from a result.
    var PENDING     = 4; // An async effect is running.
    var RETURN      = 5; // The current stack has returned.
    var COMPLETED   = 6; // The entire fiber has completed.

    function Fiber(util, supervisor, aff) {
      // Monotonically increasing tick, increased on each asynchronous turn.
      var runTick = 0;

      // The current branch of the state machine.
      var status = SUSPENDED;

      // The current point of interest for the state machine branch.
      var step      = aff;  // Successful step
      var fail      = null; // Failure step
      var interrupt = null; // Asynchronous interrupt

      // Stack of continuations for the current fiber.
      var bhead = null;
      var btail = null;

      // Stack of attempts and finalizers for error recovery. Every `Cons` is also
      // tagged with current `interrupt` state. We use this to track which items
      // should be ignored or evaluated as a result of a kill.
      var attempts = null;

      // A special state is needed for Bracket, because it cannot be killed. When
      // we enter a bracket acquisition or finalizer, we increment the counter,
      // and then decrement once complete.
      var bracketCount = 0;

      // Each join gets a new id so they can be revoked.
      var joinId  = 0;
      var joins   = null;
      var rethrow = true;

      // Each invocation of `run` requires a tick. When an asynchronous effect is
      // resolved, we must check that the local tick coincides with the fiber
      // tick before resuming. This prevents multiple async continuations from
      // accidentally resuming the same fiber. A common example may be invoking
      // the provided callback in `makeAff` more than once, but it may also be an
      // async effect resuming after the fiber was already cancelled.
      function run(localRunTick) {
        var tmp, result, attempt;
        while (true) {
          tmp       = null;
          result    = null;
          attempt   = null;

          switch (status) {
          case STEP_BIND:
            status = CONTINUE;
            try {
              step   = bhead(step);
              if (btail === null) {
                bhead = null;
              } else {
                bhead = btail._1;
                btail = btail._2;
              }
            } catch (e) {
              status = RETURN;
              fail   = util.left(e);
              step   = null;
            }
            break;

          case STEP_RESULT:
            if (util.isLeft(step)) {
              status = RETURN;
              fail   = step;
              step   = null;
            } else if (bhead === null) {
              status = RETURN;
            } else {
              status = STEP_BIND;
              step   = util.fromRight(step);
            }
            break;

          case CONTINUE:
            switch (step.tag) {
            case BIND:
              if (bhead) {
                btail = new Aff(CONS, bhead, btail);
              }
              bhead  = step._2;
              status = CONTINUE;
              step   = step._1;
              break;

            case PURE:
              if (bhead === null) {
                status = RETURN;
                step   = util.right(step._1);
              } else {
                status = STEP_BIND;
                step   = step._1;
              }
              break;

            case SYNC:
              status = STEP_RESULT;
              step   = runSync(util.left, util.right, step._1);
              break;

            case ASYNC:
              status = PENDING;
              step   = runAsync(util.left, step._1, function (result) {
                return function () {
                  if (runTick !== localRunTick) {
                    return;
                  }
                  runTick++;
                  Scheduler.enqueue(function () {
                    // It's possible to interrupt the fiber between enqueuing and
                    // resuming, so we need to check that the runTick is still
                    // valid.
                    if (runTick !== localRunTick + 1) {
                      return;
                    }
                    status = STEP_RESULT;
                    step   = result;
                    run(runTick);
                  });
                };
              });
              return;

            case THROW:
              status = RETURN;
              fail   = util.left(step._1);
              step   = null;
              break;

            // Enqueue the Catch so that we can call the error handler later on
            // in case of an exception.
            case CATCH:
              if (bhead === null) {
                attempts = new Aff(CONS, step, attempts, interrupt);
              } else {
                attempts = new Aff(CONS, step, new Aff(CONS, new Aff(RESUME, bhead, btail), attempts, interrupt), interrupt);
              }
              bhead    = null;
              btail    = null;
              status   = CONTINUE;
              step     = step._1;
              break;

            // Enqueue the Bracket so that we can call the appropriate handlers
            // after resource acquisition.
            case BRACKET:
              bracketCount++;
              if (bhead === null) {
                attempts = new Aff(CONS, step, attempts, interrupt);
              } else {
                attempts = new Aff(CONS, step, new Aff(CONS, new Aff(RESUME, bhead, btail), attempts, interrupt), interrupt);
              }
              bhead  = null;
              btail  = null;
              status = CONTINUE;
              step   = step._1;
              break;

            case FORK:
              status = STEP_RESULT;
              tmp    = Fiber(util, supervisor, step._2);
              if (supervisor) {
                supervisor.register(tmp);
              }
              if (step._1) {
                tmp.run();
              }
              step = util.right(tmp);
              break;

            case SEQ:
              status = CONTINUE;
              step   = sequential(util, supervisor, step._1);
              break;
            }
            break;

          case RETURN:
            bhead = null;
            btail = null;
            // If the current stack has returned, and we have no other stacks to
            // resume or finalizers to run, the fiber has halted and we can
            // invoke all join callbacks. Otherwise we need to resume.
            if (attempts === null) {
              status = COMPLETED;
              step   = interrupt || fail || step;
            } else {
              // The interrupt status for the enqueued item.
              tmp      = attempts._3;
              attempt  = attempts._1;
              attempts = attempts._2;

              switch (attempt.tag) {
              // We cannot recover from an unmasked interrupt. Otherwise we should
              // continue stepping, or run the exception handler if an exception
              // was raised.
              case CATCH:
                // We should compare the interrupt status as well because we
                // only want it to apply if there has been an interrupt since
                // enqueuing the catch.
                if (interrupt && interrupt !== tmp && bracketCount === 0) {
                  status = RETURN;
                } else if (fail) {
                  status = CONTINUE;
                  step   = attempt._2(util.fromLeft(fail));
                  fail   = null;
                }
                break;

              // We cannot resume from an unmasked interrupt or exception.
              case RESUME:
                // As with Catch, we only want to ignore in the case of an
                // interrupt since enqueing the item.
                if (interrupt && interrupt !== tmp && bracketCount === 0 || fail) {
                  status = RETURN;
                } else {
                  bhead  = attempt._1;
                  btail  = attempt._2;
                  status = STEP_BIND;
                  step   = util.fromRight(step);
                }
                break;

              // If we have a bracket, we should enqueue the handlers,
              // and continue with the success branch only if the fiber has
              // not been interrupted. If the bracket acquisition failed, we
              // should not run either.
              case BRACKET:
                bracketCount--;
                if (fail === null) {
                  result   = util.fromRight(step);
                  // We need to enqueue the Release with the same interrupt
                  // status as the Bracket that is initiating it.
                  attempts = new Aff(CONS, new Aff(RELEASE, attempt._2, result), attempts, tmp);
                  // We should only coninue as long as the interrupt status has not changed or
                  // we are currently within a non-interruptable finalizer.
                  if (interrupt === tmp || bracketCount > 0) {
                    status = CONTINUE;
                    step   = attempt._3(result);
                  }
                }
                break;

              // Enqueue the appropriate handler. We increase the bracket count
              // because it should not be cancelled.
              case RELEASE:
                attempts = new Aff(CONS, new Aff(FINALIZED, step, fail), attempts, interrupt);
                status   = CONTINUE;
                // It has only been killed if the interrupt status has changed
                // since we enqueued the item, and the bracket count is 0. If the
                // bracket count is non-zero then we are in a masked state so it's
                // impossible to be killed.
                if (interrupt && interrupt !== tmp && bracketCount === 0) {
                  step = attempt._1.killed(util.fromLeft(interrupt))(attempt._2);
                } else if (fail) {
                  step = attempt._1.failed(util.fromLeft(fail))(attempt._2);
                } else {
                  step = attempt._1.completed(util.fromRight(step))(attempt._2);
                }
                fail = null;
                bracketCount++;
                break;

              case FINALIZER:
                bracketCount++;
                attempts = new Aff(CONS, new Aff(FINALIZED, step, fail), attempts, interrupt);
                status   = CONTINUE;
                step     = attempt._1;
                break;

              case FINALIZED:
                bracketCount--;
                status = RETURN;
                step   = attempt._1;
                fail   = attempt._2;
                break;
              }
            }
            break;

          case COMPLETED:
            for (var k in joins) {
              if (joins.hasOwnProperty(k)) {
                rethrow = rethrow && joins[k].rethrow;
                runEff(joins[k].handler(step));
              }
            }
            joins = null;
            // If we have an interrupt and a fail, then the thread threw while
            // running finalizers. This should always rethrow in a fresh stack.
            if (interrupt && fail) {
              setTimeout(function () {
                throw util.fromLeft(fail);
              }, 0);
            // If we have an unhandled exception, and no other fiber has joined
            // then we need to throw the exception in a fresh stack.
            } else if (util.isLeft(step) && rethrow) {
              setTimeout(function () {
                // Guard on reathrow because a completely synchronous fiber can
                // still have an observer which was added after-the-fact.
                if (rethrow) {
                  throw util.fromLeft(step);
                }
              }, 0);
            }
            return;
          case SUSPENDED:
            status = CONTINUE;
            break;
          case PENDING: return;
          }
        }
      }

      function onComplete(join) {
        return function () {
          if (status === COMPLETED) {
            rethrow = rethrow && join.rethrow;
            join.handler(step)();
            return function () {};
          }

          var jid    = joinId++;
          joins      = joins || {};
          joins[jid] = join;

          return function() {
            if (joins !== null) {
              delete joins[jid];
            }
          };
        };
      }

      function kill(error, cb) {
        return function () {
          if (status === COMPLETED) {
            cb(util.right(void 0))();
            return function () {};
          }

          var canceler = onComplete({
            rethrow: false,
            handler: function (/* unused */) {
              return cb(util.right(void 0));
            }
          })();

          switch (status) {
          case SUSPENDED:
            interrupt = util.left(error);
            status    = COMPLETED;
            step      = interrupt;
            run(runTick);
            break;
          case PENDING:
            if (interrupt === null) {
              interrupt = util.left(error);
            }
            if (bracketCount === 0) {
              if (status === PENDING) {
                attempts = new Aff(CONS, new Aff(FINALIZER, step(error)), attempts, interrupt);
              }
              status   = RETURN;
              step     = null;
              fail     = null;
              run(++runTick);
            }
            break;
          default:
            if (interrupt === null) {
              interrupt = util.left(error);
            }
            if (bracketCount === 0) {
              status = RETURN;
              step   = null;
              fail   = null;
            }
          }

          return canceler;
        };
      }

      function join(cb) {
        return function () {
          var canceler = onComplete({
            rethrow: false,
            handler: cb
          })();
          if (status === SUSPENDED) {
            run(runTick);
          }
          return canceler;
        };
      }

      return {
        kill: kill,
        join: join,
        onComplete: onComplete,
        isSuspended: function () {
          return status === SUSPENDED;
        },
        run: function () {
          if (status === SUSPENDED) {
            if (!Scheduler.isDraining()) {
              Scheduler.enqueue(function () {
                run(runTick);
              });
            } else {
              run(runTick);
            }
          }
        }
      };
    }

    function runPar(util, supervisor, par, cb) {
      // Table of all forked fibers.
      var fiberId   = 0;
      var fibers    = {};

      // Table of currently running cancelers, as a product of `Alt` behavior.
      var killId    = 0;
      var kills     = {};

      // Error used for early cancelation on Alt branches.
      var early     = new Error("[ParAff] Early exit");

      // Error used to kill the entire tree.
      var interrupt = null;

      // The root pointer of the tree.
      var root      = EMPTY;

      // Walks a tree, invoking all the cancelers. Returns the table of pending
      // cancellation fibers.
      function kill(error, par, cb) {
        var step  = par;
        var head  = null;
        var tail  = null;
        var count = 0;
        var kills = {};
        var tmp, kid;

        loop: while (true) {
          tmp = null;

          switch (step.tag) {
          case FORKED:
            if (step._3 === EMPTY) {
              tmp = fibers[step._1];
              kills[count++] = tmp.kill(error, function (result) {
                return function () {
                  count--;
                  if (count === 0) {
                    cb(result)();
                  }
                };
              });
            }
            // Terminal case.
            if (head === null) {
              break loop;
            }
            // Go down the right side of the tree.
            step = head._2;
            if (tail === null) {
              head = null;
            } else {
              head = tail._1;
              tail = tail._2;
            }
            break;
          case MAP:
            step = step._2;
            break;
          case APPLY:
          case ALT:
            if (head) {
              tail = new Aff(CONS, head, tail);
            }
            head = step;
            step = step._1;
            break;
          }
        }

        if (count === 0) {
          cb(util.right(void 0))();
        } else {
          // Run the cancelation effects. We alias `count` because it's mutable.
          kid = 0;
          tmp = count;
          for (; kid < tmp; kid++) {
            kills[kid] = kills[kid]();
          }
        }

        return kills;
      }

      // When a fiber resolves, we need to bubble back up the tree with the
      // result, computing the applicative nodes.
      function join(result, head, tail) {
        var fail, step, lhs, rhs, tmp, kid;

        if (util.isLeft(result)) {
          fail = result;
          step = null;
        } else {
          step = result;
          fail = null;
        }

        loop: while (true) {
          lhs = null;
          rhs = null;
          tmp = null;
          kid = null;

          // We should never continue if the entire tree has been interrupted.
          if (interrupt !== null) {
            return;
          }

          // We've made it all the way to the root of the tree, which means
          // the tree has fully evaluated.
          if (head === null) {
            cb(fail || step)();
            return;
          }

          // The tree has already been computed, so we shouldn't try to do it
          // again. This should never happen.
          // TODO: Remove this?
          if (head._3 !== EMPTY) {
            return;
          }

          switch (head.tag) {
          case MAP:
            if (fail === null) {
              head._3 = util.right(head._1(util.fromRight(step)));
              step    = head._3;
            } else {
              head._3 = fail;
            }
            break;
          case APPLY:
            lhs = head._1._3;
            rhs = head._2._3;
            // If we have a failure we should kill the other side because we
            // can't possible yield a result anymore.
            if (fail) {
              head._3 = fail;
              tmp     = true;
              kid     = killId++;

              kills[kid] = kill(early, fail === lhs ? head._2 : head._1, function (/* unused */) {
                return function () {
                  delete kills[kid];
                  if (tmp) {
                    tmp = false;
                  } else if (tail === null) {
                    join(fail, null, null);
                  } else {
                    join(fail, tail._1, tail._2);
                  }
                };
              });

              if (tmp) {
                tmp = false;
                return;
              }
            } else if (lhs === EMPTY || rhs === EMPTY) {
              // We can only proceed if both sides have resolved.
              return;
            } else {
              step    = util.right(util.fromRight(lhs)(util.fromRight(rhs)));
              head._3 = step;
            }
            break;
          case ALT:
            lhs = head._1._3;
            rhs = head._2._3;
            // We can only proceed if both have resolved or we have a success
            if (lhs === EMPTY && util.isLeft(rhs) || rhs === EMPTY && util.isLeft(lhs)) {
              return;
            }
            // If both sides resolve with an error, we should continue with the
            // first error
            if (lhs !== EMPTY && util.isLeft(lhs) && rhs !== EMPTY && util.isLeft(rhs)) {
              fail    = step === lhs ? rhs : lhs;
              step    = null;
              head._3 = fail;
            } else {
              head._3 = step;
              tmp     = true;
              kid     = killId++;
              // Once a side has resolved, we need to cancel the side that is still
              // pending before we can continue.
              kills[kid] = kill(early, step === lhs ? head._2 : head._1, function (/* unused */) {
                return function () {
                  delete kills[kid];
                  if (tmp) {
                    tmp = false;
                  } else if (tail === null) {
                    join(step, null, null);
                  } else {
                    join(step, tail._1, tail._2);
                  }
                };
              });

              if (tmp) {
                tmp = false;
                return;
              }
            }
            break;
          }

          if (tail === null) {
            head = null;
          } else {
            head = tail._1;
            tail = tail._2;
          }
        }
      }

      function resolve(fiber) {
        return function (result) {
          return function () {
            delete fibers[fiber._1];
            fiber._3 = result;
            join(result, fiber._2._1, fiber._2._2);
          };
        };
      }

      // Walks the applicative tree, substituting non-applicative nodes with
      // `FORKED` nodes. In this tree, all applicative nodes use the `_3` slot
      // as a mutable slot for memoization. In an unresolved state, the `_3`
      // slot is `EMPTY`. In the cases of `ALT` and `APPLY`, we always walk
      // the left side first, because both operations are left-associative. As
      // we `RETURN` from those branches, we then walk the right side.
      function run() {
        var status = CONTINUE;
        var step   = par;
        var head   = null;
        var tail   = null;
        var tmp, fid;

        loop: while (true) {
          tmp = null;
          fid = null;

          switch (status) {
          case CONTINUE:
            switch (step.tag) {
            case MAP:
              if (head) {
                tail = new Aff(CONS, head, tail);
              }
              head = new Aff(MAP, step._1, EMPTY, EMPTY);
              step = step._2;
              break;
            case APPLY:
              if (head) {
                tail = new Aff(CONS, head, tail);
              }
              head = new Aff(APPLY, EMPTY, step._2, EMPTY);
              step = step._1;
              break;
            case ALT:
              if (head) {
                tail = new Aff(CONS, head, tail);
              }
              head = new Aff(ALT, EMPTY, step._2, EMPTY);
              step = step._1;
              break;
            default:
              // When we hit a leaf value, we suspend the stack in the `FORKED`.
              // When the fiber resolves, it can bubble back up the tree.
              fid    = fiberId++;
              status = RETURN;
              tmp    = step;
              step   = new Aff(FORKED, fid, new Aff(CONS, head, tail), EMPTY);
              tmp    = Fiber(util, supervisor, tmp);
              tmp.onComplete({
                rethrow: false,
                handler: resolve(step)
              })();
              fibers[fid] = tmp;
              if (supervisor) {
                supervisor.register(tmp);
              }
            }
            break;
          case RETURN:
            // Terminal case, we are back at the root.
            if (head === null) {
              break loop;
            }
            // If we are done with the right side, we need to continue down the
            // left. Otherwise we should continue up the stack.
            if (head._1 === EMPTY) {
              head._1 = step;
              status  = CONTINUE;
              step    = head._2;
              head._2 = EMPTY;
            } else {
              head._2 = step;
              step    = head;
              if (tail === null) {
                head  = null;
              } else {
                head  = tail._1;
                tail  = tail._2;
              }
            }
          }
        }

        // Keep a reference to the tree root so it can be cancelled.
        root = step;

        for (fid = 0; fid < fiberId; fid++) {
          fibers[fid].run();
        }
      }

      // Cancels the entire tree. If there are already subtrees being canceled,
      // we need to first cancel those joins. We will then add fresh joins for
      // all pending branches including those that were in the process of being
      // canceled.
      function cancel(error, cb) {
        interrupt = util.left(error);
        var innerKills;
        for (var kid in kills) {
          if (kills.hasOwnProperty(kid)) {
            innerKills = kills[kid];
            for (kid in innerKills) {
              if (innerKills.hasOwnProperty(kid)) {
                innerKills[kid]();
              }
            }
          }
        }

        kills = null;
        var newKills = kill(error, root, cb);

        return function (killError) {
          return new Aff(ASYNC, function (killCb) {
            return function () {
              for (var kid in newKills) {
                if (newKills.hasOwnProperty(kid)) {
                  newKills[kid]();
                }
              }
              return nonCanceler;
            };
          });
        };
      }

      run();

      return function (killError) {
        return new Aff(ASYNC, function (killCb) {
          return function () {
            return cancel(killError, killCb);
          };
        });
      };
    }

    function sequential(util, supervisor, par) {
      return new Aff(ASYNC, function (cb) {
        return function () {
          return runPar(util, supervisor, par, cb);
        };
      });
    }

    Aff.EMPTY       = EMPTY;
    Aff.Pure        = AffCtr(PURE);
    Aff.Throw       = AffCtr(THROW);
    Aff.Catch       = AffCtr(CATCH);
    Aff.Sync        = AffCtr(SYNC);
    Aff.Async       = AffCtr(ASYNC);
    Aff.Bind        = AffCtr(BIND);
    Aff.Bracket     = AffCtr(BRACKET);
    Aff.Fork        = AffCtr(FORK);
    Aff.Seq         = AffCtr(SEQ);
    Aff.ParMap      = AffCtr(MAP);
    Aff.ParApply    = AffCtr(APPLY);
    Aff.ParAlt      = AffCtr(ALT);
    Aff.Fiber       = Fiber;
    Aff.Supervisor  = Supervisor;
    Aff.Scheduler   = Scheduler;
    Aff.nonCanceler = nonCanceler;

    return Aff;
  }();

  exports._pure = Aff.Pure;

  exports._throwError = Aff.Throw;

  exports._catchError = function (aff) {
    return function (k) {
      return Aff.Catch(aff, k);
    };
  };

  exports._map = function (f) {
    return function (aff) {
      if (aff.tag === Aff.Pure.tag) {
        return Aff.Pure(f(aff._1));
      } else {
        return Aff.Bind(aff, function (value) {
          return Aff.Pure(f(value));
        });
      }
    };
  };

  exports._bind = function (aff) {
    return function (k) {
      return Aff.Bind(aff, k);
    };
  };

  exports._fork = function (immediate) {
    return function (aff) {
      return Aff.Fork(immediate, aff);
    };
  };

  exports._liftEffect = Aff.Sync;

  exports._parAffMap = function (f) {
    return function (aff) {
      return Aff.ParMap(f, aff);
    };
  };

  exports._parAffApply = function (aff1) {
    return function (aff2) {
      return Aff.ParApply(aff1, aff2);
    };
  };

  exports.makeAff = Aff.Async;

  exports.generalBracket = function (acquire) {
    return function (options) {
      return function (k) {
        return Aff.Bracket(acquire, options, k);
      };
    };
  };

  exports._makeFiber = function (util, aff) {
    return function () {
      return Aff.Fiber(util, null, aff);
    };
  };  

  exports._sequential = Aff.Seq;
})(PS["Effect.Aff"] = PS["Effect.Aff"] || {});
(function(exports) {
  "use strict";

  exports.pureE = function (a) {
    return function () {
      return a;
    };
  };

  exports.bindE = function (a) {
    return function (f) {
      return function () {
        return f(a())();
      };
    };
  };
})(PS["Effect"] = PS["Effect"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Effect"] = $PS["Effect"] || {};
  var exports = $PS["Effect"];
  var $foreign = $PS["Effect"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad = $PS["Control.Monad"];
  var Data_Functor = $PS["Data.Functor"];                    
  var monadEffect = new Control_Monad.Monad(function () {
      return applicativeEffect;
  }, function () {
      return bindEffect;
  });
  var bindEffect = new Control_Bind.Bind(function () {
      return applyEffect;
  }, $foreign.bindE);
  var applyEffect = new Control_Apply.Apply(function () {
      return functorEffect;
  }, Control_Monad.ap(monadEffect));
  var applicativeEffect = new Control_Applicative.Applicative(function () {
      return applyEffect;
  }, $foreign.pureE);
  var functorEffect = new Data_Functor.Functor(Control_Applicative.liftA1(applicativeEffect));
  exports["functorEffect"] = functorEffect;
  exports["applyEffect"] = applyEffect;
  exports["applicativeEffect"] = applicativeEffect;
  exports["bindEffect"] = bindEffect;
  exports["monadEffect"] = monadEffect;
})(PS);
(function(exports) {
  "use strict";

  exports.new = function (val) {
    return function () {
      return { value: val };
    };
  };

  exports.read = function (ref) {
    return function () {
      return ref.value;
    };
  };

  exports.modifyImpl = function (f) {
    return function (ref) {
      return function () {
        var t = f(ref.value);
        ref.value = t.state;
        return t.value;
      };
    };
  };

  exports.write = function (val) {
    return function (ref) {
      return function () {
        ref.value = val;
      };
    };
  };
})(PS["Effect.Ref"] = PS["Effect.Ref"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Effect.Ref"] = $PS["Effect.Ref"] || {};
  var exports = $PS["Effect.Ref"];
  var $foreign = $PS["Effect.Ref"];
  var Data_Functor = $PS["Data.Functor"];
  var Effect = $PS["Effect"];                
  var modify$prime = $foreign.modifyImpl;
  var modify = function (f) {
      return modify$prime(function (s) {
          var s$prime = f(s);
          return {
              state: s$prime,
              value: s$prime
          };
      });
  };
  var modify_ = function (f) {
      return function (s) {
          return Data_Functor["void"](Effect.functorEffect)(modify(f)(s));
      };
  };
  exports["modify'"] = modify$prime;
  exports["modify_"] = modify_;
  exports["new"] = $foreign["new"];
  exports["read"] = $foreign.read;
  exports["write"] = $foreign.write;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Control.Monad.Rec.Class"] = $PS["Control.Monad.Rec.Class"] || {};
  var exports = $PS["Control.Monad.Rec.Class"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Functor = $PS["Data.Functor"];
  var Effect = $PS["Effect"];
  var Effect_Ref = $PS["Effect.Ref"];                
  var Loop = (function () {
      function Loop(value0) {
          this.value0 = value0;
      };
      Loop.create = function (value0) {
          return new Loop(value0);
      };
      return Loop;
  })();
  var Done = (function () {
      function Done(value0) {
          this.value0 = value0;
      };
      Done.create = function (value0) {
          return new Done(value0);
      };
      return Done;
  })();
  var MonadRec = function (Monad0, tailRecM) {
      this.Monad0 = Monad0;
      this.tailRecM = tailRecM;
  };
  var tailRecM = function (dict) {
      return dict.tailRecM;
  }; 
  var monadRecEffect = new MonadRec(function () {
      return Effect.monadEffect;
  }, function (f) {
      return function (a) {
          var fromDone = function (v) {
              if (v instanceof Done) {
                  return v.value0;
              };
              throw new Error("Failed pattern match at Control.Monad.Rec.Class (line 113, column 30 - line 113, column 44): " + [ v.constructor.name ]);
          };
          return function __do() {
              var r = Control_Bind.bindFlipped(Effect.bindEffect)(Effect_Ref["new"])(f(a))();
              (function () {
                  while (!(function __do() {
                      var v = Effect_Ref.read(r)();
                      if (v instanceof Loop) {
                          var e = f(v.value0)();
                          Effect_Ref.write(e)(r)();
                          return false;
                      };
                      if (v instanceof Done) {
                          return true;
                      };
                      throw new Error("Failed pattern match at Control.Monad.Rec.Class (line 104, column 22 - line 109, column 28): " + [ v.constructor.name ]);
                  })()) {

                  };
                  return {};
              })();
              return Data_Functor.map(Effect.functorEffect)(fromDone)(Effect_Ref.read(r))();
          };
      };
  });
  exports["Loop"] = Loop;
  exports["Done"] = Done;
  exports["MonadRec"] = MonadRec;
  exports["tailRecM"] = tailRecM;
  exports["monadRecEffect"] = monadRecEffect;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Control.Parallel.Class"] = $PS["Control.Parallel.Class"] || {};
  var exports = $PS["Control.Parallel.Class"];       
  var Parallel = function (Applicative1, Monad0, parallel, sequential) {
      this.Applicative1 = Applicative1;
      this.Monad0 = Monad0;
      this.parallel = parallel;
      this.sequential = sequential;
  };
  var sequential = function (dict) {
      return dict.sequential;
  };
  var parallel = function (dict) {
      return dict.parallel;
  };
  exports["parallel"] = parallel;
  exports["sequential"] = sequential;
  exports["Parallel"] = Parallel;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Effect.Class"] = $PS["Effect.Class"] || {};
  var exports = $PS["Effect.Class"];
  var Control_Category = $PS["Control.Category"];
  var Effect = $PS["Effect"];                
  var MonadEffect = function (Monad0, liftEffect) {
      this.Monad0 = Monad0;
      this.liftEffect = liftEffect;
  };
  var monadEffectEffect = new MonadEffect(function () {
      return Effect.monadEffect;
  }, Control_Category.identity(Control_Category.categoryFn));
  var liftEffect = function (dict) {
      return dict.liftEffect;
  };
  exports["liftEffect"] = liftEffect;
  exports["MonadEffect"] = MonadEffect;
  exports["monadEffectEffect"] = monadEffectEffect;
})(PS);
(function(exports) {
  "use strict";

  exports.unsafePerformEffect = function (f) {
    return f();
  };
})(PS["Effect.Unsafe"] = PS["Effect.Unsafe"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Effect.Unsafe"] = $PS["Effect.Unsafe"] || {};
  var exports = $PS["Effect.Unsafe"];
  var $foreign = $PS["Effect.Unsafe"];
  exports["unsafePerformEffect"] = $foreign.unsafePerformEffect;
})(PS);
(function(exports) {
  "use strict";

  // module Partial.Unsafe

  exports._unsafePartial = function (f) {
    return f();
  };
})(PS["Partial.Unsafe"] = PS["Partial.Unsafe"] || {});
(function(exports) {
  "use strict";

  // module Partial

  exports._crashWith = function (msg) {
    throw new Error(msg);
  };
})(PS["Partial"] = PS["Partial"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Partial"] = $PS["Partial"] || {};
  var exports = $PS["Partial"];
  var $foreign = $PS["Partial"];         
  var crashWith = function (dictPartial) {
      return $foreign["_crashWith"];
  };
  exports["crashWith"] = crashWith;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Partial.Unsafe"] = $PS["Partial.Unsafe"] || {};
  var exports = $PS["Partial.Unsafe"];
  var $foreign = $PS["Partial.Unsafe"];
  var Partial = $PS["Partial"];                
  var unsafePartial = $foreign["_unsafePartial"];
  var unsafeCrashWith = function (msg) {
      return unsafePartial(function (dictPartial) {
          return Partial.crashWith()(msg);
      });
  };
  exports["unsafeCrashWith"] = unsafeCrashWith;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Effect.Aff"] = $PS["Effect.Aff"] || {};
  var exports = $PS["Effect.Aff"];
  var $foreign = $PS["Effect.Aff"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad = $PS["Control.Monad"];
  var Control_Monad_Error_Class = $PS["Control.Monad.Error.Class"];
  var Control_Monad_Rec_Class = $PS["Control.Monad.Rec.Class"];
  var Control_Parallel_Class = $PS["Control.Parallel.Class"];
  var Data_Either = $PS["Data.Either"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect = $PS["Effect"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Unsafe = $PS["Effect.Unsafe"];
  var Partial_Unsafe = $PS["Partial.Unsafe"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var Canceler = function (x) {
      return x;
  };
  var suspendAff = $foreign["_fork"](false);
  var functorParAff = new Data_Functor.Functor($foreign["_parAffMap"]);
  var functorAff = new Data_Functor.Functor($foreign["_map"]);
  var forkAff = $foreign["_fork"](true);
  var ffiUtil = (function () {
      var unsafeFromRight = function (v) {
          if (v instanceof Data_Either.Right) {
              return v.value0;
          };
          if (v instanceof Data_Either.Left) {
              return Partial_Unsafe.unsafeCrashWith("unsafeFromRight: Left");
          };
          throw new Error("Failed pattern match at Effect.Aff (line 404, column 21 - line 406, column 54): " + [ v.constructor.name ]);
      };
      var unsafeFromLeft = function (v) {
          if (v instanceof Data_Either.Left) {
              return v.value0;
          };
          if (v instanceof Data_Either.Right) {
              return Partial_Unsafe.unsafeCrashWith("unsafeFromLeft: Right");
          };
          throw new Error("Failed pattern match at Effect.Aff (line 399, column 20 - line 401, column 54): " + [ v.constructor.name ]);
      };
      var isLeft = function (v) {
          if (v instanceof Data_Either.Left) {
              return true;
          };
          if (v instanceof Data_Either.Right) {
              return false;
          };
          throw new Error("Failed pattern match at Effect.Aff (line 394, column 12 - line 396, column 20): " + [ v.constructor.name ]);
      };
      return {
          isLeft: isLeft,
          fromLeft: unsafeFromLeft,
          fromRight: unsafeFromRight,
          left: Data_Either.Left.create,
          right: Data_Either.Right.create
      };
  })();
  var makeFiber = function (aff) {
      return $foreign["_makeFiber"](ffiUtil, aff);
  };
  var launchAff = function (aff) {
      return function __do() {
          var fiber = makeFiber(aff)();
          fiber.run();
          return fiber;
      };
  };
  var launchAff_ = (function () {
      var $40 = Data_Functor["void"](Effect.functorEffect);
      return function ($41) {
          return $40(launchAff($41));
      };
  })();
  var bracket = function (acquire) {
      return function (completed) {
          return $foreign.generalBracket(acquire)({
              killed: Data_Function["const"](completed),
              failed: Data_Function["const"](completed),
              completed: Data_Function["const"](completed)
          });
      };
  };
  var applyParAff = new Control_Apply.Apply(function () {
      return functorParAff;
  }, $foreign["_parAffApply"]);
  var monadAff = new Control_Monad.Monad(function () {
      return applicativeAff;
  }, function () {
      return bindAff;
  });
  var bindAff = new Control_Bind.Bind(function () {
      return applyAff;
  }, $foreign["_bind"]);
  var applyAff = new Control_Apply.Apply(function () {
      return functorAff;
  }, Control_Monad.ap(monadAff));
  var applicativeAff = new Control_Applicative.Applicative(function () {
      return applyAff;
  }, $foreign["_pure"]);
  var $$finally = function (fin) {
      return function (a) {
          return bracket(Control_Applicative.pure(applicativeAff)(Data_Unit.unit))(Data_Function["const"](fin))(Data_Function["const"](a));
      };
  };
  var monadEffectAff = new Effect_Class.MonadEffect(function () {
      return monadAff;
  }, $foreign["_liftEffect"]);
  var effectCanceler = (function () {
      var $42 = Effect_Class.liftEffect(monadEffectAff);
      return function ($43) {
          return Canceler(Data_Function["const"]($42($43)));
      };
  })();
  var joinFiber = function (v) {
      return $foreign.makeAff(function (k) {
          return Data_Functor.map(Effect.functorEffect)(effectCanceler)(v.join(k));
      });
  };
  var functorFiber = new Data_Functor.Functor(function (f) {
      return function (t) {
          return Effect_Unsafe.unsafePerformEffect(makeFiber(Data_Functor.map(functorAff)(f)(joinFiber(t))));
      };
  });
  var killFiber = function (e) {
      return function (v) {
          return Control_Bind.bind(bindAff)(Effect_Class.liftEffect(monadEffectAff)(v.isSuspended))(function (v1) {
              if (v1) {
                  return Effect_Class.liftEffect(monadEffectAff)(Data_Functor["void"](Effect.functorEffect)(v.kill(e, Data_Function["const"](Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit)))));
              };
              return $foreign.makeAff(function (k) {
                  return Data_Functor.map(Effect.functorEffect)(effectCanceler)(v.kill(e, k));
              });
          });
      };
  };   
  var monadThrowAff = new Control_Monad_Error_Class.MonadThrow(function () {
      return monadAff;
  }, $foreign["_throwError"]);
  var monadErrorAff = new Control_Monad_Error_Class.MonadError(function () {
      return monadThrowAff;
  }, $foreign["_catchError"]);                                  
  var runAff = function (k) {
      return function (aff) {
          return launchAff(Control_Bind.bindFlipped(bindAff)((function () {
              var $46 = Effect_Class.liftEffect(monadEffectAff);
              return function ($47) {
                  return $46(k($47));
              };
          })())(Control_Monad_Error_Class["try"](monadErrorAff)(aff)));
      };
  };
  var runAff_ = function (k) {
      return function (aff) {
          return Data_Functor["void"](Effect.functorEffect)(runAff(k)(aff));
      };
  };
  var parallelAff = new Control_Parallel_Class.Parallel(function () {
      return applicativeParAff;
  }, function () {
      return monadAff;
  }, Unsafe_Coerce.unsafeCoerce, $foreign["_sequential"]);
  var applicativeParAff = new Control_Applicative.Applicative(function () {
      return applyParAff;
  }, (function () {
      var $50 = Control_Parallel_Class.parallel(parallelAff);
      var $51 = Control_Applicative.pure(applicativeAff);
      return function ($52) {
          return $50($51($52));
      };
  })());
  var monadRecAff = new Control_Monad_Rec_Class.MonadRec(function () {
      return monadAff;
  }, function (k) {
      var go = function (a) {
          return Control_Bind.bind(bindAff)(k(a))(function (res) {
              if (res instanceof Control_Monad_Rec_Class.Done) {
                  return Control_Applicative.pure(applicativeAff)(res.value0);
              };
              if (res instanceof Control_Monad_Rec_Class.Loop) {
                  return go(res.value0);
              };
              throw new Error("Failed pattern match at Effect.Aff (line 102, column 7 - line 104, column 22): " + [ res.constructor.name ]);
          });
      };
      return go;
  });
  var nonCanceler = Data_Function["const"](Control_Applicative.pure(applicativeAff)(Data_Unit.unit));
  exports["launchAff_"] = launchAff_;
  exports["runAff_"] = runAff_;
  exports["forkAff"] = forkAff;
  exports["suspendAff"] = suspendAff;
  exports["finally"] = $$finally;
  exports["killFiber"] = killFiber;
  exports["joinFiber"] = joinFiber;
  exports["nonCanceler"] = nonCanceler;
  exports["effectCanceler"] = effectCanceler;
  exports["functorAff"] = functorAff;
  exports["applicativeAff"] = applicativeAff;
  exports["bindAff"] = bindAff;
  exports["monadAff"] = monadAff;
  exports["monadRecAff"] = monadRecAff;
  exports["monadThrowAff"] = monadThrowAff;
  exports["monadEffectAff"] = monadEffectAff;
  exports["applicativeParAff"] = applicativeParAff;
  exports["parallelAff"] = parallelAff;
  exports["functorFiber"] = functorFiber;
  exports["makeAff"] = $foreign.makeAff;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Control.Monad.Fork.Class"] = $PS["Control.Monad.Fork.Class"] || {};
  var exports = $PS["Control.Monad.Fork.Class"];
  var Effect_Aff = $PS["Effect.Aff"];                
  var MonadFork = function (Functor1, Monad0, fork, join, suspend) {
      this.Functor1 = Functor1;
      this.Monad0 = Monad0;
      this.fork = fork;
      this.join = join;
      this.suspend = suspend;
  };
  var monadForkAff = new MonadFork(function () {
      return Effect_Aff.functorFiber;
  }, function () {
      return Effect_Aff.monadAff;
  }, Effect_Aff.forkAff, Effect_Aff.joinFiber, Effect_Aff.suspendAff);
  var fork = function (dict) {
      return dict.fork;
  };
  exports["fork"] = fork;
  exports["monadForkAff"] = monadForkAff;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.List"] = $PS["Data.List"] || {};
  var exports = $PS["Data.List"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Maybe = $PS["Data.Maybe"];                                            
  var uncons = function (v) {
      if (v instanceof Data_List_Types.Nil) {
          return Data_Maybe.Nothing.value;
      };
      if (v instanceof Data_List_Types.Cons) {
          return new Data_Maybe.Just({
              head: v.value0,
              tail: v.value1
          });
      };
      throw new Error("Failed pattern match at Data.List (line 263, column 1 - line 263, column 66): " + [ v.constructor.name ]);
  };
  var reverse = (function () {
      var go = function ($copy_acc) {
          return function ($copy_v) {
              var $tco_var_acc = $copy_acc;
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(acc, v) {
                  if (v instanceof Data_List_Types.Nil) {
                      $tco_done = true;
                      return acc;
                  };
                  if (v instanceof Data_List_Types.Cons) {
                      $tco_var_acc = new Data_List_Types.Cons(v.value0, acc);
                      $copy_v = v.value1;
                      return;
                  };
                  throw new Error("Failed pattern match at Data.List (line 372, column 3 - line 372, column 19): " + [ acc.constructor.name, v.constructor.name ]);
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($tco_var_acc, $copy_v);
              };
              return $tco_result;
          };
      };
      return go(Data_List_Types.Nil.value);
  })();
  var $$null = function (v) {
      if (v instanceof Data_List_Types.Nil) {
          return true;
      };
      return false;
  };
  exports["null"] = $$null;
  exports["uncons"] = uncons;
  exports["reverse"] = reverse;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.CatQueue"] = $PS["Data.CatQueue"] || {};
  var exports = $PS["Data.CatQueue"];
  var Data_List = $PS["Data.List"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Tuple = $PS["Data.Tuple"];                            
  var CatQueue = (function () {
      function CatQueue(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      CatQueue.create = function (value0) {
          return function (value1) {
              return new CatQueue(value0, value1);
          };
      };
      return CatQueue;
  })();
  var uncons = function ($copy_v) {
      var $tco_done = false;
      var $tco_result;
      function $tco_loop(v) {
          if (v.value0 instanceof Data_List_Types.Nil && v.value1 instanceof Data_List_Types.Nil) {
              $tco_done = true;
              return Data_Maybe.Nothing.value;
          };
          if (v.value0 instanceof Data_List_Types.Nil) {
              $copy_v = new CatQueue(Data_List.reverse(v.value1), Data_List_Types.Nil.value);
              return;
          };
          if (v.value0 instanceof Data_List_Types.Cons) {
              $tco_done = true;
              return new Data_Maybe.Just(new Data_Tuple.Tuple(v.value0.value0, new CatQueue(v.value0.value1, v.value1)));
          };
          throw new Error("Failed pattern match at Data.CatQueue (line 83, column 1 - line 83, column 63): " + [ v.constructor.name ]);
      };
      while (!$tco_done) {
          $tco_result = $tco_loop($copy_v);
      };
      return $tco_result;
  };
  var snoc = function (v) {
      return function (a) {
          return new CatQueue(v.value0, new Data_List_Types.Cons(a, v.value1));
      };
  };
  var $$null = function (v) {
      if (v.value0 instanceof Data_List_Types.Nil && v.value1 instanceof Data_List_Types.Nil) {
          return true;
      };
      return false;
  };                                                                                                
  var empty = new CatQueue(Data_List_Types.Nil.value, Data_List_Types.Nil.value);
  exports["empty"] = empty;
  exports["null"] = $$null;
  exports["snoc"] = snoc;
  exports["uncons"] = uncons;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.CatList"] = $PS["Data.CatList"] || {};
  var exports = $PS["Data.CatList"];
  var Data_CatQueue = $PS["Data.CatQueue"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_Tuple = $PS["Data.Tuple"];                            
  var CatNil = (function () {
      function CatNil() {

      };
      CatNil.value = new CatNil();
      return CatNil;
  })();
  var CatCons = (function () {
      function CatCons(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      CatCons.create = function (value0) {
          return function (value1) {
              return new CatCons(value0, value1);
          };
      };
      return CatCons;
  })();
  var link = function (v) {
      return function (v1) {
          if (v instanceof CatNil) {
              return v1;
          };
          if (v1 instanceof CatNil) {
              return v;
          };
          if (v instanceof CatCons) {
              return new CatCons(v.value0, Data_CatQueue.snoc(v.value1)(v1));
          };
          throw new Error("Failed pattern match at Data.CatList (line 109, column 1 - line 109, column 54): " + [ v.constructor.name, v1.constructor.name ]);
      };
  };
  var foldr = function (k) {
      return function (b) {
          return function (q) {
              var foldl = function ($copy_v) {
                  return function ($copy_c) {
                      return function ($copy_v1) {
                          var $tco_var_v = $copy_v;
                          var $tco_var_c = $copy_c;
                          var $tco_done = false;
                          var $tco_result;
                          function $tco_loop(v, c, v1) {
                              if (v1 instanceof Data_List_Types.Nil) {
                                  $tco_done = true;
                                  return c;
                              };
                              if (v1 instanceof Data_List_Types.Cons) {
                                  $tco_var_v = v;
                                  $tco_var_c = v(c)(v1.value0);
                                  $copy_v1 = v1.value1;
                                  return;
                              };
                              throw new Error("Failed pattern match at Data.CatList (line 125, column 3 - line 125, column 59): " + [ v.constructor.name, c.constructor.name, v1.constructor.name ]);
                          };
                          while (!$tco_done) {
                              $tco_result = $tco_loop($tco_var_v, $tco_var_c, $copy_v1);
                          };
                          return $tco_result;
                      };
                  };
              };
              var go = function ($copy_xs) {
                  return function ($copy_ys) {
                      var $tco_var_xs = $copy_xs;
                      var $tco_done1 = false;
                      var $tco_result;
                      function $tco_loop(xs, ys) {
                          var v = Data_CatQueue.uncons(xs);
                          if (v instanceof Data_Maybe.Nothing) {
                              $tco_done1 = true;
                              return foldl(function (x) {
                                  return function (i) {
                                      return i(x);
                                  };
                              })(b)(ys);
                          };
                          if (v instanceof Data_Maybe.Just) {
                              $tco_var_xs = v.value0.value1;
                              $copy_ys = new Data_List_Types.Cons(k(v.value0.value0), ys);
                              return;
                          };
                          throw new Error("Failed pattern match at Data.CatList (line 121, column 14 - line 123, column 67): " + [ v.constructor.name ]);
                      };
                      while (!$tco_done1) {
                          $tco_result = $tco_loop($tco_var_xs, $copy_ys);
                      };
                      return $tco_result;
                  };
              };
              return go(q)(Data_List_Types.Nil.value);
          };
      };
  };
  var uncons = function (v) {
      if (v instanceof CatNil) {
          return Data_Maybe.Nothing.value;
      };
      if (v instanceof CatCons) {
          return new Data_Maybe.Just(new Data_Tuple.Tuple(v.value0, (function () {
              var $45 = Data_CatQueue["null"](v.value1);
              if ($45) {
                  return CatNil.value;
              };
              return foldr(link)(CatNil.value)(v.value1);
          })()));
      };
      throw new Error("Failed pattern match at Data.CatList (line 100, column 1 - line 100, column 61): " + [ v.constructor.name ]);
  };
  var empty = CatNil.value;
  var append = link;
  var semigroupCatList = new Data_Semigroup.Semigroup(append);
  var snoc = function (cat) {
      return function (a) {
          return append(cat)(new CatCons(a, Data_CatQueue.empty));
      };
  };
  exports["empty"] = empty;
  exports["snoc"] = snoc;
  exports["uncons"] = uncons;
  exports["semigroupCatList"] = semigroupCatList;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Control.Monad.Free"] = $PS["Control.Monad.Free"] || {};
  var exports = $PS["Control.Monad.Free"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad = $PS["Control.Monad"];
  var Control_Monad_Rec_Class = $PS["Control.Monad.Rec.Class"];
  var Data_CatList = $PS["Data.CatList"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Free = (function () {
      function Free(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Free.create = function (value0) {
          return function (value1) {
              return new Free(value0, value1);
          };
      };
      return Free;
  })();
  var Return = (function () {
      function Return(value0) {
          this.value0 = value0;
      };
      Return.create = function (value0) {
          return new Return(value0);
      };
      return Return;
  })();
  var Bind = (function () {
      function Bind(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Bind.create = function (value0) {
          return function (value1) {
              return new Bind(value0, value1);
          };
      };
      return Bind;
  })();
  var toView = function ($copy_v) {
      var $tco_done = false;
      var $tco_result;
      function $tco_loop(v) {
          var runExpF = function (v2) {
              return v2;
          };
          var concatF = function (v2) {
              return function (r) {
                  return new Free(v2.value0, Data_Semigroup.append(Data_CatList.semigroupCatList)(v2.value1)(r));
              };
          };
          if (v.value0 instanceof Return) {
              var v2 = Data_CatList.uncons(v.value1);
              if (v2 instanceof Data_Maybe.Nothing) {
                  $tco_done = true;
                  return new Return(v.value0.value0);
              };
              if (v2 instanceof Data_Maybe.Just) {
                  $copy_v = concatF(runExpF(v2.value0.value0)(v.value0.value0))(v2.value0.value1);
                  return;
              };
              throw new Error("Failed pattern match at Control.Monad.Free (line 227, column 7 - line 231, column 64): " + [ v2.constructor.name ]);
          };
          if (v.value0 instanceof Bind) {
              $tco_done = true;
              return new Bind(v.value0.value0, function (a) {
                  return concatF(v.value0.value1(a))(v.value1);
              });
          };
          throw new Error("Failed pattern match at Control.Monad.Free (line 225, column 3 - line 233, column 56): " + [ v.value0.constructor.name ]);
      };
      while (!$tco_done) {
          $tco_result = $tco_loop($copy_v);
      };
      return $tco_result;
  };
  var fromView = function (f) {
      return new Free(f, Data_CatList.empty);
  };
  var freeMonad = new Control_Monad.Monad(function () {
      return freeApplicative;
  }, function () {
      return freeBind;
  });
  var freeFunctor = new Data_Functor.Functor(function (k) {
      return function (f) {
          return Control_Bind.bindFlipped(freeBind)((function () {
              var $119 = Control_Applicative.pure(freeApplicative);
              return function ($120) {
                  return $119(k($120));
              };
          })())(f);
      };
  });
  var freeBind = new Control_Bind.Bind(function () {
      return freeApply;
  }, function (v) {
      return function (k) {
          return new Free(v.value0, Data_CatList.snoc(v.value1)(k));
      };
  });
  var freeApply = new Control_Apply.Apply(function () {
      return freeFunctor;
  }, Control_Monad.ap(freeMonad));
  var freeApplicative = new Control_Applicative.Applicative(function () {
      return freeApply;
  }, function ($121) {
      return fromView(Return.create($121));
  });
  var liftF = function (f) {
      return fromView(new Bind(f, (function () {
          var $122 = Control_Applicative.pure(freeApplicative);
          return function ($123) {
              return $122($123);
          };
      })()));
  };
  var substFree = function (k) {
      var go = function (f) {
          var v = toView(f);
          if (v instanceof Return) {
              return Control_Applicative.pure(freeApplicative)(v.value0);
          };
          if (v instanceof Bind) {
              return Control_Bind.bind(freeBind)(k(v.value0))(Data_Functor.map(Data_Functor.functorFn)(go)(v.value1));
          };
          throw new Error("Failed pattern match at Control.Monad.Free (line 168, column 10 - line 170, column 33): " + [ v.constructor.name ]);
      };
      return go;
  };
  var hoistFree = function (k) {
      return substFree(function ($124) {
          return liftF(k($124));
      });
  };
  var foldFree = function (dictMonadRec) {
      return function (k) {
          var go = function (f) {
              var v = toView(f);
              if (v instanceof Return) {
                  return Data_Functor.map((((dictMonadRec.Monad0()).Bind1()).Apply0()).Functor0())(Control_Monad_Rec_Class.Done.create)(Control_Applicative.pure((dictMonadRec.Monad0()).Applicative0())(v.value0));
              };
              if (v instanceof Bind) {
                  return Data_Functor.map((((dictMonadRec.Monad0()).Bind1()).Apply0()).Functor0())(function ($135) {
                      return Control_Monad_Rec_Class.Loop.create(v.value1($135));
                  })(k(v.value0));
              };
              throw new Error("Failed pattern match at Control.Monad.Free (line 158, column 10 - line 160, column 37): " + [ v.constructor.name ]);
          };
          return Control_Monad_Rec_Class.tailRecM(dictMonadRec)(go);
      };
  };
  exports["liftF"] = liftF;
  exports["hoistFree"] = hoistFree;
  exports["foldFree"] = foldFree;
  exports["freeFunctor"] = freeFunctor;
  exports["freeBind"] = freeBind;
  exports["freeApplicative"] = freeApplicative;
  exports["freeMonad"] = freeMonad;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Control.Monad.Reader.Class"] = $PS["Control.Monad.Reader.Class"] || {};
  var exports = $PS["Control.Monad.Reader.Class"];       
  var MonadAsk = function (Monad0, ask) {
      this.Monad0 = Monad0;
      this.ask = ask;
  };
  var ask = function (dict) {
      return dict.ask;
  };
  exports["ask"] = ask;
  exports["MonadAsk"] = MonadAsk;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Control.Monad.Trans.Class"] = $PS["Control.Monad.Trans.Class"] || {};
  var exports = $PS["Control.Monad.Trans.Class"];
  var MonadTrans = function (lift) {
      this.lift = lift;
  };
  var lift = function (dict) {
      return dict.lift;
  };
  exports["lift"] = lift;
  exports["MonadTrans"] = MonadTrans;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Control.Monad.Reader.Trans"] = $PS["Control.Monad.Reader.Trans"] || {};
  var exports = $PS["Control.Monad.Reader.Trans"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad = $PS["Control.Monad"];
  var Control_Monad_Reader_Class = $PS["Control.Monad.Reader.Class"];
  var Control_Monad_Trans_Class = $PS["Control.Monad.Trans.Class"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Effect_Class = $PS["Effect.Class"];                
  var ReaderT = function (x) {
      return x;
  };
  var runReaderT = function (v) {
      return v;
  }; 
  var monadTransReaderT = new Control_Monad_Trans_Class.MonadTrans(function (dictMonad) {
      return function ($64) {
          return ReaderT(Data_Function["const"]($64));
      };
  });
  var mapReaderT = function (f) {
      return function (v) {
          return function ($65) {
              return f(v($65));
          };
      };
  };
  var functorReaderT = function (dictFunctor) {
      return new Data_Functor.Functor((function () {
          var $66 = Data_Functor.map(dictFunctor);
          return function ($67) {
              return mapReaderT($66($67));
          };
      })());
  };
  var applyReaderT = function (dictApply) {
      return new Control_Apply.Apply(function () {
          return functorReaderT(dictApply.Functor0());
      }, function (v) {
          return function (v1) {
              return function (r) {
                  return Control_Apply.apply(dictApply)(v(r))(v1(r));
              };
          };
      });
  };
  var bindReaderT = function (dictBind) {
      return new Control_Bind.Bind(function () {
          return applyReaderT(dictBind.Apply0());
      }, function (v) {
          return function (k) {
              return function (r) {
                  return Control_Bind.bind(dictBind)(v(r))(function (a) {
                      var v1 = k(a);
                      return v1(r);
                  });
              };
          };
      });
  };
  var applicativeReaderT = function (dictApplicative) {
      return new Control_Applicative.Applicative(function () {
          return applyReaderT(dictApplicative.Apply0());
      }, (function () {
          var $71 = Control_Applicative.pure(dictApplicative);
          return function ($72) {
              return ReaderT(Data_Function["const"]($71($72)));
          };
      })());
  };
  var monadReaderT = function (dictMonad) {
      return new Control_Monad.Monad(function () {
          return applicativeReaderT(dictMonad.Applicative0());
      }, function () {
          return bindReaderT(dictMonad.Bind1());
      });
  };
  var monadAskReaderT = function (dictMonad) {
      return new Control_Monad_Reader_Class.MonadAsk(function () {
          return monadReaderT(dictMonad);
      }, Control_Applicative.pure(dictMonad.Applicative0()));
  };
  var monadEffectReader = function (dictMonadEffect) {
      return new Effect_Class.MonadEffect(function () {
          return monadReaderT(dictMonadEffect.Monad0());
      }, (function () {
          var $74 = Control_Monad_Trans_Class.lift(monadTransReaderT)(dictMonadEffect.Monad0());
          var $75 = Effect_Class.liftEffect(dictMonadEffect);
          return function ($76) {
              return $74($75($76));
          };
      })());
  };
  exports["runReaderT"] = runReaderT;
  exports["bindReaderT"] = bindReaderT;
  exports["monadReaderT"] = monadReaderT;
  exports["monadTransReaderT"] = monadTransReaderT;
  exports["monadEffectReader"] = monadEffectReader;
  exports["monadAskReaderT"] = monadAskReaderT;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Control.Monad.State.Class"] = $PS["Control.Monad.State.Class"] || {};
  var exports = $PS["Control.Monad.State.Class"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Data_Unit = $PS["Data.Unit"];                
  var MonadState = function (Monad0, state) {
      this.Monad0 = Monad0;
      this.state = state;
  };
  var state = function (dict) {
      return dict.state;
  };
  var modify_ = function (dictMonadState) {
      return function (f) {
          return state(dictMonadState)(function (s) {
              return new Data_Tuple.Tuple(Data_Unit.unit, f(s));
          });
      };
  };
  var gets = function (dictMonadState) {
      return function (f) {
          return state(dictMonadState)(function (s) {
              return new Data_Tuple.Tuple(f(s), s);
          });
      };
  };
  var get = function (dictMonadState) {
      return state(dictMonadState)(function (s) {
          return new Data_Tuple.Tuple(s, s);
      });
  };
  exports["MonadState"] = MonadState;
  exports["get"] = get;
  exports["gets"] = gets;
  exports["modify_"] = modify_;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Control.Parallel"] = $PS["Control.Parallel"] || {};
  var exports = $PS["Control.Parallel"];
  var Control_Category = $PS["Control.Category"];
  var Control_Parallel_Class = $PS["Control.Parallel.Class"];
  var Data_Foldable = $PS["Data.Foldable"];                      
  var parTraverse_ = function (dictParallel) {
      return function (dictFoldable) {
          return function (f) {
              var $17 = Control_Parallel_Class.sequential(dictParallel);
              var $18 = Data_Foldable.traverse_(dictParallel.Applicative1())(dictFoldable)((function () {
                  var $20 = Control_Parallel_Class.parallel(dictParallel);
                  return function ($21) {
                      return $20(f($21));
                  };
              })());
              return function ($19) {
                  return $17($18($19));
              };
          };
      };
  };
  var parSequence_ = function (dictParallel) {
      return function (dictFoldable) {
          return parTraverse_(dictParallel)(dictFoldable)(Control_Category.identity(Control_Category.categoryFn));
      };
  };
  exports["parSequence_"] = parSequence_;
})(PS);
(function(exports) {
  /* eslint-disable no-eq-null, eqeqeq */
  "use strict";

  function id(x) {
    return x;
  }                       
  exports.fromString = id;

  function isArray(a) {
    return Object.prototype.toString.call(a) === "[object Array]";
  }

  exports._caseJson = function (isNull, isBool, isNum, isStr, isArr, isObj, j) {
    if (j == null) return isNull();
    else if (typeof j === "boolean") return isBool(j);
    else if (typeof j === "number") return isNum(j);
    else if (typeof j === "string") return isStr(j);
    else if (Object.prototype.toString.call(j) === "[object Array]")
      return isArr(j);
    else return isObj(j);
  };
})(PS["Data.Argonaut.Core"] = PS["Data.Argonaut.Core"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Argonaut.Core"] = $PS["Data.Argonaut.Core"] || {};
  var exports = $PS["Data.Argonaut.Core"];
  var $foreign = $PS["Data.Argonaut.Core"];
  var Data_Function = $PS["Data.Function"];
  var Data_Maybe = $PS["Data.Maybe"];                        
  var verbJsonType = function (def) {
      return function (f) {
          return function (g) {
              return g(def)(f);
          };
      };
  };
  var toJsonType = verbJsonType(Data_Maybe.Nothing.value)(Data_Maybe.Just.create);
  var caseJsonString = function (d) {
      return function (f) {
          return function (j) {
              return $foreign["_caseJson"](Data_Function["const"](d), Data_Function["const"](d), Data_Function["const"](d), f, Data_Function["const"](d), Data_Function["const"](d), j);
          };
      };
  };                                        
  var caseJsonObject = function (d) {
      return function (f) {
          return function (j) {
              return $foreign["_caseJson"](Data_Function["const"](d), Data_Function["const"](d), Data_Function["const"](d), Data_Function["const"](d), Data_Function["const"](d), f, j);
          };
      };
  };                                        
  var toObject = toJsonType(caseJsonObject);
  exports["caseJsonString"] = caseJsonString;
  exports["toObject"] = toObject;
  exports["fromString"] = $foreign.fromString;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Argonaut.Decode.Error"] = $PS["Data.Argonaut.Decode.Error"] || {};
  var exports = $PS["Data.Argonaut.Decode.Error"]; 
  var TypeMismatch = (function () {
      function TypeMismatch(value0) {
          this.value0 = value0;
      };
      TypeMismatch.create = function (value0) {
          return new TypeMismatch(value0);
      };
      return TypeMismatch;
  })();
  var AtKey = (function () {
      function AtKey(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      AtKey.create = function (value0) {
          return function (value1) {
              return new AtKey(value0, value1);
          };
      };
      return AtKey;
  })();
  var MissingValue = (function () {
      function MissingValue() {

      };
      MissingValue.value = new MissingValue();
      return MissingValue;
  })();
  exports["TypeMismatch"] = TypeMismatch;
  exports["AtKey"] = AtKey;
  exports["MissingValue"] = MissingValue;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Argonaut.Decode.Decoders"] = $PS["Data.Argonaut.Decode.Decoders"] || {};
  var exports = $PS["Data.Argonaut.Decode.Decoders"];
  var Data_Argonaut_Core = $PS["Data.Argonaut.Core"];
  var Data_Argonaut_Decode_Error = $PS["Data.Argonaut.Decode.Error"];
  var Data_Either = $PS["Data.Either"];
  var decodeString = Data_Argonaut_Core.caseJsonString(Data_Either.Left.create(new Data_Argonaut_Decode_Error.TypeMismatch("String")))(Data_Either.Right.create);
  exports["decodeString"] = decodeString;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Bifunctor"] = $PS["Data.Bifunctor"] || {};
  var exports = $PS["Data.Bifunctor"];
  var Control_Category = $PS["Control.Category"];
  var Data_Either = $PS["Data.Either"];
  var Data_Tuple = $PS["Data.Tuple"];                
  var Bifunctor = function (bimap) {
      this.bimap = bimap;
  };
  var bimap = function (dict) {
      return dict.bimap;
  };
  var lmap = function (dictBifunctor) {
      return function (f) {
          return bimap(dictBifunctor)(f)(Control_Category.identity(Control_Category.categoryFn));
      };
  };
  var rmap = function (dictBifunctor) {
      return bimap(dictBifunctor)(Control_Category.identity(Control_Category.categoryFn));
  };
  var bifunctorTuple = new Bifunctor(function (f) {
      return function (g) {
          return function (v) {
              return new Data_Tuple.Tuple(f(v.value0), g(v.value1));
          };
      };
  });
  var bifunctorEither = new Bifunctor(function (v) {
      return function (v1) {
          return function (v2) {
              if (v2 instanceof Data_Either.Left) {
                  return new Data_Either.Left(v(v2.value0));
              };
              if (v2 instanceof Data_Either.Right) {
                  return new Data_Either.Right(v1(v2.value0));
              };
              throw new Error("Failed pattern match at Data.Bifunctor (line 32, column 1 - line 34, column 36): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
          };
      };
  });
  exports["bimap"] = bimap;
  exports["Bifunctor"] = Bifunctor;
  exports["lmap"] = lmap;
  exports["rmap"] = rmap;
  exports["bifunctorEither"] = bifunctorEither;
  exports["bifunctorTuple"] = bifunctorTuple;
})(PS);
(function(exports) {
  "use strict";

  exports._lookup = function (no, yes, k, m) {
    return k in m ? yes(m[k]) : no;
  };

  function toArrayWithKey(f) {
    return function (m) {
      var r = [];
      for (var k in m) {
        if (hasOwnProperty.call(m, k)) {
          r.push(f(k)(m[k]));
        }
      }
      return r;
    };
  }
})(PS["Foreign.Object"] = PS["Foreign.Object"] || {});
(function(exports) {
  "use strict";

  exports.runFn3 = function (fn) {
    return function (a) {
      return function (b) {
        return function (c) {
          return fn(a, b, c);
        };
      };
    };
  };

  exports.runFn4 = function (fn) {
    return function (a) {
      return function (b) {
        return function (c) {
          return function (d) {
            return fn(a, b, c, d);
          };
        };
      };
    };
  };
})(PS["Data.Function.Uncurried"] = PS["Data.Function.Uncurried"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Function.Uncurried"] = $PS["Data.Function.Uncurried"] || {};
  var exports = $PS["Data.Function.Uncurried"];
  var $foreign = $PS["Data.Function.Uncurried"];
  exports["runFn3"] = $foreign.runFn3;
  exports["runFn4"] = $foreign.runFn4;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Foreign.Object"] = $PS["Foreign.Object"] || {};
  var exports = $PS["Foreign.Object"];
  var $foreign = $PS["Foreign.Object"];
  var Data_Function_Uncurried = $PS["Data.Function.Uncurried"];
  var Data_Maybe = $PS["Data.Maybe"];
  var lookup = Data_Function_Uncurried.runFn4($foreign["_lookup"])(Data_Maybe.Nothing.value)(Data_Maybe.Just.create);
  exports["lookup"] = lookup;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Record"] = $PS["Record"] || {};
  var exports = $PS["Record"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Record_Unsafe = $PS["Record.Unsafe"];
  var insert = function (dictIsSymbol) {
      return function (dictLacks) {
          return function (dictCons) {
              return function (l) {
                  return function (a) {
                      return function (r) {
                          return Record_Unsafe.unsafeSet(Data_Symbol.reflectSymbol(dictIsSymbol)(l))(a)(r);
                      };
                  };
              };
          };
      };
  };
  var get = function (dictIsSymbol) {
      return function (dictCons) {
          return function (l) {
              return function (r) {
                  return Record_Unsafe.unsafeGet(Data_Symbol.reflectSymbol(dictIsSymbol)(l))(r);
              };
          };
      };
  };
  exports["get"] = get;
  exports["insert"] = insert;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Argonaut.Decode.Class"] = $PS["Data.Argonaut.Decode.Class"] || {};
  var exports = $PS["Data.Argonaut.Decode.Class"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Argonaut_Core = $PS["Data.Argonaut.Core"];
  var Data_Argonaut_Decode_Decoders = $PS["Data.Argonaut.Decode.Decoders"];
  var Data_Argonaut_Decode_Error = $PS["Data.Argonaut.Decode.Error"];
  var Data_Bifunctor = $PS["Data.Bifunctor"];
  var Data_Either = $PS["Data.Either"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Foreign_Object = $PS["Foreign.Object"];
  var Record = $PS["Record"];
  var Type_Proxy = $PS["Type.Proxy"];                
  var GDecodeJson = function (gDecodeJson) {
      this.gDecodeJson = gDecodeJson;
  };
  var DecodeJsonField = function (decodeJsonField) {
      this.decodeJsonField = decodeJsonField;
  };
  var DecodeJson = function (decodeJson) {
      this.decodeJson = decodeJson;
  };
  var gDecodeJsonNil = new GDecodeJson(function (v) {
      return function (v1) {
          return new Data_Either.Right({});
      };
  });
  var gDecodeJson = function (dict) {
      return dict.gDecodeJson;
  };                                                                        
  var decodeRecord = function (dictGDecodeJson) {
      return function (dictRowToList) {
          return new DecodeJson(function (json) {
              var v = Data_Argonaut_Core.toObject(json);
              if (v instanceof Data_Maybe.Just) {
                  return gDecodeJson(dictGDecodeJson)(v.value0)(Type_Proxy["Proxy"].value);
              };
              if (v instanceof Data_Maybe.Nothing) {
                  return Data_Either.Left.create(new Data_Argonaut_Decode_Error.TypeMismatch("Object"));
              };
              throw new Error("Failed pattern match at Data.Argonaut.Decode.Class (line 103, column 5 - line 105, column 46): " + [ v.constructor.name ]);
          });
      };
  };
  var decodeJsonString = new DecodeJson(Data_Argonaut_Decode_Decoders.decodeString);
  var decodeJsonField = function (dict) {
      return dict.decodeJsonField;
  };
  var gDecodeJsonCons = function (dictDecodeJsonField) {
      return function (dictGDecodeJson) {
          return function (dictIsSymbol) {
              return function (dictCons) {
                  return function (dictLacks) {
                      return new GDecodeJson(function (object) {
                          return function (v) {
                              var fieldName = Data_Symbol.reflectSymbol(dictIsSymbol)(Type_Proxy["Proxy"].value);
                              var fieldValue = Foreign_Object.lookup(fieldName)(object);
                              var v1 = decodeJsonField(dictDecodeJsonField)(fieldValue);
                              if (v1 instanceof Data_Maybe.Just) {
                                  return Control_Bind.bind(Data_Either.bindEither)(Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither)(Data_Argonaut_Decode_Error.AtKey.create(fieldName))(v1.value0))(function (val) {
                                      return Control_Bind.bind(Data_Either.bindEither)(gDecodeJson(dictGDecodeJson)(object)(Type_Proxy["Proxy"].value))(function (rest) {
                                          return Data_Either.Right.create(Record.insert(dictIsSymbol)()()(Type_Proxy["Proxy"].value)(val)(rest));
                                      });
                                  });
                              };
                              if (v1 instanceof Data_Maybe.Nothing) {
                                  return Data_Either.Left.create(new Data_Argonaut_Decode_Error.AtKey(fieldName, Data_Argonaut_Decode_Error.MissingValue.value));
                              };
                              throw new Error("Failed pattern match at Data.Argonaut.Decode.Class (line 127, column 5 - line 134, column 44): " + [ v1.constructor.name ]);
                          };
                      });
                  };
              };
          };
      };
  };                                                                                  
  var decodeJson = function (dict) {
      return dict.decodeJson;
  };
  var decodeFieldId = function (dictDecodeJson) {
      return new DecodeJsonField(function (j) {
          return Data_Functor.map(Data_Maybe.functorMaybe)(decodeJson(dictDecodeJson))(j);
      });
  };
  exports["decodeJson"] = decodeJson;
  exports["decodeJsonString"] = decodeJsonString;
  exports["decodeRecord"] = decodeRecord;
  exports["gDecodeJsonNil"] = gDecodeJsonNil;
  exports["gDecodeJsonCons"] = gDecodeJsonCons;
  exports["decodeFieldId"] = decodeFieldId;
})(PS);
(function(exports) {
  "use strict";

  //------------------------------------------------------------------------------
  // Array creation --------------------------------------------------------------
  //------------------------------------------------------------------------------

  exports.range = function (start) {
    return function (end) {
      var step = start > end ? -1 : 1;
      var result = new Array(step * (end - start) + 1);
      var i = start, n = 0;
      while (i !== end) {
        result[n++] = i;
        i += step;
      }
      result[n] = i;
      return result;
    };
  };                                                                                                 

  exports.fromFoldableImpl = (function () {
    function Cons(head, tail) {
      this.head = head;
      this.tail = tail;
    }
    var emptyList = {};

    function curryCons(head) {
      return function (tail) {
        return new Cons(head, tail);
      };
    }

    function listToArray(list) {
      var result = [];
      var count = 0;
      var xs = list;
      while (xs !== emptyList) {
        result[count++] = xs.head;
        xs = xs.tail;
      }
      return result;
    }

    return function (foldr) {
      return function (xs) {
        return listToArray(foldr(curryCons)(emptyList)(xs));
      };
    };
  })();

  //------------------------------------------------------------------------------
  // Array size ------------------------------------------------------------------
  //------------------------------------------------------------------------------

  exports.length = function (xs) {
    return xs.length;
  };

  //------------------------------------------------------------------------------
  // Non-indexed reads -----------------------------------------------------------
  //------------------------------------------------------------------------------

  exports.unconsImpl = function (empty) {
    return function (next) {
      return function (xs) {
        return xs.length === 0 ? empty({}) : next(xs[0])(xs.slice(1));
      };
    };
  };

  //------------------------------------------------------------------------------
  // Indexed operations ----------------------------------------------------------
  //------------------------------------------------------------------------------

  exports.indexImpl = function (just) {
    return function (nothing) {
      return function (xs) {
        return function (i) {
          return i < 0 || i >= xs.length ? nothing :  just(xs[i]);
        };
      };
    };
  };

  exports.findIndexImpl = function (just) {
    return function (nothing) {
      return function (f) {
        return function (xs) {
          for (var i = 0, l = xs.length; i < l; i++) {
            if (f(xs[i])) return just(i);
          }
          return nothing;
        };
      };
    };
  };

  exports._deleteAt = function (just) {
    return function (nothing) {
      return function (i) {
        return function (l) {
          if (i < 0 || i >= l.length) return nothing;
          var l1 = l.slice();
          l1.splice(i, 1);
          return just(l1);
        };
      };
    };
  };

  exports.concat = function (xss) {
    if (xss.length <= 10000) {
      // This method is faster, but it crashes on big arrays.
      // So we use it when can and fallback to simple variant otherwise.
      return Array.prototype.concat.apply([], xss);
    }

    var result = [];
    for (var i = 0, l = xss.length; i < l; i++) {
      var xs = xss[i];
      for (var j = 0, m = xs.length; j < m; j++) {
        result.push(xs[j]);
      }
    }
    return result;
  };

  exports.filter = function (f) {
    return function (xs) {
      return xs.filter(f);
    };
  };   

  //------------------------------------------------------------------------------
  // Subarrays -------------------------------------------------------------------
  //------------------------------------------------------------------------------

  exports.slice = function (s) {
    return function (e) {
      return function (l) {
        return l.slice(s, e);
      };
    };
  };

  //------------------------------------------------------------------------------
  // Partial ---------------------------------------------------------------------
  //------------------------------------------------------------------------------

  exports.unsafeIndexImpl = function (xs) {
    return function (n) {
      return xs[n];
    };
  };
})(PS["Data.Array"] = PS["Data.Array"] || {});
(function(exports) {
  "use strict";

  exports.pushAll = function (as) {
    return function (xs) {
      return function () {
        return xs.push.apply(xs, as);
      };
    };
  };

  exports.unsafeFreeze = function (xs) {
    return function () {
      return xs;
    };
  };

  function copyImpl(xs) {
    return function () {
      return xs.slice();
    };
  }                         

  exports.thaw = copyImpl;
})(PS["Data.Array.ST"] = PS["Data.Array.ST"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Array.ST"] = $PS["Data.Array.ST"] || {};
  var exports = $PS["Data.Array.ST"];
  var $foreign = $PS["Data.Array.ST"];                     
  var withArray = function (f) {
      return function (xs) {
          return function __do() {
              var result = $foreign.thaw(xs)();
              f(result)();
              return $foreign.unsafeFreeze(result)();
          };
      };
  };
  var push = function (a) {
      return $foreign.pushAll([ a ]);
  };
  exports["withArray"] = withArray;
  exports["push"] = push;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Boolean"] = $PS["Data.Boolean"] || {};
  var exports = $PS["Data.Boolean"];
  var otherwise = true;
  exports["otherwise"] = otherwise;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Array"] = $PS["Data.Array"] || {};
  var exports = $PS["Data.Array"];
  var $foreign = $PS["Data.Array"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Category = $PS["Control.Category"];
  var Data_Array_ST = $PS["Data.Array.ST"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Semigroup = $PS["Data.Semigroup"];                                            
  var unsafeIndex = function (dictPartial) {
      return $foreign.unsafeIndexImpl;
  };
  var uncons = $foreign.unconsImpl(Data_Function["const"](Data_Maybe.Nothing.value))(function (x) {
      return function (xs) {
          return new Data_Maybe.Just({
              head: x,
              tail: xs
          });
      };
  });
  var tail = $foreign.unconsImpl(Data_Function["const"](Data_Maybe.Nothing.value))(function (v) {
      return function (xs) {
          return new Data_Maybe.Just(xs);
      };
  });
  var snoc = function (xs) {
      return function (x) {
          return Data_Array_ST.withArray(Data_Array_ST.push(x))(xs)();
      };
  };
  var singleton = function (a) {
      return [ a ];
  };
  var $$null = function (xs) {
      return $foreign.length(xs) === 0;
  };                                                                                     
  var init = function (xs) {
      if ($$null(xs)) {
          return Data_Maybe.Nothing.value;
      };
      if (Data_Boolean.otherwise) {
          return new Data_Maybe.Just($foreign.slice(0)($foreign.length(xs) - 1 | 0)(xs));
      };
      throw new Error("Failed pattern match at Data.Array (line 340, column 1 - line 340, column 45): " + [ xs.constructor.name ]);
  };
  var index = $foreign.indexImpl(Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
  var last = function (xs) {
      return index(xs)($foreign.length(xs) - 1 | 0);
  };
  var head = function (xs) {
      return index(xs)(0);
  };
  var fromFoldable = function (dictFoldable) {
      return $foreign.fromFoldableImpl(Data_Foldable.foldr(dictFoldable));
  };
  var foldr = Data_Foldable.foldr(Data_Foldable.foldableArray);
  var findIndex = $foreign.findIndexImpl(Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
  var find = function (f) {
      return function (xs) {
          return Data_Functor.map(Data_Maybe.functorMaybe)(unsafeIndex()(xs))(findIndex(f)(xs));
      };
  };
  var drop = function (n) {
      return function (xs) {
          var $89 = n < 1;
          if ($89) {
              return xs;
          };
          return $foreign.slice(n)($foreign.length(xs))(xs);
      };
  };
  var deleteAt = $foreign["_deleteAt"](Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
  var deleteBy = function (v) {
      return function (v1) {
          return function (v2) {
              if (v2.length === 0) {
                  return [  ];
              };
              return Data_Maybe.maybe(v2)(function (i) {
                  return Data_Maybe.fromJust()(deleteAt(i)(v2));
              })(findIndex(v(v1))(v2));
          };
      };
  };
  var cons = function (x) {
      return function (xs) {
          return Data_Semigroup.append(Data_Semigroup.semigroupArray)([ x ])(xs);
      };
  };
  var concatMap = Data_Function.flip(Control_Bind.bind(Control_Bind.bindArray));
  var mapMaybe = function (f) {
      return concatMap((function () {
          var $109 = Data_Maybe.maybe([  ])(singleton);
          return function ($110) {
              return $109(f($110));
          };
      })());
  };
  var catMaybes = mapMaybe(Control_Category.identity(Control_Category.categoryFn));
  exports["fromFoldable"] = fromFoldable;
  exports["singleton"] = singleton;
  exports["cons"] = cons;
  exports["snoc"] = snoc;
  exports["head"] = head;
  exports["last"] = last;
  exports["tail"] = tail;
  exports["init"] = init;
  exports["uncons"] = uncons;
  exports["index"] = index;
  exports["find"] = find;
  exports["catMaybes"] = catMaybes;
  exports["drop"] = drop;
  exports["deleteBy"] = deleteBy;
  exports["range"] = $foreign.range;
  exports["length"] = $foreign.length;
  exports["concat"] = $foreign.concat;
  exports["filter"] = $foreign.filter;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Array.NonEmpty.Internal"] = $PS["Data.Array.NonEmpty.Internal"] || {};
  var exports = $PS["Data.Array.NonEmpty.Internal"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Semigroup = $PS["Data.Semigroup"];                    
  var NonEmptyArray = function (x) {
      return x;
  };
  var semigroupNonEmptyArray = Data_Semigroup.semigroupArray;                     
  var functorNonEmptyArray = Data_Functor.functorArray;                              
  var foldableNonEmptyArray = Data_Foldable.foldableArray;
  exports["NonEmptyArray"] = NonEmptyArray;
  exports["semigroupNonEmptyArray"] = semigroupNonEmptyArray;
  exports["functorNonEmptyArray"] = functorNonEmptyArray;
  exports["foldableNonEmptyArray"] = foldableNonEmptyArray;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Array.NonEmpty"] = $PS["Data.Array.NonEmpty"] || {};
  var exports = $PS["Data.Array.NonEmpty"];
  var Data_Array = $PS["Data.Array"];
  var Data_Array_NonEmpty_Internal = $PS["Data.Array.NonEmpty.Internal"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Maybe = $PS["Data.Maybe"];               
  var unsafeFromArray = Data_Array_NonEmpty_Internal.NonEmptyArray;
  var toArray = function (v) {
      return v;
  };
  var snoc$prime = function (xs) {
      return function (x) {
          return unsafeFromArray(Data_Array.snoc(xs)(x));
      };
  };
  var snoc = function (xs) {
      return function (x) {
          return unsafeFromArray(Data_Array.snoc(toArray(xs))(x));
      };
  };
  var singleton = function ($60) {
      return unsafeFromArray(Data_Array.singleton($60));
  };
  var fromArray = function (xs) {
      if (Data_Array.length(xs) > 0) {
          return new Data_Maybe.Just(unsafeFromArray(xs));
      };
      if (Data_Boolean.otherwise) {
          return Data_Maybe.Nothing.value;
      };
      throw new Error("Failed pattern match at Data.Array.NonEmpty (line 159, column 1 - line 159, column 58): " + [ xs.constructor.name ]);
  };
  var cons$prime = function (x) {
      return function (xs) {
          return unsafeFromArray(Data_Array.cons(x)(xs));
      };
  };
  var adaptMaybe = function (f) {
      var $75 = Data_Maybe.fromJust();
      return function ($76) {
          return $75(f(toArray($76)));
      };
  };
  var head = adaptMaybe(Data_Array.head);
  var init = adaptMaybe(Data_Array.init);
  var last = adaptMaybe(Data_Array.last);
  var tail = adaptMaybe(Data_Array.tail);    
  var adaptAny = function (f) {
      return function ($78) {
          return f(toArray($78));
      };
  };                                     
  var length = adaptAny(Data_Array.length);
  var unsafeAdapt = function (f) {
      var $79 = adaptAny(f);
      return function ($80) {
          return unsafeFromArray($79($80));
      };
  };
  var cons = function (x) {
      return unsafeAdapt(Data_Array.cons(x));
  };
  exports["fromArray"] = fromArray;
  exports["singleton"] = singleton;
  exports["cons"] = cons;
  exports["cons'"] = cons$prime;
  exports["snoc"] = snoc;
  exports["snoc'"] = snoc$prime;
  exports["head"] = head;
  exports["last"] = last;
  exports["tail"] = tail;
  exports["init"] = init;
})(PS);
(function(exports) {
  "use strict";

  exports.topInt = 2147483647;
  exports.bottomInt = -2147483648;
})(PS["Data.Bounded"] = PS["Data.Bounded"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Bounded"] = $PS["Data.Bounded"] || {};
  var exports = $PS["Data.Bounded"];
  var $foreign = $PS["Data.Bounded"];
  var Data_Ord = $PS["Data.Ord"];
  var Bounded = function (Ord0, bottom, top) {
      this.Ord0 = Ord0;
      this.bottom = bottom;
      this.top = top;
  };
  var top = function (dict) {
      return dict.top;
  };                                            
  var boundedInt = new Bounded(function () {
      return Data_Ord.ordInt;
  }, $foreign.bottomInt, $foreign.topInt);
  var bottom = function (dict) {
      return dict.bottom;
  };
  exports["bottom"] = bottom;
  exports["top"] = top;
  exports["boundedInt"] = boundedInt;
})(PS);
(function(exports) {
  "use strict";

  exports.intSub = function (x) {
    return function (y) {
      /* jshint bitwise: false */
      return x - y | 0;
    };
  };
})(PS["Data.Ring"] = PS["Data.Ring"] || {});
(function(exports) {
  "use strict";

  exports.intAdd = function (x) {
    return function (y) {
      /* jshint bitwise: false */
      return x + y | 0;
    };
  };

  exports.intMul = function (x) {
    return function (y) {
      /* jshint bitwise: false */
      return x * y | 0;
    };
  };
})(PS["Data.Semiring"] = PS["Data.Semiring"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Semiring"] = $PS["Data.Semiring"] || {};
  var exports = $PS["Data.Semiring"];
  var $foreign = $PS["Data.Semiring"];
  var Semiring = function (add, mul, one, zero) {
      this.add = add;
      this.mul = mul;
      this.one = one;
      this.zero = zero;
  };                                                                            
  var semiringInt = new Semiring($foreign.intAdd, $foreign.intMul, 1, 0);
  var add = function (dict) {
      return dict.add;
  };
  exports["add"] = add;
  exports["semiringInt"] = semiringInt;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Ring"] = $PS["Data.Ring"] || {};
  var exports = $PS["Data.Ring"];
  var $foreign = $PS["Data.Ring"];
  var Data_Semiring = $PS["Data.Semiring"];
  var Ring = function (Semiring0, sub) {
      this.Semiring0 = Semiring0;
      this.sub = sub;
  };                  
  var ringInt = new Ring(function () {
      return Data_Semiring.semiringInt;
  }, $foreign.intSub);
  exports["ringInt"] = ringInt;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.CommutativeRing"] = $PS["Data.CommutativeRing"] || {};
  var exports = $PS["Data.CommutativeRing"];
  var Data_Ring = $PS["Data.Ring"];
  var CommutativeRing = function (Ring0) {
      this.Ring0 = Ring0;
  }; 
  var commutativeRingInt = new CommutativeRing(function () {
      return Data_Ring.ringInt;
  });
  exports["commutativeRingInt"] = commutativeRingInt;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Exists"] = $PS["Data.Exists"] || {};
  var exports = $PS["Data.Exists"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];                
  var runExists = Unsafe_Coerce.unsafeCoerce;
  var mkExists = Unsafe_Coerce.unsafeCoerce;
  exports["mkExists"] = mkExists;
  exports["runExists"] = runExists;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Coyoneda"] = $PS["Data.Coyoneda"] || {};
  var exports = $PS["Data.Coyoneda"];
  var Control_Category = $PS["Control.Category"];
  var Data_Exists = $PS["Data.Exists"];
  var Data_Functor = $PS["Data.Functor"];                        
  var CoyonedaF = (function () {
      function CoyonedaF(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      CoyonedaF.create = function (value0) {
          return function (value1) {
              return new CoyonedaF(value0, value1);
          };
      };
      return CoyonedaF;
  })();
  var Coyoneda = function (x) {
      return x;
  };
  var unCoyoneda = function (f) {
      return function (v) {
          return Data_Exists.runExists(function (v1) {
              return f(v1.value0)(v1.value1);
          })(v);
      };
  };
  var coyoneda = function (k) {
      return function (fi) {
          return Coyoneda(Data_Exists.mkExists(new CoyonedaF(k, fi)));
      };
  };
  var functorCoyoneda = new Data_Functor.Functor(function (f) {
      return function (v) {
          return Data_Exists.runExists(function (v1) {
              return coyoneda(function ($84) {
                  return f(v1.value0($84));
              })(v1.value1);
          })(v);
      };
  });
  var liftCoyoneda = coyoneda(Control_Category.identity(Control_Category.categoryFn));
  exports["unCoyoneda"] = unCoyoneda;
  exports["liftCoyoneda"] = liftCoyoneda;
  exports["functorCoyoneda"] = functorCoyoneda;
})(PS);
(function(exports) {
  "use strict";

  exports.intDegree = function (x) {
    return Math.min(Math.abs(x), 2147483647);
  };

  // See the Euclidean definition in
  // https://en.m.wikipedia.org/wiki/Modulo_operation.
  exports.intDiv = function (x) {
    return function (y) {
      if (y === 0) return 0;
      return y > 0 ? Math.floor(x / y) : -Math.floor(x / -y);
    };
  };

  exports.intMod = function (x) {
    return function (y) {
      if (y === 0) return 0;
      var yy = Math.abs(y);
      return ((x % yy) + yy) % yy;
    };
  };
})(PS["Data.EuclideanRing"] = PS["Data.EuclideanRing"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.EuclideanRing"] = $PS["Data.EuclideanRing"] || {};
  var exports = $PS["Data.EuclideanRing"];
  var $foreign = $PS["Data.EuclideanRing"];
  var Data_CommutativeRing = $PS["Data.CommutativeRing"];  
  var EuclideanRing = function (CommutativeRing0, degree, div, mod) {
      this.CommutativeRing0 = CommutativeRing0;
      this.degree = degree;
      this.div = div;
      this.mod = mod;
  }; 
  var euclideanRingInt = new EuclideanRing(function () {
      return Data_CommutativeRing.commutativeRingInt;
  }, $foreign.intDegree, $foreign.intDiv, $foreign.intMod);
  var div = function (dict) {
      return dict.div;
  };
  exports["div"] = div;
  exports["euclideanRingInt"] = euclideanRingInt;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Generic.Rep"] = $PS["Data.Generic.Rep"] || {};
  var exports = $PS["Data.Generic.Rep"];             
  var Inl = (function () {
      function Inl(value0) {
          this.value0 = value0;
      };
      Inl.create = function (value0) {
          return new Inl(value0);
      };
      return Inl;
  })();
  var Inr = (function () {
      function Inr(value0) {
          this.value0 = value0;
      };
      Inr.create = function (value0) {
          return new Inr(value0);
      };
      return Inr;
  })();
  var NoArguments = (function () {
      function NoArguments() {

      };
      NoArguments.value = new NoArguments();
      return NoArguments;
  })();
  var Generic = function (from, to) {
      this.from = from;
      this.to = to;
  };
  var Constructor = function (x) {
      return x;
  };
  var to = function (dict) {
      return dict.to;
  };
  var from = function (dict) {
      return dict.from;
  };
  exports["Generic"] = Generic;
  exports["to"] = to;
  exports["from"] = from;
  exports["NoArguments"] = NoArguments;
  exports["Inl"] = Inl;
  exports["Inr"] = Inr;
  exports["Constructor"] = Constructor;
})(PS);
(function(exports) {
  "use strict";

  exports.fromNumberImpl = function (just) {
    return function (nothing) {
      return function (n) {
        /* jshint bitwise: false */
        return (n | 0) === n ? just(n) : nothing;
      };
    };
  };

  exports.toNumber = function (n) {
    return n;
  };
})(PS["Data.Int"] = PS["Data.Int"] || {});
(function(exports) {
  /* globals exports */
  "use strict";               

  exports.isFinite = isFinite;
})(PS["Data.Number"] = PS["Data.Number"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Number"] = $PS["Data.Number"] || {};
  var exports = $PS["Data.Number"];
  var $foreign = $PS["Data.Number"];
  exports["isFinite"] = $foreign["isFinite"];
})(PS);
(function(exports) {
  "use strict";          

  exports.floor = Math.floor;

  function nativeImul(a) {
    return function (b) {
      return Math.imul(a, b);
    };
  }

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/imul
  function emulatedImul(a) {
    /*jshint bitwise: false*/
    return function (b) {
      var ah = a >>> 16 & 0xffff;
      var al = a & 0xffff;
      var bh = b >>> 16 & 0xffff;
      var bl = b & 0xffff;
      // the shift by 0 fixes the sign on the high part
      // the final |0 converts the unsigned value into a signed value
      return al * bl + (ah * bl + al * bh << 16 >>> 0) | 0;
    };
  }
})(PS["Math"] = PS["Math"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Math"] = $PS["Math"] || {};
  var exports = $PS["Math"];
  var $foreign = $PS["Math"];
  exports["floor"] = $foreign.floor;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Int"] = $PS["Data.Int"] || {};
  var exports = $PS["Data.Int"];
  var $foreign = $PS["Data.Int"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Bounded = $PS["Data.Bounded"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Number = $PS["Data.Number"];
  var $$Math = $PS["Math"];         
  var fromNumber = $foreign.fromNumberImpl(Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
  var unsafeClamp = function (x) {
      if (!Data_Number["isFinite"](x)) {
          return 0;
      };
      if (x >= $foreign.toNumber(Data_Bounded.top(Data_Bounded.boundedInt))) {
          return Data_Bounded.top(Data_Bounded.boundedInt);
      };
      if (x <= $foreign.toNumber(Data_Bounded.bottom(Data_Bounded.boundedInt))) {
          return Data_Bounded.bottom(Data_Bounded.boundedInt);
      };
      if (Data_Boolean.otherwise) {
          return Data_Maybe.fromMaybe(0)(fromNumber(x));
      };
      throw new Error("Failed pattern match at Data.Int (line 66, column 1 - line 66, column 29): " + [ x.constructor.name ]);
  };
  var floor = function ($24) {
      return unsafeClamp($$Math.floor($24));
  };
  exports["floor"] = floor;
  exports["toNumber"] = $foreign.toNumber;
})(PS);
(function(exports) {
  "use strict";

  exports.defer = function (thunk) {
    var v = null;
    return function() {
      if (thunk === undefined) return v;

      v = thunk();
      thunk = undefined; // eslint-disable-line no-param-reassign
      return v;
    };
  };

  exports.force = function (l) {
    return l();
  };
})(PS["Data.Lazy"] = PS["Data.Lazy"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Lazy"] = $PS["Data.Lazy"] || {};
  var exports = $PS["Data.Lazy"];
  var $foreign = $PS["Data.Lazy"];
  var Data_Functor = $PS["Data.Functor"];
  var functorLazy = new Data_Functor.Functor(function (f) {
      return function (l) {
          return $foreign.defer(function (v) {
              return f($foreign.force(l));
          });
      };
  });
  exports["functorLazy"] = functorLazy;
  exports["defer"] = $foreign.defer;
  exports["force"] = $foreign.force;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.List.Lazy.Types"] = $PS["Data.List.Lazy.Types"] || {};
  var exports = $PS["Data.List.Lazy.Types"];
  var Control_Lazy = $PS["Control.Lazy"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_Lazy = $PS["Data.Lazy"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_Unfoldable = $PS["Data.Unfoldable"];
  var Data_Unfoldable1 = $PS["Data.Unfoldable1"];                
  var List = function (x) {
      return x;
  };
  var Nil = (function () {
      function Nil() {

      };
      Nil.value = new Nil();
      return Nil;
  })();
  var Cons = (function () {
      function Cons(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Cons.create = function (value0) {
          return function (value1) {
              return new Cons(value0, value1);
          };
      };
      return Cons;
  })();
  var nil = List(Data_Lazy.defer(function (v) {
      return Nil.value;
  }));
  var step = (function () {
      var $225 = Data_Newtype.unwrap();
      return function ($226) {
          return Data_Lazy.force($225($226));
      };
  })();   
  var lazyList = new Control_Lazy.Lazy(function (f) {
      return List(Data_Lazy.defer(function ($227) {
          return step(f($227));
      }));
  });
  var cons = function (x) {
      return function (xs) {
          return List(Data_Lazy.defer(function (v) {
              return new Cons(x, xs);
          }));
      };
  };
  var foldableList = new Data_Foldable.Foldable(function (dictMonoid) {
      return function (f) {
          return Data_Foldable.foldl(foldableList)(function (b) {
              return function (a) {
                  return Data_Semigroup.append(dictMonoid.Semigroup0())(b)(f(a));
              };
          })(Data_Monoid.mempty(dictMonoid));
      };
  }, function (op) {
      var go = function ($copy_b) {
          return function ($copy_xs) {
              var $tco_var_b = $copy_b;
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(b, xs) {
                  var v = step(xs);
                  if (v instanceof Nil) {
                      $tco_done = true;
                      return b;
                  };
                  if (v instanceof Cons) {
                      $tco_var_b = op(b)(v.value0);
                      $copy_xs = v.value1;
                      return;
                  };
                  throw new Error("Failed pattern match at Data.List.Lazy.Types (line 128, column 7 - line 130, column 40): " + [ v.constructor.name ]);
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($tco_var_b, $copy_xs);
              };
              return $tco_result;
          };
      };
      return go;
  }, function (op) {
      return function (z) {
          return function (xs) {
              var rev = Data_Foldable.foldl(foldableList)(Data_Function.flip(cons))(nil);
              return Data_Foldable.foldl(foldableList)(Data_Function.flip(op))(z)(rev(xs));
          };
      };
  });
  var unfoldable1List = new Data_Unfoldable1.Unfoldable1((function () {
      var go = function (f) {
          return function (b) {
              return Control_Lazy.defer(lazyList)(function (v) {
                  var v1 = f(b);
                  if (v1.value1 instanceof Data_Maybe.Just) {
                      return cons(v1.value0)(go(f)(v1.value1.value0));
                  };
                  if (v1.value1 instanceof Data_Maybe.Nothing) {
                      return cons(v1.value0)(nil);
                  };
                  throw new Error("Failed pattern match at Data.List.Lazy.Types (line 152, column 28 - line 154, column 33): " + [ v1.constructor.name ]);
              });
          };
      };
      return go;
  })());
  var unfoldableList = new Data_Unfoldable.Unfoldable(function () {
      return unfoldable1List;
  }, (function () {
      var go = function (f) {
          return function (b) {
              return Control_Lazy.defer(lazyList)(function (v) {
                  var v1 = f(b);
                  if (v1 instanceof Data_Maybe.Nothing) {
                      return nil;
                  };
                  if (v1 instanceof Data_Maybe.Just) {
                      return cons(v1.value0.value0)(go(f)(v1.value0.value1));
                  };
                  throw new Error("Failed pattern match at Data.List.Lazy.Types (line 158, column 28 - line 160, column 39): " + [ v1.constructor.name ]);
              });
          };
      };
      return go;
  })());
  exports["List"] = List;
  exports["Nil"] = Nil;
  exports["Cons"] = Cons;
  exports["step"] = step;
  exports["foldableList"] = foldableList;
  exports["unfoldableList"] = unfoldableList;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.List.Lazy"] = $PS["Data.List.Lazy"] || {};
  var exports = $PS["Data.List.Lazy"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Lazy = $PS["Data.Lazy"];
  var Data_List_Lazy_Types = $PS["Data.List.Lazy.Types"];
  var Data_Newtype = $PS["Data.Newtype"];
  var filter = function (p) {
      var go = function ($copy_v) {
          var $tco_done = false;
          var $tco_result;
          function $tco_loop(v) {
              if (v instanceof Data_List_Lazy_Types.Nil) {
                  $tco_done = true;
                  return Data_List_Lazy_Types.Nil.value;
              };
              if (v instanceof Data_List_Lazy_Types.Cons) {
                  if (p(v.value0)) {
                      $tco_done = true;
                      return new Data_List_Lazy_Types.Cons(v.value0, filter(p)(v.value1));
                  };
                  if (Data_Boolean.otherwise) {
                      $copy_v = Data_List_Lazy_Types.step(v.value1);
                      return;
                  };
              };
              throw new Error("Failed pattern match at Data.List.Lazy (line 416, column 3 - line 416, column 15): " + [ v.constructor.name ]);
          };
          while (!$tco_done) {
              $tco_result = $tco_loop($copy_v);
          };
          return $tco_result;
      };
      var $268 = Data_Functor.map(Data_Lazy.functorLazy)(go);
      var $269 = Data_Newtype.unwrap();
      return function ($270) {
          return Data_List_Lazy_Types.List($268($269($270)));
      };
  };
  exports["filter"] = filter;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Map.Internal"] = $PS["Data.Map.Internal"] || {};
  var exports = $PS["Data.Map.Internal"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_List_Lazy = $PS["Data.List.Lazy"];
  var Data_List_Lazy_Types = $PS["Data.List.Lazy.Types"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Ordering = $PS["Data.Ordering"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Data_Unfoldable = $PS["Data.Unfoldable"];                
  var Leaf = (function () {
      function Leaf() {

      };
      Leaf.value = new Leaf();
      return Leaf;
  })();
  var Two = (function () {
      function Two(value0, value1, value2, value3) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
      };
      Two.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return new Two(value0, value1, value2, value3);
                  };
              };
          };
      };
      return Two;
  })();
  var Three = (function () {
      function Three(value0, value1, value2, value3, value4, value5, value6) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
          this.value4 = value4;
          this.value5 = value5;
          this.value6 = value6;
      };
      Three.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return function (value4) {
                          return function (value5) {
                              return function (value6) {
                                  return new Three(value0, value1, value2, value3, value4, value5, value6);
                              };
                          };
                      };
                  };
              };
          };
      };
      return Three;
  })();
  var TwoLeft = (function () {
      function TwoLeft(value0, value1, value2) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
      };
      TwoLeft.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return new TwoLeft(value0, value1, value2);
              };
          };
      };
      return TwoLeft;
  })();
  var TwoRight = (function () {
      function TwoRight(value0, value1, value2) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
      };
      TwoRight.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return new TwoRight(value0, value1, value2);
              };
          };
      };
      return TwoRight;
  })();
  var ThreeLeft = (function () {
      function ThreeLeft(value0, value1, value2, value3, value4, value5) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
          this.value4 = value4;
          this.value5 = value5;
      };
      ThreeLeft.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return function (value4) {
                          return function (value5) {
                              return new ThreeLeft(value0, value1, value2, value3, value4, value5);
                          };
                      };
                  };
              };
          };
      };
      return ThreeLeft;
  })();
  var ThreeMiddle = (function () {
      function ThreeMiddle(value0, value1, value2, value3, value4, value5) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
          this.value4 = value4;
          this.value5 = value5;
      };
      ThreeMiddle.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return function (value4) {
                          return function (value5) {
                              return new ThreeMiddle(value0, value1, value2, value3, value4, value5);
                          };
                      };
                  };
              };
          };
      };
      return ThreeMiddle;
  })();
  var ThreeRight = (function () {
      function ThreeRight(value0, value1, value2, value3, value4, value5) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
          this.value4 = value4;
          this.value5 = value5;
      };
      ThreeRight.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return function (value4) {
                          return function (value5) {
                              return new ThreeRight(value0, value1, value2, value3, value4, value5);
                          };
                      };
                  };
              };
          };
      };
      return ThreeRight;
  })();
  var KickUp = (function () {
      function KickUp(value0, value1, value2, value3) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
      };
      KickUp.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return new KickUp(value0, value1, value2, value3);
                  };
              };
          };
      };
      return KickUp;
  })();
  var values = function (v) {
      if (v instanceof Leaf) {
          return Data_List_Types.Nil.value;
      };
      if (v instanceof Two) {
          return Data_Semigroup.append(Data_List_Types.semigroupList)(values(v.value0))(Data_Semigroup.append(Data_List_Types.semigroupList)(Control_Applicative.pure(Data_List_Types.applicativeList)(v.value2))(values(v.value3)));
      };
      if (v instanceof Three) {
          return Data_Semigroup.append(Data_List_Types.semigroupList)(values(v.value0))(Data_Semigroup.append(Data_List_Types.semigroupList)(Control_Applicative.pure(Data_List_Types.applicativeList)(v.value2))(Data_Semigroup.append(Data_List_Types.semigroupList)(values(v.value3))(Data_Semigroup.append(Data_List_Types.semigroupList)(Control_Applicative.pure(Data_List_Types.applicativeList)(v.value5))(values(v.value6)))));
      };
      throw new Error("Failed pattern match at Data.Map.Internal (line 626, column 1 - line 626, column 40): " + [ v.constructor.name ]);
  };
  var size = function (v) {
      if (v instanceof Leaf) {
          return 0;
      };
      if (v instanceof Two) {
          return (1 + size(v.value0) | 0) + size(v.value3) | 0;
      };
      if (v instanceof Three) {
          return ((2 + size(v.value0) | 0) + size(v.value3) | 0) + size(v.value6) | 0;
      };
      throw new Error("Failed pattern match at Data.Map.Internal (line 676, column 1 - line 676, column 35): " + [ v.constructor.name ]);
  };
  var singleton = function (k) {
      return function (v) {
          return new Two(Leaf.value, k, v, Leaf.value);
      };
  };
  var toUnfoldable = function (dictUnfoldable) {
      return function (m) {
          var go = function ($copy_v) {
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(v) {
                  if (v instanceof Data_List_Types.Nil) {
                      $tco_done = true;
                      return Data_Maybe.Nothing.value;
                  };
                  if (v instanceof Data_List_Types.Cons) {
                      if (v.value0 instanceof Leaf) {
                          $copy_v = v.value1;
                          return;
                      };
                      if (v.value0 instanceof Two && (v.value0.value0 instanceof Leaf && v.value0.value3 instanceof Leaf)) {
                          $tco_done = true;
                          return Data_Maybe.Just.create(new Data_Tuple.Tuple(new Data_Tuple.Tuple(v.value0.value1, v.value0.value2), v.value1));
                      };
                      if (v.value0 instanceof Two && v.value0.value0 instanceof Leaf) {
                          $tco_done = true;
                          return Data_Maybe.Just.create(new Data_Tuple.Tuple(new Data_Tuple.Tuple(v.value0.value1, v.value0.value2), new Data_List_Types.Cons(v.value0.value3, v.value1)));
                      };
                      if (v.value0 instanceof Two) {
                          $copy_v = new Data_List_Types.Cons(v.value0.value0, new Data_List_Types.Cons(singleton(v.value0.value1)(v.value0.value2), new Data_List_Types.Cons(v.value0.value3, v.value1)));
                          return;
                      };
                      if (v.value0 instanceof Three) {
                          $copy_v = new Data_List_Types.Cons(v.value0.value0, new Data_List_Types.Cons(singleton(v.value0.value1)(v.value0.value2), new Data_List_Types.Cons(v.value0.value3, new Data_List_Types.Cons(singleton(v.value0.value4)(v.value0.value5), new Data_List_Types.Cons(v.value0.value6, v.value1)))));
                          return;
                      };
                      throw new Error("Failed pattern match at Data.Map.Internal (line 591, column 18 - line 600, column 71): " + [ v.value0.constructor.name ]);
                  };
                  throw new Error("Failed pattern match at Data.Map.Internal (line 590, column 3 - line 590, column 19): " + [ v.constructor.name ]);
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($copy_v);
              };
              return $tco_result;
          };
          return Data_Unfoldable.unfoldr(dictUnfoldable)(go)(new Data_List_Types.Cons(m, Data_List_Types.Nil.value));
      };
  };
  var toAscArray = toUnfoldable(Data_Unfoldable.unfoldableArray);
  var toUnfoldableUnordered = function (dictUnfoldable) {
      return function (m) {
          var go = function ($copy_v) {
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(v) {
                  if (v instanceof Data_List_Types.Nil) {
                      $tco_done = true;
                      return Data_Maybe.Nothing.value;
                  };
                  if (v instanceof Data_List_Types.Cons) {
                      if (v.value0 instanceof Leaf) {
                          $copy_v = v.value1;
                          return;
                      };
                      if (v.value0 instanceof Two) {
                          $tco_done = true;
                          return Data_Maybe.Just.create(new Data_Tuple.Tuple(new Data_Tuple.Tuple(v.value0.value1, v.value0.value2), new Data_List_Types.Cons(v.value0.value0, new Data_List_Types.Cons(v.value0.value3, v.value1))));
                      };
                      if (v.value0 instanceof Three) {
                          $tco_done = true;
                          return Data_Maybe.Just.create(new Data_Tuple.Tuple(new Data_Tuple.Tuple(v.value0.value1, v.value0.value2), new Data_List_Types.Cons(singleton(v.value0.value4)(v.value0.value5), new Data_List_Types.Cons(v.value0.value0, new Data_List_Types.Cons(v.value0.value3, new Data_List_Types.Cons(v.value0.value6, v.value1))))));
                      };
                      throw new Error("Failed pattern match at Data.Map.Internal (line 612, column 18 - line 617, column 77): " + [ v.value0.constructor.name ]);
                  };
                  throw new Error("Failed pattern match at Data.Map.Internal (line 611, column 3 - line 611, column 19): " + [ v.constructor.name ]);
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($copy_v);
              };
              return $tco_result;
          };
          return Data_Unfoldable.unfoldr(dictUnfoldable)(go)(new Data_List_Types.Cons(m, Data_List_Types.Nil.value));
      };
  };
  var lookup = function (dictOrd) {
      return function (k) {
          var comp = Data_Ord.compare(dictOrd);
          var go = function ($copy_v) {
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(v) {
                  if (v instanceof Leaf) {
                      $tco_done = true;
                      return Data_Maybe.Nothing.value;
                  };
                  if (v instanceof Two) {
                      var v2 = comp(k)(v.value1);
                      if (v2 instanceof Data_Ordering.EQ) {
                          $tco_done = true;
                          return new Data_Maybe.Just(v.value2);
                      };
                      if (v2 instanceof Data_Ordering.LT) {
                          $copy_v = v.value0;
                          return;
                      };
                      $copy_v = v.value3;
                      return;
                  };
                  if (v instanceof Three) {
                      var v3 = comp(k)(v.value1);
                      if (v3 instanceof Data_Ordering.EQ) {
                          $tco_done = true;
                          return new Data_Maybe.Just(v.value2);
                      };
                      var v4 = comp(k)(v.value4);
                      if (v4 instanceof Data_Ordering.EQ) {
                          $tco_done = true;
                          return new Data_Maybe.Just(v.value5);
                      };
                      if (v3 instanceof Data_Ordering.LT) {
                          $copy_v = v.value0;
                          return;
                      };
                      if (v4 instanceof Data_Ordering.GT) {
                          $copy_v = v.value6;
                          return;
                      };
                      $copy_v = v.value3;
                      return;
                  };
                  throw new Error("Failed pattern match at Data.Map.Internal (line 211, column 5 - line 211, column 22): " + [ v.constructor.name ]);
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($copy_v);
              };
              return $tco_result;
          };
          return go;
      };
  };
  var member = function (dictOrd) {
      return function (k) {
          return function (m) {
              return Data_Maybe.isJust(lookup(dictOrd)(k)(m));
          };
      };
  };
  var keys = function (v) {
      if (v instanceof Leaf) {
          return Data_List_Types.Nil.value;
      };
      if (v instanceof Two) {
          return Data_Semigroup.append(Data_List_Types.semigroupList)(keys(v.value0))(Data_Semigroup.append(Data_List_Types.semigroupList)(Control_Applicative.pure(Data_List_Types.applicativeList)(v.value1))(keys(v.value3)));
      };
      if (v instanceof Three) {
          return Data_Semigroup.append(Data_List_Types.semigroupList)(keys(v.value0))(Data_Semigroup.append(Data_List_Types.semigroupList)(Control_Applicative.pure(Data_List_Types.applicativeList)(v.value1))(Data_Semigroup.append(Data_List_Types.semigroupList)(keys(v.value3))(Data_Semigroup.append(Data_List_Types.semigroupList)(Control_Applicative.pure(Data_List_Types.applicativeList)(v.value4))(keys(v.value6)))));
      };
      throw new Error("Failed pattern match at Data.Map.Internal (line 620, column 1 - line 620, column 38): " + [ v.constructor.name ]);
  };
  var isEmpty = function (v) {
      if (v instanceof Leaf) {
          return true;
      };
      return false;
  };
  var functorMap = new Data_Functor.Functor(function (v) {
      return function (v1) {
          if (v1 instanceof Leaf) {
              return Leaf.value;
          };
          if (v1 instanceof Two) {
              return new Two(Data_Functor.map(functorMap)(v)(v1.value0), v1.value1, v(v1.value2), Data_Functor.map(functorMap)(v)(v1.value3));
          };
          if (v1 instanceof Three) {
              return new Three(Data_Functor.map(functorMap)(v)(v1.value0), v1.value1, v(v1.value2), Data_Functor.map(functorMap)(v)(v1.value3), v1.value4, v(v1.value5), Data_Functor.map(functorMap)(v)(v1.value6));
          };
          throw new Error("Failed pattern match at Data.Map.Internal (line 101, column 1 - line 104, column 110): " + [ v.constructor.name, v1.constructor.name ]);
      };
  });
  var fromZipper = function ($copy_dictOrd) {
      return function ($copy_v) {
          return function ($copy_tree) {
              var $tco_var_dictOrd = $copy_dictOrd;
              var $tco_var_v = $copy_v;
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(dictOrd, v, tree) {
                  if (v instanceof Data_List_Types.Nil) {
                      $tco_done = true;
                      return tree;
                  };
                  if (v instanceof Data_List_Types.Cons) {
                      if (v.value0 instanceof TwoLeft) {
                          $tco_var_dictOrd = dictOrd;
                          $tco_var_v = v.value1;
                          $copy_tree = new Two(tree, v.value0.value0, v.value0.value1, v.value0.value2);
                          return;
                      };
                      if (v.value0 instanceof TwoRight) {
                          $tco_var_dictOrd = dictOrd;
                          $tco_var_v = v.value1;
                          $copy_tree = new Two(v.value0.value0, v.value0.value1, v.value0.value2, tree);
                          return;
                      };
                      if (v.value0 instanceof ThreeLeft) {
                          $tco_var_dictOrd = dictOrd;
                          $tco_var_v = v.value1;
                          $copy_tree = new Three(tree, v.value0.value0, v.value0.value1, v.value0.value2, v.value0.value3, v.value0.value4, v.value0.value5);
                          return;
                      };
                      if (v.value0 instanceof ThreeMiddle) {
                          $tco_var_dictOrd = dictOrd;
                          $tco_var_v = v.value1;
                          $copy_tree = new Three(v.value0.value0, v.value0.value1, v.value0.value2, tree, v.value0.value3, v.value0.value4, v.value0.value5);
                          return;
                      };
                      if (v.value0 instanceof ThreeRight) {
                          $tco_var_dictOrd = dictOrd;
                          $tco_var_v = v.value1;
                          $copy_tree = new Three(v.value0.value0, v.value0.value1, v.value0.value2, v.value0.value3, v.value0.value4, v.value0.value5, tree);
                          return;
                      };
                      throw new Error("Failed pattern match at Data.Map.Internal (line 432, column 3 - line 437, column 88): " + [ v.value0.constructor.name ]);
                  };
                  throw new Error("Failed pattern match at Data.Map.Internal (line 429, column 1 - line 429, column 80): " + [ v.constructor.name, tree.constructor.name ]);
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($tco_var_dictOrd, $tco_var_v, $copy_tree);
              };
              return $tco_result;
          };
      };
  };
  var insert = function (dictOrd) {
      return function (k) {
          return function (v) {
              var up = function ($copy_v1) {
                  return function ($copy_v2) {
                      var $tco_var_v1 = $copy_v1;
                      var $tco_done = false;
                      var $tco_result;
                      function $tco_loop(v1, v2) {
                          if (v1 instanceof Data_List_Types.Nil) {
                              $tco_done = true;
                              return new Two(v2.value0, v2.value1, v2.value2, v2.value3);
                          };
                          if (v1 instanceof Data_List_Types.Cons) {
                              if (v1.value0 instanceof TwoLeft) {
                                  $tco_done = true;
                                  return fromZipper(dictOrd)(v1.value1)(new Three(v2.value0, v2.value1, v2.value2, v2.value3, v1.value0.value0, v1.value0.value1, v1.value0.value2));
                              };
                              if (v1.value0 instanceof TwoRight) {
                                  $tco_done = true;
                                  return fromZipper(dictOrd)(v1.value1)(new Three(v1.value0.value0, v1.value0.value1, v1.value0.value2, v2.value0, v2.value1, v2.value2, v2.value3));
                              };
                              if (v1.value0 instanceof ThreeLeft) {
                                  $tco_var_v1 = v1.value1;
                                  $copy_v2 = new KickUp(new Two(v2.value0, v2.value1, v2.value2, v2.value3), v1.value0.value0, v1.value0.value1, new Two(v1.value0.value2, v1.value0.value3, v1.value0.value4, v1.value0.value5));
                                  return;
                              };
                              if (v1.value0 instanceof ThreeMiddle) {
                                  $tco_var_v1 = v1.value1;
                                  $copy_v2 = new KickUp(new Two(v1.value0.value0, v1.value0.value1, v1.value0.value2, v2.value0), v2.value1, v2.value2, new Two(v2.value3, v1.value0.value3, v1.value0.value4, v1.value0.value5));
                                  return;
                              };
                              if (v1.value0 instanceof ThreeRight) {
                                  $tco_var_v1 = v1.value1;
                                  $copy_v2 = new KickUp(new Two(v1.value0.value0, v1.value0.value1, v1.value0.value2, v1.value0.value3), v1.value0.value4, v1.value0.value5, new Two(v2.value0, v2.value1, v2.value2, v2.value3));
                                  return;
                              };
                              throw new Error("Failed pattern match at Data.Map.Internal (line 468, column 5 - line 473, column 108): " + [ v1.value0.constructor.name, v2.constructor.name ]);
                          };
                          throw new Error("Failed pattern match at Data.Map.Internal (line 465, column 3 - line 465, column 56): " + [ v1.constructor.name, v2.constructor.name ]);
                      };
                      while (!$tco_done) {
                          $tco_result = $tco_loop($tco_var_v1, $copy_v2);
                      };
                      return $tco_result;
                  };
              };
              var comp = Data_Ord.compare(dictOrd);
              var down = function ($copy_ctx) {
                  return function ($copy_v1) {
                      var $tco_var_ctx = $copy_ctx;
                      var $tco_done1 = false;
                      var $tco_result;
                      function $tco_loop(ctx, v1) {
                          if (v1 instanceof Leaf) {
                              $tco_done1 = true;
                              return up(ctx)(new KickUp(Leaf.value, k, v, Leaf.value));
                          };
                          if (v1 instanceof Two) {
                              var v2 = comp(k)(v1.value1);
                              if (v2 instanceof Data_Ordering.EQ) {
                                  $tco_done1 = true;
                                  return fromZipper(dictOrd)(ctx)(new Two(v1.value0, k, v, v1.value3));
                              };
                              if (v2 instanceof Data_Ordering.LT) {
                                  $tco_var_ctx = new Data_List_Types.Cons(new TwoLeft(v1.value1, v1.value2, v1.value3), ctx);
                                  $copy_v1 = v1.value0;
                                  return;
                              };
                              $tco_var_ctx = new Data_List_Types.Cons(new TwoRight(v1.value0, v1.value1, v1.value2), ctx);
                              $copy_v1 = v1.value3;
                              return;
                          };
                          if (v1 instanceof Three) {
                              var v3 = comp(k)(v1.value1);
                              if (v3 instanceof Data_Ordering.EQ) {
                                  $tco_done1 = true;
                                  return fromZipper(dictOrd)(ctx)(new Three(v1.value0, k, v, v1.value3, v1.value4, v1.value5, v1.value6));
                              };
                              var v4 = comp(k)(v1.value4);
                              if (v4 instanceof Data_Ordering.EQ) {
                                  $tco_done1 = true;
                                  return fromZipper(dictOrd)(ctx)(new Three(v1.value0, v1.value1, v1.value2, v1.value3, k, v, v1.value6));
                              };
                              if (v3 instanceof Data_Ordering.LT) {
                                  $tco_var_ctx = new Data_List_Types.Cons(new ThreeLeft(v1.value1, v1.value2, v1.value3, v1.value4, v1.value5, v1.value6), ctx);
                                  $copy_v1 = v1.value0;
                                  return;
                              };
                              if (v3 instanceof Data_Ordering.GT && v4 instanceof Data_Ordering.LT) {
                                  $tco_var_ctx = new Data_List_Types.Cons(new ThreeMiddle(v1.value0, v1.value1, v1.value2, v1.value4, v1.value5, v1.value6), ctx);
                                  $copy_v1 = v1.value3;
                                  return;
                              };
                              $tco_var_ctx = new Data_List_Types.Cons(new ThreeRight(v1.value0, v1.value1, v1.value2, v1.value3, v1.value4, v1.value5), ctx);
                              $copy_v1 = v1.value6;
                              return;
                          };
                          throw new Error("Failed pattern match at Data.Map.Internal (line 448, column 3 - line 448, column 55): " + [ ctx.constructor.name, v1.constructor.name ]);
                      };
                      while (!$tco_done1) {
                          $tco_result = $tco_loop($tco_var_ctx, $copy_v1);
                      };
                      return $tco_result;
                  };
              };
              return down(Data_List_Types.Nil.value);
          };
      };
  };
  var pop = function (dictOrd) {
      return function (k) {
          var up = function ($copy_ctxs) {
              return function ($copy_tree) {
                  var $tco_var_ctxs = $copy_ctxs;
                  var $tco_done = false;
                  var $tco_result;
                  function $tco_loop(ctxs, tree) {
                      if (ctxs instanceof Data_List_Types.Nil) {
                          $tco_done = true;
                          return tree;
                      };
                      if (ctxs instanceof Data_List_Types.Cons) {
                          if (ctxs.value0 instanceof TwoLeft && (ctxs.value0.value2 instanceof Leaf && tree instanceof Leaf)) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Two(Leaf.value, ctxs.value0.value0, ctxs.value0.value1, Leaf.value));
                          };
                          if (ctxs.value0 instanceof TwoRight && (ctxs.value0.value0 instanceof Leaf && tree instanceof Leaf)) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Two(Leaf.value, ctxs.value0.value1, ctxs.value0.value2, Leaf.value));
                          };
                          if (ctxs.value0 instanceof TwoLeft && ctxs.value0.value2 instanceof Two) {
                              $tco_var_ctxs = ctxs.value1;
                              $copy_tree = new Three(tree, ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2.value0, ctxs.value0.value2.value1, ctxs.value0.value2.value2, ctxs.value0.value2.value3);
                              return;
                          };
                          if (ctxs.value0 instanceof TwoRight && ctxs.value0.value0 instanceof Two) {
                              $tco_var_ctxs = ctxs.value1;
                              $copy_tree = new Three(ctxs.value0.value0.value0, ctxs.value0.value0.value1, ctxs.value0.value0.value2, ctxs.value0.value0.value3, ctxs.value0.value1, ctxs.value0.value2, tree);
                              return;
                          };
                          if (ctxs.value0 instanceof TwoLeft && ctxs.value0.value2 instanceof Three) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Two(new Two(tree, ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2.value0), ctxs.value0.value2.value1, ctxs.value0.value2.value2, new Two(ctxs.value0.value2.value3, ctxs.value0.value2.value4, ctxs.value0.value2.value5, ctxs.value0.value2.value6)));
                          };
                          if (ctxs.value0 instanceof TwoRight && ctxs.value0.value0 instanceof Three) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Two(new Two(ctxs.value0.value0.value0, ctxs.value0.value0.value1, ctxs.value0.value0.value2, ctxs.value0.value0.value3), ctxs.value0.value0.value4, ctxs.value0.value0.value5, new Two(ctxs.value0.value0.value6, ctxs.value0.value1, ctxs.value0.value2, tree)));
                          };
                          if (ctxs.value0 instanceof ThreeLeft && (ctxs.value0.value2 instanceof Leaf && (ctxs.value0.value5 instanceof Leaf && tree instanceof Leaf))) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Three(Leaf.value, ctxs.value0.value0, ctxs.value0.value1, Leaf.value, ctxs.value0.value3, ctxs.value0.value4, Leaf.value));
                          };
                          if (ctxs.value0 instanceof ThreeMiddle && (ctxs.value0.value0 instanceof Leaf && (ctxs.value0.value5 instanceof Leaf && tree instanceof Leaf))) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Three(Leaf.value, ctxs.value0.value1, ctxs.value0.value2, Leaf.value, ctxs.value0.value3, ctxs.value0.value4, Leaf.value));
                          };
                          if (ctxs.value0 instanceof ThreeRight && (ctxs.value0.value0 instanceof Leaf && (ctxs.value0.value3 instanceof Leaf && tree instanceof Leaf))) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Three(Leaf.value, ctxs.value0.value1, ctxs.value0.value2, Leaf.value, ctxs.value0.value4, ctxs.value0.value5, Leaf.value));
                          };
                          if (ctxs.value0 instanceof ThreeLeft && ctxs.value0.value2 instanceof Two) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Two(new Three(tree, ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2.value0, ctxs.value0.value2.value1, ctxs.value0.value2.value2, ctxs.value0.value2.value3), ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5));
                          };
                          if (ctxs.value0 instanceof ThreeMiddle && ctxs.value0.value0 instanceof Two) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Two(new Three(ctxs.value0.value0.value0, ctxs.value0.value0.value1, ctxs.value0.value0.value2, ctxs.value0.value0.value3, ctxs.value0.value1, ctxs.value0.value2, tree), ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5));
                          };
                          if (ctxs.value0 instanceof ThreeMiddle && ctxs.value0.value5 instanceof Two) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Two(ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2, new Three(tree, ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5.value0, ctxs.value0.value5.value1, ctxs.value0.value5.value2, ctxs.value0.value5.value3)));
                          };
                          if (ctxs.value0 instanceof ThreeRight && ctxs.value0.value3 instanceof Two) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Two(ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2, new Three(ctxs.value0.value3.value0, ctxs.value0.value3.value1, ctxs.value0.value3.value2, ctxs.value0.value3.value3, ctxs.value0.value4, ctxs.value0.value5, tree)));
                          };
                          if (ctxs.value0 instanceof ThreeLeft && ctxs.value0.value2 instanceof Three) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Three(new Two(tree, ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2.value0), ctxs.value0.value2.value1, ctxs.value0.value2.value2, new Two(ctxs.value0.value2.value3, ctxs.value0.value2.value4, ctxs.value0.value2.value5, ctxs.value0.value2.value6), ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5));
                          };
                          if (ctxs.value0 instanceof ThreeMiddle && ctxs.value0.value0 instanceof Three) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Three(new Two(ctxs.value0.value0.value0, ctxs.value0.value0.value1, ctxs.value0.value0.value2, ctxs.value0.value0.value3), ctxs.value0.value0.value4, ctxs.value0.value0.value5, new Two(ctxs.value0.value0.value6, ctxs.value0.value1, ctxs.value0.value2, tree), ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5));
                          };
                          if (ctxs.value0 instanceof ThreeMiddle && ctxs.value0.value5 instanceof Three) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Three(ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2, new Two(tree, ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5.value0), ctxs.value0.value5.value1, ctxs.value0.value5.value2, new Two(ctxs.value0.value5.value3, ctxs.value0.value5.value4, ctxs.value0.value5.value5, ctxs.value0.value5.value6)));
                          };
                          if (ctxs.value0 instanceof ThreeRight && ctxs.value0.value3 instanceof Three) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Three(ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2, new Two(ctxs.value0.value3.value0, ctxs.value0.value3.value1, ctxs.value0.value3.value2, ctxs.value0.value3.value3), ctxs.value0.value3.value4, ctxs.value0.value3.value5, new Two(ctxs.value0.value3.value6, ctxs.value0.value4, ctxs.value0.value5, tree)));
                          };
                          throw new Error("Failed pattern match at Data.Map.Internal (line 525, column 9 - line 542, column 136): " + [ ctxs.value0.constructor.name, tree.constructor.name ]);
                      };
                      throw new Error("Failed pattern match at Data.Map.Internal (line 522, column 5 - line 542, column 136): " + [ ctxs.constructor.name ]);
                  };
                  while (!$tco_done) {
                      $tco_result = $tco_loop($tco_var_ctxs, $copy_tree);
                  };
                  return $tco_result;
              };
          };
          var removeMaxNode = function ($copy_ctx) {
              return function ($copy_m) {
                  var $tco_var_ctx = $copy_ctx;
                  var $tco_done1 = false;
                  var $tco_result;
                  function $tco_loop(ctx, m) {
                      if (m instanceof Two && (m.value0 instanceof Leaf && m.value3 instanceof Leaf)) {
                          $tco_done1 = true;
                          return up(ctx)(Leaf.value);
                      };
                      if (m instanceof Two) {
                          $tco_var_ctx = new Data_List_Types.Cons(new TwoRight(m.value0, m.value1, m.value2), ctx);
                          $copy_m = m.value3;
                          return;
                      };
                      if (m instanceof Three && (m.value0 instanceof Leaf && (m.value3 instanceof Leaf && m.value6 instanceof Leaf))) {
                          $tco_done1 = true;
                          return up(new Data_List_Types.Cons(new TwoRight(Leaf.value, m.value1, m.value2), ctx))(Leaf.value);
                      };
                      if (m instanceof Three) {
                          $tco_var_ctx = new Data_List_Types.Cons(new ThreeRight(m.value0, m.value1, m.value2, m.value3, m.value4, m.value5), ctx);
                          $copy_m = m.value6;
                          return;
                      };
                      throw new Error("Failed pattern match at Data.Map.Internal (line 554, column 5 - line 558, column 107): " + [ m.constructor.name ]);
                  };
                  while (!$tco_done1) {
                      $tco_result = $tco_loop($tco_var_ctx, $copy_m);
                  };
                  return $tco_result;
              };
          };
          var maxNode = function ($copy_m) {
              var $tco_done2 = false;
              var $tco_result;
              function $tco_loop(m) {
                  if (m instanceof Two && m.value3 instanceof Leaf) {
                      $tco_done2 = true;
                      return {
                          key: m.value1,
                          value: m.value2
                      };
                  };
                  if (m instanceof Two) {
                      $copy_m = m.value3;
                      return;
                  };
                  if (m instanceof Three && m.value6 instanceof Leaf) {
                      $tco_done2 = true;
                      return {
                          key: m.value4,
                          value: m.value5
                      };
                  };
                  if (m instanceof Three) {
                      $copy_m = m.value6;
                      return;
                  };
                  throw new Error("Failed pattern match at Data.Map.Internal (line 545, column 33 - line 549, column 45): " + [ m.constructor.name ]);
              };
              while (!$tco_done2) {
                  $tco_result = $tco_loop($copy_m);
              };
              return $tco_result;
          };
          var comp = Data_Ord.compare(dictOrd);
          var down = function ($copy_ctx) {
              return function ($copy_m) {
                  var $tco_var_ctx = $copy_ctx;
                  var $tco_done3 = false;
                  var $tco_result;
                  function $tco_loop(ctx, m) {
                      if (m instanceof Leaf) {
                          $tco_done3 = true;
                          return Data_Maybe.Nothing.value;
                      };
                      if (m instanceof Two) {
                          var v = comp(k)(m.value1);
                          if (m.value3 instanceof Leaf && v instanceof Data_Ordering.EQ) {
                              $tco_done3 = true;
                              return new Data_Maybe.Just(new Data_Tuple.Tuple(m.value2, up(ctx)(Leaf.value)));
                          };
                          if (v instanceof Data_Ordering.EQ) {
                              var max = maxNode(m.value0);
                              $tco_done3 = true;
                              return new Data_Maybe.Just(new Data_Tuple.Tuple(m.value2, removeMaxNode(new Data_List_Types.Cons(new TwoLeft(max.key, max.value, m.value3), ctx))(m.value0)));
                          };
                          if (v instanceof Data_Ordering.LT) {
                              $tco_var_ctx = new Data_List_Types.Cons(new TwoLeft(m.value1, m.value2, m.value3), ctx);
                              $copy_m = m.value0;
                              return;
                          };
                          $tco_var_ctx = new Data_List_Types.Cons(new TwoRight(m.value0, m.value1, m.value2), ctx);
                          $copy_m = m.value3;
                          return;
                      };
                      if (m instanceof Three) {
                          var leaves = (function () {
                              if (m.value0 instanceof Leaf && (m.value3 instanceof Leaf && m.value6 instanceof Leaf)) {
                                  return true;
                              };
                              return false;
                          })();
                          var v = comp(k)(m.value4);
                          var v3 = comp(k)(m.value1);
                          if (leaves && v3 instanceof Data_Ordering.EQ) {
                              $tco_done3 = true;
                              return new Data_Maybe.Just(new Data_Tuple.Tuple(m.value2, fromZipper(dictOrd)(ctx)(new Two(Leaf.value, m.value4, m.value5, Leaf.value))));
                          };
                          if (leaves && v instanceof Data_Ordering.EQ) {
                              $tco_done3 = true;
                              return new Data_Maybe.Just(new Data_Tuple.Tuple(m.value5, fromZipper(dictOrd)(ctx)(new Two(Leaf.value, m.value1, m.value2, Leaf.value))));
                          };
                          if (v3 instanceof Data_Ordering.EQ) {
                              var max = maxNode(m.value0);
                              $tco_done3 = true;
                              return new Data_Maybe.Just(new Data_Tuple.Tuple(m.value2, removeMaxNode(new Data_List_Types.Cons(new ThreeLeft(max.key, max.value, m.value3, m.value4, m.value5, m.value6), ctx))(m.value0)));
                          };
                          if (v instanceof Data_Ordering.EQ) {
                              var max = maxNode(m.value3);
                              $tco_done3 = true;
                              return new Data_Maybe.Just(new Data_Tuple.Tuple(m.value5, removeMaxNode(new Data_List_Types.Cons(new ThreeMiddle(m.value0, m.value1, m.value2, max.key, max.value, m.value6), ctx))(m.value3)));
                          };
                          if (v3 instanceof Data_Ordering.LT) {
                              $tco_var_ctx = new Data_List_Types.Cons(new ThreeLeft(m.value1, m.value2, m.value3, m.value4, m.value5, m.value6), ctx);
                              $copy_m = m.value0;
                              return;
                          };
                          if (v3 instanceof Data_Ordering.GT && v instanceof Data_Ordering.LT) {
                              $tco_var_ctx = new Data_List_Types.Cons(new ThreeMiddle(m.value0, m.value1, m.value2, m.value4, m.value5, m.value6), ctx);
                              $copy_m = m.value3;
                              return;
                          };
                          $tco_var_ctx = new Data_List_Types.Cons(new ThreeRight(m.value0, m.value1, m.value2, m.value3, m.value4, m.value5), ctx);
                          $copy_m = m.value6;
                          return;
                      };
                      throw new Error("Failed pattern match at Data.Map.Internal (line 495, column 34 - line 518, column 80): " + [ m.constructor.name ]);
                  };
                  while (!$tco_done3) {
                      $tco_result = $tco_loop($tco_var_ctx, $copy_m);
                  };
                  return $tco_result;
              };
          };
          return down(Data_List_Types.Nil.value);
      };
  };
  var foldableMap = new Data_Foldable.Foldable(function (dictMonoid) {
      return function (f) {
          return function (m) {
              return Data_Foldable.foldMap(Data_List_Types.foldableList)(dictMonoid)(f)(values(m));
          };
      };
  }, function (f) {
      return function (z) {
          return function (m) {
              return Data_Foldable.foldl(Data_List_Types.foldableList)(f)(z)(values(m));
          };
      };
  }, function (f) {
      return function (z) {
          return function (m) {
              return Data_Foldable.foldr(Data_List_Types.foldableList)(f)(z)(values(m));
          };
      };
  });
  var findMin = (function () {
      var go = function ($copy_v) {
          return function ($copy_v1) {
              var $tco_var_v = $copy_v;
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(v, v1) {
                  if (v1 instanceof Leaf) {
                      $tco_done = true;
                      return v;
                  };
                  if (v1 instanceof Two) {
                      $tco_var_v = new Data_Maybe.Just({
                          key: v1.value1,
                          value: v1.value2
                      });
                      $copy_v1 = v1.value0;
                      return;
                  };
                  if (v1 instanceof Three) {
                      $tco_var_v = new Data_Maybe.Just({
                          key: v1.value1,
                          value: v1.value2
                      });
                      $copy_v1 = v1.value0;
                      return;
                  };
                  throw new Error("Failed pattern match at Data.Map.Internal (line 308, column 5 - line 308, column 22): " + [ v.constructor.name, v1.constructor.name ]);
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($tco_var_v, $copy_v1);
              };
              return $tco_result;
          };
      };
      return go(Data_Maybe.Nothing.value);
  })();
  var eqMap = function (dictEq) {
      return function (dictEq1) {
          return new Data_Eq.Eq(function (m1) {
              return function (m2) {
                  return Data_Eq.eq(Data_Eq.eqArray(Data_Tuple.eqTuple(dictEq)(dictEq1)))(toAscArray(m1))(toAscArray(m2));
              };
          });
      };
  };
  var empty = Leaf.value;
  var fromFoldable = function (dictOrd) {
      return function (dictFoldable) {
          return Data_Foldable.foldl(dictFoldable)(function (m) {
              return function (v) {
                  return insert(dictOrd)(v.value0)(v.value1)(m);
              };
          })(empty);
      };
  };
  var filterWithKey = function (dictOrd) {
      return function (predicate) {
          var $764 = fromFoldable(dictOrd)(Data_List_Lazy_Types.foldableList);
          var $765 = Data_List_Lazy.filter(Data_Tuple.uncurry(predicate));
          var $766 = toUnfoldable(Data_List_Lazy_Types.unfoldableList);
          return function ($767) {
              return $764($765($766($767)));
          };
      };
  };
  var filter = function (dictOrd) {
      return function (predicate) {
          return filterWithKey(dictOrd)(Data_Function["const"](predicate));
      };
  };
  var $$delete = function (dictOrd) {
      return function (k) {
          return function (m) {
              return Data_Maybe.maybe(m)(Data_Tuple.snd)(pop(dictOrd)(k)(m));
          };
      };
  };
  var alter = function (dictOrd) {
      return function (f) {
          return function (k) {
              return function (m) {
                  var v = f(lookup(dictOrd)(k)(m));
                  if (v instanceof Data_Maybe.Nothing) {
                      return $$delete(dictOrd)(k)(m);
                  };
                  if (v instanceof Data_Maybe.Just) {
                      return insert(dictOrd)(k)(v.value0)(m);
                  };
                  throw new Error("Failed pattern match at Data.Map.Internal (line 563, column 15 - line 565, column 25): " + [ v.constructor.name ]);
              };
          };
      };
  };
  var fromFoldableWith = function (dictOrd) {
      return function (dictFoldable) {
          return function (f) {
              var combine = function (v) {
                  return function (v1) {
                      if (v1 instanceof Data_Maybe.Just) {
                          return Data_Maybe.Just.create(f(v)(v1.value0));
                      };
                      if (v1 instanceof Data_Maybe.Nothing) {
                          return new Data_Maybe.Just(v);
                      };
                      throw new Error("Failed pattern match at Data.Map.Internal (line 580, column 3 - line 580, column 38): " + [ v.constructor.name, v1.constructor.name ]);
                  };
              };
              return Data_Foldable.foldl(dictFoldable)(function (m) {
                  return function (v) {
                      return alter(dictOrd)(combine(v.value1))(v.value0)(m);
                  };
              })(empty);
          };
      };
  };
  var insertWith = function (dictOrd) {
      return function (f) {
          return function (k) {
              return function (v) {
                  return alter(dictOrd)((function () {
                      var $775 = Data_Maybe.maybe(v)(Data_Function.flip(f)(v));
                      return function ($776) {
                          return Data_Maybe.Just.create($775($776));
                      };
                  })())(k);
              };
          };
      };
  };
  var unionWith = function (dictOrd) {
      return function (f) {
          return function (m1) {
              return function (m2) {
                  var go = function (m) {
                      return function (v) {
                          return alter(dictOrd)((function () {
                              var $777 = Data_Maybe.maybe(v.value1)(f(v.value1));
                              return function ($778) {
                                  return Data_Maybe.Just.create($777($778));
                              };
                          })())(v.value0)(m);
                      };
                  };
                  return Data_Foldable.foldl(Data_List_Types.foldableList)(go)(m2)(toUnfoldable(Data_List_Types.unfoldableList)(m1));
              };
          };
      };
  };
  var union = function (dictOrd) {
      return unionWith(dictOrd)(Data_Function["const"]);
  };
  exports["empty"] = empty;
  exports["isEmpty"] = isEmpty;
  exports["singleton"] = singleton;
  exports["insert"] = insert;
  exports["insertWith"] = insertWith;
  exports["lookup"] = lookup;
  exports["findMin"] = findMin;
  exports["fromFoldable"] = fromFoldable;
  exports["fromFoldableWith"] = fromFoldableWith;
  exports["toUnfoldableUnordered"] = toUnfoldableUnordered;
  exports["delete"] = $$delete;
  exports["pop"] = pop;
  exports["member"] = member;
  exports["alter"] = alter;
  exports["keys"] = keys;
  exports["union"] = union;
  exports["size"] = size;
  exports["filterWithKey"] = filterWithKey;
  exports["eqMap"] = eqMap;
  exports["functorMap"] = functorMap;
  exports["foldableMap"] = foldableMap;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Set"] = $PS["Data.Set"] || {};
  var exports = $PS["Data.Set"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Map_Internal = $PS["Data.Map.Internal"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Unit = $PS["Data.Unit"];                
  var $$Set = function (x) {
      return x;
  };
  var union = function (dictOrd) {
      return function (v) {
          return function (v1) {
              return Data_Map_Internal.union(dictOrd)(v)(v1);
          };
      };
  };
  var toList = function (v) {
      return Data_Map_Internal.keys(v);
  };
  var size = function (v) {
      return Data_Map_Internal.size(v);
  };
  var singleton = function (a) {
      return Data_Map_Internal.singleton(a)(Data_Unit.unit);
  };
  var member = function (dictOrd) {
      return function (a) {
          return function (v) {
              return Data_Map_Internal.member(dictOrd)(a)(v);
          };
      };
  };
  var isEmpty = function (v) {
      return Data_Map_Internal.isEmpty(v);
  };
  var insert = function (dictOrd) {
      return function (a) {
          return function (v) {
              return Data_Map_Internal.insert(dictOrd)(a)(Data_Unit.unit)(v);
          };
      };
  };
  var fromMap = $$Set;
  var foldableSet = new Data_Foldable.Foldable(function (dictMonoid) {
      return function (f) {
          var $68 = Data_Foldable.foldMap(Data_List_Types.foldableList)(dictMonoid)(f);
          return function ($69) {
              return $68(toList($69));
          };
      };
  }, function (f) {
      return function (x) {
          var $70 = Data_Foldable.foldl(Data_List_Types.foldableList)(f)(x);
          return function ($71) {
              return $70(toList($71));
          };
      };
  }, function (f) {
      return function (x) {
          var $72 = Data_Foldable.foldr(Data_List_Types.foldableList)(f)(x);
          return function ($73) {
              return $72(toList($73));
          };
      };
  });
  var findMin = function (v) {
      return Data_Functor.map(Data_Maybe.functorMaybe)(function (v1) {
          return v1.key;
      })(Data_Map_Internal.findMin(v));
  };
  var filter = function (dictOrd) {
      return function (f) {
          return function (v) {
              return Data_Map_Internal.filterWithKey(dictOrd)(function (k) {
                  return function (v1) {
                      return f(k);
                  };
              })(v);
          };
      };
  };
  var eqSet = function (dictEq) {
      return new Data_Eq.Eq(function (v) {
          return function (v1) {
              return Data_Eq.eq(Data_Map_Internal.eqMap(dictEq)(Data_Eq.eqUnit))(v)(v1);
          };
      });
  };
  var ordSet = function (dictOrd) {
      return new Data_Ord.Ord(function () {
          return eqSet(dictOrd.Eq0());
      }, function (s1) {
          return function (s2) {
              return Data_Ord.compare(Data_List_Types.ordList(dictOrd))(toList(s1))(toList(s2));
          };
      });
  }; 
  var empty = Data_Map_Internal.empty;
  var fromFoldable = function (dictFoldable) {
      return function (dictOrd) {
          return Data_Foldable.foldl(dictFoldable)(function (m) {
              return function (a) {
                  return insert(dictOrd)(a)(m);
              };
          })(empty);
      };
  };
  var map = function (dictOrd) {
      return function (f) {
          return Data_Foldable.foldl(foldableSet)(function (m) {
              return function (a) {
                  return insert(dictOrd)(f(a))(m);
              };
          })(empty);
      };
  };
  var unions = function (dictFoldable) {
      return function (dictOrd) {
          return Data_Foldable.foldl(dictFoldable)(union(dictOrd))(empty);
      };
  };
  var $$delete = function (dictOrd) {
      return function (a) {
          return function (v) {
              return Data_Map_Internal["delete"](dictOrd)(a)(v);
          };
      };
  };
  var difference = function (dictOrd) {
      return function (s1) {
          return function (s2) {
              return Data_Foldable.foldl(Data_List_Types.foldableList)(Data_Function.flip($$delete(dictOrd)))(s1)(toList(s2));
          };
      };
  };
  exports["fromFoldable"] = fromFoldable;
  exports["empty"] = empty;
  exports["isEmpty"] = isEmpty;
  exports["singleton"] = singleton;
  exports["map"] = map;
  exports["insert"] = insert;
  exports["member"] = member;
  exports["delete"] = $$delete;
  exports["size"] = size;
  exports["findMin"] = findMin;
  exports["union"] = union;
  exports["unions"] = unions;
  exports["difference"] = difference;
  exports["filter"] = filter;
  exports["fromMap"] = fromMap;
  exports["eqSet"] = eqSet;
  exports["ordSet"] = ordSet;
  exports["foldableSet"] = foldableSet;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Map"] = $PS["Data.Map"] || {};
  var exports = $PS["Data.Map"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Map_Internal = $PS["Data.Map.Internal"];
  var Data_Set = $PS["Data.Set"];
  var keys = (function () {
      var $20 = Data_Functor["void"](Data_Map_Internal.functorMap);
      return function ($21) {
          return Data_Set.fromMap($20($21));
      };
  })();
  exports["keys"] = keys;
})(PS);
(function(exports) {
  /* eslint-disable no-eq-null, eqeqeq */

  "use strict";

  exports["null"] = null;

  exports.nullable = function (a, r, f) {
    return a == null ? r : f(a);
  };

  exports.notNull = function (x) {
    return x;
  };
})(PS["Data.Nullable"] = PS["Data.Nullable"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Nullable"] = $PS["Data.Nullable"] || {};
  var exports = $PS["Data.Nullable"];
  var $foreign = $PS["Data.Nullable"];
  var Data_Maybe = $PS["Data.Maybe"];              
  var toNullable = Data_Maybe.maybe($foreign["null"])($foreign.notNull);
  var toMaybe = function (n) {
      return $foreign.nullable(n, Data_Maybe.Nothing.value, Data_Maybe.Just.create);
  };
  exports["toMaybe"] = toMaybe;
  exports["toNullable"] = toNullable;
  exports["null"] = $foreign["null"];
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Profunctor"] = $PS["Data.Profunctor"] || {};
  var exports = $PS["Data.Profunctor"];                  
  var Profunctor = function (dimap) {
      this.dimap = dimap;
  }; 
  var dimap = function (dict) {
      return dict.dimap;
  };
  exports["dimap"] = dimap;
  exports["Profunctor"] = Profunctor;
})(PS);
(function(exports) {
  "use strict";

  exports.showIntImpl = function (n) {
    return n.toString();
  };

  exports.showNumberImpl = function (n) {
    var str = n.toString();
    return isNaN(str + ".0") ? str : str + ".0";
  };
})(PS["Data.Show"] = PS["Data.Show"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Show"] = $PS["Data.Show"] || {};
  var exports = $PS["Data.Show"];
  var $foreign = $PS["Data.Show"];
  var Show = function (show) {
      this.show = show;
  }; 
  var showNumber = new Show($foreign.showNumberImpl);
  var showInt = new Show($foreign.showIntImpl);
  var show = function (dict) {
      return dict.show;
  };
  exports["show"] = show;
  exports["showInt"] = showInt;
  exports["showNumber"] = showNumber;
})(PS);
(function(exports) {
  "use strict";

  exports.length = function (s) {
    return s.length;
  };

  exports._indexOf = function (just) {
    return function (nothing) {
      return function (x) {
        return function (s) {
          var i = s.indexOf(x);
          return i === -1 ? nothing : just(i);
        };
      };
    };
  };

  exports.take = function (n) {
    return function (s) {
      return s.substr(0, n);
    };
  };

  exports.drop = function (n) {
    return function (s) {
      return s.substring(n);
    };
  };

  exports.splitAt = function (i) {
    return function (s) {
      return { before: s.substring(0, i), after: s.substring(i) };
    };
  };
})(PS["Data.String.CodeUnits"] = PS["Data.String.CodeUnits"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.String.CodeUnits"] = $PS["Data.String.CodeUnits"] || {};
  var exports = $PS["Data.String.CodeUnits"];
  var $foreign = $PS["Data.String.CodeUnits"];
  var Data_Maybe = $PS["Data.Maybe"];
  var stripPrefix = function (v) {
      return function (str) {
          var v1 = $foreign.splitAt($foreign.length(v))(str);
          var $15 = v1.before === v;
          if ($15) {
              return new Data_Maybe.Just(v1.after);
          };
          return Data_Maybe.Nothing.value;
      };
  };                                                                                                   
  var indexOf = $foreign["_indexOf"](Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
  exports["stripPrefix"] = stripPrefix;
  exports["indexOf"] = indexOf;
  exports["length"] = $foreign.length;
  exports["take"] = $foreign.take;
  exports["drop"] = $foreign.drop;
})(PS);
(function(exports) {
  "use strict";

  exports.split = function (sep) {
    return function (s) {
      return s.split(sep);
    };
  };

  exports.toUpper = function (s) {
    return s.toUpperCase();
  };

  exports.joinWith = function (s) {
    return function (xs) {
      return xs.join(s);
    };
  };
})(PS["Data.String.Common"] = PS["Data.String.Common"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.String.Common"] = $PS["Data.String.Common"] || {};
  var exports = $PS["Data.String.Common"];
  var $foreign = $PS["Data.String.Common"];
  exports["split"] = $foreign.split;
  exports["toUpper"] = $foreign.toUpper;
  exports["joinWith"] = $foreign.joinWith;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Data.Void"] = $PS["Data.Void"] || {};
  var exports = $PS["Data.Void"];
  var absurd = function (a) {
      var spin = function ($copy_v) {
          var $tco_result;
          function $tco_loop(v) {
              $copy_v = v;
              return;
          };
          while (!false) {
              $tco_result = $tco_loop($copy_v);
          };
          return $tco_result;
      };
      return spin(a);
  };
  exports["absurd"] = absurd;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Effect.Aff.Class"] = $PS["Effect.Aff.Class"] || {};
  var exports = $PS["Effect.Aff.Class"];
  var Control_Category = $PS["Control.Category"];
  var Control_Monad_Reader_Trans = $PS["Control.Monad.Reader.Trans"];
  var Control_Monad_Trans_Class = $PS["Control.Monad.Trans.Class"];
  var Effect_Aff = $PS["Effect.Aff"];                
  var MonadAff = function (MonadEffect0, liftAff) {
      this.MonadEffect0 = MonadEffect0;
      this.liftAff = liftAff;
  };
  var monadAffAff = new MonadAff(function () {
      return Effect_Aff.monadEffectAff;
  }, Control_Category.identity(Control_Category.categoryFn));
  var liftAff = function (dict) {
      return dict.liftAff;
  };
  var monadAffReader = function (dictMonadAff) {
      return new MonadAff(function () {
          return Control_Monad_Reader_Trans.monadEffectReader(dictMonadAff.MonadEffect0());
      }, (function () {
          var $25 = Control_Monad_Trans_Class.lift(Control_Monad_Reader_Trans.monadTransReaderT)((dictMonadAff.MonadEffect0()).Monad0());
          var $26 = liftAff(dictMonadAff);
          return function ($27) {
              return $25($26($27));
          };
      })());
  };
  exports["monadAffAff"] = monadAffAff;
  exports["monadAffReader"] = monadAffReader;
})(PS);
(function(exports) {
  "use strict";

  exports.warn = function (s) {
    return function () {
      console.warn(s);
    };
  };

  exports.info = function (s) {
    return function () {
      console.info(s);
    };
  };
})(PS["Effect.Console"] = PS["Effect.Console"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Effect.Console"] = $PS["Effect.Console"] || {};
  var exports = $PS["Effect.Console"];
  var $foreign = $PS["Effect.Console"];
  exports["warn"] = $foreign.warn;
  exports["info"] = $foreign.info;
})(PS);
(function(exports) {
  "use strict";

  exports.error = function (msg) {
    return new Error(msg);
  };

  exports.throwException = function (e) {
    return function () {
      throw e;
    };
  };
})(PS["Effect.Exception"] = PS["Effect.Exception"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Effect.Exception"] = $PS["Effect.Exception"] || {};
  var exports = $PS["Effect.Exception"];
  var $foreign = $PS["Effect.Exception"];
  var $$throw = function ($2) {
      return $foreign.throwException($foreign.error($2));
  };
  exports["throw"] = $$throw;
  exports["error"] = $foreign.error;
  exports["throwException"] = $foreign.throwException;
})(PS);
(function(exports) {
  "use strict";

  exports.random = Math.random;
})(PS["Effect.Random"] = PS["Effect.Random"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Effect.Random"] = $PS["Effect.Random"] || {};
  var exports = $PS["Effect.Random"];
  var $foreign = $PS["Effect.Random"];
  var Data_Int = $PS["Data.Int"];
  var randomInt = function (low) {
      return function (high) {
          return function __do() {
              var n = $foreign.random();
              var asNumber = ((Data_Int.toNumber(high) - Data_Int.toNumber(low)) + 1) * n + Data_Int.toNumber(low);
              return Data_Int.floor(asNumber);
          };
      };
  };
  exports["randomInt"] = randomInt;
  exports["random"] = $foreign.random;
})(PS);
(function(exports) {
  "use strict";

  exports.typeOf = function (value) {
    return typeof value;
  };
})(PS["Foreign"] = PS["Foreign"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Foreign"] = $PS["Foreign"] || {};
  var exports = $PS["Foreign"];
  var $foreign = $PS["Foreign"];
  exports["typeOf"] = $foreign.typeOf;
})(PS);
(function(exports) {
  "use strict";

  exports["new"] = function () {
    return {};
  };
})(PS["Foreign.Object.ST"] = PS["Foreign.Object.ST"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Foreign.Object.ST"] = $PS["Foreign.Object.ST"] || {};
  var exports = $PS["Foreign.Object.ST"];
  var $foreign = $PS["Foreign.Object.ST"];
  exports["new"] = $foreign["new"];
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Halogen.Data.OrdBox"] = $PS["Halogen.Data.OrdBox"] || {};
  var exports = $PS["Halogen.Data.OrdBox"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Ord = $PS["Data.Ord"];                
  var OrdBox = (function () {
      function OrdBox(value0, value1, value2) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
      };
      OrdBox.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return new OrdBox(value0, value1, value2);
              };
          };
      };
      return OrdBox;
  })();
  var mkOrdBox = function (dictOrd) {
      return OrdBox.create(Data_Eq.eq(dictOrd.Eq0()))(Data_Ord.compare(dictOrd));
  };
  var eqOrdBox = new Data_Eq.Eq(function (v) {
      return function (v1) {
          return v.value0(v.value2)(v1.value2);
      };
  });
  var ordOrdBox = new Data_Ord.Ord(function () {
      return eqOrdBox;
  }, function (v) {
      return function (v1) {
          return v.value1(v.value2)(v1.value2);
      };
  });
  exports["mkOrdBox"] = mkOrdBox;
  exports["ordOrdBox"] = ordOrdBox;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Halogen.Data.Slot"] = $PS["Halogen.Data.Slot"] || {};
  var exports = $PS["Halogen.Data.Slot"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Map_Internal = $PS["Data.Map.Internal"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Halogen_Data_OrdBox = $PS["Halogen.Data.OrdBox"];
  var pop = function (dictCons) {
      return function (dictIsSymbol) {
          return function (dictOrd) {
              return function (sym) {
                  return function (key) {
                      return function (v) {
                          return Data_Map_Internal.pop(Data_Tuple.ordTuple(Data_Ord.ordString)(Halogen_Data_OrdBox.ordOrdBox))(new Data_Tuple.Tuple(Data_Symbol.reflectSymbol(dictIsSymbol)(sym), Halogen_Data_OrdBox.mkOrdBox(dictOrd)(key)))(v);
                      };
                  };
              };
          };
      };
  };
  var lookup = function (dictCons) {
      return function (dictIsSymbol) {
          return function (dictOrd) {
              return function (sym) {
                  return function (key) {
                      return function (v) {
                          return Data_Map_Internal.lookup(Data_Tuple.ordTuple(Data_Ord.ordString)(Halogen_Data_OrdBox.ordOrdBox))(new Data_Tuple.Tuple(Data_Symbol.reflectSymbol(dictIsSymbol)(sym), Halogen_Data_OrdBox.mkOrdBox(dictOrd)(key)))(v);
                      };
                  };
              };
          };
      };
  };
  var insert = function (dictCons) {
      return function (dictIsSymbol) {
          return function (dictOrd) {
              return function (sym) {
                  return function (key) {
                      return function (val) {
                          return function (v) {
                              return Data_Map_Internal.insert(Data_Tuple.ordTuple(Data_Ord.ordString)(Halogen_Data_OrdBox.ordOrdBox))(new Data_Tuple.Tuple(Data_Symbol.reflectSymbol(dictIsSymbol)(sym), Halogen_Data_OrdBox.mkOrdBox(dictOrd)(key)))(val)(v);
                          };
                      };
                  };
              };
          };
      };
  };
  var foreachSlot = function (dictApplicative) {
      return function (v) {
          return function (k) {
              return Data_Foldable.traverse_(dictApplicative)(Data_Map_Internal.foldableMap)(function ($37) {
                  return k($37);
              })(v);
          };
      };
  };
  var empty = Data_Map_Internal.empty;
  exports["empty"] = empty;
  exports["lookup"] = lookup;
  exports["insert"] = insert;
  exports["pop"] = pop;
  exports["foreachSlot"] = foreachSlot;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Halogen.Aff.Driver.State"] = $PS["Halogen.Aff.Driver.State"] || {};
  var exports = $PS["Halogen.Aff.Driver.State"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Map_Internal = $PS["Data.Map.Internal"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Effect_Ref = $PS["Effect.Ref"];
  var Halogen_Data_Slot = $PS["Halogen.Data.Slot"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var unRenderStateX = Unsafe_Coerce.unsafeCoerce;
  var unDriverStateX = Unsafe_Coerce.unsafeCoerce;
  var renderStateX_ = function (dictApplicative) {
      return function (f) {
          return unDriverStateX(function (st) {
              return Data_Foldable.traverse_(dictApplicative)(Data_Foldable.foldableMaybe)(f)(st.rendering);
          });
      };
  };
  var mkRenderStateX = Unsafe_Coerce.unsafeCoerce;
  var renderStateX = function (dictFunctor) {
      return function (f) {
          return unDriverStateX(function (st) {
              return mkRenderStateX(f(st.rendering));
          });
      };
  };
  var mkDriverStateXRef = Unsafe_Coerce.unsafeCoerce;
  var mapDriverState = function (f) {
      return function (v) {
          return f(v);
      };
  };
  var initDriverState = function (component) {
      return function (input) {
          return function (handler) {
              return function (lchs) {
                  return function __do() {
                      var selfRef = Effect_Ref["new"]({})();
                      var childrenIn = Effect_Ref["new"](Halogen_Data_Slot.empty)();
                      var childrenOut = Effect_Ref["new"](Halogen_Data_Slot.empty)();
                      var handlerRef = Effect_Ref["new"](handler)();
                      var pendingQueries = Effect_Ref["new"](new Data_Maybe.Just(Data_List_Types.Nil.value))();
                      var pendingOuts = Effect_Ref["new"](new Data_Maybe.Just(Data_List_Types.Nil.value))();
                      var pendingHandlers = Effect_Ref["new"](Data_Maybe.Nothing.value)();
                      var fresh = Effect_Ref["new"](1)();
                      var subscriptions = Effect_Ref["new"](new Data_Maybe.Just(Data_Map_Internal.empty))();
                      var forks = Effect_Ref["new"](Data_Map_Internal.empty)();
                      var ds = {
                          component: component,
                          state: component.initialState(input),
                          refs: Data_Map_Internal.empty,
                          children: Halogen_Data_Slot.empty,
                          childrenIn: childrenIn,
                          childrenOut: childrenOut,
                          selfRef: selfRef,
                          handlerRef: handlerRef,
                          pendingQueries: pendingQueries,
                          pendingOuts: pendingOuts,
                          pendingHandlers: pendingHandlers,
                          rendering: Data_Maybe.Nothing.value,
                          fresh: fresh,
                          subscriptions: subscriptions,
                          forks: forks,
                          lifecycleHandlers: lchs
                      };
                      Effect_Ref.write(ds)(selfRef)();
                      return mkDriverStateXRef(selfRef);
                  };
              };
          };
      };
  };
  exports["mapDriverState"] = mapDriverState;
  exports["unDriverStateX"] = unDriverStateX;
  exports["renderStateX"] = renderStateX;
  exports["renderStateX_"] = renderStateX_;
  exports["unRenderStateX"] = unRenderStateX;
  exports["initDriverState"] = initDriverState;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Halogen.Query.ChildQuery"] = $PS["Halogen.Query.ChildQuery"] || {};
  var exports = $PS["Halogen.Query.ChildQuery"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];                
  var ChildQuery = (function () {
      function ChildQuery(value0, value1, value2) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
      };
      ChildQuery.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return new ChildQuery(value0, value1, value2);
              };
          };
      };
      return ChildQuery;
  })();
  var unChildQueryBox = Unsafe_Coerce.unsafeCoerce;
  var mkChildQueryBox = Unsafe_Coerce.unsafeCoerce;
  exports["ChildQuery"] = ChildQuery;
  exports["mkChildQueryBox"] = mkChildQueryBox;
  exports["unChildQueryBox"] = unChildQueryBox;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Halogen.Query.HalogenM"] = $PS["Halogen.Query.HalogenM"] || {};
  var exports = $PS["Halogen.Query.HalogenM"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Applicative_Free = $PS["Control.Applicative.Free"];
  var Control_Category = $PS["Control.Category"];
  var Control_Monad_Free = $PS["Control.Monad.Free"];
  var Control_Monad_State_Class = $PS["Control.Monad.State.Class"];
  var Control_Monad_Trans_Class = $PS["Control.Monad.Trans.Class"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect_Class = $PS["Effect.Class"];
  var Halogen_Data_Slot = $PS["Halogen.Data.Slot"];
  var Halogen_Query_ChildQuery = $PS["Halogen.Query.ChildQuery"];        
  var SubscriptionId = function (x) {
      return x;
  };
  var ForkId = function (x) {
      return x;
  };
  var State = (function () {
      function State(value0) {
          this.value0 = value0;
      };
      State.create = function (value0) {
          return new State(value0);
      };
      return State;
  })();
  var Subscribe = (function () {
      function Subscribe(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Subscribe.create = function (value0) {
          return function (value1) {
              return new Subscribe(value0, value1);
          };
      };
      return Subscribe;
  })();
  var Unsubscribe = (function () {
      function Unsubscribe(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Unsubscribe.create = function (value0) {
          return function (value1) {
              return new Unsubscribe(value0, value1);
          };
      };
      return Unsubscribe;
  })();
  var Lift = (function () {
      function Lift(value0) {
          this.value0 = value0;
      };
      Lift.create = function (value0) {
          return new Lift(value0);
      };
      return Lift;
  })();
  var ChildQuery = (function () {
      function ChildQuery(value0) {
          this.value0 = value0;
      };
      ChildQuery.create = function (value0) {
          return new ChildQuery(value0);
      };
      return ChildQuery;
  })();
  var Raise = (function () {
      function Raise(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Raise.create = function (value0) {
          return function (value1) {
              return new Raise(value0, value1);
          };
      };
      return Raise;
  })();
  var Par = (function () {
      function Par(value0) {
          this.value0 = value0;
      };
      Par.create = function (value0) {
          return new Par(value0);
      };
      return Par;
  })();
  var Fork = (function () {
      function Fork(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Fork.create = function (value0) {
          return function (value1) {
              return new Fork(value0, value1);
          };
      };
      return Fork;
  })();
  var Kill = (function () {
      function Kill(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Kill.create = function (value0) {
          return function (value1) {
              return new Kill(value0, value1);
          };
      };
      return Kill;
  })();
  var GetRef = (function () {
      function GetRef(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      GetRef.create = function (value0) {
          return function (value1) {
              return new GetRef(value0, value1);
          };
      };
      return GetRef;
  })();
  var HalogenAp = function (x) {
      return x;
  };
  var HalogenM = function (x) {
      return x;
  };
  var raise = function (o) {
      return HalogenM(Control_Monad_Free.liftF(new Raise(o, Data_Unit.unit)));
  };
  var query = function (dictCons) {
      return function (dictIsSymbol) {
          return function (dictOrd) {
              return function (label) {
                  return function (p) {
                      return function (q) {
                          return HalogenM(Control_Monad_Free.liftF(ChildQuery.create(Halogen_Query_ChildQuery.mkChildQueryBox(new Halogen_Query_ChildQuery.ChildQuery(function (dictApplicative) {
                              return function (k) {
                                  var $132 = Data_Maybe.maybe(Control_Applicative.pure(dictApplicative)(Data_Maybe.Nothing.value))(k);
                                  var $133 = Halogen_Data_Slot.lookup()(dictIsSymbol)(dictOrd)(label)(p);
                                  return function ($134) {
                                      return $132($133($134));
                                  };
                              };
                          }, q, Control_Category.identity(Control_Category.categoryFn))))));
                      };
                  };
              };
          };
      };
  };
  var ordSubscriptionId = Data_Ord.ordInt;
  var ordForkId = Data_Ord.ordInt;
  var monadTransHalogenM = new Control_Monad_Trans_Class.MonadTrans(function (dictMonad) {
      return function ($135) {
          return HalogenM(Control_Monad_Free.liftF(Lift.create($135)));
      };
  });
  var monadHalogenM = Control_Monad_Free.freeMonad;
  var monadStateHalogenM = new Control_Monad_State_Class.MonadState(function () {
      return monadHalogenM;
  }, function ($136) {
      return HalogenM(Control_Monad_Free.liftF(State.create($136)));
  });
  var monadEffectHalogenM = function (dictMonadEffect) {
      return new Effect_Class.MonadEffect(function () {
          return monadHalogenM;
      }, (function () {
          var $141 = Effect_Class.liftEffect(dictMonadEffect);
          return function ($142) {
              return HalogenM(Control_Monad_Free.liftF(Lift.create($141($142))));
          };
      })());
  };
  var hoist = function (dictFunctor) {
      return function (nat) {
          return function (v) {
              var go = function (v1) {
                  if (v1 instanceof State) {
                      return new State(v1.value0);
                  };
                  if (v1 instanceof Subscribe) {
                      return new Subscribe(v1.value0, v1.value1);
                  };
                  if (v1 instanceof Unsubscribe) {
                      return new Unsubscribe(v1.value0, v1.value1);
                  };
                  if (v1 instanceof Lift) {
                      return new Lift(nat(v1.value0));
                  };
                  if (v1 instanceof ChildQuery) {
                      return new ChildQuery(v1.value0);
                  };
                  if (v1 instanceof Raise) {
                      return new Raise(v1.value0, v1.value1);
                  };
                  if (v1 instanceof Par) {
                      return new Par(Data_Newtype.over()()(HalogenAp)(Control_Applicative_Free.hoistFreeAp(hoist(dictFunctor)(nat)))(v1.value0));
                  };
                  if (v1 instanceof Fork) {
                      return new Fork(hoist(dictFunctor)(nat)(v1.value0), v1.value1);
                  };
                  if (v1 instanceof Kill) {
                      return new Kill(v1.value0, v1.value1);
                  };
                  if (v1 instanceof GetRef) {
                      return new GetRef(v1.value0, v1.value1);
                  };
                  throw new Error("Failed pattern match at Halogen.Query.HalogenM (line 300, column 8 - line 310, column 29): " + [ v1.constructor.name ]);
              };
              return Control_Monad_Free.hoistFree(go)(v);
          };
      };
  };
  var functorHalogenM = Control_Monad_Free.freeFunctor;
  var bindHalogenM = Control_Monad_Free.freeBind;           
  var applicativeHalogenM = Control_Monad_Free.freeApplicative;
  exports["State"] = State;
  exports["Subscribe"] = Subscribe;
  exports["Unsubscribe"] = Unsubscribe;
  exports["Lift"] = Lift;
  exports["ChildQuery"] = ChildQuery;
  exports["Raise"] = Raise;
  exports["Par"] = Par;
  exports["Fork"] = Fork;
  exports["Kill"] = Kill;
  exports["GetRef"] = GetRef;
  exports["raise"] = raise;
  exports["query"] = query;
  exports["SubscriptionId"] = SubscriptionId;
  exports["ForkId"] = ForkId;
  exports["hoist"] = hoist;
  exports["functorHalogenM"] = functorHalogenM;
  exports["applicativeHalogenM"] = applicativeHalogenM;
  exports["bindHalogenM"] = bindHalogenM;
  exports["monadHalogenM"] = monadHalogenM;
  exports["monadEffectHalogenM"] = monadEffectHalogenM;
  exports["monadTransHalogenM"] = monadTransHalogenM;
  exports["monadStateHalogenM"] = monadStateHalogenM;
  exports["ordSubscriptionId"] = ordSubscriptionId;
  exports["ordForkId"] = ordForkId;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Halogen.Query.HalogenQ"] = $PS["Halogen.Query.HalogenQ"] || {};
  var exports = $PS["Halogen.Query.HalogenQ"];           
  var Initialize = (function () {
      function Initialize(value0) {
          this.value0 = value0;
      };
      Initialize.create = function (value0) {
          return new Initialize(value0);
      };
      return Initialize;
  })();
  var Finalize = (function () {
      function Finalize(value0) {
          this.value0 = value0;
      };
      Finalize.create = function (value0) {
          return new Finalize(value0);
      };
      return Finalize;
  })();
  var Receive = (function () {
      function Receive(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Receive.create = function (value0) {
          return function (value1) {
              return new Receive(value0, value1);
          };
      };
      return Receive;
  })();
  var Action = (function () {
      function Action(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Action.create = function (value0) {
          return function (value1) {
              return new Action(value0, value1);
          };
      };
      return Action;
  })();
  var Query = (function () {
      function Query(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Query.create = function (value0) {
          return function (value1) {
              return new Query(value0, value1);
          };
      };
      return Query;
  })();
  exports["Initialize"] = Initialize;
  exports["Finalize"] = Finalize;
  exports["Receive"] = Receive;
  exports["Action"] = Action;
  exports["Query"] = Query;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Halogen.Query.Input"] = $PS["Halogen.Query.Input"] || {};
  var exports = $PS["Halogen.Query.Input"];
  var Data_Functor = $PS["Data.Functor"];
  var RefUpdate = (function () {
      function RefUpdate(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      RefUpdate.create = function (value0) {
          return function (value1) {
              return new RefUpdate(value0, value1);
          };
      };
      return RefUpdate;
  })();
  var Action = (function () {
      function Action(value0) {
          this.value0 = value0;
      };
      Action.create = function (value0) {
          return new Action(value0);
      };
      return Action;
  })();
  var functorInput = new Data_Functor.Functor(function (f) {
      return function (m) {
          if (m instanceof RefUpdate) {
              return new RefUpdate(m.value0, m.value1);
          };
          if (m instanceof Action) {
              return new Action(f(m.value0));
          };
          throw new Error("Failed pattern match at Halogen.Query.Input (line 19, column 1 - line 19, column 46): " + [ m.constructor.name ]);
      };
  });
  exports["RefUpdate"] = RefUpdate;
  exports["Action"] = Action;
  exports["functorInput"] = functorInput;
})(PS);
(function(exports) {
  "use strict";

  exports.reallyUnsafeRefEq = function (a) {
    return function (b) {
      return a === b;
    };
  };
})(PS["Unsafe.Reference"] = PS["Unsafe.Reference"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Unsafe.Reference"] = $PS["Unsafe.Reference"] || {};
  var exports = $PS["Unsafe.Reference"];
  var $foreign = $PS["Unsafe.Reference"];
  var unsafeRefEq = $foreign.reallyUnsafeRefEq;
  exports["unsafeRefEq"] = unsafeRefEq;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Halogen.Subscription"] = $PS["Halogen.Subscription"] || {};
  var exports = $PS["Halogen.Subscription"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Array = $PS["Data.Array"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Effect = $PS["Effect"];
  var Effect_Ref = $PS["Effect.Ref"];
  var Safe_Coerce = $PS["Safe.Coerce"];
  var Unsafe_Reference = $PS["Unsafe.Reference"];
  var unsubscribe = function (v) {
      return v;
  };
  var subscribe = function (v) {
      return function (k) {
          return v((function () {
              var $55 = Data_Functor["void"](Effect.functorEffect);
              return function ($56) {
                  return $55(k($56));
              };
          })());
      };
  };                                                                               
  var notify = function (v) {
      return function (a) {
          return v(a);
      };
  };                                                                   
  var makeEmitter = Safe_Coerce.coerce();
  var create = function __do() {
      var subscribers = Effect_Ref["new"]([  ])();
      return {
          emitter: function (k) {
              return function __do() {
                  Effect_Ref.modify_(function (v) {
                      return Data_Semigroup.append(Data_Semigroup.semigroupArray)(v)([ k ]);
                  })(subscribers)();
                  return Effect_Ref.modify_(Data_Array.deleteBy(Unsafe_Reference.unsafeRefEq)(k))(subscribers);
              };
          },
          listener: function (a) {
              return Control_Bind.bind(Effect.bindEffect)(Effect_Ref.read(subscribers))(Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableArray)(function (k) {
                  return k(a);
              }));
          }
      };
  };
  exports["create"] = create;
  exports["notify"] = notify;
  exports["makeEmitter"] = makeEmitter;
  exports["subscribe"] = subscribe;
  exports["unsubscribe"] = unsubscribe;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Halogen.Aff.Driver.Eval"] = $PS["Halogen.Aff.Driver.Eval"] || {};
  var exports = $PS["Halogen.Aff.Driver.Eval"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Applicative_Free = $PS["Control.Applicative.Free"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad = $PS["Control.Monad"];
  var Control_Monad_Fork_Class = $PS["Control.Monad.Fork.Class"];
  var Control_Monad_Free = $PS["Control.Monad.Free"];
  var Control_Parallel = $PS["Control.Parallel"];
  var Control_Parallel_Class = $PS["Control.Parallel.Class"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Coyoneda = $PS["Data.Coyoneda"];
  var Data_Either = $PS["Data.Either"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Map_Internal = $PS["Data.Map.Internal"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect = $PS["Effect"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Exception = $PS["Effect.Exception"];
  var Effect_Ref = $PS["Effect.Ref"];
  var Halogen_Aff_Driver_State = $PS["Halogen.Aff.Driver.State"];
  var Halogen_Query_ChildQuery = $PS["Halogen.Query.ChildQuery"];
  var Halogen_Query_HalogenM = $PS["Halogen.Query.HalogenM"];
  var Halogen_Query_HalogenQ = $PS["Halogen.Query.HalogenQ"];
  var Halogen_Query_Input = $PS["Halogen.Query.Input"];
  var Halogen_Subscription = $PS["Halogen.Subscription"];
  var Unsafe_Reference = $PS["Unsafe.Reference"];                
  var unsubscribe = function (sid) {
      return function (ref) {
          return function __do() {
              var v = Effect_Ref.read(ref)();
              var subs = Effect_Ref.read(v.subscriptions)();
              return Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(Halogen_Subscription.unsubscribe)(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Data_Map_Internal.lookup(Halogen_Query_HalogenM.ordSubscriptionId)(sid))(subs))();
          };
      };
  };
  var queueOrRun = function (ref) {
      return function (au) {
          return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v) {
              if (v instanceof Data_Maybe.Nothing) {
                  return au;
              };
              if (v instanceof Data_Maybe.Just) {
                  return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.write(new Data_Maybe.Just(new Data_List_Types.Cons(au, v.value0)))(ref));
              };
              throw new Error("Failed pattern match at Halogen.Aff.Driver.Eval (line 182, column 33 - line 184, column 57): " + [ v.constructor.name ]);
          });
      };
  };
  var handleLifecycle = function (lchs) {
      return function (f) {
          return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.write({
              initializers: Data_List_Types.Nil.value,
              finalizers: Data_List_Types.Nil.value
          })(lchs)))(function () {
              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(f))(function (result) {
                  return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(lchs)))(function (v) {
                      return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Data_Foldable.traverse_(Effect_Aff.applicativeAff)(Data_List_Types.foldableList)(Control_Monad_Fork_Class.fork(Control_Monad_Fork_Class.monadForkAff))(v.finalizers))(function () {
                          return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Control_Parallel.parSequence_(Effect_Aff.parallelAff)(Data_List_Types.foldableList)(v.initializers))(function () {
                              return Control_Applicative.pure(Effect_Aff.applicativeAff)(result);
                          });
                      });
                  });
              });
          });
      };
  };
  var handleAff = Effect_Aff.runAff_(Data_Either.either(Effect_Exception.throwException)(Data_Function["const"](Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit))));
  var fresh = function (f) {
      return function (ref) {
          return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v) {
              return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref["modify'"](function (i) {
                  return {
                      state: i + 1 | 0,
                      value: f(i)
                  };
              })(v.fresh));
          });
      };
  };
  var evalQ = function (render) {
      return function (ref) {
          return function (q) {
              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v) {
                  return evalM(render)(ref)(v["component"]["eval"](new Halogen_Query_HalogenQ.Query(Data_Functor.map(Data_Coyoneda.functorCoyoneda)(Data_Maybe.Just.create)(Data_Coyoneda.liftCoyoneda(q)), Data_Function["const"](Data_Maybe.Nothing.value))));
              });
          };
      };
  };
  var evalM = function (render) {
      return function (initRef) {
          return function (v) {
              var evalChildQuery = function (ref) {
                  return function (cqb) {
                      return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v1) {
                          return Halogen_Query_ChildQuery.unChildQueryBox(function (v2) {
                              var evalChild = function (v3) {
                                  return Control_Parallel_Class.parallel(Effect_Aff.parallelAff)(Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(v3)))(function (dsx) {
                                      return Halogen_Aff_Driver_State.unDriverStateX(function (ds) {
                                          return evalQ(render)(ds.selfRef)(v2.value1);
                                      })(dsx);
                                  }));
                              };
                              return Data_Functor.map(Effect_Aff.functorAff)(v2.value2)(Control_Parallel_Class.sequential(Effect_Aff.parallelAff)(v2.value0(Effect_Aff.applicativeParAff)(evalChild)(v1.children)));
                          })(cqb);
                      });
                  };
              };
              var go = function (ref) {
                  return function (v1) {
                      if (v1 instanceof Halogen_Query_HalogenM.State) {
                          return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v2) {
                              var v3 = v1.value0(v2.state);
                              if (Unsafe_Reference.unsafeRefEq(v2.state)(v3.value1)) {
                                  return Control_Applicative.pure(Effect_Aff.applicativeAff)(v3.value0);
                              };
                              if (Data_Boolean.otherwise) {
                                  return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.write({
                                      component: v2.component,
                                      state: v3.value1,
                                      refs: v2.refs,
                                      children: v2.children,
                                      childrenIn: v2.childrenIn,
                                      childrenOut: v2.childrenOut,
                                      selfRef: v2.selfRef,
                                      handlerRef: v2.handlerRef,
                                      pendingQueries: v2.pendingQueries,
                                      pendingOuts: v2.pendingOuts,
                                      pendingHandlers: v2.pendingHandlers,
                                      rendering: v2.rendering,
                                      fresh: v2.fresh,
                                      subscriptions: v2.subscriptions,
                                      forks: v2.forks,
                                      lifecycleHandlers: v2.lifecycleHandlers
                                  })(ref)))(function () {
                                      return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(handleLifecycle(v2.lifecycleHandlers)(render(v2.lifecycleHandlers)(ref)))(function () {
                                          return Control_Applicative.pure(Effect_Aff.applicativeAff)(v3.value0);
                                      });
                                  });
                              };
                              throw new Error("Failed pattern match at Halogen.Aff.Driver.Eval (line 86, column 7 - line 92, column 21): " + [ v3.constructor.name ]);
                          });
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.Subscribe) {
                          return Control_Bind.bind(Effect_Aff.bindAff)(fresh(Halogen_Query_HalogenM.SubscriptionId)(ref))(function (sid) {
                              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Halogen_Subscription.subscribe(v1.value0(sid))(function (act) {
                                  return handleAff(evalF(render)(ref)(new Halogen_Query_Input.Action(act)));
                              })))(function (finalize) {
                                  return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v2) {
                                      return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.modify_(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Map_Internal.insert(Halogen_Query_HalogenM.ordSubscriptionId)(sid)(finalize)))(v2.subscriptions)))(function () {
                                          return Control_Applicative.pure(Effect_Aff.applicativeAff)(v1.value1(sid));
                                      });
                                  });
                              });
                          });
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.Unsubscribe) {
                          return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(unsubscribe(v1.value0)(ref)))(function () {
                              return Control_Applicative.pure(Effect_Aff.applicativeAff)(v1.value1);
                          });
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.Lift) {
                          return v1.value0;
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.ChildQuery) {
                          return evalChildQuery(ref)(v1.value0);
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.Raise) {
                          return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v2) {
                              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(v2.handlerRef)))(function (handler) {
                                  return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(queueOrRun(v2.pendingOuts)(handler(v1.value0)))(function () {
                                      return Control_Applicative.pure(Effect_Aff.applicativeAff)(v1.value1);
                                  });
                              });
                          });
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.Par) {
                          return Control_Parallel_Class.sequential(Effect_Aff.parallelAff)(Control_Applicative_Free.retractFreeAp(Effect_Aff.applicativeParAff)(Control_Applicative_Free.hoistFreeAp((function () {
                              var $78 = Control_Parallel_Class.parallel(Effect_Aff.parallelAff);
                              var $79 = evalM(render)(ref);
                              return function ($80) {
                                  return $78($79($80));
                              };
                          })())(v1.value0)));
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.Fork) {
                          return Control_Bind.bind(Effect_Aff.bindAff)(fresh(Halogen_Query_HalogenM.ForkId)(ref))(function (fid) {
                              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v2) {
                                  return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref["new"](false)))(function (doneRef) {
                                      return Control_Bind.bind(Effect_Aff.bindAff)(Control_Monad_Fork_Class.fork(Control_Monad_Fork_Class.monadForkAff)(Effect_Aff["finally"](Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(function __do() {
                                          Effect_Ref.modify_(Data_Map_Internal["delete"](Halogen_Query_HalogenM.ordForkId)(fid))(v2.forks)();
                                          return Effect_Ref.write(true)(doneRef)();
                                      }))(evalM(render)(ref)(v1.value0))))(function (fiber) {
                                          return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Control_Monad.unlessM(Effect.monadEffect)(Effect_Ref.read(doneRef))(Effect_Ref.modify_(Data_Map_Internal.insert(Halogen_Query_HalogenM.ordForkId)(fid)(fiber))(v2.forks))))(function () {
                                              return Control_Applicative.pure(Effect_Aff.applicativeAff)(v1.value1(fid));
                                          });
                                      });
                                  });
                              });
                          });
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.Kill) {
                          return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v2) {
                              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(v2.forks)))(function (forkMap) {
                                  return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Data_Foldable.traverse_(Effect_Aff.applicativeAff)(Data_Foldable.foldableMaybe)(Effect_Aff.killFiber(Effect_Exception.error("Cancelled")))(Data_Map_Internal.lookup(Halogen_Query_HalogenM.ordForkId)(v1.value0)(forkMap)))(function () {
                                      return Control_Applicative.pure(Effect_Aff.applicativeAff)(v1.value1);
                                  });
                              });
                          });
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.GetRef) {
                          return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v2) {
                              return Control_Applicative.pure(Effect_Aff.applicativeAff)(v1.value1(Data_Map_Internal.lookup(Data_Ord.ordString)(v1.value0)(v2.refs)));
                          });
                      };
                      throw new Error("Failed pattern match at Halogen.Aff.Driver.Eval (line 83, column 12 - line 133, column 33): " + [ v1.constructor.name ]);
                  };
              };
              return Control_Monad_Free.foldFree(Effect_Aff.monadRecAff)(go(initRef))(v);
          };
      };
  };
  var evalF = function (render) {
      return function (ref) {
          return function (v) {
              if (v instanceof Halogen_Query_Input.RefUpdate) {
                  return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Data_Function.flip(Effect_Ref.modify_)(ref)(Halogen_Aff_Driver_State.mapDriverState(function (st) {
                      return {
                          component: st.component,
                          state: st.state,
                          refs: Data_Map_Internal.alter(Data_Ord.ordString)(Data_Function["const"](v.value1))(v.value0)(st.refs),
                          children: st.children,
                          childrenIn: st.childrenIn,
                          childrenOut: st.childrenOut,
                          selfRef: st.selfRef,
                          handlerRef: st.handlerRef,
                          pendingQueries: st.pendingQueries,
                          pendingOuts: st.pendingOuts,
                          pendingHandlers: st.pendingHandlers,
                          rendering: st.rendering,
                          fresh: st.fresh,
                          subscriptions: st.subscriptions,
                          forks: st.forks,
                          lifecycleHandlers: st.lifecycleHandlers
                      };
                  })));
              };
              if (v instanceof Halogen_Query_Input.Action) {
                  return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v1) {
                      return evalM(render)(ref)(v1["component"]["eval"](new Halogen_Query_HalogenQ.Action(v.value0, Data_Unit.unit)));
                  });
              };
              throw new Error("Failed pattern match at Halogen.Aff.Driver.Eval (line 52, column 20 - line 58, column 62): " + [ v.constructor.name ]);
          };
      };
  };
  exports["evalF"] = evalF;
  exports["evalQ"] = evalQ;
  exports["evalM"] = evalM;
  exports["handleLifecycle"] = handleLifecycle;
  exports["queueOrRun"] = queueOrRun;
  exports["handleAff"] = handleAff;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Halogen.VDom.Machine"] = $PS["Halogen.VDom.Machine"] || {};
  var exports = $PS["Halogen.VDom.Machine"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];                
  var Step = (function () {
      function Step(value0, value1, value2, value3) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
      };
      Step.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return new Step(value0, value1, value2, value3);
                  };
              };
          };
      };
      return Step;
  })();
  var unStep = Unsafe_Coerce.unsafeCoerce;
  var step = function (v, a) {
      return v.value2(v.value1, a);
  };
  var mkStep = Unsafe_Coerce.unsafeCoerce;
  var halt = function (v) {
      return v.value3(v.value1);
  };
  var extract = unStep(function (v) {
      return v.value0;
  });
  exports["Step"] = Step;
  exports["mkStep"] = mkStep;
  exports["unStep"] = unStep;
  exports["extract"] = extract;
  exports["step"] = step;
  exports["halt"] = halt;
})(PS);
(function(exports) {
  "use strict";

  exports.unsafeGetAny = function (key, obj) {
    return obj[key];
  };

  exports.unsafeHasAny = function (key, obj) {
    return obj.hasOwnProperty(key);
  };

  exports.unsafeSetAny = function (key, val, obj) {
      obj[key] = val;
  };

  exports.forE = function (a, f) {
    var b = [];
    for (var i = 0; i < a.length; i++) {
      b.push(f(i, a[i]));
    }
    return b;
  };

  exports.forEachE = function (a, f) {
    for (var i = 0; i < a.length; i++) {
      f(a[i]);
    }
  };

  exports.forInE = function (o, f) {
    var ks = Object.keys(o);
    for (var i = 0; i < ks.length; i++) {
      var k = ks[i];
      f(k, o[k]);
    }
  };

  exports.diffWithIxE = function (a1, a2, f1, f2, f3) {
    var a3 = [];
    var l1 = a1.length;
    var l2 = a2.length;
    var i  = 0;
    while (1) {
      if (i < l1) {
        if (i < l2) {
          a3.push(f1(i, a1[i], a2[i]));
        } else {
          f2(i, a1[i]);
        }
      } else if (i < l2) {
        a3.push(f3(i, a2[i]));
      } else {
        break;
      }
      i++;
    }
    return a3;
  };

  exports.strMapWithIxE = function (as, fk, f) {
    var o = {};
    for (var i = 0; i < as.length; i++) {
      var a = as[i];
      var k = fk(a);
      o[k] = f(k, i, a);
    }
    return o;
  };

  exports.diffWithKeyAndIxE = function (o1, as, fk, f1, f2, f3) {
    var o2 = {};
    for (var i = 0; i < as.length; i++) {
      var a = as[i];
      var k = fk(a);
      if (o1.hasOwnProperty(k)) {
        o2[k] = f1(k, i, o1[k], a);
      } else {
        o2[k] = f3(k, i, a);
      }
    }
    for (var k in o1) {
      if (k in o2) {
        continue;
      }
      f2(k, o1[k]);
    }
    return o2;
  };

  exports.refEq = function (a, b) {
    return a === b;
  };

  exports.createTextNode = function (s, doc) {
    return doc.createTextNode(s);
  };

  exports.setTextContent = function (s, n) {
    n.textContent = s;
  };

  exports.createElement = function (ns, name, doc) {
    if (ns != null) {
      return doc.createElementNS(ns, name);
    } else {
      return doc.createElement(name)
    }
  };

  exports.insertChildIx = function (i, a, b) {
    var n = b.childNodes.item(i) || null;
    if (n !== a) {
      b.insertBefore(a, n);
    }
  };

  exports.removeChild = function (a, b) {
    if (b && a.parentNode === b) {
      b.removeChild(a);
    }
  };

  exports.parentNode = function (a) {
    return a.parentNode;
  };

  exports.setAttribute = function (ns, attr, val, el) {
    if (ns != null) {
      el.setAttributeNS(ns, attr, val);
    } else {
      el.setAttribute(attr, val);
    }
  };

  exports.removeAttribute = function (ns, attr, el) {
    if (ns != null) {
      el.removeAttributeNS(ns, attr);
    } else {
      el.removeAttribute(attr);
    }
  };

  exports.hasAttribute = function (ns, attr, el) {
    if (ns != null) {
      return el.hasAttributeNS(ns, attr);
    } else {
      return el.hasAttribute(attr);
    }
  };

  exports.addEventListener = function (ev, listener, el) {
    el.addEventListener(ev, listener, false);
  };

  exports.removeEventListener = function (ev, listener, el) {
    el.removeEventListener(ev, listener, false);
  };

  exports.jsUndefined = void 0;
})(PS["Halogen.VDom.Util"] = PS["Halogen.VDom.Util"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Halogen.VDom.Util"] = $PS["Halogen.VDom.Util"] || {};
  var exports = $PS["Halogen.VDom.Util"];
  var $foreign = $PS["Halogen.VDom.Util"];
  var Foreign_Object_ST = $PS["Foreign.Object.ST"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];                
  var unsafeLookup = $foreign.unsafeGetAny;
  var unsafeFreeze = Unsafe_Coerce.unsafeCoerce;
  var pokeMutMap = $foreign.unsafeSetAny;
  var newMutMap = Foreign_Object_ST["new"];
  exports["newMutMap"] = newMutMap;
  exports["pokeMutMap"] = pokeMutMap;
  exports["unsafeFreeze"] = unsafeFreeze;
  exports["unsafeLookup"] = unsafeLookup;
  exports["unsafeGetAny"] = $foreign.unsafeGetAny;
  exports["unsafeHasAny"] = $foreign.unsafeHasAny;
  exports["unsafeSetAny"] = $foreign.unsafeSetAny;
  exports["forE"] = $foreign.forE;
  exports["forEachE"] = $foreign.forEachE;
  exports["forInE"] = $foreign.forInE;
  exports["diffWithIxE"] = $foreign.diffWithIxE;
  exports["diffWithKeyAndIxE"] = $foreign.diffWithKeyAndIxE;
  exports["strMapWithIxE"] = $foreign.strMapWithIxE;
  exports["refEq"] = $foreign.refEq;
  exports["createTextNode"] = $foreign.createTextNode;
  exports["setTextContent"] = $foreign.setTextContent;
  exports["createElement"] = $foreign.createElement;
  exports["insertChildIx"] = $foreign.insertChildIx;
  exports["removeChild"] = $foreign.removeChild;
  exports["parentNode"] = $foreign.parentNode;
  exports["setAttribute"] = $foreign.setAttribute;
  exports["removeAttribute"] = $foreign.removeAttribute;
  exports["hasAttribute"] = $foreign.hasAttribute;
  exports["addEventListener"] = $foreign.addEventListener;
  exports["removeEventListener"] = $foreign.removeEventListener;
  exports["jsUndefined"] = $foreign.jsUndefined;
})(PS);
(function(exports) {
  "use strict";

  exports.eventListener = function (fn) {
    return function () {
      return function (event) {
        return fn(event)();
      };
    };
  };

  exports.addEventListener = function (type) {
    return function (listener) {
      return function (useCapture) {
        return function (target) {
          return function () {
            return target.addEventListener(type, listener, useCapture);
          };
        };
      };
    };
  };

  exports.removeEventListener = function (type) {
    return function (listener) {
      return function (useCapture) {
        return function (target) {
          return function () {
            return target.removeEventListener(type, listener, useCapture);
          };
        };
      };
    };
  };
})(PS["Web.Event.EventTarget"] = PS["Web.Event.EventTarget"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Web.Event.EventTarget"] = $PS["Web.Event.EventTarget"] || {};
  var exports = $PS["Web.Event.EventTarget"];
  var $foreign = $PS["Web.Event.EventTarget"];
  exports["eventListener"] = $foreign.eventListener;
  exports["addEventListener"] = $foreign.addEventListener;
  exports["removeEventListener"] = $foreign.removeEventListener;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Halogen.VDom.DOM.Prop"] = $PS["Halogen.VDom.DOM.Prop"] || {};
  var exports = $PS["Halogen.VDom.DOM.Prop"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Nullable = $PS["Data.Nullable"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect_Ref = $PS["Effect.Ref"];
  var Foreign = $PS["Foreign"];
  var Foreign_Object = $PS["Foreign.Object"];
  var Halogen_VDom_Machine = $PS["Halogen.VDom.Machine"];
  var Halogen_VDom_Util = $PS["Halogen.VDom.Util"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var Web_Event_EventTarget = $PS["Web.Event.EventTarget"];                
  var Created = (function () {
      function Created(value0) {
          this.value0 = value0;
      };
      Created.create = function (value0) {
          return new Created(value0);
      };
      return Created;
  })();
  var Removed = (function () {
      function Removed(value0) {
          this.value0 = value0;
      };
      Removed.create = function (value0) {
          return new Removed(value0);
      };
      return Removed;
  })();
  var Attribute = (function () {
      function Attribute(value0, value1, value2) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
      };
      Attribute.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return new Attribute(value0, value1, value2);
              };
          };
      };
      return Attribute;
  })();
  var Property = (function () {
      function Property(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Property.create = function (value0) {
          return function (value1) {
              return new Property(value0, value1);
          };
      };
      return Property;
  })();
  var Handler = (function () {
      function Handler(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Handler.create = function (value0) {
          return function (value1) {
              return new Handler(value0, value1);
          };
      };
      return Handler;
  })();
  var Ref = (function () {
      function Ref(value0) {
          this.value0 = value0;
      };
      Ref.create = function (value0) {
          return new Ref(value0);
      };
      return Ref;
  })();
  var unsafeGetProperty = Halogen_VDom_Util.unsafeGetAny;
  var setProperty = Halogen_VDom_Util.unsafeSetAny;
  var removeProperty = function (key, el) {
      var v = Halogen_VDom_Util.hasAttribute(Data_Nullable["null"], key, el);
      if (v) {
          return Halogen_VDom_Util.removeAttribute(Data_Nullable["null"], key, el);
      };
      var v1 = Foreign.typeOf(Halogen_VDom_Util.unsafeGetAny(key, el));
      if (v1 === "string") {
          return Halogen_VDom_Util.unsafeSetAny(key, "", el);
      };
      if (key === "rowSpan") {
          return Halogen_VDom_Util.unsafeSetAny(key, 1, el);
      };
      if (key === "colSpan") {
          return Halogen_VDom_Util.unsafeSetAny(key, 1, el);
      };
      return Halogen_VDom_Util.unsafeSetAny(key, Halogen_VDom_Util.jsUndefined, el);
  };
  var propToStrKey = function (v) {
      if (v instanceof Attribute && v.value0 instanceof Data_Maybe.Just) {
          return "attr/" + (v.value0.value0 + (":" + v.value1));
      };
      if (v instanceof Attribute) {
          return "attr/:" + v.value1;
      };
      if (v instanceof Property) {
          return "prop/" + v.value0;
      };
      if (v instanceof Handler) {
          return "handler/" + v.value0;
      };
      if (v instanceof Ref) {
          return "ref";
      };
      throw new Error("Failed pattern match at Halogen.VDom.DOM.Prop (line 182, column 16 - line 187, column 16): " + [ v.constructor.name ]);
  };
  var propFromString = Unsafe_Coerce.unsafeCoerce; 
  var functorProp = new Data_Functor.Functor(function (v) {
      return function (v1) {
          if (v1 instanceof Handler) {
              return new Handler(v1.value0, Data_Functor.map(Data_Functor.functorFn)(Data_Functor.map(Data_Maybe.functorMaybe)(v))(v1.value1));
          };
          if (v1 instanceof Ref) {
              return new Ref(Data_Functor.map(Data_Functor.functorFn)(Data_Functor.map(Data_Maybe.functorMaybe)(v))(v1.value0));
          };
          return v1;
      };
  });
  var buildProp = function (emit) {
      return function (el) {
          var removeProp = function (prevEvents) {
              return function (v, v1) {
                  if (v1 instanceof Attribute) {
                      return Halogen_VDom_Util.removeAttribute(Data_Nullable.toNullable(v1.value0), v1.value1, el);
                  };
                  if (v1 instanceof Property) {
                      return removeProperty(v1.value0, el);
                  };
                  if (v1 instanceof Handler) {
                      var handler = Halogen_VDom_Util.unsafeLookup(v1.value0, prevEvents);
                      return Halogen_VDom_Util.removeEventListener(v1.value0, Data_Tuple.fst(handler), el);
                  };
                  if (v1 instanceof Ref) {
                      return Data_Unit.unit;
                  };
                  throw new Error("Failed pattern match at Halogen.VDom.DOM.Prop (line 169, column 5 - line 179, column 18): " + [ v1.constructor.name ]);
              };
          };
          var mbEmit = function (v) {
              if (v instanceof Data_Maybe.Just) {
                  return emit(v.value0)();
              };
              return Data_Unit.unit;
          };
          var haltProp = function (state) {
              var v = Foreign_Object.lookup("ref")(state.props);
              if (v instanceof Data_Maybe.Just && v.value0 instanceof Ref) {
                  return mbEmit(v.value0.value0(new Removed(el)));
              };
              return Data_Unit.unit;
          };
          var diffProp = function (prevEvents, events) {
              return function (v, v1, v11, v2) {
                  if (v11 instanceof Attribute && v2 instanceof Attribute) {
                      var $57 = v11.value2 === v2.value2;
                      if ($57) {
                          return v2;
                      };
                      Halogen_VDom_Util.setAttribute(Data_Nullable.toNullable(v2.value0), v2.value1, v2.value2, el);
                      return v2;
                  };
                  if (v11 instanceof Property && v2 instanceof Property) {
                      var v4 = Halogen_VDom_Util.refEq(v11.value1, v2.value1);
                      if (v4) {
                          return v2;
                      };
                      if (v2.value0 === "value") {
                          var elVal = unsafeGetProperty("value", el);
                          var $66 = Halogen_VDom_Util.refEq(elVal, v2.value1);
                          if ($66) {
                              return v2;
                          };
                          setProperty(v2.value0, v2.value1, el);
                          return v2;
                      };
                      setProperty(v2.value0, v2.value1, el);
                      return v2;
                  };
                  if (v11 instanceof Handler && v2 instanceof Handler) {
                      var handler = Halogen_VDom_Util.unsafeLookup(v2.value0, prevEvents);
                      Effect_Ref.write(v2.value1)(Data_Tuple.snd(handler))();
                      Halogen_VDom_Util.pokeMutMap(v2.value0, handler, events);
                      return v2;
                  };
                  return v2;
              };
          };
          var applyProp = function (events) {
              return function (v, v1, v2) {
                  if (v2 instanceof Attribute) {
                      Halogen_VDom_Util.setAttribute(Data_Nullable.toNullable(v2.value0), v2.value1, v2.value2, el);
                      return v2;
                  };
                  if (v2 instanceof Property) {
                      setProperty(v2.value0, v2.value1, el);
                      return v2;
                  };
                  if (v2 instanceof Handler) {
                      var v3 = Halogen_VDom_Util.unsafeGetAny(v2.value0, events);
                      if (Halogen_VDom_Util.unsafeHasAny(v2.value0, events)) {
                          Effect_Ref.write(v2.value1)(Data_Tuple.snd(v3))();
                          return v2;
                      };
                      var ref = Effect_Ref["new"](v2.value1)();
                      var listener = Web_Event_EventTarget.eventListener(function (ev) {
                          return function __do() {
                              var f$prime = Effect_Ref.read(ref)();
                              return mbEmit(f$prime(ev));
                          };
                      })();
                      Halogen_VDom_Util.pokeMutMap(v2.value0, new Data_Tuple.Tuple(listener, ref), events);
                      Halogen_VDom_Util.addEventListener(v2.value0, listener, el);
                      return v2;
                  };
                  if (v2 instanceof Ref) {
                      mbEmit(v2.value0(new Created(el)));
                      return v2;
                  };
                  throw new Error("Failed pattern match at Halogen.VDom.DOM.Prop (line 113, column 5 - line 135, column 15): " + [ v2.constructor.name ]);
              };
          };
          var patchProp = function (state, ps2) {
              var events = Halogen_VDom_Util.newMutMap();
              var onThis = removeProp(state.events);
              var onThese = diffProp(state.events, events);
              var onThat = applyProp(events);
              var props = Halogen_VDom_Util.diffWithKeyAndIxE(state.props, ps2, propToStrKey, onThese, onThis, onThat);
              var nextState = {
                  events: Halogen_VDom_Util.unsafeFreeze(events),
                  props: props
              };
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(Data_Unit.unit, nextState, patchProp, haltProp));
          };
          var renderProp = function (ps1) {
              var events = Halogen_VDom_Util.newMutMap();
              var ps1$prime = Halogen_VDom_Util.strMapWithIxE(ps1, propToStrKey, applyProp(events));
              var state = {
                  events: Halogen_VDom_Util.unsafeFreeze(events),
                  props: ps1$prime
              };
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(Data_Unit.unit, state, patchProp, haltProp));
          };
          return renderProp;
      };
  };
  exports["Attribute"] = Attribute;
  exports["Property"] = Property;
  exports["Handler"] = Handler;
  exports["propFromString"] = propFromString;
  exports["buildProp"] = buildProp;
  exports["functorProp"] = functorProp;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Halogen.VDom.Types"] = $PS["Halogen.VDom.Types"] || {};
  var exports = $PS["Halogen.VDom.Types"];
  var Data_Bifunctor = $PS["Data.Bifunctor"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var Text = (function () {
      function Text(value0) {
          this.value0 = value0;
      };
      Text.create = function (value0) {
          return new Text(value0);
      };
      return Text;
  })();
  var Elem = (function () {
      function Elem(value0, value1, value2, value3) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
      };
      Elem.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return new Elem(value0, value1, value2, value3);
                  };
              };
          };
      };
      return Elem;
  })();
  var Keyed = (function () {
      function Keyed(value0, value1, value2, value3) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
      };
      Keyed.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return new Keyed(value0, value1, value2, value3);
                  };
              };
          };
      };
      return Keyed;
  })();
  var Widget = (function () {
      function Widget(value0) {
          this.value0 = value0;
      };
      Widget.create = function (value0) {
          return new Widget(value0);
      };
      return Widget;
  })();
  var Grafted = (function () {
      function Grafted(value0) {
          this.value0 = value0;
      };
      Grafted.create = function (value0) {
          return new Grafted(value0);
      };
      return Grafted;
  })();
  var Graft = (function () {
      function Graft(value0, value1, value2) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
      };
      Graft.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return new Graft(value0, value1, value2);
              };
          };
      };
      return Graft;
  })();
  var unGraft = function (f) {
      return function ($55) {
          return f($55);
      };
  }; 
  var graft = Unsafe_Coerce.unsafeCoerce;
  var bifunctorGraft = new Data_Bifunctor.Bifunctor(function (f) {
      return function (g) {
          return unGraft(function (v) {
              return graft(new Graft(function ($57) {
                  return f(v.value0($57));
              }, function ($58) {
                  return g(v.value1($58));
              }, v.value2));
          });
      };
  });
  var bifunctorVDom = new Data_Bifunctor.Bifunctor(function (v) {
      return function (v1) {
          return function (v2) {
              if (v2 instanceof Text) {
                  return new Text(v2.value0);
              };
              if (v2 instanceof Grafted) {
                  return new Grafted(Data_Bifunctor.bimap(bifunctorGraft)(v)(v1)(v2.value0));
              };
              return new Grafted(graft(new Graft(v, v1, v2)));
          };
      };
  });
  var runGraft = unGraft(function (v) {
      var go = function (v2) {
          if (v2 instanceof Text) {
              return new Text(v2.value0);
          };
          if (v2 instanceof Elem) {
              return new Elem(v2.value0, v2.value1, v.value0(v2.value2), Data_Functor.map(Data_Functor.functorArray)(go)(v2.value3));
          };
          if (v2 instanceof Keyed) {
              return new Keyed(v2.value0, v2.value1, v.value0(v2.value2), Data_Functor.map(Data_Functor.functorArray)(Data_Functor.map(Data_Tuple.functorTuple)(go))(v2.value3));
          };
          if (v2 instanceof Widget) {
              return new Widget(v.value1(v2.value0));
          };
          if (v2 instanceof Grafted) {
              return new Grafted(Data_Bifunctor.bimap(bifunctorGraft)(v.value0)(v.value1)(v2.value0));
          };
          throw new Error("Failed pattern match at Halogen.VDom.Types (line 86, column 7 - line 86, column 27): " + [ v2.constructor.name ]);
      };
      return go(v.value2);
  });
  exports["Text"] = Text;
  exports["Elem"] = Elem;
  exports["Keyed"] = Keyed;
  exports["Widget"] = Widget;
  exports["Grafted"] = Grafted;
  exports["runGraft"] = runGraft;
  exports["bifunctorVDom"] = bifunctorVDom;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Halogen.HTML.Core"] = $PS["Halogen.HTML.Core"] || {};
  var exports = $PS["Halogen.HTML.Core"];
  var Data_Bifunctor = $PS["Data.Bifunctor"];
  var Data_Functor = $PS["Data.Functor"];
  var Halogen_Query_Input = $PS["Halogen.Query.Input"];
  var Halogen_VDom_DOM_Prop = $PS["Halogen.VDom.DOM.Prop"];
  var Halogen_VDom_Types = $PS["Halogen.VDom.Types"];          
  var IsProp = function (toPropValue) {
      this.toPropValue = toPropValue;
  };
  var HTML = function (x) {
      return x;
  };
  var widget = function ($18) {
      return HTML(Halogen_VDom_Types.Widget.create($18));
  };
  var toPropValue = function (dict) {
      return dict.toPropValue;
  };
  var text = function ($19) {
      return HTML(Halogen_VDom_Types.Text.create($19));
  };
  var prop = function (dictIsProp) {
      return function (v) {
          var $21 = Halogen_VDom_DOM_Prop.Property.create(v);
          var $22 = toPropValue(dictIsProp);
          return function ($23) {
              return $21($22($23));
          };
      };
  }; 
  var isPropString = new IsProp(Halogen_VDom_DOM_Prop.propFromString);  
  var handler = Halogen_VDom_DOM_Prop.Handler.create;
  var element = function (ns) {
      return function (name) {
          return function (props) {
              return function (children) {
                  return new Halogen_VDom_Types.Elem(ns, name, props, children);
              };
          };
      };
  };
  var bifunctorHTML = new Data_Bifunctor.Bifunctor(function (f) {
      return function (g) {
          return function (v) {
              return Data_Bifunctor.bimap(Halogen_VDom_Types.bifunctorVDom)(Data_Functor.map(Data_Functor.functorArray)(Data_Functor.map(Halogen_VDom_DOM_Prop.functorProp)(Data_Functor.map(Halogen_Query_Input.functorInput)(g))))(f)(v);
          };
      };
  });
  exports["widget"] = widget;
  exports["text"] = text;
  exports["element"] = element;
  exports["prop"] = prop;
  exports["handler"] = handler;
  exports["bifunctorHTML"] = bifunctorHTML;
  exports["isPropString"] = isPropString;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Web.DOM.Element"] = $PS["Web.DOM.Element"] || {};
  var exports = $PS["Web.DOM.Element"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];                   
  var toNode = Unsafe_Coerce.unsafeCoerce;
  exports["toNode"] = toNode;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Halogen.VDom.DOM"] = $PS["Halogen.VDom.DOM"] || {};
  var exports = $PS["Halogen.VDom.DOM"];
  var Data_Array = $PS["Data.Array"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Nullable = $PS["Data.Nullable"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Halogen_VDom_Machine = $PS["Halogen.VDom.Machine"];
  var Halogen_VDom_Types = $PS["Halogen.VDom.Types"];
  var Halogen_VDom_Util = $PS["Halogen.VDom.Util"];
  var Web_DOM_Element = $PS["Web.DOM.Element"];
  var haltWidget = function (v) {
      return Halogen_VDom_Machine.halt(v.widget);
  };
  var patchWidget = function (state, vdom) {
      if (vdom instanceof Halogen_VDom_Types.Grafted) {
          return patchWidget(state, Halogen_VDom_Types.runGraft(vdom.value0));
      };
      if (vdom instanceof Halogen_VDom_Types.Widget) {
          var res = Halogen_VDom_Machine.step(state.widget, vdom.value0);
          var res$prime = Halogen_VDom_Machine.unStep(function (v) {
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(v.value0, {
                  build: state.build,
                  widget: res
              }, patchWidget, haltWidget));
          })(res);
          return res$prime;
      };
      haltWidget(state);
      return state.build(vdom);
  };
  var haltText = function (v) {
      var parent = Halogen_VDom_Util.parentNode(v.node);
      return Halogen_VDom_Util.removeChild(v.node, parent);
  };
  var patchText = function (state, vdom) {
      if (vdom instanceof Halogen_VDom_Types.Grafted) {
          return patchText(state, Halogen_VDom_Types.runGraft(vdom.value0));
      };
      if (vdom instanceof Halogen_VDom_Types.Text) {
          if (state.value === vdom.value0) {
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(state.node, state, patchText, haltText));
          };
          if (Data_Boolean.otherwise) {
              var nextState = {
                  build: state.build,
                  node: state.node,
                  value: vdom.value0
              };
              Halogen_VDom_Util.setTextContent(vdom.value0, state.node);
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(state.node, nextState, patchText, haltText));
          };
      };
      haltText(state);
      return state.build(vdom);
  };
  var haltKeyed = function (v) {
      var parent = Halogen_VDom_Util.parentNode(v.node);
      Halogen_VDom_Util.removeChild(v.node, parent);
      Halogen_VDom_Util.forInE(v.children, function (v1, s) {
          return Halogen_VDom_Machine.halt(s);
      });
      return Halogen_VDom_Machine.halt(v.attrs);
  };
  var haltElem = function (v) {
      var parent = Halogen_VDom_Util.parentNode(v.node);
      Halogen_VDom_Util.removeChild(v.node, parent);
      Halogen_VDom_Util.forEachE(v.children, Halogen_VDom_Machine.halt);
      return Halogen_VDom_Machine.halt(v.attrs);
  };
  var eqElemSpec = function (ns1, v, ns2, v1) {
      var $58 = v === v1;
      if ($58) {
          if (ns1 instanceof Data_Maybe.Just && (ns2 instanceof Data_Maybe.Just && ns1.value0 === ns2.value0)) {
              return true;
          };
          if (ns1 instanceof Data_Maybe.Nothing && ns2 instanceof Data_Maybe.Nothing) {
              return true;
          };
          return false;
      };
      return false;
  };
  var patchElem = function (state, vdom) {
      if (vdom instanceof Halogen_VDom_Types.Grafted) {
          return patchElem(state, Halogen_VDom_Types.runGraft(vdom.value0));
      };
      if (vdom instanceof Halogen_VDom_Types.Elem && eqElemSpec(state.ns, state.name, vdom.value0, vdom.value1)) {
          var v = Data_Array.length(vdom.value3);
          var v1 = Data_Array.length(state.children);
          if (v1 === 0 && v === 0) {
              var attrs2 = Halogen_VDom_Machine.step(state.attrs, vdom.value2);
              var nextState = {
                  build: state.build,
                  node: state.node,
                  attrs: attrs2,
                  ns: vdom.value0,
                  name: vdom.value1,
                  children: state.children
              };
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(state.node, nextState, patchElem, haltElem));
          };
          var onThis = function (v2, s) {
              return Halogen_VDom_Machine.halt(s);
          };
          var onThese = function (ix, s, v2) {
              var res = Halogen_VDom_Machine.step(s, v2);
              Halogen_VDom_Util.insertChildIx(ix, Halogen_VDom_Machine.extract(res), state.node);
              return res;
          };
          var onThat = function (ix, v2) {
              var res = state.build(v2);
              Halogen_VDom_Util.insertChildIx(ix, Halogen_VDom_Machine.extract(res), state.node);
              return res;
          };
          var children2 = Halogen_VDom_Util.diffWithIxE(state.children, vdom.value3, onThese, onThis, onThat);
          var attrs2 = Halogen_VDom_Machine.step(state.attrs, vdom.value2);
          var nextState = {
              build: state.build,
              node: state.node,
              attrs: attrs2,
              ns: vdom.value0,
              name: vdom.value1,
              children: children2
          };
          return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(state.node, nextState, patchElem, haltElem));
      };
      haltElem(state);
      return state.build(vdom);
  };
  var patchKeyed = function (state, vdom) {
      if (vdom instanceof Halogen_VDom_Types.Grafted) {
          return patchKeyed(state, Halogen_VDom_Types.runGraft(vdom.value0));
      };
      if (vdom instanceof Halogen_VDom_Types.Keyed && eqElemSpec(state.ns, state.name, vdom.value0, vdom.value1)) {
          var v = Data_Array.length(vdom.value3);
          if (state.length === 0 && v === 0) {
              var attrs2 = Halogen_VDom_Machine.step(state.attrs, vdom.value2);
              var nextState = {
                  build: state.build,
                  node: state.node,
                  attrs: attrs2,
                  ns: vdom.value0,
                  name: vdom.value1,
                  children: state.children,
                  length: 0
              };
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(state.node, nextState, patchKeyed, haltKeyed));
          };
          var onThis = function (v2, s) {
              return Halogen_VDom_Machine.halt(s);
          };
          var onThese = function (v2, ix$prime, s, v3) {
              var res = Halogen_VDom_Machine.step(s, v3.value1);
              Halogen_VDom_Util.insertChildIx(ix$prime, Halogen_VDom_Machine.extract(res), state.node);
              return res;
          };
          var onThat = function (v2, ix, v3) {
              var res = state.build(v3.value1);
              Halogen_VDom_Util.insertChildIx(ix, Halogen_VDom_Machine.extract(res), state.node);
              return res;
          };
          var children2 = Halogen_VDom_Util.diffWithKeyAndIxE(state.children, vdom.value3, Data_Tuple.fst, onThese, onThis, onThat);
          var attrs2 = Halogen_VDom_Machine.step(state.attrs, vdom.value2);
          var nextState = {
              build: state.build,
              node: state.node,
              attrs: attrs2,
              ns: vdom.value0,
              name: vdom.value1,
              children: children2,
              length: v
          };
          return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(state.node, nextState, patchKeyed, haltKeyed));
      };
      haltKeyed(state);
      return state.build(vdom);
  };
  var buildWidget = function (v, build, w) {
      var res = v.buildWidget(v)(w);
      var res$prime = Halogen_VDom_Machine.unStep(function (v1) {
          return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(v1.value0, {
              build: build,
              widget: res
          }, patchWidget, haltWidget));
      })(res);
      return res$prime;
  };
  var buildText = function (v, build, s) {
      var node = Halogen_VDom_Util.createTextNode(s, v.document);
      var state = {
          build: build,
          node: node,
          value: s
      };
      return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(node, state, patchText, haltText));
  };
  var buildKeyed = function (v, build, ns1, name1, as1, ch1) {
      var el = Halogen_VDom_Util.createElement(Data_Nullable.toNullable(ns1), name1, v.document);
      var node = Web_DOM_Element.toNode(el);
      var onChild = function (v1, ix, v2) {
          var res = build(v2.value1);
          Halogen_VDom_Util.insertChildIx(ix, Halogen_VDom_Machine.extract(res), node);
          return res;
      };
      var children = Halogen_VDom_Util.strMapWithIxE(ch1, Data_Tuple.fst, onChild);
      var attrs = v.buildAttributes(el)(as1);
      var state = {
          build: build,
          node: node,
          attrs: attrs,
          ns: ns1,
          name: name1,
          children: children,
          length: Data_Array.length(ch1)
      };
      return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(node, state, patchKeyed, haltKeyed));
  };
  var buildElem = function (v, build, ns1, name1, as1, ch1) {
      var el = Halogen_VDom_Util.createElement(Data_Nullable.toNullable(ns1), name1, v.document);
      var node = Web_DOM_Element.toNode(el);
      var onChild = function (ix, child) {
          var res = build(child);
          Halogen_VDom_Util.insertChildIx(ix, Halogen_VDom_Machine.extract(res), node);
          return res;
      };
      var children = Halogen_VDom_Util.forE(ch1, onChild);
      var attrs = v.buildAttributes(el)(as1);
      var state = {
          build: build,
          node: node,
          attrs: attrs,
          ns: ns1,
          name: name1,
          children: children
      };
      return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(node, state, patchElem, haltElem));
  };
  var buildVDom = function (spec) {
      var build = function (v) {
          if (v instanceof Halogen_VDom_Types.Text) {
              return buildText(spec, build, v.value0);
          };
          if (v instanceof Halogen_VDom_Types.Elem) {
              return buildElem(spec, build, v.value0, v.value1, v.value2, v.value3);
          };
          if (v instanceof Halogen_VDom_Types.Keyed) {
              return buildKeyed(spec, build, v.value0, v.value1, v.value2, v.value3);
          };
          if (v instanceof Halogen_VDom_Types.Widget) {
              return buildWidget(spec, build, v.value0);
          };
          if (v instanceof Halogen_VDom_Types.Grafted) {
              return build(Halogen_VDom_Types.runGraft(v.value0));
          };
          throw new Error("Failed pattern match at Halogen.VDom.DOM (line 58, column 27 - line 63, column 52): " + [ v.constructor.name ]);
      };
      return build;
  };
  exports["buildVDom"] = buildVDom;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Halogen.VDom.Thunk"] = $PS["Halogen.VDom.Thunk"] || {};
  var exports = $PS["Halogen.VDom.Thunk"];
  var Halogen_VDom_DOM = $PS["Halogen.VDom.DOM"];
  var Halogen_VDom_Machine = $PS["Halogen.VDom.Machine"];
  var Halogen_VDom_Util = $PS["Halogen.VDom.Util"];        
  var Thunk = (function () {
      function Thunk(value0, value1, value2, value3) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
      };
      Thunk.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return new Thunk(value0, value1, value2, value3);
                  };
              };
          };
      };
      return Thunk;
  })();                                          
  var unsafeEqThunk = function (v, v1) {
      return Halogen_VDom_Util.refEq(v.value0, v1.value0) && (Halogen_VDom_Util.refEq(v.value1, v1.value1) && v.value1(v.value3, v1.value3));
  };
  var thunk = function (tid, eqFn, f, a) {
      return new Thunk(tid, eqFn, f, a);
  };
  var runThunk = function (v) {
      return v.value2(v.value3);
  };
  var mapThunk = function (k) {
      return function (v) {
          return new Thunk(v.value0, v.value1, function ($46) {
              return k(v.value2($46));
          }, v.value3);
      };
  };
  var hoist = mapThunk;
  var buildThunk = function (toVDom) {
      var haltThunk = function (state) {
          return Halogen_VDom_Machine.halt(state.vdom);
      };
      var patchThunk = function (state, t2) {
          var $43 = unsafeEqThunk(state.thunk, t2);
          if ($43) {
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(Halogen_VDom_Machine.extract(state.vdom), state, patchThunk, haltThunk));
          };
          var vdom = Halogen_VDom_Machine.step(state.vdom, toVDom(runThunk(t2)));
          return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(Halogen_VDom_Machine.extract(vdom), {
              vdom: vdom,
              thunk: t2
          }, patchThunk, haltThunk));
      };
      var renderThunk = function (spec) {
          return function (t) {
              var vdom = Halogen_VDom_DOM.buildVDom(spec)(toVDom(runThunk(t)));
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(Halogen_VDom_Machine.extract(vdom), {
                  thunk: t,
                  vdom: vdom
              }, patchThunk, haltThunk));
          };
      };
      return renderThunk;
  };
  exports["buildThunk"] = buildThunk;
  exports["hoist"] = hoist;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Halogen.Component"] = $PS["Halogen.Component"] || {};
  var exports = $PS["Halogen.Component"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Data_Bifunctor = $PS["Data.Bifunctor"];
  var Data_Coyoneda = $PS["Data.Coyoneda"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Unit = $PS["Data.Unit"];
  var Halogen_Data_Slot = $PS["Halogen.Data.Slot"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var Halogen_Query_HalogenM = $PS["Halogen.Query.HalogenM"];
  var Halogen_Query_HalogenQ = $PS["Halogen.Query.HalogenQ"];
  var Halogen_VDom_Thunk = $PS["Halogen.VDom.Thunk"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];                
  var ComponentSlot = (function () {
      function ComponentSlot(value0) {
          this.value0 = value0;
      };
      ComponentSlot.create = function (value0) {
          return new ComponentSlot(value0);
      };
      return ComponentSlot;
  })();
  var ThunkSlot = (function () {
      function ThunkSlot(value0) {
          this.value0 = value0;
      };
      ThunkSlot.create = function (value0) {
          return new ThunkSlot(value0);
      };
      return ThunkSlot;
  })();
  var unComponentSlot = Unsafe_Coerce.unsafeCoerce;
  var unComponent = Unsafe_Coerce.unsafeCoerce;
  var mkEval = function (args) {
      return function (v) {
          if (v instanceof Halogen_Query_HalogenQ.Initialize) {
              return Data_Functor.voidLeft(Halogen_Query_HalogenM.functorHalogenM)(Data_Foldable.traverse_(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Foldable.foldableMaybe)(args.handleAction)(args.initialize))(v.value0);
          };
          if (v instanceof Halogen_Query_HalogenQ.Finalize) {
              return Data_Functor.voidLeft(Halogen_Query_HalogenM.functorHalogenM)(Data_Foldable.traverse_(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Foldable.foldableMaybe)(args.handleAction)(args.finalize))(v.value0);
          };
          if (v instanceof Halogen_Query_HalogenQ.Receive) {
              return Data_Functor.voidLeft(Halogen_Query_HalogenM.functorHalogenM)(Data_Foldable.traverse_(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Foldable.foldableMaybe)(args.handleAction)(args.receive(v.value0)))(v.value1);
          };
          if (v instanceof Halogen_Query_HalogenQ.Action) {
              return Data_Functor.voidLeft(Halogen_Query_HalogenM.functorHalogenM)(args.handleAction(v.value0))(v.value1);
          };
          if (v instanceof Halogen_Query_HalogenQ.Query) {
              return Data_Coyoneda.unCoyoneda(function (g) {
                  var $25 = Data_Functor.map(Halogen_Query_HalogenM.functorHalogenM)(Data_Maybe.maybe(v.value1(Data_Unit.unit))(g));
                  return function ($26) {
                      return $25(args.handleQuery($26));
                  };
              })(v.value0);
          };
          throw new Error("Failed pattern match at Halogen.Component (line 182, column 15 - line 192, column 70): " + [ v.constructor.name ]);
      };
  };
  var mkComponentSlot = Unsafe_Coerce.unsafeCoerce;
  var mkComponent = Unsafe_Coerce.unsafeCoerce;
  var hoistSlot = function (dictFunctor) {
      return function (nat) {
          return function (v) {
              if (v instanceof ComponentSlot) {
                  return unComponentSlot(function (slot) {
                      return ComponentSlot.create(mkComponentSlot({
                          get: slot.get,
                          pop: slot.pop,
                          set: slot.set,
                          component: hoist(dictFunctor)(nat)(slot.component),
                          input: slot.input,
                          output: slot.output
                      }));
                  })(v.value0);
              };
              if (v instanceof ThunkSlot) {
                  return ThunkSlot.create(Halogen_VDom_Thunk.hoist(Data_Bifunctor.lmap(Halogen_HTML_Core.bifunctorHTML)(hoistSlot(dictFunctor)(nat)))(v.value0));
              };
              throw new Error("Failed pattern match at Halogen.Component (line 279, column 17 - line 284, column 53): " + [ v.constructor.name ]);
          };
      };
  };
  var hoist = function (dictFunctor) {
      return function (nat) {
          return unComponent(function (c) {
              return mkComponent({
                  initialState: c.initialState,
                  render: (function () {
                      var $27 = Data_Bifunctor.lmap(Halogen_HTML_Core.bifunctorHTML)(hoistSlot(dictFunctor)(nat));
                      return function ($28) {
                          return $27(c.render($28));
                      };
                  })(),
                  "eval": (function () {
                      var $29 = Halogen_Query_HalogenM.hoist(dictFunctor)(nat);
                      return function ($30) {
                          return $29(c["eval"]($30));
                      };
                  })()
              });
          });
      };
  }; 
  var defaultEval = {
      handleAction: Data_Function["const"](Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit)),
      handleQuery: Data_Function["const"](Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Maybe.Nothing.value)),
      receive: Data_Function["const"](Data_Maybe.Nothing.value),
      initialize: Data_Maybe.Nothing.value,
      finalize: Data_Maybe.Nothing.value
  };
  var componentSlot = function (dictCons) {
      return function (dictIsSymbol) {
          return function (dictOrd) {
              return function (label) {
                  return function (p) {
                      return function (comp) {
                          return function (input) {
                              return function (output) {
                                  return mkComponentSlot({
                                      get: Halogen_Data_Slot.lookup()(dictIsSymbol)(dictOrd)(label)(p),
                                      pop: Halogen_Data_Slot.pop()(dictIsSymbol)(dictOrd)(label)(p),
                                      set: Halogen_Data_Slot.insert()(dictIsSymbol)(dictOrd)(label)(p),
                                      component: comp,
                                      input: input,
                                      output: output
                                  });
                              };
                          };
                      };
                  };
              };
          };
      };
  };
  exports["mkComponent"] = mkComponent;
  exports["unComponent"] = unComponent;
  exports["hoist"] = hoist;
  exports["mkEval"] = mkEval;
  exports["defaultEval"] = defaultEval;
  exports["ComponentSlot"] = ComponentSlot;
  exports["ThunkSlot"] = ThunkSlot;
  exports["componentSlot"] = componentSlot;
  exports["unComponentSlot"] = unComponentSlot;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Halogen.Aff.Driver"] = $PS["Halogen.Aff.Driver"] || {};
  var exports = $PS["Halogen.Aff.Driver"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Category = $PS["Control.Category"];
  var Control_Monad_Fork_Class = $PS["Control.Monad.Fork.Class"];
  var Control_Monad_Rec_Class = $PS["Control.Monad.Rec.Class"];
  var Control_Parallel = $PS["Control.Parallel"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_List = $PS["Data.List"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Map_Internal = $PS["Data.Map.Internal"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect = $PS["Effect"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Console = $PS["Effect.Console"];
  var Effect_Exception = $PS["Effect.Exception"];
  var Effect_Ref = $PS["Effect.Ref"];
  var Halogen_Aff_Driver_Eval = $PS["Halogen.Aff.Driver.Eval"];
  var Halogen_Aff_Driver_State = $PS["Halogen.Aff.Driver.State"];
  var Halogen_Component = $PS["Halogen.Component"];
  var Halogen_Data_Slot = $PS["Halogen.Data.Slot"];
  var Halogen_Query_HalogenQ = $PS["Halogen.Query.HalogenQ"];
  var Halogen_Query_Input = $PS["Halogen.Query.Input"];
  var Halogen_Subscription = $PS["Halogen.Subscription"];                
  var newLifecycleHandlers = Effect_Ref["new"]({
      initializers: Data_List_Types.Nil.value,
      finalizers: Data_List_Types.Nil.value
  });
  var handlePending = function (ref) {
      return function __do() {
          var queue = Effect_Ref.read(ref)();
          Effect_Ref.write(Data_Maybe.Nothing.value)(ref)();
          return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(queue)((function () {
              var $28 = Data_Foldable.traverse_(Effect_Aff.applicativeAff)(Data_List_Types.foldableList)(Control_Monad_Fork_Class.fork(Control_Monad_Fork_Class.monadForkAff));
              return function ($29) {
                  return Halogen_Aff_Driver_Eval.handleAff($28(Data_List.reverse($29)));
              };
          })())();
      };
  };
  var cleanupSubscriptionsAndForks = function (v) {
      return function __do() {
          Control_Bind.bindFlipped(Effect.bindEffect)(Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Map_Internal.foldableMap)(Halogen_Subscription.unsubscribe)))(Effect_Ref.read(v.subscriptions))();
          Effect_Ref.write(Data_Maybe.Nothing.value)(v.subscriptions)();
          Control_Bind.bindFlipped(Effect.bindEffect)(Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Map_Internal.foldableMap)((function () {
              var $30 = Effect_Aff.killFiber(Effect_Exception.error("finalized"));
              return function ($31) {
                  return Halogen_Aff_Driver_Eval.handleAff($30($31));
              };
          })()))(Effect_Ref.read(v.forks))();
          return Effect_Ref.write(Data_Map_Internal.empty)(v.forks)();
      };
  };
  var runUI = function (renderSpec) {
      return function (component) {
          return function (i) {
              var squashChildInitializers = function (lchs) {
                  return function (preInits) {
                      return Halogen_Aff_Driver_State.unDriverStateX(function (st) {
                          var parentInitializer = Halogen_Aff_Driver_Eval.evalM(render)(st.selfRef)(st["component"]["eval"](new Halogen_Query_HalogenQ.Initialize(Data_Unit.unit)));
                          return Effect_Ref.modify_(function (handlers) {
                              return {
                                  initializers: new Data_List_Types.Cons(Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Control_Parallel.parSequence_(Effect_Aff.parallelAff)(Data_List_Types.foldableList)(Data_List.reverse(handlers.initializers)))(function () {
                                      return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(parentInitializer)(function () {
                                          return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(function __do() {
                                              handlePending(st.pendingQueries)();
                                              return handlePending(st.pendingOuts)();
                                          });
                                      });
                                  }), preInits),
                                  finalizers: handlers.finalizers
                              };
                          })(lchs);
                      });
                  };
              };
              var runComponent = function (lchs) {
                  return function (handler) {
                      return function (j) {
                          return Halogen_Component.unComponent(function (c) {
                              return function __do() {
                                  var lchs$prime = newLifecycleHandlers();
                                  var $$var = Halogen_Aff_Driver_State.initDriverState(c)(j)(handler)(lchs$prime)();
                                  var pre = Effect_Ref.read(lchs)();
                                  Effect_Ref.write({
                                      initializers: Data_List_Types.Nil.value,
                                      finalizers: pre.finalizers
                                  })(lchs)();
                                  Control_Bind.bindFlipped(Effect.bindEffect)(Halogen_Aff_Driver_State.unDriverStateX((function () {
                                      var $32 = render(lchs);
                                      return function ($33) {
                                          return $32((function (v) {
                                              return v.selfRef;
                                          })($33));
                                      };
                                  })()))(Effect_Ref.read($$var))();
                                  Control_Bind.bindFlipped(Effect.bindEffect)(squashChildInitializers(lchs)(pre.initializers))(Effect_Ref.read($$var))();
                                  return $$var;
                              };
                          });
                      };
                  };
              };
              var renderChild = function (lchs) {
                  return function (handler) {
                      return function (childrenInRef) {
                          return function (childrenOutRef) {
                              return Halogen_Component.unComponentSlot(function (slot) {
                                  return function __do() {
                                      var childrenIn = Data_Functor.map(Effect.functorEffect)(slot.pop)(Effect_Ref.read(childrenInRef))();
                                      var $$var = (function () {
                                          if (childrenIn instanceof Data_Maybe.Just) {
                                              Effect_Ref.write(childrenIn.value0.value1)(childrenInRef)();
                                              var dsx = Effect_Ref.read(childrenIn.value0.value0)();
                                              Halogen_Aff_Driver_State.unDriverStateX(function (st) {
                                                  return function __do() {
                                                      Data_Function.flip(Effect_Ref.write)(st.handlerRef)((function () {
                                                          var $34 = Data_Maybe.maybe(Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Unit.unit))(handler);
                                                          return function ($35) {
                                                              return $34(slot.output($35));
                                                          };
                                                      })())();
                                                      return Halogen_Aff_Driver_Eval.handleAff(Halogen_Aff_Driver_Eval.evalM(render)(st.selfRef)(st["component"]["eval"](new Halogen_Query_HalogenQ.Receive(slot.input, Data_Unit.unit))))();
                                                  };
                                              })(dsx)();
                                              return childrenIn.value0.value0;
                                          };
                                          if (childrenIn instanceof Data_Maybe.Nothing) {
                                              return runComponent(lchs)((function () {
                                                  var $36 = Data_Maybe.maybe(Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Unit.unit))(handler);
                                                  return function ($37) {
                                                      return $36(slot.output($37));
                                                  };
                                              })())(slot.input)(slot.component)();
                                          };
                                          throw new Error("Failed pattern match at Halogen.Aff.Driver (line 210, column 14 - line 219, column 98): " + [ childrenIn.constructor.name ]);
                                      })();
                                      var isDuplicate = Data_Functor.map(Effect.functorEffect)(function ($38) {
                                          return Data_Maybe.isJust(slot.get($38));
                                      })(Effect_Ref.read(childrenOutRef))();
                                      Control_Applicative.when(Effect.applicativeEffect)(isDuplicate)(Effect_Console.warn("Halogen: Duplicate slot address was detected during rendering, unexpected results may occur"))();
                                      Effect_Ref.modify_(slot.set($$var))(childrenOutRef)();
                                      return Control_Bind.bind(Effect.bindEffect)(Effect_Ref.read($$var))(Halogen_Aff_Driver_State.renderStateX(Effect.functorEffect)(function (v) {
                                          if (v instanceof Data_Maybe.Nothing) {
                                              return Effect_Exception["throw"]("Halogen internal error: child was not initialized in renderChild");
                                          };
                                          if (v instanceof Data_Maybe.Just) {
                                              return Control_Applicative.pure(Effect.applicativeEffect)(renderSpec.renderChild(v.value0));
                                          };
                                          throw new Error("Failed pattern match at Halogen.Aff.Driver (line 224, column 37 - line 226, column 50): " + [ v.constructor.name ]);
                                      }))();
                                  };
                              });
                          };
                      };
                  };
              };
              var render = function (lchs) {
                  return function ($$var) {
                      return function __do() {
                          var v = Effect_Ref.read($$var)();
                          var shouldProcessHandlers = Data_Functor.map(Effect.functorEffect)(Data_Maybe.isNothing)(Effect_Ref.read(v.pendingHandlers))();
                          Control_Applicative.when(Effect.applicativeEffect)(shouldProcessHandlers)(Effect_Ref.write(new Data_Maybe.Just(Data_List_Types.Nil.value))(v.pendingHandlers))();
                          Effect_Ref.write(Halogen_Data_Slot.empty)(v.childrenOut)();
                          Effect_Ref.write(v.children)(v.childrenIn)();
                          var selfRef = Control_Category.identity(Control_Category.categoryFn)(v.selfRef);
                          var pendingQueries = Control_Category.identity(Control_Category.categoryFn)(v.pendingQueries);
                          var pendingHandlers = Control_Category.identity(Control_Category.categoryFn)(v.pendingHandlers);
                          var handler = (function () {
                              var $39 = Halogen_Aff_Driver_Eval.queueOrRun(pendingHandlers);
                              var $40 = Data_Functor["void"](Effect_Aff.functorAff);
                              var $41 = Halogen_Aff_Driver_Eval.evalF(render)(selfRef);
                              return function ($42) {
                                  return $39($40($41($42)));
                              };
                          })();
                          var childHandler = (function () {
                              var $43 = Halogen_Aff_Driver_Eval.queueOrRun(pendingQueries);
                              return function ($44) {
                                  return $43(handler(Halogen_Query_Input.Action.create($44)));
                              };
                          })();
                          var rendering = renderSpec.render(function ($45) {
                              return Halogen_Aff_Driver_Eval.handleAff(handler($45));
                          })(renderChild(lchs)(childHandler)(v.childrenIn)(v.childrenOut))(v.component.render(v.state))(v.rendering)();
                          var children = Effect_Ref.read(v.childrenOut)();
                          var childrenIn = Effect_Ref.read(v.childrenIn)();
                          Halogen_Data_Slot.foreachSlot(Effect.applicativeEffect)(childrenIn)(function (v1) {
                              return function __do() {
                                  var childDS = Effect_Ref.read(v1)();
                                  Halogen_Aff_Driver_State.renderStateX_(Effect.applicativeEffect)(renderSpec.removeChild)(childDS)();
                                  return finalize(lchs)(childDS)();
                              };
                          })();
                          Data_Function.flip(Effect_Ref.modify_)(v.selfRef)(Halogen_Aff_Driver_State.mapDriverState(function (ds$prime) {
                              return {
                                  component: ds$prime.component,
                                  state: ds$prime.state,
                                  refs: ds$prime.refs,
                                  children: children,
                                  childrenIn: ds$prime.childrenIn,
                                  childrenOut: ds$prime.childrenOut,
                                  selfRef: ds$prime.selfRef,
                                  handlerRef: ds$prime.handlerRef,
                                  pendingQueries: ds$prime.pendingQueries,
                                  pendingOuts: ds$prime.pendingOuts,
                                  pendingHandlers: ds$prime.pendingHandlers,
                                  rendering: new Data_Maybe.Just(rendering),
                                  fresh: ds$prime.fresh,
                                  subscriptions: ds$prime.subscriptions,
                                  forks: ds$prime.forks,
                                  lifecycleHandlers: ds$prime.lifecycleHandlers
                              };
                          }))();
                          return Control_Applicative.when(Effect.applicativeEffect)(shouldProcessHandlers)(Data_Function.flip(Control_Monad_Rec_Class.tailRecM(Control_Monad_Rec_Class.monadRecEffect))(Data_Unit.unit)(function (v1) {
                              return function __do() {
                                  var handlers = Effect_Ref.read(pendingHandlers)();
                                  Effect_Ref.write(new Data_Maybe.Just(Data_List_Types.Nil.value))(pendingHandlers)();
                                  Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)((function () {
                                      var $46 = Data_Foldable.traverse_(Effect_Aff.applicativeAff)(Data_List_Types.foldableList)(Control_Monad_Fork_Class.fork(Control_Monad_Fork_Class.monadForkAff));
                                      return function ($47) {
                                          return Halogen_Aff_Driver_Eval.handleAff($46(Data_List.reverse($47)));
                                      };
                                  })())(handlers)();
                                  var mmore = Effect_Ref.read(pendingHandlers)();
                                  var $21 = Data_Maybe.maybe(false)(Data_List["null"])(mmore);
                                  if ($21) {
                                      return Data_Functor.voidLeft(Effect.functorEffect)(Effect_Ref.write(Data_Maybe.Nothing.value)(pendingHandlers))(new Control_Monad_Rec_Class.Done(Data_Unit.unit))();
                                  };
                                  return new Control_Monad_Rec_Class.Loop(Data_Unit.unit);
                              };
                          }))();
                      };
                  };
              };
              var finalize = function (lchs) {
                  return Halogen_Aff_Driver_State.unDriverStateX(function (st) {
                      return function __do() {
                          cleanupSubscriptionsAndForks(st)();
                          var f = Halogen_Aff_Driver_Eval.evalM(render)(st.selfRef)(st["component"]["eval"](new Halogen_Query_HalogenQ.Finalize(Data_Unit.unit)));
                          Effect_Ref.modify_(function (handlers) {
                              return {
                                  initializers: handlers.initializers,
                                  finalizers: new Data_List_Types.Cons(f, handlers.finalizers)
                              };
                          })(lchs)();
                          return Halogen_Data_Slot.foreachSlot(Effect.applicativeEffect)(st.children)(function (v) {
                              return function __do() {
                                  var dsx = Effect_Ref.read(v)();
                                  return finalize(lchs)(dsx)();
                              };
                          })();
                      };
                  });
              };
              var evalDriver = function (disposed) {
                  return function (ref) {
                      return function (q) {
                          return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(disposed)))(function (v) {
                              if (v) {
                                  return Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Maybe.Nothing.value);
                              };
                              return Halogen_Aff_Driver_Eval.evalQ(render)(ref)(q);
                          });
                      };
                  };
              };
              var dispose = function (disposed) {
                  return function (lchs) {
                      return function (dsx) {
                          return Halogen_Aff_Driver_Eval.handleLifecycle(lchs)(function __do() {
                              var v = Effect_Ref.read(disposed)();
                              if (v) {
                                  return Data_Unit.unit;
                              };
                              Effect_Ref.write(true)(disposed)();
                              finalize(lchs)(dsx)();
                              return Halogen_Aff_Driver_State.unDriverStateX(function (v1) {
                                  return function __do() {
                                      var v2 = Effect_Class.liftEffect(Effect_Class.monadEffectEffect)(Effect_Ref.read(v1.selfRef))();
                                      return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(v2.rendering)(renderSpec.dispose)();
                                  };
                              })(dsx)();
                          });
                      };
                  };
              };
              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(newLifecycleHandlers))(function (lchs) {
                  return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref["new"](false)))(function (disposed) {
                      return Halogen_Aff_Driver_Eval.handleLifecycle(lchs)(function __do() {
                          var sio = Halogen_Subscription.create();
                          var dsx = Control_Bind.bindFlipped(Effect.bindEffect)(Effect_Ref.read)(runComponent(lchs)((function () {
                              var $48 = Effect_Class.liftEffect(Effect_Aff.monadEffectAff);
                              var $49 = Halogen_Subscription.notify(sio.listener);
                              return function ($50) {
                                  return $48($49($50));
                              };
                          })())(i)(component))();
                          return Halogen_Aff_Driver_State.unDriverStateX(function (st) {
                              return Control_Applicative.pure(Effect.applicativeEffect)({
                                  query: evalDriver(disposed)(st.selfRef),
                                  messages: sio.emitter,
                                  dispose: dispose(disposed)(lchs)(dsx)
                              });
                          })(dsx)();
                      });
                  });
              });
          };
      };
  };
  exports["runUI"] = runUI;
})(PS);
(function(exports) {
  "use strict";                                               

  exports._querySelector = function (selector) {
    return function (node) {
      return function () {
        return node.querySelector(selector);
      };
    };
  };
})(PS["Web.DOM.ParentNode"] = PS["Web.DOM.ParentNode"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Web.DOM.ParentNode"] = $PS["Web.DOM.ParentNode"] || {};
  var exports = $PS["Web.DOM.ParentNode"];
  var $foreign = $PS["Web.DOM.ParentNode"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Nullable = $PS["Data.Nullable"];
  var Effect = $PS["Effect"];
  var querySelector = function (qs) {
      var $0 = Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe);
      var $1 = $foreign["_querySelector"](qs);
      return function ($2) {
          return $0($1($2));
      };
  };
  exports["querySelector"] = querySelector;
})(PS);
(function(exports) {
  "use strict";

  exports.window = function () {
    return window;
  };
})(PS["Web.HTML"] = PS["Web.HTML"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Web.HTML"] = $PS["Web.HTML"] || {};
  var exports = $PS["Web.HTML"];
  var $foreign = $PS["Web.HTML"];
  exports["window"] = $foreign.window;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Web.HTML.Event.EventTypes"] = $PS["Web.HTML.Event.EventTypes"] || {};
  var exports = $PS["Web.HTML.Event.EventTypes"];
  var domcontentloaded = "DOMContentLoaded";
  exports["domcontentloaded"] = domcontentloaded;
})(PS);
(function(exports) {
  "use strict";

  exports._readyState = function (doc) {
    return function () {
      return doc.readyState;
    };
  };
})(PS["Web.HTML.HTMLDocument"] = PS["Web.HTML.HTMLDocument"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Web.HTML.HTMLDocument.ReadyState"] = $PS["Web.HTML.HTMLDocument.ReadyState"] || {};
  var exports = $PS["Web.HTML.HTMLDocument.ReadyState"];
  var Data_Maybe = $PS["Data.Maybe"];              
  var Loading = (function () {
      function Loading() {

      };
      Loading.value = new Loading();
      return Loading;
  })();
  var Interactive = (function () {
      function Interactive() {

      };
      Interactive.value = new Interactive();
      return Interactive;
  })();
  var Complete = (function () {
      function Complete() {

      };
      Complete.value = new Complete();
      return Complete;
  })();
  var parse = function (v) {
      if (v === "loading") {
          return new Data_Maybe.Just(Loading.value);
      };
      if (v === "interactive") {
          return new Data_Maybe.Just(Interactive.value);
      };
      if (v === "complete") {
          return new Data_Maybe.Just(Complete.value);
      };
      return Data_Maybe.Nothing.value;
  };
  exports["Loading"] = Loading;
  exports["parse"] = parse;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Web.HTML.HTMLDocument"] = $PS["Web.HTML.HTMLDocument"] || {};
  var exports = $PS["Web.HTML.HTMLDocument"];
  var $foreign = $PS["Web.HTML.HTMLDocument"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Effect = $PS["Effect"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var Web_HTML_HTMLDocument_ReadyState = $PS["Web.HTML.HTMLDocument.ReadyState"];
  var toParentNode = Unsafe_Coerce.unsafeCoerce; 
  var toDocument = Unsafe_Coerce.unsafeCoerce;
  var readyState = (function () {
      var $0 = Data_Functor.map(Effect.functorEffect)((function () {
          var $2 = Data_Maybe.fromMaybe(Web_HTML_HTMLDocument_ReadyState.Loading.value);
          return function ($3) {
              return $2(Web_HTML_HTMLDocument_ReadyState.parse($3));
          };
      })());
      return function ($1) {
          return $0($foreign["_readyState"]($1));
      };
  })();
  exports["toDocument"] = toDocument;
  exports["toParentNode"] = toParentNode;
  exports["readyState"] = readyState;
})(PS);
(function(exports) {
  "use strict";

  exports._read = function (nothing, just, value) {
    var tag = Object.prototype.toString.call(value);
    if (tag.indexOf("[object HTML") === 0 && tag.indexOf("Element]") === tag.length - 8) {
      return just(value);
    } else {
      return nothing;
    }
  };
})(PS["Web.HTML.HTMLElement"] = PS["Web.HTML.HTMLElement"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Web.HTML.HTMLElement"] = $PS["Web.HTML.HTMLElement"] || {};
  var exports = $PS["Web.HTML.HTMLElement"];
  var $foreign = $PS["Web.HTML.HTMLElement"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];                   
  var toNode = Unsafe_Coerce.unsafeCoerce;
  var fromElement = function (x) {
      return $foreign["_read"](Data_Maybe.Nothing.value, Data_Maybe.Just.create, x);
  };
  exports["fromElement"] = fromElement;
  exports["toNode"] = toNode;
})(PS);
(function(exports) {
  "use strict";

  exports.document = function (window) {
    return function () {
      return window.document;
    };
  };

  exports.location = function (window) {
    return function () {
      return window.location;
    };
  };

  exports.localStorage = function (window) {
    return function () {
      return window.localStorage;
    };
  };
})(PS["Web.HTML.Window"] = PS["Web.HTML.Window"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Web.HTML.Window"] = $PS["Web.HTML.Window"] || {};
  var exports = $PS["Web.HTML.Window"];
  var $foreign = $PS["Web.HTML.Window"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var toEventTarget = Unsafe_Coerce.unsafeCoerce;
  exports["toEventTarget"] = toEventTarget;
  exports["document"] = $foreign.document;
  exports["location"] = $foreign.location;
  exports["localStorage"] = $foreign.localStorage;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Halogen.Aff.Util"] = $PS["Halogen.Aff.Util"] || {};
  var exports = $PS["Halogen.Aff.Util"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad_Error_Class = $PS["Control.Monad.Error.Class"];
  var Data_Either = $PS["Data.Either"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect = $PS["Effect"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Exception = $PS["Effect.Exception"];
  var Web_DOM_ParentNode = $PS["Web.DOM.ParentNode"];
  var Web_Event_EventTarget = $PS["Web.Event.EventTarget"];
  var Web_HTML = $PS["Web.HTML"];
  var Web_HTML_Event_EventTypes = $PS["Web.HTML.Event.EventTypes"];
  var Web_HTML_HTMLDocument = $PS["Web.HTML.HTMLDocument"];
  var Web_HTML_HTMLDocument_ReadyState = $PS["Web.HTML.HTMLDocument.ReadyState"];
  var Web_HTML_HTMLElement = $PS["Web.HTML.HTMLElement"];
  var Web_HTML_Window = $PS["Web.HTML.Window"];                
  var selectElement = function (query) {
      return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Control_Bind.bindFlipped(Effect.bindEffect)(Control_Bind.composeKleisliFlipped(Effect.bindEffect)((function () {
          var $2 = Web_DOM_ParentNode.querySelector(query);
          return function ($3) {
              return $2(Web_HTML_HTMLDocument.toParentNode($3));
          };
      })())(Web_HTML_Window.document))(Web_HTML.window)))(function (mel) {
          return Control_Applicative.pure(Effect_Aff.applicativeAff)(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Web_HTML_HTMLElement.fromElement)(mel));
      });
  };
  var runHalogenAff = Effect_Aff.runAff_(Data_Either.either(Effect_Exception.throwException)(Data_Function["const"](Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit))));
  var awaitLoad = Effect_Aff.makeAff(function (callback) {
      return function __do() {
          var rs = Control_Bind.bindFlipped(Effect.bindEffect)(Web_HTML_HTMLDocument.readyState)(Control_Bind.bindFlipped(Effect.bindEffect)(Web_HTML_Window.document)(Web_HTML.window))();
          if (rs instanceof Web_HTML_HTMLDocument_ReadyState.Loading) {
              var et = Data_Functor.map(Effect.functorEffect)(Web_HTML_Window.toEventTarget)(Web_HTML.window)();
              var listener = Web_Event_EventTarget.eventListener(function (v) {
                  return callback(new Data_Either.Right(Data_Unit.unit));
              })();
              Web_Event_EventTarget.addEventListener(Web_HTML_Event_EventTypes.domcontentloaded)(listener)(false)(et)();
              return Effect_Aff.effectCanceler(Web_Event_EventTarget.removeEventListener(Web_HTML_Event_EventTypes.domcontentloaded)(listener)(false)(et));
          };
          callback(new Data_Either.Right(Data_Unit.unit))();
          return Effect_Aff.nonCanceler;
      };
  });
  var awaitBody = Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(awaitLoad)(function () {
      return Control_Bind.bind(Effect_Aff.bindAff)(selectElement("body"))(function (body) {
          return Data_Maybe.maybe(Control_Monad_Error_Class.throwError(Effect_Aff.monadThrowAff)(Effect_Exception.error("Could not find body")))(Control_Applicative.pure(Effect_Aff.applicativeAff))(body);
      });
  });
  exports["awaitBody"] = awaitBody;
  exports["runHalogenAff"] = runHalogenAff;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Halogen.HTML"] = $PS["Halogen.HTML"] || {};
  var exports = $PS["Halogen.HTML"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Halogen_Component = $PS["Halogen.Component"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var slot = function (dictCons) {
      return function (dictIsSymbol) {
          return function (dictOrd) {
              return function (label) {
                  return function (p) {
                      return function (component) {
                          return function (input) {
                              return function (outputQuery) {
                                  return Halogen_HTML_Core.widget(new Halogen_Component.ComponentSlot(Halogen_Component.componentSlot()(dictIsSymbol)(dictOrd)(label)(p)(component)(input)(function ($6) {
                                      return Data_Maybe.Just.create(outputQuery($6));
                                  })));
                              };
                          };
                      };
                  };
              };
          };
      };
  };
  exports["slot"] = slot;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Halogen.HTML.Elements"] = $PS["Halogen.HTML.Elements"] || {};
  var exports = $PS["Halogen.HTML.Elements"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var element = Halogen_HTML_Core.element(Data_Maybe.Nothing.value);
  var h1 = element("h1");    
  var p = element("p");
  var div = element("div");
  var div_ = div([  ]);
  var button = element("button");
  exports["button"] = button;
  exports["div"] = div;
  exports["div_"] = div_;
  exports["h1"] = h1;
  exports["p"] = p;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Web.UIEvent.MouseEvent.EventTypes"] = $PS["Web.UIEvent.MouseEvent.EventTypes"] || {};
  var exports = $PS["Web.UIEvent.MouseEvent.EventTypes"];
  var click = "click";
  exports["click"] = click;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Halogen.HTML.Events"] = $PS["Halogen.HTML.Events"] || {};
  var exports = $PS["Halogen.HTML.Events"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var Halogen_Query_Input = $PS["Halogen.Query.Input"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var Web_UIEvent_MouseEvent_EventTypes = $PS["Web.UIEvent.MouseEvent.EventTypes"];
  var mouseHandler = Unsafe_Coerce.unsafeCoerce;
  var handler = function (et) {
      return function (f) {
          return Halogen_HTML_Core.handler(et)(function (ev) {
              return new Data_Maybe.Just(new Halogen_Query_Input.Action(f(ev)));
          });
      };
  };                                                       
  var onClick = (function () {
      var $1 = handler(Web_UIEvent_MouseEvent_EventTypes.click);
      return function ($2) {
          return $1(mouseHandler($2));
      };
  })();
  exports["onClick"] = onClick;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Halogen.HTML.Properties"] = $PS["Halogen.HTML.Properties"] || {};
  var exports = $PS["Halogen.HTML.Properties"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Data_String_Common = $PS["Data.String.Common"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var prop = function (dictIsProp) {
      return Halogen_HTML_Core.prop(dictIsProp);
  };                                                         
  var classes = (function () {
      var $12 = prop(Halogen_HTML_Core.isPropString)("className");
      var $13 = Data_String_Common.joinWith(" ");
      var $14 = Data_Functor.map(Data_Functor.functorArray)(Data_Newtype.unwrap());
      return function ($15) {
          return $12($13($14($15)));
      };
  })();
  exports["classes"] = classes;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Halogen.Query"] = $PS["Halogen.Query"] || {};
  var exports = $PS["Halogen.Query"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Unit = $PS["Data.Unit"];
  var Halogen_Query_HalogenM = $PS["Halogen.Query.HalogenM"];
  var tell = function (dictCons) {
      return function (dictIsSymbol) {
          return function (dictOrd) {
              return function (slot) {
                  return function (label) {
                      return function (req) {
                          return Data_Functor["void"](Halogen_Query_HalogenM.functorHalogenM)(Halogen_Query_HalogenM.query()(dictIsSymbol)(dictOrd)(slot)(label)(req(Data_Unit.unit)));
                      };
                  };
              };
          };
      };
  };
  var mkTell = function (act) {
      return act(Data_Unit.unit);
  };
  exports["mkTell"] = mkTell;
  exports["tell"] = tell;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Halogen.Store.Monad"] = $PS["Halogen.Store.Monad"] || {};
  var exports = $PS["Halogen.Store.Monad"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad_Reader_Class = $PS["Control.Monad.Reader.Class"];
  var Control_Monad_Reader_Trans = $PS["Control.Monad.Reader.Trans"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Effect = $PS["Effect"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Aff_Class = $PS["Effect.Aff.Class"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Ref = $PS["Effect.Ref"];
  var Halogen_Component = $PS["Halogen.Component"];
  var Halogen_Subscription = $PS["Halogen.Subscription"];                
  var MonadStore = function (MonadEffect0, emitSelected, getStore, updateStore) {
      this.MonadEffect0 = MonadEffect0;
      this.emitSelected = emitSelected;
      this.getStore = getStore;
      this.updateStore = updateStore;
  };
  var runStoreT = function (dictMonad) {
      return function (initialStore) {
          return function (reducer) {
              return function (component) {
                  return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(function __do() {
                      var value = Effect_Ref["new"](initialStore)();
                      var v = Halogen_Subscription.create();
                      return {
                          value: value,
                          emitter: v.emitter,
                          listener: v.listener,
                          reducer: reducer
                      };
                  }))(function (hs) {
                      return Control_Applicative.pure(Effect_Aff.applicativeAff)(Halogen_Component.hoist(((dictMonad.Bind1()).Apply0()).Functor0())(function (v) {
                          return Control_Monad_Reader_Trans.runReaderT(v)(hs);
                      })(component));
                  });
              };
          };
      };
  };
  var monadStoreT = function (dictMonad) {
      return Control_Monad_Reader_Trans.monadReaderT(dictMonad);
  };
  var monadEffectStoreT = function (dictMonadEffect) {
      return Control_Monad_Reader_Trans.monadEffectReader(dictMonadEffect);
  };
  var monadStoreStoreT = function (dictMonadAff) {
      return new MonadStore(function () {
          return monadEffectStoreT(dictMonadAff.MonadEffect0());
      }, function (v) {
          var filterEmitter = function (emitter) {
              return function (predicate) {
                  return Halogen_Subscription.makeEmitter(function (k) {
                      return emitter(function (a) {
                          return Control_Bind.bind(Effect.bindEffect)(predicate(a))(Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(k));
                      });
                  });
              };
          };
          return Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(((dictMonadAff.MonadEffect0()).Monad0()).Bind1()))(Control_Monad_Reader_Class.ask(Control_Monad_Reader_Trans.monadAskReaderT((dictMonadAff.MonadEffect0()).Monad0())))(function (store) {
              return Effect_Class.liftEffect(Control_Monad_Reader_Trans.monadEffectReader(dictMonadAff.MonadEffect0()))(function __do() {
                  var init = Effect_Ref.read(store.value)();
                  var prevRef = Effect_Ref["new"](v.select(init))();
                  return filterEmitter(store.emitter)(function ($$new) {
                      return function __do() {
                          var prevDerived = Effect_Ref.read(prevRef)();
                          var newDerived = v.select($$new);
                          var $19 = v.eq(prevDerived)(newDerived);
                          if ($19) {
                              return Data_Maybe.Nothing.value;
                          };
                          Effect_Class.liftEffect(Effect_Class.monadEffectEffect)(Effect_Ref.write(newDerived)(prevRef))();
                          return new Data_Maybe.Just(newDerived);
                      };
                  });
              });
          });
      }, Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(((dictMonadAff.MonadEffect0()).Monad0()).Bind1()))(Control_Monad_Reader_Class.ask(Control_Monad_Reader_Trans.monadAskReaderT((dictMonadAff.MonadEffect0()).Monad0())))(function (store) {
          return Effect_Class.liftEffect(Control_Monad_Reader_Trans.monadEffectReader(dictMonadAff.MonadEffect0()))(Effect_Ref.read(store.value));
      }), function (action) {
          return Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(((dictMonadAff.MonadEffect0()).Monad0()).Bind1()))(Control_Monad_Reader_Class.ask(Control_Monad_Reader_Trans.monadAskReaderT((dictMonadAff.MonadEffect0()).Monad0())))(function (store) {
              return Effect_Class.liftEffect(Control_Monad_Reader_Trans.monadEffectReader(dictMonadAff.MonadEffect0()))(function __do() {
                  var current = Effect_Ref.read(store.value)();
                  var newStore = store.reducer(current)(action);
                  Effect_Ref.write(newStore)(store.value)();
                  return Halogen_Subscription.notify(store.listener)(newStore)();
              });
          });
      });
  };
  var monadAffStoreT = function (dictMonadAff) {
      return Effect_Aff_Class.monadAffReader(dictMonadAff);
  };
  exports["runStoreT"] = runStoreT;
  exports["monadStoreT"] = monadStoreT;
  exports["monadEffectStoreT"] = monadEffectStoreT;
  exports["monadAffStoreT"] = monadAffStoreT;
  exports["monadStoreStoreT"] = monadStoreStoreT;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Halogen.Svg.Core"] = $PS["Halogen.Svg.Core"] || {};
  var exports = $PS["Halogen.Svg.Core"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Halogen_VDom_DOM_Prop = $PS["Halogen.VDom.DOM.Prop"];
  var Halogen_VDom_Types = $PS["Halogen.VDom.Types"];                
  var ns = Data_Maybe.Just.create("http://www.w3.org/2000/svg");
  var element = function (name) {
      return function (props) {
          return function (children) {
              return new Halogen_VDom_Types.Elem(ns, name, props, children);
          };
      };
  };
  var attr = function (v) {
      return Halogen_VDom_DOM_Prop.Attribute.create(Data_Maybe.Nothing.value)(v);
  };
  exports["element"] = element;
  exports["attr"] = attr;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Halogen.Svg.Attributes"] = $PS["Halogen.Svg.Attributes"] || {};
  var exports = $PS["Halogen.Svg.Attributes"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Show = $PS["Data.Show"];
  var Data_String_Common = $PS["Data.String.Common"];
  var Halogen_Svg_Core = $PS["Halogen.Svg.Core"];
  var Start = (function () {
      function Start() {

      };
      Start.value = new Start();
      return Start;
  })();
  var AnchorMiddle = (function () {
      function AnchorMiddle() {

      };
      AnchorMiddle.value = new AnchorMiddle();
      return AnchorMiddle;
  })();
  var End = (function () {
      function End() {

      };
      End.value = new End();
      return End;
  })();
  var PathCommand = function (x3) {
      return x3;
  };   
  var Rel = (function () {
      function Rel() {

      };
      Rel.value = new Rel();
      return Rel;
  })();
  var Abs = (function () {
      function Abs() {

      };
      Abs.value = new Abs();
      return Abs;
  })();
  var Auto = (function () {
      function Auto() {

      };
      Auto.value = new Auto();
      return Auto;
  })();
  var UseScript = (function () {
      function UseScript() {

      };
      UseScript.value = new UseScript();
      return UseScript;
  })();
  var NoChange = (function () {
      function NoChange() {

      };
      NoChange.value = new NoChange();
      return NoChange;
  })();
  var ResetSize = (function () {
      function ResetSize() {

      };
      ResetSize.value = new ResetSize();
      return ResetSize;
  })();
  var Ideographic = (function () {
      function Ideographic() {

      };
      Ideographic.value = new Ideographic();
      return Ideographic;
  })();
  var Alphabetic = (function () {
      function Alphabetic() {

      };
      Alphabetic.value = new Alphabetic();
      return Alphabetic;
  })();
  var Hanging = (function () {
      function Hanging() {

      };
      Hanging.value = new Hanging();
      return Hanging;
  })();
  var Mathematical = (function () {
      function Mathematical() {

      };
      Mathematical.value = new Mathematical();
      return Mathematical;
  })();
  var Central = (function () {
      function Central() {

      };
      Central.value = new Central();
      return Central;
  })();
  var BaselineMiddle = (function () {
      function BaselineMiddle() {

      };
      BaselineMiddle.value = new BaselineMiddle();
      return BaselineMiddle;
  })();
  var TextAfterEdge = (function () {
      function TextAfterEdge() {

      };
      TextAfterEdge.value = new TextAfterEdge();
      return TextAfterEdge;
  })();
  var TextBeforeEdge = (function () {
      function TextBeforeEdge() {

      };
      TextBeforeEdge.value = new TextBeforeEdge();
      return TextBeforeEdge;
  })();
  var z = "z";
  var renderCommand = function (cmd) {
      return function (s_) {
          if (cmd instanceof Rel) {
              return s_;
          };
          if (cmd instanceof Abs) {
              return Data_String_Common.toUpper(s_);
          };
          throw new Error("Failed pattern match at Halogen.Svg.Attributes (line 273, column 24 - line 275, column 20): " + [ cmd.constructor.name ]);
      };
  };                             
  var renderCommand2Args = function (s_) {
      return function (ref) {
          return function (a_) {
              return function (b) {
                  return PathCommand(renderCommand(ref)(s_) + (Data_Show.show(Data_Show.showNumber)(a_) + (", " + Data_Show.show(Data_Show.showNumber)(b))));
              };
          };
      };
  };
  var printTextAnchor = function (v1) {
      if (v1 instanceof Start) {
          return "start";
      };
      if (v1 instanceof AnchorMiddle) {
          return "middle";
      };
      if (v1 instanceof End) {
          return "end";
      };
      throw new Error("Failed pattern match at Halogen.Svg.Attributes (line 189, column 19 - line 192, column 15): " + [ v1.constructor.name ]);
  };
  var printBaseline = function (v1) {
      if (v1 instanceof Auto) {
          return "auto";
      };
      if (v1 instanceof UseScript) {
          return "use-script";
      };
      if (v1 instanceof NoChange) {
          return "no-change";
      };
      if (v1 instanceof ResetSize) {
          return "reset-size";
      };
      if (v1 instanceof Ideographic) {
          return "ideographic";
      };
      if (v1 instanceof Alphabetic) {
          return "alphabetic";
      };
      if (v1 instanceof Hanging) {
          return "hanging";
      };
      if (v1 instanceof Mathematical) {
          return "mathematical";
      };
      if (v1 instanceof Central) {
          return "central";
      };
      if (v1 instanceof BaselineMiddle) {
          return "middle";
      };
      if (v1 instanceof TextAfterEdge) {
          return "text-after-edge";
      };
      if (v1 instanceof TextBeforeEdge) {
          return "text-before-edge";
      };
      throw new Error("Failed pattern match at Halogen.Svg.Attributes (line 199, column 17 - line 211, column 39): " + [ v1.constructor.name ]);
  };
  var m = renderCommand2Args("m");
  var l = renderCommand2Args("l");
  var attr = Halogen_Svg_Core.attr;
  var classes = (function () {
      var $121 = attr("class");
      var $122 = Data_String_Common.joinWith(" ");
      return function ($123) {
          return $121($122($123));
      };
  })();
  var cx = (function () {
      var $124 = attr("cx");
      var $125 = Data_Show.show(Data_Show.showNumber);
      return function ($126) {
          return $124($125($126));
      };
  })();
  var cy = (function () {
      var $127 = attr("cy");
      var $128 = Data_Show.show(Data_Show.showNumber);
      return function ($129) {
          return $127($128($129));
      };
  })();
  var d = (function () {
      var $130 = attr("d");
      var $131 = Data_String_Common.joinWith(" ");
      return function ($132) {
          return $130($131($132));
      };
  })();
  var dominant_baseline = (function () {
      var $133 = attr("dominant-baseline");
      return function ($134) {
          return $133(printBaseline($134));
      };
  })();                   
  var height = (function () {
      var $144 = attr("height");
      var $145 = Data_Show.show(Data_Show.showNumber);
      return function ($146) {
          return $144($145($146));
      };
  })();
  var r = (function () {
      var $160 = attr("r");
      var $161 = Data_Show.show(Data_Show.showNumber);
      return function ($162) {
          return $160($161($162));
      };
  })();
  var text_anchor = (function () {
      var $183 = attr("text-anchor");
      return function ($184) {
          return $183(printTextAnchor($184));
      };
  })();
  var viewBox = function (x_) {
      return function (y_) {
          return function (w) {
              return function (h_) {
                  return attr("viewBox")(Data_String_Common.joinWith(" ")(Data_Functor.map(Data_Functor.functorArray)(Data_Show.show(Data_Show.showNumber))([ x_, y_, w, h_ ])));
              };
          };
      };
  };
  var width = (function () {
      var $189 = attr("width");
      var $190 = Data_Show.show(Data_Show.showNumber);
      return function ($191) {
          return $189($190($191));
      };
  })();
  var x = (function () {
      var $192 = attr("x");
      var $193 = Data_Show.show(Data_Show.showNumber);
      return function ($194) {
          return $192($193($194));
      };
  })();
  var y = (function () {
      var $201 = attr("y");
      var $202 = Data_Show.show(Data_Show.showNumber);
      return function ($203) {
          return $201($202($203));
      };
  })();
  exports["AnchorMiddle"] = AnchorMiddle;
  exports["Central"] = Central;
  exports["Rel"] = Rel;
  exports["Abs"] = Abs;
  exports["m"] = m;
  exports["l"] = l;
  exports["z"] = z;
  exports["cx"] = cx;
  exports["cy"] = cy;
  exports["r"] = r;
  exports["viewBox"] = viewBox;
  exports["width"] = width;
  exports["height"] = height;
  exports["x"] = x;
  exports["y"] = y;
  exports["d"] = d;
  exports["text_anchor"] = text_anchor;
  exports["dominant_baseline"] = dominant_baseline;
  exports["classes"] = classes;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Halogen.Svg.Elements"] = $PS["Halogen.Svg.Elements"] || {};
  var exports = $PS["Halogen.Svg.Elements"];
  var Halogen_Svg_Core = $PS["Halogen.Svg.Core"];                
  var element = Halogen_Svg_Core.element;      
  var g = element("g");
  var path = function (props) {
      return element("path")(props)([  ]);
  };
  var rect = function (props) {
      return element("rect")(props)([  ]);
  };
  var svg = element("svg");
  var text = element("text");        
  var circle = function (props) {
      return element("circle")(props)([  ]);
  };
  exports["svg"] = svg;
  exports["g"] = g;
  exports["circle"] = circle;
  exports["rect"] = rect;
  exports["path"] = path;
  exports["text"] = text;
})(PS);
(function(exports) {
  "use strict";

  var getEffProp = function (name) {
    return function (node) {
      return function () {
        return node[name];
      };
    };
  };                                                   

  exports._parentNode = getEffProp("parentNode");          

  exports._nextSibling = getEffProp("nextSibling");

  exports.insertBefore = function (node1) {
    return function (node2) {
      return function (parent) {
        return function () {
          parent.insertBefore(node1, node2);
        };
      };
    };
  };

  exports.appendChild = function (node) {
    return function (parent) {
      return function () {
        parent.appendChild(node);
      };
    };
  };

  exports.removeChild = function (node) {
    return function (parent) {
      return function () {
        parent.removeChild(node);
      };
    };
  };
})(PS["Web.DOM.Node"] = PS["Web.DOM.Node"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Web.DOM.Node"] = $PS["Web.DOM.Node"] || {};
  var exports = $PS["Web.DOM.Node"];
  var $foreign = $PS["Web.DOM.Node"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Nullable = $PS["Data.Nullable"];
  var Effect = $PS["Effect"];
  var parentNode = (function () {
      var $3 = Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe);
      return function ($4) {
          return $3($foreign["_parentNode"]($4));
      };
  })();
  var nextSibling = (function () {
      var $14 = Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe);
      return function ($15) {
          return $14($foreign["_nextSibling"]($15));
      };
  })();
  exports["parentNode"] = parentNode;
  exports["nextSibling"] = nextSibling;
  exports["insertBefore"] = $foreign.insertBefore;
  exports["appendChild"] = $foreign.appendChild;
  exports["removeChild"] = $foreign.removeChild;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Halogen.VDom.Driver"] = $PS["Halogen.VDom.Driver"] || {};
  var exports = $PS["Halogen.VDom.Driver"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Category = $PS["Control.Category"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_HeytingAlgebra = $PS["Data.HeytingAlgebra"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect = $PS["Effect"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Ref = $PS["Effect.Ref"];
  var Halogen_Aff_Driver = $PS["Halogen.Aff.Driver"];
  var Halogen_Aff_Driver_State = $PS["Halogen.Aff.Driver.State"];
  var Halogen_Component = $PS["Halogen.Component"];
  var Halogen_VDom_DOM = $PS["Halogen.VDom.DOM"];
  var Halogen_VDom_DOM_Prop = $PS["Halogen.VDom.DOM.Prop"];
  var Halogen_VDom_Machine = $PS["Halogen.VDom.Machine"];
  var Halogen_VDom_Thunk = $PS["Halogen.VDom.Thunk"];
  var Unsafe_Reference = $PS["Unsafe.Reference"];
  var Web_DOM_Node = $PS["Web.DOM.Node"];
  var Web_HTML = $PS["Web.HTML"];
  var Web_HTML_HTMLDocument = $PS["Web.HTML.HTMLDocument"];
  var Web_HTML_HTMLElement = $PS["Web.HTML.HTMLElement"];
  var Web_HTML_Window = $PS["Web.HTML.Window"];
  var substInParent = function (v) {
      return function (v1) {
          return function (v2) {
              if (v1 instanceof Data_Maybe.Just && v2 instanceof Data_Maybe.Just) {
                  return Data_Functor["void"](Effect.functorEffect)(Web_DOM_Node.insertBefore(v)(v1.value0)(v2.value0));
              };
              if (v1 instanceof Data_Maybe.Nothing && v2 instanceof Data_Maybe.Just) {
                  return Data_Functor["void"](Effect.functorEffect)(Web_DOM_Node.appendChild(v)(v2.value0));
              };
              return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
          };
      };
  };
  var removeChild = function (v) {
      return function __do() {
          var npn = Web_DOM_Node.parentNode(v.node)();
          return Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(function (pn) {
              return Web_DOM_Node.removeChild(v.node)(pn);
          })(npn)();
      };
  };
  var mkSpec = function (handler) {
      return function (renderChildRef) {
          return function (document) {
              var getNode = Halogen_Aff_Driver_State.unRenderStateX(function (v) {
                  return v.node;
              });
              var done = function (st) {
                  if (st instanceof Data_Maybe.Just) {
                      return Halogen_VDom_Machine.halt(st.value0);
                  };
                  return Data_Unit.unit;
              };
              var buildWidget = function (spec) {
                  var buildThunk = Halogen_VDom_Thunk.buildThunk(Data_Newtype.unwrap())(spec);
                  var renderComponentSlot = function (cs) {
                      var renderChild = Effect_Ref.read(renderChildRef)();
                      var rsx = renderChild(cs)();
                      var node = getNode(rsx);
                      return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(node, Data_Maybe.Nothing.value, patch, done));
                  };
                  var render = function (slot) {
                      if (slot instanceof Halogen_Component.ComponentSlot) {
                          return renderComponentSlot(slot.value0);
                      };
                      if (slot instanceof Halogen_Component.ThunkSlot) {
                          var step = buildThunk(slot.value0);
                          return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(Halogen_VDom_Machine.extract(step), new Data_Maybe.Just(step), patch, done));
                      };
                      throw new Error("Failed pattern match at Halogen.VDom.Driver (line 85, column 7 - line 90, column 75): " + [ slot.constructor.name ]);
                  };
                  var patch = function (st, slot) {
                      if (st instanceof Data_Maybe.Just) {
                          if (slot instanceof Halogen_Component.ComponentSlot) {
                              Halogen_VDom_Machine.halt(st.value0);
                              return renderComponentSlot(slot.value0);
                          };
                          if (slot instanceof Halogen_Component.ThunkSlot) {
                              var step$prime = Halogen_VDom_Machine.step(st.value0, slot.value0);
                              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(Halogen_VDom_Machine.extract(step$prime), new Data_Maybe.Just(step$prime), patch, done));
                          };
                          throw new Error("Failed pattern match at Halogen.VDom.Driver (line 98, column 22 - line 104, column 79): " + [ slot.constructor.name ]);
                      };
                      return render(slot);
                  };
                  return render;
              };
              var buildAttributes = Halogen_VDom_DOM_Prop.buildProp(handler);
              return {
                  buildWidget: buildWidget,
                  buildAttributes: buildAttributes,
                  document: document
              };
          };
      };
  };
  var renderSpec = function (document) {
      return function (container) {
          var render = function (handler) {
              return function (child) {
                  return function (v) {
                      return function (v1) {
                          if (v1 instanceof Data_Maybe.Nothing) {
                              return function __do() {
                                  var renderChildRef = Effect_Ref["new"](child)();
                                  var spec = mkSpec(handler)(renderChildRef)(document);
                                  var machine = Halogen_VDom_DOM.buildVDom(spec)(v);
                                  var node = Halogen_VDom_Machine.extract(machine);
                                  Data_Functor["void"](Effect.functorEffect)(Web_DOM_Node.appendChild(node)(Web_HTML_HTMLElement.toNode(container)))();
                                  return {
                                      machine: machine,
                                      node: node,
                                      renderChildRef: renderChildRef
                                  };
                              };
                          };
                          if (v1 instanceof Data_Maybe.Just) {
                              return function __do() {
                                  Effect_Ref.write(child)(v1.value0.renderChildRef)();
                                  var parent = Web_DOM_Node.parentNode(v1.value0.node)();
                                  var nextSib = Web_DOM_Node.nextSibling(v1.value0.node)();
                                  var machine$prime = Halogen_VDom_Machine.step(v1.value0.machine, v);
                                  var newNode = Halogen_VDom_Machine.extract(machine$prime);
                                  Control_Applicative.when(Effect.applicativeEffect)(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean)))(Unsafe_Reference.unsafeRefEq)(v1.value0.node)(newNode))(substInParent(newNode)(nextSib)(parent))();
                                  return {
                                      machine: machine$prime,
                                      node: newNode,
                                      renderChildRef: v1.value0.renderChildRef
                                  };
                              };
                          };
                          throw new Error("Failed pattern match at Halogen.VDom.Driver (line 159, column 5 - line 175, column 80): " + [ v1.constructor.name ]);
                      };
                  };
              };
          };
          return {
              render: render,
              renderChild: Control_Category.identity(Control_Category.categoryFn),
              removeChild: removeChild,
              dispose: removeChild
          };
      };
  };
  var runUI = function (component) {
      return function (i) {
          return function (element) {
              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Data_Functor.map(Effect.functorEffect)(Web_HTML_HTMLDocument.toDocument)(Control_Bind.bindFlipped(Effect.bindEffect)(Web_HTML_Window.document)(Web_HTML.window))))(function (document) {
                  return Halogen_Aff_Driver.runUI(renderSpec(document)(element))(component)(i);
              });
          };
      };
  };
  exports["runUI"] = runUI;
})(PS);
(function(exports) {
  "use strict";

  // A helper which transforms the result ofencodeURIComponent to be compliant
  // with RFC3896, as described in the MDN documentation here:
  //
  // https://web.archive.org/web/20201206001047/https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
  function toRFC3896(input) {
    return input.replace(/[!'()*]/g, function (c) {
      return "%" + c.charCodeAt(0).toString(16);
    });
  }

  exports._encodeURIComponent = function encode(fail, succeed, input) {
    try {
      return succeed(toRFC3896(encodeURIComponent(input)));
    } catch (err) {
      return fail(err);
    }
  };

  function _decodeURIComponent(fail, succeed, input) {
    try {
      return succeed(decodeURIComponent(input));
    } catch (err) {
      return fail(err);
    }
  }

  exports._decodeURIComponent = _decodeURIComponent;
})(PS["JSURI"] = PS["JSURI"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["JSURI"] = $PS["JSURI"] || {};
  var exports = $PS["JSURI"];
  var $foreign = $PS["JSURI"];
  var Data_Function = $PS["Data.Function"];
  var Data_Function_Uncurried = $PS["Data.Function.Uncurried"];
  var Data_Maybe = $PS["Data.Maybe"];                
  var $$encodeURIComponent = Data_Function_Uncurried.runFn3($foreign["_encodeURIComponent"])(Data_Function["const"](Data_Maybe.Nothing.value))(Data_Maybe.Just.create);      
  var $$decodeURIComponent = Data_Function_Uncurried.runFn3($foreign["_decodeURIComponent"])(Data_Function["const"](Data_Maybe.Nothing.value))(Data_Maybe.Just.create);
  exports["encodeURIComponent"] = $$encodeURIComponent;
  exports["decodeURIComponent"] = $$decodeURIComponent;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Routing.Duplex.Parser"] = $PS["Routing.Duplex.Parser"] || {};
  var exports = $PS["Routing.Duplex.Parser"];
  var Control_Alt = $PS["Control.Alt"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Data_Array = $PS["Data.Array"];
  var Data_Array_NonEmpty = $PS["Data.Array.NonEmpty"];
  var Data_Array_NonEmpty_Internal = $PS["Data.Array.NonEmpty.Internal"];
  var Data_Bifunctor = $PS["Data.Bifunctor"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Either = $PS["Data.Either"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_String_CodeUnits = $PS["Data.String.CodeUnits"];
  var Data_String_Common = $PS["Data.String.Common"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Data_Unit = $PS["Data.Unit"];
  var JSURI = $PS["JSURI"];                                              
  var Expected = (function () {
      function Expected(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Expected.create = function (value0) {
          return function (value1) {
              return new Expected(value0, value1);
          };
      };
      return Expected;
  })();
  var ExpectedEndOfPath = (function () {
      function ExpectedEndOfPath(value0) {
          this.value0 = value0;
      };
      ExpectedEndOfPath.create = function (value0) {
          return new ExpectedEndOfPath(value0);
      };
      return ExpectedEndOfPath;
  })();
  var EndOfPath = (function () {
      function EndOfPath() {

      };
      EndOfPath.value = new EndOfPath();
      return EndOfPath;
  })();
  var Fail = (function () {
      function Fail(value0) {
          this.value0 = value0;
      };
      Fail.create = function (value0) {
          return new Fail(value0);
      };
      return Fail;
  })();
  var Success = (function () {
      function Success(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Success.create = function (value0) {
          return function (value1) {
              return new Success(value0, value1);
          };
      };
      return Success;
  })();
  var Alt = (function () {
      function Alt(value0) {
          this.value0 = value0;
      };
      Alt.create = function (value0) {
          return new Alt(value0);
      };
      return Alt;
  })();
  var Chomp = (function () {
      function Chomp(value0) {
          this.value0 = value0;
      };
      Chomp.create = function (value0) {
          return new Chomp(value0);
      };
      return Chomp;
  })();
  var Prefix = (function () {
      function Prefix(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Prefix.create = function (value0) {
          return function (value1) {
              return new Prefix(value0, value1);
          };
      };
      return Prefix;
  })();
  var take = new Chomp(function (state) {
      var v = Data_Array.uncons(state.segments);
      if (v instanceof Data_Maybe.Just) {
          return new Success({
              segments: v.value0.tail,
              params: state.params,
              hash: state.hash
          }, v.value0.head);
      };
      return new Fail(EndOfPath.value);
  });
  var prefix = Prefix.create;
  var parsePath = (function () {
      var unsafeDecodeURIComponent = (function () {
          var $236 = Data_Maybe.fromJust();
          return function ($237) {
              return $236(JSURI["decodeURIComponent"]($237));
          };
      })();
      var toRouteState = function (v) {
          return {
              segments: v.value0.value0,
              params: v.value0.value1,
              hash: v.value1
          };
      };
      var splitNonEmpty = function (v) {
          return function (v1) {
              if (v1 === "") {
                  return [  ];
              };
              return Data_String_Common.split(v)(v1);
          };
      };
      var splitSegments = (function () {
          var $238 = splitNonEmpty("/");
          return function ($239) {
              return (function (v) {
                  if (v.length === 2 && (v[0] === "" && v[1] === "")) {
                      return [ "" ];
                  };
                  return Data_Functor.map(Data_Functor.functorArray)(unsafeDecodeURIComponent)(v);
              })($238($239));
          };
      })();
      var splitAt = function (k) {
          return function (p) {
              return function (str) {
                  var v = Data_String_CodeUnits.indexOf(p)(str);
                  if (v instanceof Data_Maybe.Just) {
                      return new Data_Tuple.Tuple(Data_String_CodeUnits.take(v.value0)(str), Data_String_CodeUnits.drop(v.value0 + Data_String_CodeUnits.length(p) | 0)(str));
                  };
                  if (v instanceof Data_Maybe.Nothing) {
                      return k(str);
                  };
                  throw new Error("Failed pattern match at Routing.Duplex.Parser (line 185, column 5 - line 187, column 23): " + [ v.constructor.name ]);
              };
          };
      };
      var splitKeyValue = (function () {
          var $240 = Data_Bifunctor.bimap(Data_Bifunctor.bifunctorTuple)(unsafeDecodeURIComponent)(unsafeDecodeURIComponent);
          var $241 = splitAt(Data_Function.flip(Data_Tuple.Tuple.create)(""))("=");
          return function ($242) {
              return $240($241($242));
          };
      })();
      var splitParams = (function () {
          var $243 = Data_Functor.map(Data_Functor.functorArray)(splitKeyValue);
          var $244 = splitNonEmpty("&");
          return function ($245) {
              return $243($244($245));
          };
      })();
      var splitPath = (function () {
          var $246 = Data_Bifunctor.bimap(Data_Bifunctor.bifunctorTuple)(splitSegments)(splitParams);
          var $247 = splitAt(Data_Function.flip(Data_Tuple.Tuple.create)(""))("?");
          return function ($248) {
              return $246($247($248));
          };
      })();
      var $249 = Data_Bifunctor.lmap(Data_Bifunctor.bifunctorTuple)(splitPath);
      var $250 = splitAt(Data_Function.flip(Data_Tuple.Tuple.create)(""))("#");
      return function ($251) {
          return toRouteState($249($250($251)));
      };
  })();
  var hash = new Chomp(function (state) {
      return new Success(state, state.hash);
  });
  var functorRouteResult = new Data_Functor.Functor(function (f) {
      return function (m) {
          if (m instanceof Fail) {
              return new Fail(m.value0);
          };
          if (m instanceof Success) {
              return new Success(m.value0, f(m.value1));
          };
          throw new Error("Failed pattern match at Routing.Duplex.Parser (line 53, column 1 - line 53, column 58): " + [ m.constructor.name ]);
      };
  });
  var functorRouteParser = new Data_Functor.Functor(function (f) {
      return function (m) {
          if (m instanceof Alt) {
              return new Alt(Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray)(Data_Functor.map(functorRouteParser)(f))(m.value0));
          };
          if (m instanceof Chomp) {
              return new Chomp(Data_Functor.map(Data_Functor.functorFn)(Data_Functor.map(functorRouteResult)(f))(m.value0));
          };
          if (m instanceof Prefix) {
              return new Prefix(m.value0, Data_Functor.map(functorRouteParser)(f)(m.value1));
          };
          throw new Error("Failed pattern match at Routing.Duplex.Parser (line 72, column 1 - line 72, column 58): " + [ m.constructor.name ]);
      };
  });
  var end = new Chomp(function (state) {
      var v = Data_Array.head(state.segments);
      if (v instanceof Data_Maybe.Nothing) {
          return new Success(state, Data_Unit.unit);
      };
      if (v instanceof Data_Maybe.Just) {
          return new Fail(new ExpectedEndOfPath(v.value0));
      };
      throw new Error("Failed pattern match at Routing.Duplex.Parser (line 256, column 3 - line 258, column 45): " + [ v.constructor.name ]);
  });
  var chompPrefix = function (pre) {
      return function (state) {
          var v = Data_Array.head(state.segments);
          if (v instanceof Data_Maybe.Just && pre === v.value0) {
              return new Success({
                  segments: Data_Array.drop(1)(state.segments),
                  params: state.params,
                  hash: state.hash
              }, Data_Unit.unit);
          };
          if (v instanceof Data_Maybe.Just) {
              return Fail.create(new Expected(pre, v.value0));
          };
          return Fail.create(EndOfPath.value);
      };
  };
  var runRouteParser = (function () {
      var goAlt = function (v) {
          return function (v1) {
              return function (v2) {
                  if (v1 instanceof Fail) {
                      return runRouteParser(v)(v2);
                  };
                  return v1;
              };
          };
      };
      var go = function ($copy_state) {
          return function ($copy_v) {
              var $tco_var_state = $copy_state;
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(state, v) {
                  if (v instanceof Alt) {
                      $tco_done = true;
                      return Data_Foldable.foldl(Data_Array_NonEmpty_Internal.foldableNonEmptyArray)(goAlt(state))(new Fail(EndOfPath.value))(v.value0);
                  };
                  if (v instanceof Chomp) {
                      $tco_done = true;
                      return v.value0(state);
                  };
                  if (v instanceof Prefix) {
                      var v1 = chompPrefix(v.value0)(state);
                      if (v1 instanceof Fail) {
                          $tco_done = true;
                          return new Fail(v1.value0);
                      };
                      if (v1 instanceof Success) {
                          $tco_var_state = v1.value0;
                          $copy_v = v.value1;
                          return;
                      };
                      throw new Error("Failed pattern match at Routing.Duplex.Parser (line 149, column 7 - line 151, column 40): " + [ v1.constructor.name ]);
                  };
                  throw new Error("Failed pattern match at Routing.Duplex.Parser (line 145, column 14 - line 151, column 40): " + [ v.constructor.name ]);
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($tco_var_state, $copy_v);
              };
              return $tco_result;
          };
      };
      return go;
  })();
  var run = function (p) {
      var $254 = Data_Function.flip(runRouteParser)(p);
      return function ($255) {
          return (function (v) {
              if (v instanceof Fail) {
                  return new Data_Either.Left(v.value0);
              };
              if (v instanceof Success) {
                  return new Data_Either.Right(v.value1);
              };
              throw new Error("Failed pattern match at Routing.Duplex.Parser (line 190, column 49 - line 192, column 29): " + [ v.constructor.name ]);
          })($254(parsePath($255)));
      };
  };
  var applyRouteParser = new Control_Apply.Apply(function () {
      return functorRouteParser;
  }, function (fx) {
      return function (x) {
          return new Chomp(function (state) {
              var v = runRouteParser(state)(fx);
              if (v instanceof Fail) {
                  return new Fail(v.value0);
              };
              if (v instanceof Success) {
                  return Data_Functor.map(functorRouteResult)(v.value1)(runRouteParser(v.value0)(x));
              };
              throw new Error("Failed pattern match at Routing.Duplex.Parser (line 76, column 5 - line 78, column 56): " + [ v.constructor.name ]);
          });
      };
  });
  var applicativeRouteParser = new Control_Applicative.Applicative(function () {
      return applyRouteParser;
  }, (function () {
      var $256 = Data_Function.flip(Success.create);
      return function ($257) {
          return Chomp.create($256($257));
      };
  })());
  var altSnoc = function (ls) {
      return function (v) {
          var v1 = function (v2) {
              return Data_Array_NonEmpty.snoc(ls)(v);
          };
          if (v instanceof Prefix) {
              var $197 = Data_Array_NonEmpty.last(ls);
              if ($197 instanceof Prefix) {
                  var $198 = v.value0 === $197.value0;
                  if ($198) {
                      return Data_Array_NonEmpty["snoc'"](Data_Array_NonEmpty.init(ls))(new Prefix(v.value0, Control_Alt.alt(altRouteParser)($197.value1)(v.value1)));
                  };
                  return v1(true);
              };
              return v1(true);
          };
          return v1(true);
      };
  };
  var altRouteParser = new Control_Alt.Alt(function () {
      return functorRouteParser;
  }, function (v) {
      return function (v1) {
          if (v instanceof Alt && v1 instanceof Alt) {
              return new Alt(altAppend(v.value0)(v1.value0));
          };
          if (v instanceof Alt) {
              return new Alt(altSnoc(v.value0)(v1));
          };
          if (v1 instanceof Alt) {
              return new Alt(altCons(v)(v1.value0));
          };
          if (v instanceof Prefix && (v1 instanceof Prefix && v.value0 === v1.value0)) {
              return new Prefix(v.value0, Control_Alt.alt(altRouteParser)(v.value1)(v1.value1));
          };
          return new Alt(Data_Array_NonEmpty.cons(v)(Data_Array_NonEmpty.singleton(v1)));
      };
  });
  var altCons = function (v) {
      return function (rs) {
          var v1 = function (v2) {
              return Data_Array_NonEmpty.cons(v)(rs);
          };
          if (v instanceof Prefix) {
              var $217 = Data_Array_NonEmpty.head(rs);
              if ($217 instanceof Prefix) {
                  var $218 = v.value0 === $217.value0;
                  if ($218) {
                      return Data_Array_NonEmpty["cons'"](new Prefix(v.value0, Control_Alt.alt(altRouteParser)(v.value1)($217.value1)))(Data_Array_NonEmpty.tail(rs));
                  };
                  return v1(true);
              };
              return v1(true);
          };
          return v1(true);
      };
  };
  var altAppend = function ($copy_ls) {
      return function ($copy_rs) {
          var $tco_var_ls = $copy_ls;
          var $tco_done = false;
          var $tco_result;
          function $tco_loop(ls, rs) {
              var v = function (v1) {
                  if (Data_Boolean.otherwise) {
                      return Data_Semigroup.append(Data_Array_NonEmpty_Internal.semigroupNonEmptyArray)(ls)(rs);
                  };
                  throw new Error("Failed pattern match at Routing.Duplex.Parser (line 98, column 1 - line 101, column 32): " + [ ls.constructor.name, rs.constructor.name ]);
              };
              var $227 = Data_Array_NonEmpty.last(ls);
              if ($227 instanceof Prefix) {
                  var $228 = Data_Array_NonEmpty.head(rs);
                  if ($228 instanceof Prefix) {
                      var $229 = $227.value0 === $228.value0;
                      if ($229) {
                          var rs$prime = Data_Array_NonEmpty["cons'"](new Prefix($227.value0, Control_Alt.alt(altRouteParser)($227.value1)($228.value1)))(Data_Array_NonEmpty.tail(rs));
                          var v1 = Data_Array_NonEmpty.fromArray(Data_Array_NonEmpty.init(ls));
                          if (v1 instanceof Data_Maybe.Just) {
                              $tco_var_ls = v1.value0;
                              $copy_rs = rs$prime;
                              return;
                          };
                          if (v1 instanceof Data_Maybe.Nothing) {
                              $tco_done = true;
                              return rs$prime;
                          };
                          throw new Error("Failed pattern match at Routing.Duplex.Parser (line 110, column 9 - line 112, column 26): " + [ v1.constructor.name ]);
                      };
                      $tco_done = true;
                      return v(true);
                  };
                  $tco_done = true;
                  return v(true);
              };
              $tco_done = true;
              return v(true);
          };
          while (!$tco_done) {
              $tco_result = $tco_loop($tco_var_ls, $copy_rs);
          };
          return $tco_result;
      };
  };
  exports["run"] = run;
  exports["prefix"] = prefix;
  exports["end"] = end;
  exports["functorRouteParser"] = functorRouteParser;
  exports["applyRouteParser"] = applyRouteParser;
  exports["applicativeRouteParser"] = applicativeRouteParser;
  exports["altRouteParser"] = altRouteParser;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Routing.Duplex.Types"] = $PS["Routing.Duplex.Types"] || {};
  var exports = $PS["Routing.Duplex.Types"];
  var emptyRouteState = {
      segments: [  ],
      params: [  ],
      hash: ""
  };
  exports["emptyRouteState"] = emptyRouteState;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Routing.Duplex.Printer"] = $PS["Routing.Duplex.Printer"] || {};
  var exports = $PS["Routing.Duplex.Printer"];
  var Control_Category = $PS["Control.Category"];
  var Data_Array = $PS["Data.Array"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_String_Common = $PS["Data.String.Common"];
  var Data_Tuple = $PS["Data.Tuple"];
  var JSURI = $PS["JSURI"];
  var Routing_Duplex_Types = $PS["Routing.Duplex.Types"];
  var semigroupRoutePrinter = new Data_Semigroup.Semigroup(function (v) {
      return function (v1) {
          return function ($25) {
              return v1(v($25));
          };
      };
  });
  var put = function (str) {
      return function (state) {
          return {
              segments: Data_Array.snoc(state.segments)(str),
              params: state.params,
              hash: state.hash
          };
      };
  };
  var printPath = function (v) {
      var unsafeEncodeURIComponent = (function () {
          var $26 = Data_Maybe.fromJust();
          return function ($27) {
              return $26(JSURI["encodeURIComponent"]($27));
          };
      })();
      var printSegments = function (v1) {
          if (v1.length === 1 && v1[0] === "") {
              return "/";
          };
          return Data_String_Common.joinWith("/")(Data_Functor.map(Data_Functor.functorArray)(unsafeEncodeURIComponent)(v1));
      };
      var printParam = function (key) {
          return function (v1) {
              if (v1 === "") {
                  return unsafeEncodeURIComponent(key);
              };
              return unsafeEncodeURIComponent(key) + ("=" + unsafeEncodeURIComponent(v1));
          };
      };
      var printParams = function (v1) {
          if (v1.length === 0) {
              return "";
          };
          return "?" + Data_String_Common.joinWith("&")(Data_Functor.map(Data_Functor.functorArray)(Data_Tuple.uncurry(printParam))(v1));
      };
      var printHash = function (v1) {
          if (v1 === "") {
              return "";
          };
          return "#" + v1;
      };
      return printSegments(v.segments) + (printParams(v.params) + printHash(v.hash));
  }; 
  var run = (function () {
      var $28 = Data_Function.applyFlipped(Routing_Duplex_Types.emptyRouteState);
      var $29 = Data_Newtype.unwrap();
      return function ($30) {
          return printPath($28($29($30)));
      };
  })();
  var monoidRoutePRinter = new Data_Monoid.Monoid(function () {
      return semigroupRoutePrinter;
  }, Control_Category.identity(Control_Category.categoryFn));
  var hash = function (h) {
      return function (v) {
          return {
              segments: v.segments,
              params: v.params,
              hash: h
          };
      };
  };
  exports["put"] = put;
  exports["run"] = run;
  exports["semigroupRoutePrinter"] = semigroupRoutePrinter;
  exports["monoidRoutePRinter"] = monoidRoutePRinter;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Routing.Duplex"] = $PS["Routing.Duplex"] || {};
  var exports = $PS["Routing.Duplex"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Profunctor = $PS["Data.Profunctor"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_String_Common = $PS["Data.String.Common"];
  var Routing_Duplex_Parser = $PS["Routing.Duplex.Parser"];
  var Routing_Duplex_Printer = $PS["Routing.Duplex.Printer"];
  var RouteDuplex = (function () {
      function RouteDuplex(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      RouteDuplex.create = function (value0) {
          return function (value1) {
              return new RouteDuplex(value0, value1);
          };
      };
      return RouteDuplex;
  })();
  var profunctorRouteDuplex = new Data_Profunctor.Profunctor(function (f) {
      return function (g) {
          return function (v) {
              return new RouteDuplex(function ($101) {
                  return v.value0(f($101));
              }, Data_Functor.map(Routing_Duplex_Parser.functorRouteParser)(g)(v.value1));
          };
      };
  });
  var print = function (v) {
      return function ($102) {
          return Routing_Duplex_Printer.run(v.value0($102));
      };
  };
  var prefix = function (s) {
      return function (v) {
          return new RouteDuplex(function (a) {
              return Data_Semigroup.append(Routing_Duplex_Printer.semigroupRoutePrinter)(Routing_Duplex_Printer.put(s))(v.value0(a));
          }, Routing_Duplex_Parser.prefix(s)(v.value1));
      };
  };
  var path = (function () {
      var $103 = Data_Function.flip(Data_Foldable.foldr(Data_Foldable.foldableArray)(prefix));
      var $104 = Data_String_Common.split("/");
      return function ($105) {
          return $103($104($105));
      };
  })();
  var root = path("");
  var parse = function (v) {
      return Routing_Duplex_Parser.run(v.value1);
  };
  var functorRouteDuplex = new Data_Functor.Functor(function (f) {
      return function (m) {
          return new RouteDuplex(m.value0, Data_Functor.map(Routing_Duplex_Parser.functorRouteParser)(f)(m.value1));
      };
  });
  var end = function (v) {
      return new RouteDuplex(v.value0, Control_Apply.applyFirst(Routing_Duplex_Parser.applyRouteParser)(v.value1)(Routing_Duplex_Parser.end));
  };                                                                              
  var applyRouteDuplex = new Control_Apply.Apply(function () {
      return functorRouteDuplex;
  }, function (v) {
      return function (v1) {
          return new RouteDuplex(Control_Apply.apply(Control_Apply.applyFn)(Data_Functor.map(Data_Functor.functorFn)(Data_Semigroup.append(Routing_Duplex_Printer.semigroupRoutePrinter))(v.value0))(v1.value0), Control_Apply.apply(Routing_Duplex_Parser.applyRouteParser)(v.value1)(v1.value1));
      };
  });
  var applicativeRouteDuplex = new Control_Applicative.Applicative(function () {
      return applyRouteDuplex;
  }, (function () {
      var $107 = RouteDuplex.create(Data_Function["const"](Data_Monoid.mempty(Routing_Duplex_Printer.monoidRoutePRinter)));
      var $108 = Control_Applicative.pure(Routing_Duplex_Parser.applicativeRouteParser);
      return function ($109) {
          return $107($108($109));
      };
  })());
  exports["RouteDuplex"] = RouteDuplex;
  exports["parse"] = parse;
  exports["print"] = print;
  exports["prefix"] = prefix;
  exports["root"] = root;
  exports["end"] = end;
  exports["applicativeRouteDuplex"] = applicativeRouteDuplex;
  exports["profunctorRouteDuplex"] = profunctorRouteDuplex;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Web.HTML.Event.HashChangeEvent.EventTypes"] = $PS["Web.HTML.Event.HashChangeEvent.EventTypes"] || {};
  var exports = $PS["Web.HTML.Event.HashChangeEvent.EventTypes"];
  var hashchange = "hashchange";
  exports["hashchange"] = hashchange;
})(PS);
(function(exports) {
  "use strict";

  exports.hash = function (location) {
    return function () {
      return location.hash;
    };
  };

  exports.setHash = function (hash) {
    return function (location) {
      return function () {
        location.hash = hash;
      };
    };
  };
})(PS["Web.HTML.Location"] = PS["Web.HTML.Location"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Web.HTML.Location"] = $PS["Web.HTML.Location"] || {};
  var exports = $PS["Web.HTML.Location"];
  var $foreign = $PS["Web.HTML.Location"];
  exports["hash"] = $foreign.hash;
  exports["setHash"] = $foreign.setHash;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Routing.Hash"] = $PS["Routing.Hash"] || {};
  var exports = $PS["Routing.Hash"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_String_CodeUnits = $PS["Data.String.CodeUnits"];
  var Effect = $PS["Effect"];
  var Effect_Ref = $PS["Effect.Ref"];
  var Web_Event_EventTarget = $PS["Web.Event.EventTarget"];
  var Web_HTML = $PS["Web.HTML"];
  var Web_HTML_Event_HashChangeEvent_EventTypes = $PS["Web.HTML.Event.HashChangeEvent.EventTypes"];
  var Web_HTML_Location = $PS["Web.HTML.Location"];
  var Web_HTML_Window = $PS["Web.HTML.Window"];                
  var setHash = function (h) {
      return Control_Bind.bind(Effect.bindEffect)(Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)(Web_HTML_Window.location))(Web_HTML_Location.setHash(h));
  };
  var getHash = Control_Bind.bind(Effect.bindEffect)(Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)(Web_HTML_Window.location))((function () {
      var $2 = Data_Functor.map(Effect.functorEffect)((function () {
          var $4 = Data_Maybe.fromMaybe("");
          var $5 = Data_String_CodeUnits.stripPrefix("#");
          return function ($6) {
              return $4($5($6));
          };
      })());
      return function ($3) {
          return $2(Web_HTML_Location.hash($3));
      };
  })());
  var foldHashes = function (cb) {
      return function (init) {
          return function __do() {
              var ref = Control_Bind.bindFlipped(Effect.bindEffect)(Effect_Ref["new"])(Control_Bind.bindFlipped(Effect.bindEffect)(init)(getHash))();
              var win = Data_Functor.map(Effect.functorEffect)(Web_HTML_Window.toEventTarget)(Web_HTML.window)();
              var listener = Web_Event_EventTarget.eventListener(function (v) {
                  return Control_Bind.bindFlipped(Effect.bindEffect)(Data_Function.flip(Effect_Ref.write)(ref))(Control_Bind.join(Effect.bindEffect)(Control_Apply.apply(Effect.applyEffect)(Data_Functor.map(Effect.functorEffect)(cb)(Effect_Ref.read(ref)))(getHash)));
              })();
              Web_Event_EventTarget.addEventListener(Web_HTML_Event_HashChangeEvent_EventTypes.hashchange)(listener)(false)(win)();
              return Web_Event_EventTarget.removeEventListener(Web_HTML_Event_HashChangeEvent_EventTypes.hashchange)(listener)(false)(win);
          };
      };
  };
  var matchesWith = function (dictFoldable) {
      return function (parser) {
          return function (cb) {
              var go = function (a) {
                  var $7 = Data_Maybe.maybe(Control_Applicative.pure(Effect.applicativeEffect)(a))(function (b) {
                      return Data_Functor.voidRight(Effect.functorEffect)(new Data_Maybe.Just(b))(cb(a)(b));
                  });
                  var $8 = Data_Foldable.indexl(dictFoldable)(0);
                  return function ($9) {
                      return $7($8(parser($9)));
                  };
              };
              return foldHashes(go)(go(Data_Maybe.Nothing.value));
          };
      };
  };
  exports["getHash"] = getHash;
  exports["setHash"] = setHash;
  exports["matchesWith"] = matchesWith;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Rymden.Capability.Navigate"] = $PS["Rymden.Capability.Navigate"] || {};
  var exports = $PS["Rymden.Capability.Navigate"];
  var Control_Monad_Trans_Class = $PS["Control.Monad.Trans.Class"];
  var Halogen_Query_HalogenM = $PS["Halogen.Query.HalogenM"];                
  var Navigate = function (Monad0, navigate) {
      this.Monad0 = Monad0;
      this.navigate = navigate;
  };
  var navigate = function (dict) {
      return dict.navigate;
  };
  var navigateHalogenM = function (dictNavigate) {
      return new Navigate(function () {
          return Halogen_Query_HalogenM.monadHalogenM;
      }, (function () {
          var $1 = Control_Monad_Trans_Class.lift(Halogen_Query_HalogenM.monadTransHalogenM)(dictNavigate.Monad0());
          var $2 = navigate(dictNavigate);
          return function ($3) {
              return $1($2($3));
          };
      })());
  };
  exports["navigate"] = navigate;
  exports["Navigate"] = Navigate;
  exports["navigateHalogenM"] = navigateHalogenM;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Routing.Duplex.Generic"] = $PS["Routing.Duplex.Generic"] || {};
  var exports = $PS["Routing.Duplex.Generic"];
  var Control_Alt = $PS["Control.Alt"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Category = $PS["Control.Category"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Generic_Rep = $PS["Data.Generic.Rep"];
  var Data_Profunctor = $PS["Data.Profunctor"];
  var Record = $PS["Record"];
  var Routing_Duplex = $PS["Routing.Duplex"];
  var Routing_Duplex_Parser = $PS["Routing.Duplex.Parser"];
  var Type_Proxy = $PS["Type.Proxy"];                
  var GRouteDuplexCtr = function (gRouteDuplexCtr) {
      this.gRouteDuplexCtr = gRouteDuplexCtr;
  };
  var GRouteDuplex = function (gRouteDuplex) {
      this.gRouteDuplex = gRouteDuplex;
  };
  var noArgs = Control_Applicative.pure(Routing_Duplex.applicativeRouteDuplex)(Data_Generic_Rep.NoArguments.value);
  var gRouteNoArguments = new GRouteDuplexCtr(Control_Category.identity(Control_Category.categoryFn));
  var gRouteDuplexCtr = function (dict) {
      return dict.gRouteDuplexCtr;
  };
  var gRouteDuplex = function (dict) {
      return dict.gRouteDuplex;
  };
  var gRouteSum = function (dictGRouteDuplex) {
      return function (dictGRouteDuplex1) {
          return new GRouteDuplex(function (r) {
              var v = gRouteDuplex(dictGRouteDuplex)(r);
              var v1 = gRouteDuplex(dictGRouteDuplex1)(r);
              var enc = function (v2) {
                  if (v2 instanceof Data_Generic_Rep.Inl) {
                      return v.value0(v2.value0);
                  };
                  if (v2 instanceof Data_Generic_Rep.Inr) {
                      return v1.value0(v2.value0);
                  };
                  throw new Error("Failed pattern match at Routing.Duplex.Generic (line 33, column 11 - line 35, column 22): " + [ v2.constructor.name ]);
              };
              var dec = Control_Alt.alt(Routing_Duplex_Parser.altRouteParser)(Data_Functor.map(Routing_Duplex_Parser.functorRouteParser)(Data_Generic_Rep.Inl.create)(v.value1))(Data_Functor.map(Routing_Duplex_Parser.functorRouteParser)(Data_Generic_Rep.Inr.create)(v1.value1));
              return new Routing_Duplex.RouteDuplex(enc, dec);
          });
      };
  };
  var sum = function (dictGeneric) {
      return function (dictGRouteDuplex) {
          var $48 = Data_Profunctor.dimap(Routing_Duplex.profunctorRouteDuplex)(Data_Generic_Rep.from(dictGeneric))(Data_Generic_Rep.to(dictGeneric));
          var $49 = gRouteDuplex(dictGRouteDuplex);
          return function ($50) {
              return $48($49($50));
          };
      };
  };
  var gRouteConstructor = function (dictIsSymbol) {
      return function (dictCons) {
          return function (dictGRouteDuplexCtr) {
              return new GRouteDuplex(function (r) {
                  var v = Routing_Duplex.end(gRouteDuplexCtr(dictGRouteDuplexCtr)(Record.get(dictIsSymbol)()(Type_Proxy["Proxy"].value)(r)));
                  var enc = function (v1) {
                      return v.value0(v1);
                  };
                  var dec = Data_Functor.map(Routing_Duplex_Parser.functorRouteParser)(Data_Generic_Rep.Constructor)(v.value1);
                  return new Routing_Duplex.RouteDuplex(enc, dec);
              });
          };
      };
  };
  exports["gRouteDuplexCtr"] = gRouteDuplexCtr;
  exports["sum"] = sum;
  exports["noArgs"] = noArgs;
  exports["gRouteSum"] = gRouteSum;
  exports["gRouteConstructor"] = gRouteConstructor;
  exports["gRouteNoArguments"] = gRouteNoArguments;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Routing.Duplex.Generic.Syntax"] = $PS["Routing.Duplex.Generic.Syntax"] || {};
  var exports = $PS["Routing.Duplex.Generic.Syntax"];
  var Routing_Duplex = $PS["Routing.Duplex"];
  var Routing_Duplex_Generic = $PS["Routing.Duplex.Generic"];                
  var GSep = function (gsep) {
      this.gsep = gsep;
  }; 
  var gsepStringRoute = function (dictGRouteDuplexCtr) {
      return new GSep(function (a) {
          var $5 = Routing_Duplex.prefix(a);
          var $6 = Routing_Duplex_Generic.gRouteDuplexCtr(dictGRouteDuplexCtr);
          return function ($7) {
              return $5($6($7));
          };
      });
  };
  var gsep = function (dict) {
      return dict.gsep;
  };
  exports["gsep"] = gsep;
  exports["gsepStringRoute"] = gsepStringRoute;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Rymden.Data.Route"] = $PS["Rymden.Data.Route"] || {};
  var exports = $PS["Rymden.Data.Route"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Generic_Rep = $PS["Data.Generic.Rep"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Routing_Duplex = $PS["Routing.Duplex"];
  var Routing_Duplex_Generic = $PS["Routing.Duplex.Generic"];
  var Routing_Duplex_Generic_Syntax = $PS["Routing.Duplex.Generic.Syntax"];                
  var Home = (function () {
      function Home() {

      };
      Home.value = new Home();
      return Home;
  })();
  var Settings = (function () {
      function Settings() {

      };
      Settings.value = new Settings();
      return Settings;
  })();
  var genericRoute = new Data_Generic_Rep.Generic(function (x) {
      if (x instanceof Home) {
          return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
      };
      if (x instanceof Settings) {
          return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
      };
      throw new Error("Failed pattern match at Rymden.Data.Route (line 15, column 1 - line 15, column 48): " + [ x.constructor.name ]);
  }, function (x) {
      if (x instanceof Data_Generic_Rep.Inl) {
          return Home.value;
      };
      if (x instanceof Data_Generic_Rep.Inr) {
          return Settings.value;
      };
      throw new Error("Failed pattern match at Rymden.Data.Route (line 15, column 1 - line 15, column 48): " + [ x.constructor.name ]);
  });
  var route = Routing_Duplex.root(Routing_Duplex_Generic.sum(genericRoute)(Routing_Duplex_Generic.gRouteSum(Routing_Duplex_Generic.gRouteConstructor(new Data_Symbol.IsSymbol(function () {
      return "Home";
  }))()(Routing_Duplex_Generic.gRouteNoArguments))(Routing_Duplex_Generic.gRouteConstructor(new Data_Symbol.IsSymbol(function () {
      return "Settings";
  }))()(Routing_Duplex_Generic.gRouteNoArguments)))({
      Home: Routing_Duplex_Generic.noArgs,
      Settings: Routing_Duplex_Generic_Syntax.gsep(Routing_Duplex_Generic_Syntax.gsepStringRoute(Routing_Duplex_Generic.gRouteNoArguments))("settings")(Routing_Duplex_Generic.noArgs)
  }));   
  var eqRoute = new Data_Eq.Eq(function (x) {
      return function (y) {
          if (x instanceof Home && y instanceof Home) {
              return true;
          };
          if (x instanceof Settings && y instanceof Settings) {
              return true;
          };
          return false;
      };
  });
  exports["Home"] = Home;
  exports["Settings"] = Settings;
  exports["route"] = route;
  exports["eqRoute"] = eqRoute;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Rymden.Data.Store"] = $PS["Rymden.Data.Store"] || {};
  var exports = $PS["Rymden.Data.Store"];
  var reduce = function (store) {
      return function (v) {
          return store;
      };
  };
  exports["reduce"] = reduce;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Rymden.AppM"] = $PS["Rymden.AppM"] || {};
  var exports = $PS["Rymden.AppM"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Aff_Class = $PS["Effect.Aff.Class"];
  var Effect_Class = $PS["Effect.Class"];
  var Halogen_Store_Monad = $PS["Halogen.Store.Monad"];
  var Routing_Duplex = $PS["Routing.Duplex"];
  var Routing_Hash = $PS["Routing.Hash"];
  var Rymden_Capability_Navigate = $PS["Rymden.Capability.Navigate"];
  var Rymden_Data_Route = $PS["Rymden.Data.Route"];
  var Rymden_Data_Store = $PS["Rymden.Data.Store"];
  var Safe_Coerce = $PS["Safe.Coerce"];
  var runAppM = function (store) {
      var $0 = Halogen_Store_Monad.runStoreT(Effect_Aff.monadAff)(store)(Rymden_Data_Store.reduce);
      var $1 = Safe_Coerce.coerce();
      return function ($2) {
          return $0($1($2));
      };
  };
  var monadStoreAppM = Halogen_Store_Monad.monadStoreStoreT(Effect_Aff_Class.monadAffAff);
  var monadEffectAppM = Halogen_Store_Monad.monadEffectStoreT(Effect_Aff.monadEffectAff);
  var monadAppM = Halogen_Store_Monad.monadStoreT(Effect_Aff.monadAff);
  var navigateAppM = new Rymden_Capability_Navigate.Navigate(function () {
      return monadAppM;
  }, (function () {
      var $3 = Effect_Class.liftEffect(monadEffectAppM);
      var $4 = Routing_Duplex.print(Rymden_Data_Route.route);
      return function ($5) {
          return $3(Routing_Hash.setHash($4($5)));
      };
  })());
  var monadAffAppM = Halogen_Store_Monad.monadAffStoreT(Effect_Aff_Class.monadAffAff);
  exports["runAppM"] = runAppM;
  exports["monadEffectAppM"] = monadEffectAppM;
  exports["monadAffAppM"] = monadAffAppM;
  exports["monadStoreAppM"] = monadStoreAppM;
  exports["navigateAppM"] = navigateAppM;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Rymden.Data.Settings"] = $PS["Rymden.Data.Settings"] || {};
  var exports = $PS["Rymden.Data.Settings"];
  var initialSettings = {
      username: ""
  };
  exports["initialSettings"] = initialSettings;
})(PS);
(function(exports) {
  "use strict";

  exports._getItem = function (key) {
    return function (storage) {
      return function () {
        return storage.getItem(key);
      };
    };
  };
})(PS["Web.Storage.Storage"] = PS["Web.Storage.Storage"] || {});
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Web.Storage.Storage"] = $PS["Web.Storage.Storage"] || {};
  var exports = $PS["Web.Storage.Storage"];
  var $foreign = $PS["Web.Storage.Storage"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Nullable = $PS["Data.Nullable"];
  var Effect = $PS["Effect"];
  var getItem = function (s) {
      var $3 = Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe);
      var $4 = $foreign["_getItem"](s);
      return function ($5) {
          return $3($4($5));
      };
  };
  exports["getItem"] = getItem;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Rymden.Capability.ManageSettings"] = $PS["Rymden.Capability.ManageSettings"] || {};
  var exports = $PS["Rymden.Capability.ManageSettings"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Argonaut_Core = $PS["Data.Argonaut.Core"];
  var Data_Argonaut_Decode_Class = $PS["Data.Argonaut.Decode.Class"];
  var Data_Either = $PS["Data.Either"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Effect = $PS["Effect"];
  var Rymden_Data_Settings = $PS["Rymden.Data.Settings"];
  var Web_HTML = $PS["Web.HTML"];
  var Web_HTML_Window = $PS["Web.HTML.Window"];
  var Web_Storage_Storage = $PS["Web.Storage.Storage"];
  var settingsKey = "settings";
  var loadSettingsFromLocalStorage = function __do() {
      var storage = Control_Bind.bindFlipped(Effect.bindEffect)(Web_HTML_Window.localStorage)(Web_HTML.window)();
      var maybeSettingsString = Web_Storage_Storage.getItem(settingsKey)(storage)();
      return Data_Maybe.fromMaybe(Rymden_Data_Settings.initialSettings)((function () {
          if (maybeSettingsString instanceof Data_Maybe.Just) {
              return Data_Either.hush(Data_Argonaut_Decode_Class.decodeJson(Data_Argonaut_Decode_Class.decodeRecord(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonString))(Data_Argonaut_Decode_Class.gDecodeJsonNil)(new Data_Symbol.IsSymbol(function () {
                  return "username";
              }))()())())(Data_Argonaut_Core.fromString(maybeSettingsString.value0)));
          };
          if (maybeSettingsString instanceof Data_Maybe.Nothing) {
              return Data_Maybe.Nothing.value;
          };
          throw new Error("Failed pattern match at Rymden.Capability.ManageSettings (line 34, column 7 - line 36, column 25): " + [ maybeSettingsString.constructor.name ]);
      })());
  };
  exports["loadSettingsFromLocalStorage"] = loadSettingsFromLocalStorage;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Web.HTML.Common"] = $PS["Web.HTML.Common"] || {};
  var exports = $PS["Web.HTML.Common"];
  var ClassName = function (x) {
      return x;
  };
  exports["ClassName"] = ClassName;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Rymden.Component.Helpers.Property"] = $PS["Rymden.Component.Helpers.Property"] || {};
  var exports = $PS["Rymden.Component.Helpers.Property"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_String_Common = $PS["Data.String.Common"];
  var Halogen_HTML_Properties = $PS["Halogen.HTML.Properties"];
  var Halogen_Svg_Attributes = $PS["Halogen.Svg.Attributes"];
  var Web_HTML_Common = $PS["Web.HTML.Common"];                
  var sclass = function (string) {
      return Halogen_Svg_Attributes.classes(Data_Functor.map(Data_Functor.functorArray)(Web_HTML_Common.ClassName)(Data_String_Common.split(" ")(string)));
  };   
  var classes = function (string) {
      return Halogen_HTML_Properties.classes(Data_Functor.map(Data_Functor.functorArray)(Web_HTML_Common.ClassName)(Data_String_Common.split(" ")(string)));
  };
  exports["classes"] = classes;
  exports["sclass"] = sclass;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Rymden.Data.Position"] = $PS["Rymden.Data.Position"] || {};
  var exports = $PS["Rymden.Data.Position"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Set = $PS["Data.Set"];
  var Data_Tuple = $PS["Data.Tuple"];                
  var up = function (v) {
      return new Data_Tuple.Tuple(v.value0 - 1 | 0, v.value1);
  };
  var right = function (v) {
      return new Data_Tuple.Tuple(v.value0, v.value1 + 1 | 0);
  };
  var left = function (v) {
      return new Data_Tuple.Tuple(v.value0, v.value1 - 1 | 0);
  };
  var hasLeftRight = function (availablePositions) {
      return function (position) {
          return Data_Set.member(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(left(position))(availablePositions) && Data_Set.member(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(right(position))(availablePositions);
      };
  };
  var down = function (v) {
      return new Data_Tuple.Tuple(v.value0 + 1 | 0, v.value1);
  };
  var hasUpDown = function (availablePositions) {
      return function (position) {
          return Data_Set.member(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(up(position))(availablePositions) && Data_Set.member(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(down(position))(availablePositions);
      };
  };
  var hasFourNeighbours = function (availablePositions) {
      return function (position) {
          return hasLeftRight(availablePositions)(position) && hasUpDown(availablePositions)(position);
      };
  };
  var hasTwoNeighbours = function (availablePositions) {
      return function (position) {
          return hasLeftRight(availablePositions)(position) || hasUpDown(availablePositions)(position);
      };
  };
  exports["left"] = left;
  exports["up"] = up;
  exports["right"] = right;
  exports["down"] = down;
  exports["hasFourNeighbours"] = hasFourNeighbours;
  exports["hasTwoNeighbours"] = hasTwoNeighbours;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Rymden.Helper.Random"] = $PS["Rymden.Helper.Random"] || {};
  var exports = $PS["Rymden.Helper.Random"];
  var Data_Array = $PS["Data.Array"];
  var Data_Functor = $PS["Data.Functor"];
  var Effect = $PS["Effect"];
  var Effect_Random = $PS["Effect.Random"];                
  var randomBoolean = function (probability) {
      return Data_Functor.map(Effect.functorEffect)(function (v) {
          return v < probability;
      })(Effect_Random.random);
  };
  var chooseOne = function (dictFoldable) {
      return function (foldable) {
          var array = Data_Array.fromFoldable(dictFoldable)(foldable);
          var maxIndex = Data_Array.length(array) - 1 | 0;
          return function __do() {
              var index = Effect_Random.randomInt(0)(maxIndex)();
              return Data_Array.index(array)(index);
          };
      };
  };
  exports["randomBoolean"] = randomBoolean;
  exports["chooseOne"] = chooseOne;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Rymden.Data.GalaxyCluster"] = $PS["Rymden.Data.GalaxyCluster"] || {};
  var exports = $PS["Rymden.Data.GalaxyCluster"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Array = $PS["Data.Array"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Set = $PS["Data.Set"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Effect_Random = $PS["Effect.Random"];
  var Rymden_Data_Position = $PS["Rymden.Data.Position"];
  var Rymden_Helper_Random = $PS["Rymden.Helper.Random"];
  var availableArms = function (availablePositions) {
      return function (arm) {
          var u2 = Rymden_Data_Position.up(arm.p2);
          var u1 = Rymden_Data_Position.up(arm.p1);
          var r2 = Rymden_Data_Position.right(arm.p2);
          var r1 = Rymden_Data_Position.right(arm.p1);
          var l2 = Rymden_Data_Position.left(arm.p2);
          var maybeRight = (function () {
              var $2 = Data_Set.member(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(r1)(availablePositions) && Data_Set.member(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(l2)(availablePositions);
              if ($2) {
                  return new Data_Maybe.Just({
                      p1: r1,
                      p2: l2
                  });
              };
              return Data_Maybe.Nothing.value;
          })();
          var l1 = Rymden_Data_Position.left(arm.p1);
          var maybeLeft = (function () {
              var $3 = Data_Set.member(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(l1)(availablePositions) && Data_Set.member(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(r2)(availablePositions);
              if ($3) {
                  return new Data_Maybe.Just({
                      p1: l1,
                      p2: r2
                  });
              };
              return Data_Maybe.Nothing.value;
          })();
          var d2 = Rymden_Data_Position.down(arm.p2);
          var maybeUp = (function () {
              var $4 = Data_Set.member(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(u1)(availablePositions) && Data_Set.member(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(d2)(availablePositions);
              if ($4) {
                  return new Data_Maybe.Just({
                      p1: u1,
                      p2: d2
                  });
              };
              return Data_Maybe.Nothing.value;
          })();
          var d1 = Rymden_Data_Position.down(arm.p1);
          var maybeDown = (function () {
              var $5 = Data_Set.member(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(d1)(availablePositions) && Data_Set.member(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(u2)(availablePositions);
              if ($5) {
                  return new Data_Maybe.Just({
                      p1: d1,
                      p2: u2
                  });
              };
              return Data_Maybe.Nothing.value;
          })();
          return Data_Array.catMaybes([ maybeLeft, maybeUp, maybeRight, maybeDown ]);
      };
  };
  var extendGalaxyArms = function ($copy_availablePositions) {
      return function ($copy_arms) {
          return function __do() {
              var $tco_var_availablePositions = $copy_availablePositions;
              var $tco_var_arms = $copy_arms;
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(availablePositions, arms) {
                  var armIndex = Effect_Random.randomInt(0)(Data_Array.length(arms) - 1 | 0)();
                  var v = Data_Array.index(arms)(armIndex);
                  if (v instanceof Data_Maybe.Nothing) {
                      $tco_done = true;
                      return availablePositions;
                  };
                  if (v instanceof Data_Maybe.Just) {
                      var availableArms$prime = availableArms(availablePositions)(v.value0);
                      var armIndex1 = Effect_Random.randomInt(0)(Data_Array.length(availableArms$prime) - 1 | 0)();
                      var v1 = Data_Array.index(availableArms$prime)(armIndex1);
                      if (v1 instanceof Data_Maybe.Nothing) {
                          $tco_done = true;
                          return availablePositions;
                      };
                      if (v1 instanceof Data_Maybe.Just) {
                          var availablePositions$prime = Data_Set["delete"](Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(v1.value0.p2)(Data_Set["delete"](Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(v1.value0.p1)(availablePositions));
                          var $$continue = Rymden_Helper_Random.randomBoolean(0.9)();
                          if ($$continue) {
                              $tco_var_availablePositions = availablePositions$prime;
                              $tco_var_arms = [ v1.value0 ];
                              return;
                          };
                          $tco_done = true;
                          return availablePositions$prime;
                      };
                      throw new Error("Failed pattern match at Rymden.Data.GalaxyCluster (line 114, column 7 - line 126, column 37): " + [ v1.constructor.name ]);
                  };
                  throw new Error("Failed pattern match at Rymden.Data.GalaxyCluster (line 108, column 3 - line 126, column 37): " + [ v.constructor.name ]);
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($tco_var_availablePositions, $tco_var_arms);
              };
              return $tco_result;
          };
      };
  };
  var generateCluster = function (width) {
      return function (height) {
          var chooseCenter = function (availablePositions) {
              return function __do() {
                  var fourNeighbours = Rymden_Helper_Random.chooseOne(Data_Set.foldableSet)(Data_Set.filter(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(Rymden_Data_Position.hasFourNeighbours(availablePositions))(availablePositions))();
                  if (fourNeighbours instanceof Data_Maybe.Just) {
                      return fourNeighbours;
                  };
                  if (fourNeighbours instanceof Data_Maybe.Nothing) {
                      var twoNeighbours = Rymden_Helper_Random.chooseOne(Data_Set.foldableSet)(Data_Set.filter(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(Rymden_Data_Position.hasTwoNeighbours(availablePositions))(availablePositions))();
                      if (twoNeighbours instanceof Data_Maybe.Just) {
                          return twoNeighbours;
                      };
                      if (twoNeighbours instanceof Data_Maybe.Nothing) {
                          return Rymden_Helper_Random.chooseOne(Data_Set.foldableSet)(availablePositions)();
                      };
                      throw new Error("Failed pattern match at Rymden.Data.GalaxyCluster (line 56, column 9 - line 58, column 50): " + [ twoNeighbours.constructor.name ]);
                  };
                  throw new Error("Failed pattern match at Rymden.Data.GalaxyCluster (line 52, column 5 - line 58, column 50): " + [ fourNeighbours.constructor.name ]);
              };
          };
          var generateGalaxy = function (availablePositions) {
              return function __do() {
                  var maybeCenter = chooseCenter(availablePositions)();
                  if (maybeCenter instanceof Data_Maybe.Nothing) {
                      return Data_Maybe.Nothing.value;
                  };
                  if (maybeCenter instanceof Data_Maybe.Just) {
                      var u = Rymden_Data_Position.up(maybeCenter.value0);
                      var r = Rymden_Data_Position.right(maybeCenter.value0);
                      var l = Rymden_Data_Position.left(maybeCenter.value0);
                      var d = Rymden_Data_Position.down(maybeCenter.value0);
                      var availablePositions$prime = Data_Set["delete"](Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(maybeCenter.value0)(availablePositions);
                      var $16 = Data_Set.member(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(l)(availablePositions$prime) && (Data_Set.member(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(u)(availablePositions$prime) && (Data_Set.member(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(r)(availablePositions$prime) && Data_Set.member(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(d)(availablePositions$prime)));
                      if ($16) {
                          var availablePositions$prime$prime = extendGalaxyArms(availablePositions$prime)([ {
                              p1: l,
                              p2: r
                          }, {
                              p1: u,
                              p2: d
                          } ])();
                          return Data_Maybe.Just.create(Data_Set.insert(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(d)(Data_Set.insert(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(u)(Data_Set.insert(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(r)(Data_Set.insert(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(l)(Data_Set.insert(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(maybeCenter.value0)(Data_Set.difference(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(availablePositions$prime)(availablePositions$prime$prime)))))));
                      };
                      var $17 = Data_Set.member(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(l)(availablePositions$prime) && Data_Set.member(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(r)(availablePositions$prime);
                      if ($17) {
                          var availablePositions$prime$prime = extendGalaxyArms(availablePositions$prime)([ {
                              p1: l,
                              p2: r
                          } ])();
                          return Data_Maybe.Just.create(Data_Set.insert(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(r)(Data_Set.insert(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(l)(Data_Set.insert(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(maybeCenter.value0)(Data_Set.difference(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(availablePositions$prime)(availablePositions$prime$prime)))));
                      };
                      var $18 = Data_Set.member(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(u)(availablePositions$prime) && Data_Set.member(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(d)(availablePositions$prime);
                      if ($18) {
                          var availablePositions$prime$prime = extendGalaxyArms(availablePositions$prime)([ {
                              p1: u,
                              p2: d
                          } ])();
                          return Data_Maybe.Just.create(Data_Set.insert(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(d)(Data_Set.insert(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(u)(Data_Set.insert(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(maybeCenter.value0)(Data_Set.difference(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(availablePositions$prime)(availablePositions$prime$prime)))));
                      };
                      return Data_Maybe.Just.create(Data_Set.singleton(maybeCenter.value0));
                  };
                  throw new Error("Failed pattern match at Rymden.Data.GalaxyCluster (line 63, column 5 - line 103, column 45): " + [ maybeCenter.constructor.name ]);
              };
          };
          var generateGalaxies = (function () {
              var generateGalaxies$prime = function (availablePositions) {
                  return function __do() {
                      var maybeGalaxy = generateGalaxy(availablePositions)();
                      if (maybeGalaxy instanceof Data_Maybe.Just) {
                          var galaxies = generateGalaxies$prime(Data_Set.difference(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(availablePositions)(maybeGalaxy.value0))();
                          return new Data_List_Types.Cons(maybeGalaxy.value0, galaxies);
                      };
                      if (maybeGalaxy instanceof Data_Maybe.Nothing) {
                          return Data_List_Types.Nil.value;
                      };
                      throw new Error("Failed pattern match at Rymden.Data.GalaxyCluster (line 43, column 7 - line 47, column 28): " + [ maybeGalaxy.constructor.name ]);
                  };
              };
              return function __do() {
                  var galaxies = generateGalaxies$prime(Data_Set.fromFoldable(Data_Foldable.foldableArray)(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(Control_Bind.bind(Control_Bind.bindArray)(Data_Array.range(0)(height - 1 | 0))(function (row) {
                      return Control_Bind.bind(Control_Bind.bindArray)(Data_Array.range(0)(width - 1 | 0))(function (column) {
                          return Control_Applicative.pure(Control_Applicative.applicativeArray)(new Data_Tuple.Tuple(row, column));
                      });
                  })))();
                  return Data_Set.fromFoldable(Data_List_Types.foldableList)(Data_Set.ordSet(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt)))(galaxies);
              };
          })();
          return function __do() {
              var galaxies = generateGalaxies();
              return {
                  width: width,
                  height: height,
                  galaxies: galaxies
              };
          };
      };
  };
  exports["generateCluster"] = generateCluster;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Rymden.Helper.Foldable"] = $PS["Rymden.Helper.Foldable"] || {};
  var exports = $PS["Rymden.Helper.Foldable"];
  var Data_Bifunctor = $PS["Data.Bifunctor"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Map_Internal = $PS["Data.Map.Internal"];
  var Data_Semiring = $PS["Data.Semiring"];
  var Data_Set = $PS["Data.Set"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Data_Unfoldable = $PS["Data.Unfoldable"];
  var inverseMap = function (dictOrd) {
      return function (dictOrd1) {
          return function (map) {
              var tuples = Data_Map_Internal.toUnfoldableUnordered(Data_Unfoldable.unfoldableArray)(map);
              return Data_Map_Internal.fromFoldableWith(dictOrd1)(Data_Foldable.foldableArray)(Data_Set.union(dictOrd))(Data_Functor.mapFlipped(Data_Functor.functorArray)(Data_Functor.mapFlipped(Data_Functor.functorArray)(tuples)(Data_Tuple.swap))(Data_Bifunctor.rmap(Data_Bifunctor.bifunctorTuple)(Data_Set.singleton)));
          };
      };
  };
  var count = function (dictFoldable) {
      return function (dictOrd) {
          return function (foldable) {
              return Data_Foldable.foldl(dictFoldable)(function (map) {
                  return function (a) {
                      return Data_Map_Internal.insertWith(dictOrd)(Data_Semiring.add(Data_Semiring.semiringInt))(a)(1)(map);
                  };
              })(Data_Map_Internal.empty)(foldable);
          };
      };
  };
  exports["count"] = count;
  exports["inverseMap"] = inverseMap;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Rymden.Data.Board"] = $PS["Rymden.Data.Board"] || {};
  var exports = $PS["Rymden.Data.Board"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Array = $PS["Data.Array"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_EuclideanRing = $PS["Data.EuclideanRing"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_HeytingAlgebra = $PS["Data.HeytingAlgebra"];
  var Data_Map = $PS["Data.Map"];
  var Data_Map_Internal = $PS["Data.Map.Internal"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Set = $PS["Data.Set"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Effect = $PS["Effect"];
  var Rymden_Data_GalaxyCluster = $PS["Rymden.Data.GalaxyCluster"];
  var Rymden_Data_Position = $PS["Rymden.Data.Position"];
  var Rymden_Helper_Foldable = $PS["Rymden.Helper.Foldable"];                
  var toggleBorderSegment = function (borderSegment) {
      return function (board) {
          return {
              width: board.width,
              height: board.height,
              borderSegments: (function () {
                  var $16 = Data_Set.member(Data_Tuple.ordTuple(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt)))(borderSegment)(board.borderSegments);
                  if ($16) {
                      return Data_Set["delete"](Data_Tuple.ordTuple(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt)))(borderSegment)(board.borderSegments);
                  };
                  return Data_Set.insert(Data_Tuple.ordTuple(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt)))(borderSegment)(board.borderSegments);
              })(),
              centers: board.centers
          };
      };
  };
  var getPositions = function (v) {
      return Control_Bind.bind(Control_Bind.bindArray)(Data_Array.range(0)(v.height - 1 | 0))(function (row) {
          return Control_Bind.bind(Control_Bind.bindArray)(Data_Array.range(0)(v.width - 1 | 0))(function (column) {
              return Control_Applicative.pure(Control_Applicative.applicativeArray)(new Data_Tuple.Tuple(row, column));
          });
      });
  };
  var getGalaxyCenter = function (galaxy) {
      var galaxySize = Data_Set.size(galaxy);
      var galaxyPositions = Data_Array.fromFoldable(Data_Set.foldableSet)(galaxy);
      var maxColumn = Data_Maybe.fromMaybe(0)(Data_Foldable.maximum(Data_Ord.ordInt)(Data_Foldable.foldableArray)(Data_Functor.map(Data_Functor.functorArray)(Data_Tuple.snd)(galaxyPositions)));
      var maxRow = Data_Maybe.fromMaybe(0)(Data_Foldable.maximum(Data_Ord.ordInt)(Data_Foldable.foldableArray)(Data_Functor.map(Data_Functor.functorArray)(Data_Tuple.fst)(galaxyPositions)));
      var minColumn = Data_Maybe.fromMaybe(0)(Data_Foldable.minimum(Data_Ord.ordInt)(Data_Foldable.foldableArray)(Data_Functor.map(Data_Functor.functorArray)(Data_Tuple.snd)(galaxyPositions)));
      var minRow = Data_Maybe.fromMaybe(0)(Data_Foldable.minimum(Data_Ord.ordInt)(Data_Foldable.foldableArray)(Data_Functor.map(Data_Functor.functorArray)(Data_Tuple.fst)(galaxyPositions)));
      var centerRow = (maxRow + minRow | 0) + 1 | 0;
      var centerColumn = (maxColumn + minColumn | 0) + 1 | 0;
      var position = new Data_Tuple.Tuple(centerRow, centerColumn);
      return {
          position: position,
          galaxySize: galaxySize
      };
  };
  var getConnectedComponents = function (board) {
      var getConnectedComponent = function (position) {
          return function (remainingPositions) {
              var up$prime = Rymden_Data_Position.up(position);
              var right$prime = Rymden_Data_Position.right(position);
              var remainingPositionsCenter = Data_Set["delete"](Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(position)(remainingPositions);
              var left$prime = Rymden_Data_Position.left(position);
              var downRight$prime = Rymden_Data_Position.down(right$prime);
              var down$prime = Rymden_Data_Position.down(position);
              var componentCenter = Data_Set.singleton(position);
              var borderUp = new Data_Tuple.Tuple(position, right$prime);
              var borderRight = new Data_Tuple.Tuple(right$prime, downRight$prime);
              var borderLeft = new Data_Tuple.Tuple(position, down$prime);
              var componentLeft = (function () {
                  var $24 = Data_Set.member(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(left$prime)(remainingPositionsCenter) && Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean)))(Data_Set.member(Data_Tuple.ordTuple(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))))(borderLeft)(board.borderSegments);
                  if ($24) {
                      return getConnectedComponent(left$prime)(remainingPositionsCenter);
                  };
                  return componentCenter;
              })();
              var remainingPositionsLeft = Data_Set.difference(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(remainingPositionsCenter)(componentLeft);
              var componentUp = (function () {
                  var $25 = Data_Set.member(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(up$prime)(remainingPositionsLeft) && Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean)))(Data_Set.member(Data_Tuple.ordTuple(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))))(borderUp)(board.borderSegments);
                  if ($25) {
                      return getConnectedComponent(up$prime)(remainingPositionsLeft);
                  };
                  return componentLeft;
              })();
              var remainingPositionsUp = Data_Set.difference(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(remainingPositionsLeft)(componentUp);
              var componentRight = (function () {
                  var $26 = Data_Set.member(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(right$prime)(remainingPositionsUp) && Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean)))(Data_Set.member(Data_Tuple.ordTuple(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))))(borderRight)(board.borderSegments);
                  if ($26) {
                      return getConnectedComponent(right$prime)(remainingPositionsUp);
                  };
                  return componentUp;
              })();
              var remainingPositionsRight = Data_Set.difference(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(remainingPositionsUp)(componentRight);
              var borderDown = new Data_Tuple.Tuple(down$prime, downRight$prime);
              var componentDown = (function () {
                  var $27 = Data_Set.member(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(down$prime)(remainingPositionsRight) && Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean)))(Data_Set.member(Data_Tuple.ordTuple(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))))(borderDown)(board.borderSegments);
                  if ($27) {
                      return getConnectedComponent(down$prime)(remainingPositionsRight);
                  };
                  return componentRight;
              })();
              return Data_Set.unions(Data_Foldable.foldableArray)(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))([ componentCenter, componentLeft, componentUp, componentRight, componentDown ]);
          };
      };
      var getConnectedComponents$prime = function (remainingPositions) {
          var v = Data_Set.findMin(remainingPositions);
          if (v instanceof Data_Maybe.Just) {
              var component = getConnectedComponent(v.value0)(remainingPositions);
              var remainingPositions$prime = Data_Set.difference(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(remainingPositions)(component);
              return Data_Set.insert(Data_Set.ordSet(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt)))(component)(getConnectedComponents$prime(remainingPositions$prime));
          };
          if (v instanceof Data_Maybe.Nothing) {
              return Data_Set.empty;
          };
          throw new Error("Failed pattern match at Rymden.Data.Board (line 63, column 48 - line 73, column 25): " + [ v.constructor.name ]);
      };
      return getConnectedComponents$prime(Data_Set.fromFoldable(Data_Foldable.foldableArray)(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(getPositions(board)));
  };
  var fromGalaxyCluster = function (cluster) {
      return {
          width: cluster.width,
          height: cluster.height,
          centers: Data_Functor.map(Data_Functor.functorArray)(getGalaxyCenter)(Data_Array.fromFoldable(Data_Set.foldableSet)(cluster.galaxies)),
          borderSegments: Data_Set.empty
      };
  };
  var generate = function (width) {
      return function (height) {
          return Data_Functor.map(Effect.functorEffect)(fromGalaxyCluster)(Rymden_Data_GalaxyCluster.generateCluster(width)(height));
      };
  };
  var empty = function (width) {
      return function (height) {
          return {
              width: width,
              height: height,
              borderSegments: Data_Set.empty,
              centers: [  ]
          };
      };
  };
  var clear = function (board) {
      return {
          width: board.width,
          height: board.height,
          borderSegments: Data_Set.empty,
          centers: board.centers
      };
  };
  var checkSolution = function (board) {
      var danglingPositions = Data_Map.keys(Data_Map_Internal.filterWithKey(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(function (v) {
          return function (n) {
              return n === 1 && (v.value0 > 0 && (v.value1 > 0 && (v.value0 < board.height && v.value1 < board.width)));
          };
      })(Rymden_Helper_Foldable.count(Data_Foldable.foldableArray)(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(Control_Bind.join(Control_Bind.bindArray)(Data_Functor.mapFlipped(Data_Functor.functorArray)(Data_Array.fromFoldable(Data_Set.foldableSet)(board.borderSegments))(function (v) {
          return [ v.value0, v.value1 ];
      })))));
      var isBorderDangling = function (v) {
          return Data_Set.member(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(v.value0)(danglingPositions) || Data_Set.member(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(v.value1)(danglingPositions);
      };
      var danglingBorders = Data_Set.filter(Data_Tuple.ordTuple(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt)))(isBorderDangling)(board.borderSegments);
      var connectedComponents = getConnectedComponents(board);
      var getConnectedComponentByPosition = function (position) {
          return Data_Array.find(Data_Set.member(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(position))(Data_Array.fromFoldable(Data_Set.foldableSet)(connectedComponents));
      };
      var incorrectGalaxySizes = (function () {
          var hasIncorrectGalaxySize = function (center) {
              var position = new Data_Tuple.Tuple(Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(Data_Tuple.fst(center.position))(2), Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(Data_Tuple.snd(center.position))(2));
              var v = getConnectedComponentByPosition(position);
              if (v instanceof Data_Maybe.Just) {
                  return Data_Set.size(v.value0) !== center.galaxySize;
              };
              if (v instanceof Data_Maybe.Nothing) {
                  return false;
              };
              throw new Error("Failed pattern match at Rymden.Data.Board (line 208, column 11 - line 210, column 29): " + [ v.constructor.name ]);
          };
          return Data_Set.fromFoldable(Data_Foldable.foldableArray)(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(Data_Functor.map(Data_Functor.functorArray)(function (v) {
              return v.position;
          })(Data_Array.filter(hasIncorrectGalaxySize)(board.centers)));
      })();
      var componentByCenterMap = Data_Map_Internal.fromFoldable(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(Data_Foldable.foldableArray)(Data_Array.catMaybes(Data_Functor.mapFlipped(Data_Functor.functorArray)(Data_Functor.mapFlipped(Data_Functor.functorArray)(board.centers)(function (v) {
          return v.position;
      }))(function (v) {
          var v1 = getConnectedComponentByPosition(new Data_Tuple.Tuple(Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(v.value0)(2), Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(v.value1)(2)));
          if (v1 instanceof Data_Maybe.Just) {
              return new Data_Maybe.Just(new Data_Tuple.Tuple(new Data_Tuple.Tuple(v.value0, v.value1), v1.value0));
          };
          if (v1 instanceof Data_Maybe.Nothing) {
              return Data_Maybe.Nothing.value;
          };
          throw new Error("Failed pattern match at Rymden.Data.Board (line 186, column 29 - line 188, column 33): " + [ v1.constructor.name ]);
      })));
      var centersByComponentMap = Rymden_Helper_Foldable.inverseMap(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(Data_Set.ordSet(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt)))(componentByCenterMap);
      var componentsWithoutCenters = Data_Set.difference(Data_Set.ordSet(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt)))(connectedComponents)(Data_Map.keys(centersByComponentMap));
      var cellsInComponentsWithoutCenter = Data_Set.unions(Data_Set.foldableSet)(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(componentsWithoutCenters);
      var asymmetricCenters = (function () {
          var isAsymmetrical = function (center) {
              var v = Data_Map_Internal.lookup(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(center)(componentByCenterMap);
              if (v instanceof Data_Maybe.Just) {
                  var cr = Data_Tuple.fst(center);
                  var cc = Data_Tuple.snd(center);
                  var reflectCell = function (v1) {
                      return new Data_Tuple.Tuple((cr - v1.value0 | 0) - 1 | 0, (cc - v1.value1 | 0) - 1 | 0);
                  };
                  return Data_Eq.notEq(Data_Set.eqSet(Data_Tuple.eqTuple(Data_Eq.eqInt)(Data_Eq.eqInt)))(Data_Set.map(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(reflectCell)(v.value0))(v.value0);
              };
              if (v instanceof Data_Maybe.Nothing) {
                  return false;
              };
              throw new Error("Failed pattern match at Rymden.Data.Board (line 218, column 31 - line 231, column 25): " + [ v.constructor.name ]);
          };
          return Data_Set.fromFoldable(Data_Foldable.foldableArray)(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(Data_Array.filter(isAsymmetrical)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
              return v.position;
          })(board.centers)));
      })();
      return {
          danglingBorders: danglingBorders,
          incorrectGalaxySizes: incorrectGalaxySizes,
          cellsInComponentsWithoutCenter: cellsInComponentsWithoutCenter,
          asymmetricCenters: asymmetricCenters
      };
  };
  exports["generate"] = generate;
  exports["toggleBorderSegment"] = toggleBorderSegment;
  exports["clear"] = clear;
  exports["checkSolution"] = checkSolution;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Rymden.Data.BoardErrors"] = $PS["Rymden.Data.BoardErrors"] || {};
  var exports = $PS["Rymden.Data.BoardErrors"];
  var Data_HeytingAlgebra = $PS["Data.HeytingAlgebra"];
  var Data_Set = $PS["Data.Set"];                
  var hasErrors = function (boardErrors) {
      return Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_Set.isEmpty)(boardErrors.asymmetricCenters) || (Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_Set.isEmpty)(boardErrors.cellsInComponentsWithoutCenter) || (Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_Set.isEmpty)(boardErrors.danglingBorders) || Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_Set.isEmpty)(boardErrors.incorrectGalaxySizes)));
  };
  var empty = {
      asymmetricCenters: Data_Set.empty,
      cellsInComponentsWithoutCenter: Data_Set.empty,
      danglingBorders: Data_Set.empty,
      incorrectGalaxySizes: Data_Set.empty
  };
  exports["empty"] = empty;
  exports["hasErrors"] = hasErrors;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Rymden.Data.History"] = $PS["Rymden.Data.History"] || {};
  var exports = $PS["Rymden.Data.History"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_List = $PS["Data.List"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Maybe = $PS["Data.Maybe"];              
  var History = (function () {
      function History(value0) {
          this.value0 = value0;
      };
      History.create = function (value0) {
          return new History(value0);
      };
      return History;
  })();
  var singleton = function (current1) {
      return new History({
          past: Data_List_Types.Nil.value,
          current: current1,
          future: Data_List_Types.Nil.value
      });
  };
  var current = function (v) {
      return v.value0.current;
  };
  var back = function (v) {
      var v1 = Data_List.uncons(v.value0.past);
      if (v1 instanceof Data_Maybe.Just) {
          return History.create({
              past: v1.value0.tail,
              current: v1.value0.head,
              future: new Data_List_Types.Cons(v.value0.current, v.value0.future)
          });
      };
      if (v1 instanceof Data_Maybe.Nothing) {
          return new History(v.value0);
      };
      throw new Error("Failed pattern match at Rymden.Data.History (line 30, column 3 - line 34, column 16): " + [ v1.constructor.name ]);
  };
  var append = function (current1) {
      return function (v) {
          return History.create({
              past: new Data_List_Types.Cons(v.value0.current, v.value0.past),
              current: current1,
              future: Data_List_Types.Nil.value
          });
      };
  };
  var append$prime = function (dictEq) {
      return function (updateFunction) {
          return function (history) {
              var old = current(history);
              var $$new = updateFunction(old);
              var $64 = Data_Eq.notEq(dictEq)(old)($$new);
              if ($64) {
                  return append($$new)(history);
              };
              return history;
          };
      };
  };
  exports["singleton"] = singleton;
  exports["append'"] = append$prime;
  exports["back"] = back;
  exports["current"] = current;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Rymden.Component.Board"] = $PS["Rymden.Component.Board"] || {};
  var exports = $PS["Rymden.Component.Board"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad_State_Class = $PS["Control.Monad.State.Class"];
  var Data_Array = $PS["Data.Array"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_HeytingAlgebra = $PS["Data.HeytingAlgebra"];
  var Data_Int = $PS["Data.Int"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Set = $PS["Data.Set"];
  var Data_Show = $PS["Data.Show"];
  var Data_String_Common = $PS["Data.String.Common"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect_Class = $PS["Effect.Class"];
  var Halogen_Component = $PS["Halogen.Component"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var Halogen_HTML_Elements = $PS["Halogen.HTML.Elements"];
  var Halogen_HTML_Events = $PS["Halogen.HTML.Events"];
  var Halogen_Query_HalogenM = $PS["Halogen.Query.HalogenM"];
  var Halogen_Svg_Attributes = $PS["Halogen.Svg.Attributes"];
  var Halogen_Svg_Elements = $PS["Halogen.Svg.Elements"];
  var Rymden_Component_Helpers_Property = $PS["Rymden.Component.Helpers.Property"];
  var Rymden_Data_Board = $PS["Rymden.Data.Board"];
  var Rymden_Data_BoardErrors = $PS["Rymden.Data.BoardErrors"];
  var Rymden_Data_History = $PS["Rymden.Data.History"];                
  var Check = (function () {
      function Check(value0) {
          this.value0 = value0;
      };
      Check.create = function (value0) {
          return new Check(value0);
      };
      return Check;
  })();
  var Undo = (function () {
      function Undo(value0) {
          this.value0 = value0;
      };
      Undo.create = function (value0) {
          return new Undo(value0);
      };
      return Undo;
  })();
  var Redo = (function () {
      function Redo(value0) {
          this.value0 = value0;
      };
      Redo.create = function (value0) {
          return new Redo(value0);
      };
      return Redo;
  })();
  var New = (function () {
      function New(value0) {
          this.value0 = value0;
      };
      New.create = function (value0) {
          return new New(value0);
      };
      return New;
  })();
  var Clear = (function () {
      function Clear(value0) {
          this.value0 = value0;
      };
      Clear.create = function (value0) {
          return new Clear(value0);
      };
      return Clear;
  })();
  var Solved = (function () {
      function Solved(value0) {
          this.value0 = value0;
      };
      Solved.create = function (value0) {
          return new Solved(value0);
      };
      return Solved;
  })();
  var Initialize = (function () {
      function Initialize() {

      };
      Initialize.value = new Initialize();
      return Initialize;
  })();
  var ClickedBorder = (function () {
      function ClickedBorder(value0) {
          this.value0 = value0;
      };
      ClickedBorder.create = function (value0) {
          return new ClickedBorder(value0);
      };
      return ClickedBorder;
  })();
  var numberOfColumns = 10;
  var numberOfRows = numberOfColumns;
  var component = function (dictMonadEffect) {
      var render = function (state) {
          var renderBoard = function (board) {
              var borderHeight = state.height / (Data_Int.toNumber(board.height) / 0.15 + (Data_Int.toNumber(board.height) + 1.0));
              var borderWidth = state.width / (Data_Int.toNumber(board.width) / 0.15 + (Data_Int.toNumber(board.width) + 1.0));
              var outerBorders = [ Halogen_Svg_Elements.rect([ Halogen_Svg_Attributes.width(borderWidth), Halogen_Svg_Attributes.height(state.height), Halogen_Svg_Attributes.x(0.0), Halogen_Svg_Attributes.y(0.0), Rymden_Component_Helpers_Property.sclass("corner active") ]), Halogen_Svg_Elements.rect([ Halogen_Svg_Attributes.width(state.width), Halogen_Svg_Attributes.height(borderHeight), Halogen_Svg_Attributes.x(0.0), Halogen_Svg_Attributes.y(0.0), Rymden_Component_Helpers_Property.sclass("corner active") ]), Halogen_Svg_Elements.rect([ Halogen_Svg_Attributes.width(borderWidth), Halogen_Svg_Attributes.height(state.height), Halogen_Svg_Attributes.x(state.width - borderWidth), Halogen_Svg_Attributes.y(0.0), Rymden_Component_Helpers_Property.sclass("corner active") ]), Halogen_Svg_Elements.rect([ Halogen_Svg_Attributes.width(state.width), Halogen_Svg_Attributes.height(borderHeight), Halogen_Svg_Attributes.x(0.0), Halogen_Svg_Attributes.y(state.height - borderHeight), Rymden_Component_Helpers_Property.sclass("corner active") ]) ];
              var cellHeight = state.height / (Data_Int.toNumber(board.height) + (Data_Int.toNumber(board.height) + 1.0) * 0.15);
              var cellOffsetY = function (index) {
                  return borderHeight + Data_Int.toNumber(index) * (borderHeight + cellHeight);
              };
              var centerY = function (row) {
                  return (borderHeight + Data_Int.toNumber(row) * (borderHeight + cellHeight)) / 2.0;
              };
              var cornerOffsetY = function (index) {
                  return Data_Int.toNumber(index) * (borderHeight + cellHeight);
              };
              var horizontalBorderOffsetY = function (index) {
                  return Data_Int.toNumber(index) * (borderHeight + cellHeight);
              };
              var verticalBorderOffsetY = function (index) {
                  return borderHeight + Data_Int.toNumber(index) * (borderHeight + cellHeight);
              };
              var cellWidth = state.width / (Data_Int.toNumber(board.width) + (Data_Int.toNumber(board.width) + 1.0) * 0.15);
              var cellOffsetX = function (index) {
                  return borderWidth + Data_Int.toNumber(index) * (borderWidth + cellWidth);
              };
              var cells = (function () {
                  var renderCell = function (row) {
                      return function (column) {
                          var position = new Data_Tuple.Tuple(row, column);
                          var classes = (function () {
                              var missingCenterClass = (function () {
                                  var $10 = Data_Set.member(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(position)(state.boardErrors.cellsInComponentsWithoutCenter);
                                  if ($10) {
                                      return new Data_Maybe.Just("missing-center");
                                  };
                                  return Data_Maybe.Nothing.value;
                              })();
                              return Data_String_Common.joinWith(" ")(Data_Array.catMaybes([ new Data_Maybe.Just("cell"), missingCenterClass ]));
                          })();
                          return Halogen_Svg_Elements.rect([ Halogen_Svg_Attributes.width(cellWidth), Halogen_Svg_Attributes.height(cellHeight), Halogen_Svg_Attributes.x(cellOffsetX(column)), Halogen_Svg_Attributes.y(cellOffsetY(row)), Rymden_Component_Helpers_Property.sclass(classes) ]);
                      };
                  };
                  return Control_Bind.bind(Control_Bind.bindArray)(Data_Array.range(0)(board.height - 1 | 0))(function (row) {
                      return Control_Bind.bind(Control_Bind.bindArray)(Data_Array.range(0)(board.width - 1 | 0))(function (column) {
                          return Control_Applicative.pure(Control_Applicative.applicativeArray)(renderCell(row)(column));
                      });
                  });
              })();
              var centerX = function (column) {
                  return (borderWidth + Data_Int.toNumber(column) * (borderWidth + cellWidth)) / 2.0;
              };
              var centers = (function () {
                  var renderGalaxyCenter = function (center) {
                      var row = Data_Tuple.fst(center.position);
                      var column = Data_Tuple.snd(center.position);
                      var classes = (function () {
                          var incorrectSizeClass = (function () {
                              var $11 = Data_Set.member(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(center.position)(state.boardErrors.incorrectGalaxySizes);
                              if ($11) {
                                  return new Data_Maybe.Just("incorrect-size");
                              };
                              return Data_Maybe.Nothing.value;
                          })();
                          return Data_String_Common.joinWith(" ")(Data_Array.catMaybes([ new Data_Maybe.Just("galaxy-center"), incorrectSizeClass ]));
                      })();
                      return Halogen_Svg_Elements.text([ Halogen_Svg_Attributes.x(centerX(column)), Halogen_Svg_Attributes.y(centerY(row)), Halogen_Svg_Attributes.text_anchor(Halogen_Svg_Attributes.AnchorMiddle.value), Halogen_Svg_Attributes.dominant_baseline(Halogen_Svg_Attributes.Central.value), Rymden_Component_Helpers_Property.sclass(classes) ])([ Halogen_HTML_Core.text(Data_Show.show(Data_Show.showInt)(center.galaxySize)) ]);
                  };
                  return Data_Functor.map(Data_Functor.functorArray)(renderGalaxyCenter)(board.centers);
              })();
              var cornerOffsetX = function (index) {
                  return Data_Int.toNumber(index) * (borderWidth + cellWidth);
              };
              var corners = Control_Bind.bind(Control_Bind.bindArray)(Data_Array.range(1)(board.height - 1 | 0))(function (r) {
                  return Control_Bind.bind(Control_Bind.bindArray)(Data_Array.range(1)(board.width - 1 | 0))(function (c) {
                      var offsetUp = cornerOffsetY(r) - 0.5;
                      var offsetLeft = cornerOffsetX(c) - 0.5;
                      var offsetRight = offsetLeft + borderWidth + 1.0;
                      var offsetDown = offsetUp + borderHeight + 1.0;
                      var hasNeighbourUp = Data_Set.member(Data_Tuple.ordTuple(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt)))(new Data_Tuple.Tuple(new Data_Tuple.Tuple(r - 1 | 0, c), new Data_Tuple.Tuple(r, c)))(board.borderSegments);
                      var hasNeighbourRight = Data_Set.member(Data_Tuple.ordTuple(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt)))(new Data_Tuple.Tuple(new Data_Tuple.Tuple(r, c), new Data_Tuple.Tuple(r, c + 1 | 0)))(board.borderSegments);
                      var hasNeighbourLeft = Data_Set.member(Data_Tuple.ordTuple(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt)))(new Data_Tuple.Tuple(new Data_Tuple.Tuple(r, c - 1 | 0), new Data_Tuple.Tuple(r, c)))(board.borderSegments);
                      var hasNeighbourDown = Data_Set.member(Data_Tuple.ordTuple(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt)))(new Data_Tuple.Tuple(new Data_Tuple.Tuple(r, c), new Data_Tuple.Tuple(r + 1 | 0, c)))(board.borderSegments);
                      return Control_Applicative.pure(Control_Applicative.applicativeArray)((function () {
                          var $12 = hasNeighbourLeft && hasNeighbourRight || hasNeighbourUp && hasNeighbourDown;
                          if ($12) {
                              return Halogen_Svg_Elements.rect([ Halogen_Svg_Attributes.width(borderWidth + 0.2), Halogen_Svg_Attributes.height(borderHeight + 0.2), Halogen_Svg_Attributes.x(cornerOffsetX(c) - 0.1), Halogen_Svg_Attributes.y(cornerOffsetY(r) - 0.1), Rymden_Component_Helpers_Property.sclass("corner active") ]);
                          };
                          var $13 = hasNeighbourLeft && hasNeighbourUp;
                          if ($13) {
                              return Halogen_Svg_Elements.path([ Halogen_Svg_Attributes.d([ Halogen_Svg_Attributes.m(Halogen_Svg_Attributes.Abs.value)(offsetLeft)(offsetUp), Halogen_Svg_Attributes.l(Halogen_Svg_Attributes.Abs.value)(offsetRight)(offsetUp), Halogen_Svg_Attributes.l(Halogen_Svg_Attributes.Abs.value)(offsetLeft)(offsetDown), Halogen_Svg_Attributes.z ]), Rymden_Component_Helpers_Property.sclass("corner active") ]);
                          };
                          var $14 = hasNeighbourUp && hasNeighbourRight;
                          if ($14) {
                              return Halogen_Svg_Elements.path([ Halogen_Svg_Attributes.d([ Halogen_Svg_Attributes.m(Halogen_Svg_Attributes.Abs.value)(offsetRight)(offsetUp), Halogen_Svg_Attributes.l(Halogen_Svg_Attributes.Abs.value)(offsetRight)(offsetDown), Halogen_Svg_Attributes.l(Halogen_Svg_Attributes.Abs.value)(offsetLeft)(offsetUp), Halogen_Svg_Attributes.z ]), Rymden_Component_Helpers_Property.sclass("corner active") ]);
                          };
                          var $15 = hasNeighbourRight && hasNeighbourDown;
                          if ($15) {
                              return Halogen_Svg_Elements.path([ Halogen_Svg_Attributes.d([ Halogen_Svg_Attributes.m(Halogen_Svg_Attributes.Abs.value)(offsetRight)(offsetDown), Halogen_Svg_Attributes.l(Halogen_Svg_Attributes.Abs.value)(offsetLeft)(offsetDown), Halogen_Svg_Attributes.l(Halogen_Svg_Attributes.Abs.value)(offsetRight)(offsetUp), Halogen_Svg_Attributes.z ]), Rymden_Component_Helpers_Property.sclass("corner active") ]);
                          };
                          var $16 = hasNeighbourDown && hasNeighbourLeft;
                          if ($16) {
                              return Halogen_Svg_Elements.path([ Halogen_Svg_Attributes.d([ Halogen_Svg_Attributes.m(Halogen_Svg_Attributes.Abs.value)(offsetLeft)(offsetDown), Halogen_Svg_Attributes.l(Halogen_Svg_Attributes.Abs.value)(offsetLeft)(offsetUp), Halogen_Svg_Attributes.l(Halogen_Svg_Attributes.Abs.value)(offsetRight)(offsetDown), Halogen_Svg_Attributes.z ]), Rymden_Component_Helpers_Property.sclass("corner active") ]);
                          };
                          return Halogen_HTML_Core.text("");
                      })());
                  });
              });
              var horizontalBorderOffsetX = function (index) {
                  return borderWidth + Data_Int.toNumber(index) * (borderWidth + cellWidth);
              };
              var horizontalBorders = (function () {
                  var hoverAreaHalfWidth = (cellWidth + borderWidth) / 2.0;
                  var hoverAreaHalfHeight = (cellHeight + borderHeight) / 2.0;
                  var classes = function (row) {
                      return function (column) {
                          var borderSegment = new Data_Tuple.Tuple(new Data_Tuple.Tuple(row, column), new Data_Tuple.Tuple(row, column + 1 | 0));
                          var $17 = Data_Set.member(Data_Tuple.ordTuple(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt)))(borderSegment)(board.borderSegments);
                          if ($17) {
                              var $18 = Data_Set.member(Data_Tuple.ordTuple(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt)))(borderSegment)(state.boardErrors.danglingBorders);
                              if ($18) {
                                  return "border active dangling";
                              };
                              return "border active";
                          };
                          return "border";
                      };
                  };
                  return Control_Bind.bind(Control_Bind.bindArray)(Data_Array.range(1)(board.height - 1 | 0))(function (r) {
                      return Control_Bind.bind(Control_Bind.bindArray)(Data_Array.range(0)(board.width - 1 | 0))(function (c) {
                          return Control_Applicative.pure(Control_Applicative.applicativeArray)(Halogen_Svg_Elements.g([ Rymden_Component_Helpers_Property.sclass("border-group"), Halogen_HTML_Events.onClick(Data_Function["const"](ClickedBorder.create(new Data_Tuple.Tuple(new Data_Tuple.Tuple(r, c), new Data_Tuple.Tuple(r, c + 1 | 0))))) ])([ Halogen_Svg_Elements.path([ Halogen_Svg_Attributes.d([ Halogen_Svg_Attributes.m(Halogen_Svg_Attributes.Abs.value)(horizontalBorderOffsetX(c) - borderHeight / 2.0)(horizontalBorderOffsetY(r) + borderHeight / 2.0), Halogen_Svg_Attributes.l(Halogen_Svg_Attributes.Rel.value)(hoverAreaHalfWidth)(-hoverAreaHalfHeight), Halogen_Svg_Attributes.l(Halogen_Svg_Attributes.Rel.value)(hoverAreaHalfWidth)(hoverAreaHalfHeight), Halogen_Svg_Attributes.l(Halogen_Svg_Attributes.Rel.value)(-hoverAreaHalfWidth)(hoverAreaHalfHeight), Halogen_Svg_Attributes.z ]), Rymden_Component_Helpers_Property.sclass("border-hover-area") ]), Halogen_Svg_Elements.rect([ Halogen_Svg_Attributes.width(cellWidth), Halogen_Svg_Attributes.height(borderHeight), Halogen_Svg_Attributes.x(horizontalBorderOffsetX(c)), Halogen_Svg_Attributes.y(horizontalBorderOffsetY(r)), Rymden_Component_Helpers_Property.sclass(classes(r)(c)) ]) ]));
                      });
                  });
              })();
              var verticalBorderOffsetX = function (index) {
                  return Data_Int.toNumber(index) * (borderWidth + cellWidth);
              };
              var verticalBorders = (function () {
                  var hoverAreaHalfWidth = (cellWidth + borderWidth) / 2.0;
                  var hoverAreaHalfHeight = (cellHeight + borderHeight) / 2.0;
                  var classes = function (row) {
                      return function (column) {
                          var borderSegment = new Data_Tuple.Tuple(new Data_Tuple.Tuple(row, column), new Data_Tuple.Tuple(row + 1 | 0, column));
                          var $19 = Data_Set.member(Data_Tuple.ordTuple(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt)))(borderSegment)(board.borderSegments);
                          if ($19) {
                              var $20 = Data_Set.member(Data_Tuple.ordTuple(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt))(Data_Tuple.ordTuple(Data_Ord.ordInt)(Data_Ord.ordInt)))(borderSegment)(state.boardErrors.danglingBorders);
                              if ($20) {
                                  return "border active dangling";
                              };
                              return "border active";
                          };
                          return "border";
                      };
                  };
                  return Control_Bind.bind(Control_Bind.bindArray)(Data_Array.range(0)(board.height - 1 | 0))(function (r) {
                      return Control_Bind.bind(Control_Bind.bindArray)(Data_Array.range(1)(board.width - 1 | 0))(function (c) {
                          return Control_Applicative.pure(Control_Applicative.applicativeArray)(Halogen_Svg_Elements.g([ Rymden_Component_Helpers_Property.sclass("border-group"), Halogen_HTML_Events.onClick(Data_Function["const"](ClickedBorder.create(new Data_Tuple.Tuple(new Data_Tuple.Tuple(r, c), new Data_Tuple.Tuple(r + 1 | 0, c))))) ])([ Halogen_Svg_Elements.path([ Halogen_Svg_Attributes.d([ Halogen_Svg_Attributes.m(Halogen_Svg_Attributes.Abs.value)(verticalBorderOffsetX(c) + borderWidth / 2.0)(verticalBorderOffsetY(r) - borderHeight / 2.0), Halogen_Svg_Attributes.l(Halogen_Svg_Attributes.Rel.value)(hoverAreaHalfWidth)(hoverAreaHalfHeight), Halogen_Svg_Attributes.l(Halogen_Svg_Attributes.Rel.value)(-hoverAreaHalfWidth)(hoverAreaHalfHeight), Halogen_Svg_Attributes.l(Halogen_Svg_Attributes.Rel.value)(-hoverAreaHalfWidth)(-hoverAreaHalfHeight), Halogen_Svg_Attributes.z ]), Rymden_Component_Helpers_Property.sclass("border-hover-area") ]), Halogen_Svg_Elements.rect([ Halogen_Svg_Attributes.width(borderWidth), Halogen_Svg_Attributes.height(cellHeight), Halogen_Svg_Attributes.x(verticalBorderOffsetX(c)), Halogen_Svg_Attributes.y(verticalBorderOffsetY(r)), Rymden_Component_Helpers_Property.sclass(classes(r)(c)) ]) ]));
                      });
                  });
              })();
              var asymetricCenterIndicators = (function () {
                  var renderAssymetricCenterIndicator = function (center) {
                      return Halogen_Svg_Elements.circle([ Halogen_Svg_Attributes.cx(centerX(Data_Tuple.snd(center))), Halogen_Svg_Attributes.cy(centerY(Data_Tuple.fst(center))), Halogen_Svg_Attributes.r((cellWidth + cellHeight) / 7.0), Rymden_Component_Helpers_Property.sclass("asymmetric-center") ]);
                  };
                  return Data_Functor.map(Data_Functor.functorArray)(renderAssymetricCenterIndicator)(Data_Array.fromFoldable(Data_Set.foldableSet)(state.boardErrors.asymmetricCenters));
              })();
              return Halogen_Svg_Elements.svg([ Halogen_Svg_Attributes.viewBox(0.0)(0.0)(state.width)(state.height), Rymden_Component_Helpers_Property.sclass("board") ])(Data_Array.concat([ outerBorders, corners, cells, asymetricCenterIndicators, centers, verticalBorders, horizontalBorders ]));
          };
          if (state.boardHistory instanceof Data_Maybe.Just) {
              return renderBoard(Rymden_Data_History.current(state.boardHistory.value0));
          };
          if (state.boardHistory instanceof Data_Maybe.Nothing) {
              return Halogen_HTML_Elements.div_([ Halogen_HTML_Core.text("Loading...") ]);
          };
          throw new Error("Failed pattern match at Rymden.Component.Board (line 71, column 18 - line 73, column 48): " + [ state.boardHistory.constructor.name ]);
      };
      var initialState = function (v) {
          return {
              boardHistory: Data_Maybe.Nothing.value,
              width: 1024.0,
              height: 1024.0,
              boardErrors: Rymden_Data_BoardErrors.empty
          };
      };
      var $$eval = (function () {
          var updateBoardHistory = function (updateFunction) {
              return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.gets(Halogen_Query_HalogenM.monadStateHalogenM)(function (v) {
                  return v.boardHistory;
              }))(function (maybeBoardHistory) {
                  if (maybeBoardHistory instanceof Data_Maybe.Just) {
                      return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v) {
                          var $24 = {};
                          for (var $25 in v) {
                              if ({}.hasOwnProperty.call(v, $25)) {
                                  $24[$25] = v[$25];
                              };
                          };
                          $24.boardHistory = Data_Maybe.Just.create(updateFunction(maybeBoardHistory.value0));
                          $24.boardErrors = Rymden_Data_BoardErrors.empty;
                          return $24;
                      }))(function () {
                          return Halogen_Query_HalogenM.raise(new Solved(false));
                      });
                  };
                  if (maybeBoardHistory instanceof Data_Maybe.Nothing) {
                      return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
                  };
                  throw new Error("Failed pattern match at Rymden.Component.Board (line 435, column 7 - line 443, column 29): " + [ maybeBoardHistory.constructor.name ]);
              });
          };
          var initialize = Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadEffect))(Rymden_Data_Board.generate(numberOfColumns)(numberOfRows)))(function (board) {
              return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v) {
                  var $28 = {};
                  for (var $29 in v) {
                      if ({}.hasOwnProperty.call(v, $29)) {
                          $28[$29] = v[$29];
                      };
                  };
                  $28.boardHistory = Data_Maybe.Just.create(Rymden_Data_History.singleton(board));
                  return $28;
              }))(function () {
                  return Control_Applicative.when(Halogen_Query_HalogenM.applicativeHalogenM)(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Rymden_Data_BoardErrors.hasErrors)(Rymden_Data_Board.checkSolution(board)))(Halogen_Query_HalogenM.raise(new Solved(true)));
              });
          });
          var handleQuery = function (v) {
              if (v instanceof Check) {
                  return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.gets(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                      return v1.boardHistory;
                  }))(function (maybeBoardHistory) {
                      return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)((function () {
                          if (maybeBoardHistory instanceof Data_Maybe.Just) {
                              var board = Rymden_Data_History.current(maybeBoardHistory.value0);
                              var boardErrors = Rymden_Data_Board.checkSolution(board);
                              return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                                  var $33 = {};
                                  for (var $34 in v1) {
                                      if ({}.hasOwnProperty.call(v1, $34)) {
                                          $33[$34] = v1[$34];
                                      };
                                  };
                                  $33.boardErrors = boardErrors;
                                  return $33;
                              }))(function () {
                                  return Halogen_Query_HalogenM.raise(Solved.create(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Rymden_Data_BoardErrors.hasErrors)(boardErrors)));
                              });
                          };
                          if (maybeBoardHistory instanceof Data_Maybe.Nothing) {
                              return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
                          };
                          throw new Error("Failed pattern match at Rymden.Component.Board (line 453, column 9 - line 460, column 31): " + [ maybeBoardHistory.constructor.name ]);
                      })())(function () {
                          return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(new Data_Maybe.Just(v.value0));
                      });
                  });
              };
              if (v instanceof Undo) {
                  return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(updateBoardHistory(Rymden_Data_History.back))(function () {
                      return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(new Data_Maybe.Just(v.value0));
                  });
              };
              if (v instanceof Redo) {
                  return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(new Data_Maybe.Just(v.value0));
              };
              if (v instanceof New) {
                  return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(initialize)(function () {
                      return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(new Data_Maybe.Just(v.value0));
                  });
              };
              if (v instanceof Clear) {
                  return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(updateBoardHistory(Rymden_Data_History["append'"](Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()(new Data_Symbol.IsSymbol(function () {
                      return "width";
                  }))(Data_Eq.eqInt))()(new Data_Symbol.IsSymbol(function () {
                      return "height";
                  }))(Data_Eq.eqInt))()(new Data_Symbol.IsSymbol(function () {
                      return "centers";
                  }))(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()(new Data_Symbol.IsSymbol(function () {
                      return "position";
                  }))(Data_Tuple.eqTuple(Data_Eq.eqInt)(Data_Eq.eqInt)))()(new Data_Symbol.IsSymbol(function () {
                      return "galaxySize";
                  }))(Data_Eq.eqInt)))))()(new Data_Symbol.IsSymbol(function () {
                      return "borderSegments";
                  }))(Data_Set.eqSet(Data_Tuple.eqTuple(Data_Tuple.eqTuple(Data_Eq.eqInt)(Data_Eq.eqInt))(Data_Tuple.eqTuple(Data_Eq.eqInt)(Data_Eq.eqInt))))))(Rymden_Data_Board.clear)))(function () {
                      return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(new Data_Maybe.Just(v.value0));
                  });
              };
              throw new Error("Failed pattern match at Rymden.Component.Board (line 450, column 19 - line 472, column 22): " + [ v.constructor.name ]);
          };
          var handleAction = function (v) {
              if (v instanceof Initialize) {
                  return initialize;
              };
              if (v instanceof ClickedBorder) {
                  return updateBoardHistory(Rymden_Data_History["append'"](Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()(new Data_Symbol.IsSymbol(function () {
                      return "width";
                  }))(Data_Eq.eqInt))()(new Data_Symbol.IsSymbol(function () {
                      return "height";
                  }))(Data_Eq.eqInt))()(new Data_Symbol.IsSymbol(function () {
                      return "centers";
                  }))(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()(new Data_Symbol.IsSymbol(function () {
                      return "position";
                  }))(Data_Tuple.eqTuple(Data_Eq.eqInt)(Data_Eq.eqInt)))()(new Data_Symbol.IsSymbol(function () {
                      return "galaxySize";
                  }))(Data_Eq.eqInt)))))()(new Data_Symbol.IsSymbol(function () {
                      return "borderSegments";
                  }))(Data_Set.eqSet(Data_Tuple.eqTuple(Data_Tuple.eqTuple(Data_Eq.eqInt)(Data_Eq.eqInt))(Data_Tuple.eqTuple(Data_Eq.eqInt)(Data_Eq.eqInt))))))(Rymden_Data_Board.toggleBorderSegment(v.value0)));
              };
              throw new Error("Failed pattern match at Rymden.Component.Board (line 445, column 20 - line 447, column 110): " + [ v.constructor.name ]);
          };
          return Halogen_Component.mkEval({
              handleAction: handleAction,
              handleQuery: handleQuery,
              receive: Halogen_Component.defaultEval.receive,
              initialize: new Data_Maybe.Just(Initialize.value),
              finalize: Halogen_Component.defaultEval.finalize
          });
      })();
      return Halogen_Component.mkComponent({
          initialState: initialState,
          render: render,
          "eval": $$eval
      });
  };
  exports["Check"] = Check;
  exports["Undo"] = Undo;
  exports["Redo"] = Redo;
  exports["New"] = New;
  exports["Clear"] = Clear;
  exports["component"] = component;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Rymden.Page.Home"] = $PS["Rymden.Page.Home"] || {};
  var exports = $PS["Rymden.Page.Home"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad_State_Class = $PS["Control.Monad.State.Class"];
  var Data_Function = $PS["Data.Function"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Data_Unit = $PS["Data.Unit"];
  var Halogen_Component = $PS["Halogen.Component"];
  var Halogen_HTML = $PS["Halogen.HTML"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var Halogen_HTML_Elements = $PS["Halogen.HTML.Elements"];
  var Halogen_HTML_Events = $PS["Halogen.HTML.Events"];
  var Halogen_Query = $PS["Halogen.Query"];
  var Halogen_Query_HalogenM = $PS["Halogen.Query.HalogenM"];
  var Rymden_Component_Board = $PS["Rymden.Component.Board"];
  var Rymden_Component_Helpers_Property = $PS["Rymden.Component.Helpers.Property"];
  var Type_Proxy = $PS["Type.Proxy"];                
  var ReceiveBoardOutput = (function () {
      function ReceiveBoardOutput(value0) {
          this.value0 = value0;
      };
      ReceiveBoardOutput.create = function (value0) {
          return new ReceiveBoardOutput(value0);
      };
      return ReceiveBoardOutput;
  })();
  var ClickedVerify = (function () {
      function ClickedVerify() {

      };
      ClickedVerify.value = new ClickedVerify();
      return ClickedVerify;
  })();
  var ClickedUndo = (function () {
      function ClickedUndo() {

      };
      ClickedUndo.value = new ClickedUndo();
      return ClickedUndo;
  })();
  var ClickedRedo = (function () {
      function ClickedRedo() {

      };
      ClickedRedo.value = new ClickedRedo();
      return ClickedRedo;
  })();
  var ClickedNew = (function () {
      function ClickedNew() {

      };
      ClickedNew.value = new ClickedNew();
      return ClickedNew;
  })();
  var ClickedClear = (function () {
      function ClickedClear() {

      };
      ClickedClear.value = new ClickedClear();
      return ClickedClear;
  })();
  var component = function (dictMonadEffect) {
      return function (dictMonadStore) {
          var initialState = function (v) {
              return {
                  solved: false
              };
          };
          var $$eval = (function () {
              var handleAction = function (v) {
                  if (v instanceof ReceiveBoardOutput) {
                      return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                          var $8 = {};
                          for (var $9 in v1) {
                              if ({}.hasOwnProperty.call(v1, $9)) {
                                  $8[$9] = v1[$9];
                              };
                          };
                          $8.solved = v.value0.value0;
                          return $8;
                      });
                  };
                  if (v instanceof ClickedVerify) {
                      return Halogen_Query.tell()(new Data_Symbol.IsSymbol(function () {
                          return "board";
                      }))(Data_Ord.ordUnit)(Type_Proxy["Proxy"].value)(Data_Unit.unit)(Rymden_Component_Board.Check.create);
                  };
                  if (v instanceof ClickedUndo) {
                      return Halogen_Query.tell()(new Data_Symbol.IsSymbol(function () {
                          return "board";
                      }))(Data_Ord.ordUnit)(Type_Proxy["Proxy"].value)(Data_Unit.unit)(Rymden_Component_Board.Undo.create);
                  };
                  if (v instanceof ClickedRedo) {
                      return Halogen_Query.tell()(new Data_Symbol.IsSymbol(function () {
                          return "board";
                      }))(Data_Ord.ordUnit)(Type_Proxy["Proxy"].value)(Data_Unit.unit)(Rymden_Component_Board.Redo.create);
                  };
                  if (v instanceof ClickedNew) {
                      return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                          var $13 = {};
                          for (var $14 in v1) {
                              if ({}.hasOwnProperty.call(v1, $14)) {
                                  $13[$14] = v1[$14];
                              };
                          };
                          $13.solved = false;
                          return $13;
                      }))(function () {
                          return Halogen_Query.tell()(new Data_Symbol.IsSymbol(function () {
                              return "board";
                          }))(Data_Ord.ordUnit)(Type_Proxy["Proxy"].value)(Data_Unit.unit)(Rymden_Component_Board.New.create);
                      });
                  };
                  if (v instanceof ClickedClear) {
                      return Halogen_Query.tell()(new Data_Symbol.IsSymbol(function () {
                          return "board";
                      }))(Data_Ord.ordUnit)(Type_Proxy["Proxy"].value)(Data_Unit.unit)(Rymden_Component_Board.Clear.create);
                  };
                  throw new Error("Failed pattern match at Rymden.Page.Home (line 82, column 20 - line 90, column 53): " + [ v.constructor.name ]);
              };
              return Halogen_Component.mkEval({
                  handleAction: handleAction,
                  handleQuery: Halogen_Component.defaultEval.handleQuery,
                  receive: Halogen_Component.defaultEval.receive,
                  initialize: Halogen_Component.defaultEval.initialize,
                  finalize: Halogen_Component.defaultEval.finalize
              });
          })();
          var render = function (state) {
              var renderButtons = (function () {
                  if (state.solved) {
                      return Halogen_HTML_Elements.div([ Rymden_Component_Helpers_Property.classes("board-control-buttons") ])([ Halogen_HTML_Elements.p([ Rymden_Component_Helpers_Property.classes("celebration-text") ])([ Halogen_HTML_Core.text("Well done!") ]), Halogen_HTML_Elements.button([ Rymden_Component_Helpers_Property.classes("board-control-button"), Halogen_HTML_Events.onClick(Data_Function["const"](ClickedNew.value)) ])([ Halogen_HTML_Core.text("New game") ]) ]);
                  };
                  return Halogen_HTML_Elements.div([ Rymden_Component_Helpers_Property.classes("board-control-buttons") ])([ Halogen_HTML_Elements.button([ Rymden_Component_Helpers_Property.classes("board-control-button"), Halogen_HTML_Events.onClick(Data_Function["const"](ClickedVerify.value)) ])([ Halogen_HTML_Core.text("Check") ]), Halogen_HTML_Elements.button([ Rymden_Component_Helpers_Property.classes("board-control-button"), Halogen_HTML_Events.onClick(Data_Function["const"](ClickedUndo.value)) ])([ Halogen_HTML_Core.text("Undo") ]), Halogen_HTML_Elements.button([ Rymden_Component_Helpers_Property.classes("board-control-button"), Halogen_HTML_Events.onClick(Data_Function["const"](ClickedClear.value)) ])([ Halogen_HTML_Core.text("Clear") ]) ]);
              })();
              return Halogen_HTML_Elements.div([ Rymden_Component_Helpers_Property.classes("board-container") ])([ Halogen_HTML.slot()(new Data_Symbol.IsSymbol(function () {
                  return "board";
              }))(Data_Ord.ordUnit)(Type_Proxy["Proxy"].value)(Data_Unit.unit)(Rymden_Component_Board.component(dictMonadEffect))(Data_Unit.unit)(ReceiveBoardOutput.create), renderButtons ]);
          };
          return Halogen_Component.mkComponent({
              initialState: initialState,
              render: render,
              "eval": $$eval
          });
      };
  };
  exports["component"] = component;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Rymden.Page.Settings"] = $PS["Rymden.Page.Settings"] || {};
  var exports = $PS["Rymden.Page.Settings"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Monad_State_Class = $PS["Control.Monad.State.Class"];
  var Data_Function = $PS["Data.Function"];
  var Data_Unit = $PS["Data.Unit"];
  var Halogen_Component = $PS["Halogen.Component"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var Halogen_HTML_Elements = $PS["Halogen.HTML.Elements"];
  var Halogen_HTML_Events = $PS["Halogen.HTML.Events"];
  var Halogen_Query_HalogenM = $PS["Halogen.Query.HalogenM"];
  var Rymden_Component_Helpers_Property = $PS["Rymden.Component.Helpers.Property"];                
  var ClickedClear = (function () {
      function ClickedClear() {

      };
      ClickedClear.value = new ClickedClear();
      return ClickedClear;
  })();
  var ClickedConfirmClear = (function () {
      function ClickedConfirmClear() {

      };
      ClickedConfirmClear.value = new ClickedConfirmClear();
      return ClickedConfirmClear;
  })();
  var ClickedCancelClear = (function () {
      function ClickedCancelClear() {

      };
      ClickedCancelClear.value = new ClickedCancelClear();
      return ClickedCancelClear;
  })();
  var component = function (dictMonadEffect) {
      return function (dictMonadStore) {
          var render = function (state) {
              return Halogen_HTML_Elements.div([ Rymden_Component_Helpers_Property.classes("section") ])([ Halogen_HTML_Elements.div([ Rymden_Component_Helpers_Property.classes("container") ])([ Halogen_HTML_Elements.h1([ Rymden_Component_Helpers_Property.classes("title") ])([ Halogen_HTML_Core.text("Settings") ]), (function () {
                  if (state.confirmClear) {
                      return Halogen_HTML_Elements.div_([ Halogen_HTML_Elements.p([  ])([ Halogen_HTML_Core.text("Are you sure? All data will be removed.") ]), Halogen_HTML_Elements.button([ Rymden_Component_Helpers_Property.classes("button is-info is-outlined"), Halogen_HTML_Events.onClick(Data_Function["const"](ClickedCancelClear.value)) ])([ Halogen_HTML_Core.text("Cancel") ]), Halogen_HTML_Elements.button([ Rymden_Component_Helpers_Property.classes("button is-danger") ])([ Halogen_HTML_Core.text("Yes, clear data") ]) ]);
                  };
                  return Halogen_HTML_Elements.button([ Rymden_Component_Helpers_Property.classes("button is-danger"), Halogen_HTML_Events.onClick(Data_Function["const"](ClickedClear.value)) ])([ Halogen_HTML_Core.text("Clear data") ]);
              })() ]) ]);
          };
          var initialState = Data_Function["const"]({
              confirmClear: false
          });
          var $$eval = (function () {
              var handleAction = function (v) {
                  if (v instanceof ClickedClear) {
                      return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                          var $8 = {};
                          for (var $9 in v1) {
                              if ({}.hasOwnProperty.call(v1, $9)) {
                                  $8[$9] = v1[$9];
                              };
                          };
                          $8.confirmClear = true;
                          return $8;
                      });
                  };
                  if (v instanceof ClickedCancelClear) {
                      return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                          var $11 = {};
                          for (var $12 in v1) {
                              if ({}.hasOwnProperty.call(v1, $12)) {
                                  $11[$12] = v1[$12];
                              };
                          };
                          $11.confirmClear = false;
                          return $11;
                      });
                  };
                  if (v instanceof ClickedConfirmClear) {
                      return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
                  };
                  throw new Error("Failed pattern match at Rymden.Page.Settings (line 55, column 20 - line 58, column 39): " + [ v.constructor.name ]);
              };
              return Halogen_Component.mkEval({
                  handleAction: handleAction,
                  handleQuery: Halogen_Component.defaultEval.handleQuery,
                  receive: Halogen_Component.defaultEval.receive,
                  initialize: Halogen_Component.defaultEval.initialize,
                  finalize: Halogen_Component.defaultEval.finalize
              });
          })();
          return Halogen_Component.mkComponent({
              initialState: initialState,
              render: render,
              "eval": $$eval
          });
      };
  };
  exports["component"] = component;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Rymden.Component.Router"] = $PS["Rymden.Component.Router"] || {};
  var exports = $PS["Rymden.Component.Router"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad_State_Class = $PS["Control.Monad.State.Class"];
  var Data_Either = $PS["Data.Either"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Data_Unit = $PS["Data.Unit"];
  var Data_Void = $PS["Data.Void"];
  var Effect = $PS["Effect"];
  var Effect_Class = $PS["Effect.Class"];
  var Halogen_Component = $PS["Halogen.Component"];
  var Halogen_HTML = $PS["Halogen.HTML"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var Halogen_HTML_Elements = $PS["Halogen.HTML.Elements"];
  var Halogen_Query_HalogenM = $PS["Halogen.Query.HalogenM"];
  var Routing_Duplex = $PS["Routing.Duplex"];
  var Routing_Hash = $PS["Routing.Hash"];
  var Rymden_Capability_Navigate = $PS["Rymden.Capability.Navigate"];
  var Rymden_Component_Helpers_Property = $PS["Rymden.Component.Helpers.Property"];
  var Rymden_Data_Route = $PS["Rymden.Data.Route"];
  var Rymden_Page_Home = $PS["Rymden.Page.Home"];
  var Rymden_Page_Settings = $PS["Rymden.Page.Settings"];
  var Type_Proxy = $PS["Type.Proxy"];                
  var Navigate = (function () {
      function Navigate(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Navigate.create = function (value0) {
          return function (value1) {
              return new Navigate(value0, value1);
          };
      };
      return Navigate;
  })();
  var Initialize = (function () {
      function Initialize() {

      };
      Initialize.value = new Initialize();
      return Initialize;
  })();
  var component = function (dictMonadAff) {
      return function (dictMonadEffect) {
          return function (dictMonadStore) {
              return function (dictNavigate) {
                  var render = function (state) {
                      return Halogen_HTML_Elements.div([ Rymden_Component_Helpers_Property.classes("main") ])([ (function () {
                          if (state.route instanceof Data_Maybe.Just && state.route.value0 instanceof Rymden_Data_Route.Home) {
                              return Halogen_HTML.slot()(new Data_Symbol.IsSymbol(function () {
                                  return "home";
                              }))(Data_Ord.ordUnit)(Type_Proxy["Proxy"].value)(Data_Unit.unit)(Rymden_Page_Home.component(dictMonadAff.MonadEffect0())(dictMonadStore))(Data_Unit.unit)(Data_Void.absurd);
                          };
                          if (state.route instanceof Data_Maybe.Just && state.route.value0 instanceof Rymden_Data_Route.Settings) {
                              return Halogen_HTML.slot()(new Data_Symbol.IsSymbol(function () {
                                  return "settings";
                              }))(Data_Ord.ordUnit)(Type_Proxy["Proxy"].value)(Data_Unit.unit)(Rymden_Page_Settings.component(dictMonadAff.MonadEffect0())(dictMonadStore))(Data_Unit.unit)(Data_Void.absurd);
                          };
                          if (state.route instanceof Data_Maybe.Nothing) {
                              return Halogen_HTML_Core.text("404 - Not found");
                          };
                          throw new Error("Failed pattern match at Rymden.Component.Router (line 60, column 9 - line 63, column 47): " + [ state.route.constructor.name ]);
                      })() ]);
                  };
                  var initialState = Data_Function["const"]({
                      route: Data_Maybe.Nothing.value
                  });
                  var $$eval = (function () {
                      var initialize = new Data_Maybe.Just(Initialize.value);
                      var handleQuery = function (query) {
                          return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (v) {
                              return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Applicative.when(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Eq.notEq(Data_Maybe.eqMaybe(Rymden_Data_Route.eqRoute))(v.route)(new Data_Maybe.Just(query.value0)))(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                                  var $12 = {};
                                  for (var $13 in v1) {
                                      if ({}.hasOwnProperty.call(v1, $13)) {
                                          $12[$13] = v1[$13];
                                      };
                                  };
                                  $12.route = new Data_Maybe.Just(query.value0);
                                  return $12;
                              })))(function () {
                                  return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(new Data_Maybe.Just(query.value1));
                              });
                          });
                      };
                      var handleAction = function (action) {
                          return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Data_Functor.map(Effect.functorEffect)((function () {
                              var $19 = Routing_Duplex.parse(Rymden_Data_Route.route);
                              return function ($20) {
                                  return Data_Either.hush($19($20));
                              };
                          })())(Routing_Hash.getHash)))(function (initialRoute) {
                              return Rymden_Capability_Navigate.navigate(Rymden_Capability_Navigate.navigateHalogenM(dictNavigate))(Data_Maybe.fromMaybe(Rymden_Data_Route.Home.value)(initialRoute));
                          });
                      };
                      return Halogen_Component.mkEval({
                          handleAction: handleAction,
                          handleQuery: handleQuery,
                          receive: Halogen_Component.defaultEval.receive,
                          initialize: initialize,
                          finalize: Halogen_Component.defaultEval.finalize
                      });
                  })();
                  return Halogen_Component.mkComponent({
                      initialState: initialState,
                      render: render,
                      "eval": $$eval
                  });
              };
          };
      };
  };
  exports["Navigate"] = Navigate;
  exports["component"] = component;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Rymden.Meta.BuildTime"] = $PS["Rymden.Meta.BuildTime"] || {};
  var exports = $PS["Rymden.Meta.BuildTime"];
  var buildTime = "2021-09-11T09:39:37Z";
  exports["buildTime"] = buildTime;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Rymden.Meta.Version"] = $PS["Rymden.Meta.Version"] || {};
  var exports = $PS["Rymden.Meta.Version"];
  var version = "90e844a111d3a177242218ce7159e747981f7645";
  exports["version"] = version;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.3
  "use strict";
  $PS["Main"] = $PS["Main"] || {};
  var exports = $PS["Main"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect = $PS["Effect"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Console = $PS["Effect.Console"];
  var Halogen_Aff_Util = $PS["Halogen.Aff.Util"];
  var Halogen_Query = $PS["Halogen.Query"];
  var Halogen_VDom_Driver = $PS["Halogen.VDom.Driver"];
  var Routing_Duplex = $PS["Routing.Duplex"];
  var Routing_Hash = $PS["Routing.Hash"];
  var Rymden_AppM = $PS["Rymden.AppM"];
  var Rymden_Capability_ManageSettings = $PS["Rymden.Capability.ManageSettings"];
  var Rymden_Component_Router = $PS["Rymden.Component.Router"];
  var Rymden_Data_Route = $PS["Rymden.Data.Route"];
  var Rymden_Meta_BuildTime = $PS["Rymden.Meta.BuildTime"];
  var Rymden_Meta_Version = $PS["Rymden.Meta.Version"];                
  var main = function __do() {
      Effect_Console.info("Build info: \x0aVersion: " + (Rymden_Meta_Version.version + ("\x0aBuild time: " + Rymden_Meta_BuildTime.buildTime)))();
      return Halogen_Aff_Util.runHalogenAff(Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Rymden_Capability_ManageSettings.loadSettingsFromLocalStorage))(function (settings) {
          var initialStore = {
              settings: settings
          };
          return Control_Bind.bind(Effect_Aff.bindAff)(Rymden_AppM.runAppM(initialStore)(Rymden_Component_Router.component(Rymden_AppM.monadAffAppM)(Rymden_AppM.monadEffectAppM)(Rymden_AppM.monadStoreAppM)(Rymden_AppM.navigateAppM)))(function (rootComponent) {
              return Control_Bind.bind(Effect_Aff.bindAff)(Halogen_Aff_Util.awaitBody)(function (body) {
                  return Control_Bind.bind(Effect_Aff.bindAff)(Halogen_VDom_Driver.runUI(rootComponent)(Data_Unit.unit)(body))(function (halogenIO) {
                      return Data_Functor["void"](Effect_Aff.functorAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Routing_Hash.matchesWith(Data_Foldable.foldableEither)(Routing_Duplex.parse(Rymden_Data_Route.route))(function (old) {
                          return function ($$new) {
                              return Data_Functor["void"](Effect.functorEffect)(Control_Applicative.when(Effect.applicativeEffect)(Data_Eq.notEq(Data_Maybe.eqMaybe(Rymden_Data_Route.eqRoute))(old)(new Data_Maybe.Just($$new)))(Effect_Aff.launchAff_(halogenIO.query(Halogen_Query.mkTell(Rymden_Component_Router.Navigate.create($$new))))));
                          };
                      })));
                  });
              });
          });
      }))();
  };
  exports["main"] = main;
})(PS);
PS["Main"].main();